/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Box, DialogContent, Typography, Button, Alert, LinearProgress } from "@mui/material";
import Modal from "../Modal/Modal";

import { addToast as addToastAction } from "../../actions/toasts";

import {
  deleteBeam as deleteBeamAction
} from "../../actions/beams";

import {
  deleteBeamsLineups as deleteBeamsLineupsAction
} from "../../actions/beams_lineups";

import {
  indexLineups as indexLineupsAction
} from "../../actions/lineups";

import {
  deleteTransponder as deleteTransponderAction
} from "../../actions/transponders";

import {
  listLineups as listLineupsSelector
} from "../../selectors/lineups";

import {
  listBeams as listBeamsSelector
} from "../../selectors/beams";

import {
  listBeamsLineups as listBeamsLineupsSelector
} from "../../selectors/beams_lineups";

import {
  listTransponders as listTranspondersSelector
} from "../../selectors/transponders";

import { isLoading as isLoadingSelector } from "../../selectors/loaders";

import {
  getObjectNameFromLocales
} from "../../helpers/utils";

const initialState = {
  currentStep: 0,
  steps: 0,
};

class RemoveBeamModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      steps: props.currentBeamIds.length - 1
    };
  }

  componentDidMount() {
    const { indexLineups } = this.props;

    indexLineups(null, true);
  }

  componentDidUpdate(prevProps) {
    const {
      currentBeamIds
    } = this.props;

    if (currentBeamIds.length !== prevProps.currentBeamIds.length) {
      this.setState({
        currentStep: 0,
        steps: currentBeamIds.length - 1
      });
    }
  }

  nextStep() {
    const {
      currentStep,
      steps
    } = this.state;

    const {
      addToast
    } = this.props;

    if (currentStep < steps) {
      this.setState({
        currentStep: currentStep + 1
      });
    } else {
      addToast("success", "Success", "Beam removed!");
      this.closeModal();
    }
  }

  async removeBeam() {
    const {
      currentStep
    } = this.state;

    const {
      currentBeamIds,
      beamsLineups,
      transponders,

      deleteBeamsLineups,
      deleteTransponder,
      deleteBeam
    } = this.props;

    if (currentStep < currentBeamIds.length) {
      const currentBeamId = parseInt(currentBeamIds[currentStep], 10);

      // Filter transponders
      const currentTransponders = transponders.filter(t => currentBeamId === t.beam_id);
      // Filter BeamsLineups
      const currentBeamsLineups = beamsLineups.filter(bl => currentBeamId === bl.beam_id);

      // 1. Delete beams_lineups
      for (let i = 0; i < currentBeamsLineups.length; i += 1) {
        const currentBeamLineup = currentBeamsLineups[i];

        await deleteBeamsLineups(currentBeamLineup.id);
      }

      // 2. Delete transponders
      for (let i = 0; i < currentTransponders.length; i += 1) {
        const currentTransponder = currentTransponders[i];

        await deleteTransponder(currentTransponder.id);
      }

      // 3. Delete beam
      await deleteBeam(currentBeamId);
    }

    // Go to the next beam
    // If it is the last beam,
    // nextStep() will close the modal
    this.nextStep();
  }

  closeModal() {
    const { closeBeamModal } = this.props;

    closeBeamModal();
    this.setState(initialState);
  }

  getCurrentBeam() {
    const { currentStep } = this.state;
    const { currentBeamIds, beams } = this.props;

    const currentBeamId = currentBeamIds[currentStep];

    return beams.find(b => b.id === currentBeamId);
  }

  getBeamsLineups() {
    const { beamsLineups } = this.props;

    const currentBeam = this.getCurrentBeam();

    return beamsLineups.filter(bl => bl.beam_id === currentBeam?.id);
  }

  modalContent() {
    const { currentStep, steps } = this.state;
    const { lineups } = this.props;

    const pitch = steps > 0 ? 100 / steps : 100;
    const currentBeam = this.getCurrentBeam();
    const currentBeamsLineups = this.getBeamsLineups();

    return (
      <DialogContent sx={{ display: "flex", gap: 3, flexDirection: "column", marginTop: 1, width: "80%", alignItems: "center", alignSelf: "center" }}>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 2 }}>
          <Typography alignSelf="center">
            {`Beam ${currentStep + 1} of ${steps + 1}`}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={Math.min(currentStep * pitch, 100)}
            sx={{ borderRadius: "10px" }}
          />
        </Box>
        <Typography sx={{ textAlign: "center" }}>{currentBeam?.name}</Typography>
        {!currentBeamsLineups?.length
          ? <Alert severity="info">
            This beam has not any lineup. Deleting will only remove its own beams and transponders
          </Alert>

          : <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Alert severity="warning">
              This beams owns the following lineups. By removing this beam, it will delete its own beams, transponders, lineups, etc...
            </Alert>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5, alignSelf: "flex-start", mt: 3 }}>
              <Typography variant="h5" component="span" sx={{ mb: 2 }}>
                Lineup
              </Typography>
              {
                currentBeamsLineups.map(cbl =>
                  <Typography>
                    {getObjectNameFromLocales(lineups.find(lineup =>
                      lineup.id === cbl.lineup_id
                    ))}
                  </Typography>
                )
              }
            </Box>
          </Box>
        }
      </DialogContent>
    );
  }

  modalActions() {
    return (
      [
        <Button variant="outlined"
          key="cancel"
          onClick={() => {
            this.closeModal();
          }} >
            Cancel
        </Button>,
        <Button variant="contained"
          key="next"
          disabled={ false }
          color="secondary"
          onClick={() => {
            this.nextStep();
          }}>
            Ignore this beam
        </Button>,
        <Button variant="contained"
          key="save"
          color="error"
          onClick={() => {
            this.removeBeam();
          }}>
            Delete it!
        </Button>
      ]
    );
  }

  render() {
    const { modalOpened } = this.props;

    return (
      <Modal
        isOpen={modalOpened}
        closeModal={() => this.closeModal()}
        title="Remove Beam"
        customModalContent={() => this.modalContent()}
        actions={() => this.modalActions()}
        actionAlign="flex-end"
      >
      </Modal>
    );
  }
}

RemoveBeamModal.propTypes = {
  currentBeamIds: PropTypes.array.isRequired,

  modalOpened: PropTypes.bool.isRequired,
  closeBeamModal: PropTypes.func.isRequired,

  beams: PropTypes.array.isRequired,
  beamsLineups: PropTypes.array.isRequired,
  transponders: PropTypes.array.isRequired,
  lineups: PropTypes.array.isRequired,

  addToast: PropTypes.func.isRequired,
  indexLineups: PropTypes.func.isRequired,
  deleteBeamsLineups: PropTypes.func.isRequired,
  deleteTransponder: PropTypes.func.isRequired,
  deleteBeam: PropTypes.func.isRequired,

  isLoading: PropTypes.bool.isRequired
};

const mapDispatchToProps = {
  addToast: addToastAction,
  deleteBeamsLineups: deleteBeamsLineupsAction,
  deleteTransponder: deleteTransponderAction,
  deleteBeam: deleteBeamAction,
  indexLineups: indexLineupsAction
};

function mapStateToProps(state) {
  return {
    beams: listBeamsSelector(state),
    beamsLineups: listBeamsLineupsSelector(state),
    transponders: listTranspondersSelector(state),
    lineups: listLineupsSelector(state),

    isLoading: isLoadingSelector(state, deleteBeamAction.toString())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoveBeamModal);
