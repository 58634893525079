import { handleActions } from "redux-actions";

const initialState = {
  allChannelTiers: [],
  filters: [],
  totalCount: 0
};

const channelTiersReducer = handleActions(
  {
    STORE_CHANNEL_TIERS: (state, action) => ({
      ...state,
      allChannelTiers: action.payload.result,
      totalCount: action.payload.total_count || 0
    }),
    STORE_CHANNELS_TIERS_FILTERS: (state, action) => ({
      ...state,
      filters: action.payload.result
    }),
    ADD_CHANNEL_TIER: (state, action) => {
      const allChannelTiers = [...state.allChannelTiers];

      allChannelTiers.push(action.payload.result);

      return {
        ...state,
        allChannelTiers,
        totalCount: state.totalCount + 1
      };
    },
    MODIFY_CHANNEL_TIER: (state, action) => {
      const newState = {
        ...state,
        allChannelTiers: [...state.allChannelTiers]
      };

      const channelTierIndex = newState.allChannelTiers.findIndex(ct => action.payload.result.id === ct.id);

      newState.allChannelTiers[channelTierIndex] = action.payload.result;

      return newState;
    },
    REMOVE_CHANNEL_TIER: (state, action) => {
      const channelTierIndex = state.allChannelTiers.findIndex(ct => action.payload.id === ct.id);
      const allChannelTiers = Array.from(state.allChannelTiers);

      allChannelTiers.splice(channelTierIndex, 1);

      return {
        ...state,
        allChannelTiers,
        totalCount: channelTierIndex > -1 ? state.totalCount - 1 : state.totalCount
      };
    }
  },
  initialState
);

export default channelTiersReducer;
