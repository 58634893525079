import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Menu, MenuItem, Stack } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { addToast as addToastAction } from "../../actions/toasts";
import {
  indexProviders as indexProvidersAction,
  createProvider as createProviderAction,
  indexProvidersFilters as indexProvidersFiltersAction,
  updateProvider as updateProviderAction,
  deleteProvider as deleteProviderAction
} from "../../actions/providers";
import {
  listProviders as listProvidersSelector,
  listProvidersFilters as listProvidersFiltersSelector,
} from "../../selectors/providers";
import { isLoading as isLoadingSelector } from "../../selectors/loaders";

import EditProviderModal from "../ProvidersModal/EditProviderModal";
import DeleteObjectsModal from "../GlobalObjectsModal/deleteObjectsModal";

import DataTable from "../DataTable";

class Providers extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentProvider: null,

      selectedProvidersIds: [],

      modalOpened: false,
      menuAnchorEl: null,
      openedMenu: null,
      deleteModalOpened: false
    };

    this.deleteProvider = this.deleteProvider.bind(this);
  }

  setSelectedProvidersIds = newSelection => {
    this.setState({
      selectedProvidersIds: newSelection,
    });
  };

  /* Menus methods */

  setMenuAnchorEl = target => {
    this.setState({
      menuAnchorEl: target
    });
  };

  setOpenedMenu = menuId => {
    this.setState({
      openedMenu: menuId
    });
  };

  openMenu = (event, menuId) => {
    this.setMenuAnchorEl(event.currentTarget);
    this.setOpenedMenu(menuId);
  };
  closeMenu = menuId => {
    this.setMenuAnchorEl(null);
    this.setOpenedMenu(menuId);
  };

  /* Edit Modal */

  openEditModal = currentProvider => {
    this.setState({
      currentProvider,
      modalOpened: true
    });
  };

  closeEditModal = () => {
    this.setState({
      currentProvider: null,
      modalOpened: false
    });
  };

  closeDeleteModal = () => {
    this.setState({
      deleteModalOpened: false
    });
  };

  /* Add Modal */

  async deleteProvider(selectedProviders) {
    const { deleteProvider, addToast } = this.props;

    if (selectedProviders.length > 0) {
      let deleted = 0;

      for (let i = 0; i < selectedProviders.length; i += 1) {
        const selectedOb = selectedProviders[i];
        const ret = await deleteProvider(selectedOb);

        if (ret.isSuccess) {
          deleted += 1;
        }
      }

      if (deleted === selectedProviders.length) {
        addToast("success", "Success", "Provider(s) successfully deleted!");
      } else if (deleted === 0) {
        addToast("error", "Error", "Provider(s) cannot be deleted. Try again.");
      } else {
        addToast("warning", "Warning", "Some provider(s) cannot be deleted. Try again.");
      }

      this.setState({
        selectedProvidersIds: [],
        deleteModalOpened: false
      });
    }
  }

  /* Modal */

  render() {
    const {
      currentProvider,
      modalOpened,
      selectedProvidersIds,
      menuAnchorEl,
      openedMenu,
      deleteModalOpened
    } = this.state;

    const {
      providers
    } = this.props;

    const menuOpen = Boolean(menuAnchorEl);

    return (
      <Stack spacing={2}>
        <div>
          <Button
            id="actions-button"
            sx={{
              borderColor: "primary.smokeBorder",
            }}
            variant="outlined"
            aria-controls={menuOpen && openedMenu === "actions-menu" ? "actions-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen && openedMenu === "actions-menu" ? "true" : undefined}
            disableElevation
            onClick={e => this.openMenu(e, "actions-menu")}
            endIcon={<KeyboardArrowDownIcon />}
            disabled={ selectedProvidersIds.length <= 0 }
          >
            Actions
          </Button>
          <Menu
            MenuListProps={{
              "aria-labelledby": "actions-button",
            }}
            id="actions-menu"
            anchorEl={menuAnchorEl}
            open={menuOpen && openedMenu === "actions-menu"}
            onClose={() => this.closeMenu()}
          >
            <MenuItem
              sx={{
                color: "red"
              }}
              onClick={() => {
                this.closeMenu();
                this.setState({
                  deleteModalOpened: true
                });
              }}
              disableRipple
            >
              Delete selected providers
            </MenuItem>
          </Menu>
        </div>
        <div style={{ height: "calc(100vh - 270px)", minHeight: "160px" }}>
          <DataTable
            cols={[
              { headerName: "Id", field: "id", flex: 1 },
              { headerName: "Name", field: "name", flex: 1 },
              { headerName: "Reference Language", field: "reference_language", flex: 1 },
              { headerName: "Modified", field: "updated_at", flex: 1 }
            ]}
            data={providers}
            onRowClick={id => this.openEditModal(id)}
            onRowSelectionModelChange={this.setSelectedProvidersIds}
            checkboxSelection
          />
        </div>

        {/* MODAL */}

        <EditProviderModal
          currentId={currentProvider?.id}
          modalOpened={modalOpened}
          closeEditModal={this.closeEditModal}
        />

        <DeleteObjectsModal
          selectedIds={selectedProvidersIds}
          modalOpened={deleteModalOpened}
          deleteObjects={this.deleteProvider}
          closeDeleteModal={this.closeDeleteModal}
          name="Provider(s)"
        />
      </Stack>
    );
  }
}

Providers.propTypes = {
  providers: PropTypes.arrayOf(PropTypes.object).isRequired,

  deleteProvider: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    providers: listProvidersSelector(state),
    providersFilters: listProvidersFiltersSelector(state),

    isProvidersLoading: isLoadingSelector(state, indexProvidersAction.toString()),
    isProvidersCreating: isLoadingSelector(state, createProviderAction.toString()),
    isProvidersEditing: isLoadingSelector(state, updateProviderAction.toString())
  };
}

const mapDispatchToProps = {
  indexProviders: indexProvidersAction,
  indexProvidersFilters: indexProvidersFiltersAction,
  updateProvider: updateProviderAction,
  deleteProvider: deleteProviderAction,
  addToast: addToastAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Providers);
