import { handleActions } from "redux-actions";

const initialState = {
  allBeams: [],
  filters: [],
  totalCount: 0
};

const beamsReducer = handleActions(
  {
    STORE_BEAMS: (state, action) => ({
      ...state,
      allBeams: action.payload.result,
      totalCount: action.payload.total_count || 0
    }),
    STORE_BEAMS_FILTERS: (state, action) => ({
      ...state,
      filters: action.payload.result
    }),
    ADD_BEAM: (state, action) => {
      const allBeams = [...state.allBeams];

      allBeams.push(action.payload.result);

      return {
        ...state,
        allBeams,
        totalCount: state.totalCount + 1
      };
    },
    MODIFY_BEAM: (state, action) => {
      const newState = {
        ...state
      };

      const beamIndex = newState.allBeams.findIndex(b => action.payload.result.id === b.id);

      newState.allBeams[beamIndex] = action.payload.result;

      return newState;
    },
    REMOVE_BEAM: (state, action) => {
      const beamIndex = state.allBeams.findIndex(b => action.payload.id === b.id);
      const allBeams = Array.from(state.allBeams);

      allBeams.splice(beamIndex, 1);

      return {
        ...state,
        allBeams,
        totalCount: beamIndex > -1 ? state.totalCount - 1 : state.totalCount
      };
    },
    SELECT_ORBITAL_POSITIONS: () => initialState
  },
  initialState
);

export default beamsReducer;
