import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Menu, MenuItem, Stack } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { addToast as addToastAction } from "../../actions/toasts";
import {
  indexServiceTypes as indexServiceTypesAction,
  createServiceType as createServiceTypeAction,
  indexServiceTypesFilters as indexServiceTypesFiltersAction,
  updateServiceType as updateServiceTypeAction,
  deleteServiceType as deleteServiceTypeAction
} from "../../actions/service_types";
import {
  listServiceTypes as listServiceTypesSelector,
  listServiceTypesFilters as listServiceTypesFiltersSelector,
} from "../../selectors/service_types";
import { isLoading as isLoadingSelector } from "../../selectors/loaders";

import EditServiceTypesModal from "../ServiceTypesModal/EditServiceTypeModal";
import DeleteObjectsModal from "../GlobalObjectsModal/deleteObjectsModal";

import DataTable from "../DataTable";

class ServiceTypes extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {

      currentServiceType: null,

      selectedServiceTypesIds: [],

      modalOpened: false,
      menuAnchorEl: null,
      openedMenu: null,
      deleteModalOpened: false
    };

    this.deleteServiceType = this.deleteServiceType.bind(this);
  }

  setSelectedServiceTypesIds = newSelection => {
    this.setState({
      selectedServiceTypesIds: newSelection,
    });
  };

  /* Menus methods */

  setMenuAnchorEl = target => {
    this.setState({
      menuAnchorEl: target
    });
  };

  setOpenedMenu = menuId => {
    this.setState({
      openedMenu: menuId
    });
  };

  openMenu = (event, menuId) => {
    this.setMenuAnchorEl(event.currentTarget);
    this.setOpenedMenu(menuId);
  };
  closeMenu = menuId => {
    this.setMenuAnchorEl(null);
    this.setOpenedMenu(menuId);
  };

  /* Edit Modal */

  openEditModal = currentServiceType => {
    this.setState({
      currentServiceType,
      modalOpened: true
    });
  };

  closeEditModal = () => {
    this.setState({
      currentServiceType: null,
      modalOpened: false
    });
  };

  closeDeleteModal = () => {
    this.setState({
      deleteModalOpened: false
    });
  };

  /* Add Modal */

  async deleteServiceType(selectedServiceTypes) {
    const { deleteServiceType, addToast } = this.props;

    if (selectedServiceTypes.length > 0) {
      let deleted = 0;

      for (let i = 0; i < selectedServiceTypes.length; i += 1) {
        const selectedSt = selectedServiceTypes[i];
        const ret = await deleteServiceType(selectedSt);

        if (ret.isSuccess) {
          deleted += 1;
        }
      }

      if (deleted === selectedServiceTypes.length) {
        addToast("success", "Success", "Service Type(s) successfully deleted!");
      } else if (deleted === 0) {
        addToast("error", "Error", "Service Type cannot be deleted. Try again.");
      } else {
        addToast("warning", "Warning", "Some service type cannot be deleted. Try again.");
      }

      this.setState({
        selectedServiceTypesIds: [],
        deleteModalOpened: false
      });
    }
  }

  /* Modal */

  render() {
    const {
      currentServiceType,
      modalOpened,
      selectedServiceTypesIds,
      menuAnchorEl,
      openedMenu,
      deleteModalOpened
    } = this.state;

    const {
      serviceTypes,
    } = this.props;

    const menuOpen = Boolean(menuAnchorEl);

    return (
      <Stack spacing={2}>
        <div>
          <Button
            id="actions-button"
            sx={{
              borderColor: "primary.smokeBorder",
            }}
            variant="outlined"
            aria-controls={menuOpen && openedMenu === "actions-menu" ? "actions-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen && openedMenu === "actions-menu" ? "true" : undefined}
            disableElevation
            onClick={e => this.openMenu(e, "actions-menu")}
            endIcon={<KeyboardArrowDownIcon />}
            disabled={ selectedServiceTypesIds.length <= 0 }
          >
            Actions
          </Button>
          <Menu
            MenuListProps={{
              "aria-labelledby": "actions-button",
            }}
            id="actions-menu"
            anchorEl={menuAnchorEl}
            open={menuOpen && openedMenu === "actions-menu"}
            onClose={() => this.closeMenu()}
          >
            <MenuItem
              sx={{
                color: "red"
              }}
              onClick={() => {
                this.closeMenu();
                this.setState({
                  deleteModalOpened: true
                });
              }}
              disableRipple
            >
              Delete selected service types
            </MenuItem>
          </Menu>
        </div>
        <div style={{ height: "calc(100vh - 270px)", minHeight: "160px" }}>
          <DataTable
            cols={[
              { headerName: "Id", field: "id", flex: 1 },
              { headerName: "Service Type", field: "name", flex: 1 },
              { headerName: "Modified", field: "updated_at", flex: 1 }
            ]}
            data={serviceTypes}
            onRowClick={id => this.openEditModal(id)}
            onRowSelectionModelChange={this.setSelectedServiceTypesIds}
            checkboxSelection
          />
        </div>

        {/* MODAL */}

        <EditServiceTypesModal
          currentId={currentServiceType?.id}
          modalOpened={modalOpened}
          closeEditModal={this.closeEditModal}
        />

        <DeleteObjectsModal
          selectedIds={selectedServiceTypesIds}
          modalOpened={deleteModalOpened}
          deleteObjects={this.deleteServiceType}
          closeDeleteModal={this.closeDeleteModal}
          name="Service Type(s)"
        />
      </Stack>
    );
  }
}

ServiceTypes.propTypes = {
  serviceTypes: PropTypes.arrayOf(PropTypes.object).isRequired,

  deleteServiceType: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    serviceTypes: listServiceTypesSelector(state),
    serviceTypesFilters: listServiceTypesFiltersSelector(state),

    isServiceTypesLoading: isLoadingSelector(state, indexServiceTypesAction.toString()),
    isServiceTypesCreating: isLoadingSelector(state, createServiceTypeAction.toString()),
    isServiceTypesEditing: isLoadingSelector(state, updateServiceTypeAction.toString())
  };
}

const mapDispatchToProps = {
  indexServiceTypes: indexServiceTypesAction,
  indexServiceTypesFilters: indexServiceTypesFiltersAction,
  updateServiceType: updateServiceTypeAction,
  deleteServiceType: deleteServiceTypeAction,
  addToast: addToastAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceTypes);
