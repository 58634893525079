import React from "react";

import "../../styles/errorPages.css";

export default function PageNotFound() {
  return (
    <div id="page_not_found" className="error_page">
      <div>Page not found</div>
    </div>
  );
}
