/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Box, DialogContent, Button, Alert, TextField } from "@mui/material";
import Modal from "../Modal/Modal";
import withStepper from "../Modal/withStepper";

import { addToast as addToastAction } from "../../actions/toasts";

import {
  updateProvider as updateProviderAction
} from "../../actions/providers";
import { showProvider } from "../../selectors/providers";

import { isLoading as isLoadingSelector } from "../../selectors/loaders";

const initialState = {
  name: "",
  reference_language: "",
};

class EditProviderModal extends PureComponent {
  constructor(props) {
    super(props);

    let state = { ...initialState };

    if (props.provider) {
      const {
        name,
        reference_language
      } = props.provider;

      state = {
        ...state,
        name,
        reference_language
      };
    }

    this.state = { ...state };
  }

  componentDidUpdate(prevProps) {
    const { provider } = this.props;

    if (prevProps.provider !== provider && provider !== null) {
      const {
        name,
        reference_language
      } = provider;

      this.setState({
        name: name || initialState.name,
        reference_language: reference_language || initialState.reference_language
      });
    }
  }

  changeInput(inputName, event) {
    const { value } = event.target;

    this.setState(() => ({ [inputName]: value }), this.validateStep);
  }

  async editProvider() {
    const { currentId, updateProvider, addToast } = this.props;

    const {
      name,
      reference_language
    } = this.state;

    const ret = await updateProvider(currentId, {
      name,
      reference_language
    });

    if (ret.isSuccess) {
      addToast("success", "Success", "Provider successfully updated!");
      this.closeModal();
    } else {
      addToast("error", "Error", "Error while updating provider. Try again.");
    }
  }

  closeModal() {
    const { closeEditModal } = this.props;

    this.setState(initialState);
    closeEditModal();
  }

  isNameValid() {
    const { name } = this.state;
    return name?.length !== 0;
  }

  isReferenceValid() {
    const { reference_language } = this.state;
    return reference_language?.length !== 0 && /([a-z]{2}[_][A-Z]{2})/g.test(reference_language);
  }

  modalContent() {
    const { name, reference_language } = this.state;
    const { isLastStep, stepperComponent } = this.props;

    const isNameValid = this.isNameValid();
    const isReferenceValid = this.isReferenceValid();

    return (
      <DialogContent sx={{ display: "flex", gap: 3, flexDirection: "column", marginTop: 1, width: "80%", alignItems: "center", alignSelf: "center" }}>
        {stepperComponent}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3, width: "100%" }}>
          {!isNameValid && <Alert severity="error">
            Provider name is not valid
          </Alert>}
          <TextField label="Name" inputProps={{ readOnly: isLastStep }} error={!isNameValid} value={name} onChange={e => this.changeInput("name", e)}/>
          {!isReferenceValid && <Alert severity="error">
            {reference_language?.length === 0 ? "Reference Language is not valid" : "Reference Language format is not valid. Use <2letters>_<2LETTERS>"}
          </Alert>}
          <TextField label="Reference language" placeholder="en_GB" inputProps={{ readOnly: isLastStep }} error={!isReferenceValid} value={reference_language} onChange={e => this.changeInput("reference_language", e)}/>
        </Box>
      </DialogContent>
    );
  }

  modalActions() {
    const { currentStep, previousStep, nextStep, isLastStep } = this.props;
    const isNameValid = this.isNameValid();
    const isReferenceValid = this.isReferenceValid();

    return (
      [
        <Button variant="outlined"
          key="cancel"
          onClick={() => {
            this.closeModal();
          }} >
            Cancel
        </Button>,
        currentStep > 0 && <Button variant="outlined"
          key="previous"
          onClick={() => {
            previousStep();
          }}>
            Previous
        </Button>,
        !isLastStep && <Button variant="contained"
          key="next"
          disabled={!isReferenceValid || !isNameValid}
          color="secondary"
          onClick={() => {
            nextStep();
          }}>
            Next
        </Button>,
        isLastStep && <Button variant="contained"
          key="save"
          color="secondary"
          onClick={() => {
            this.editProvider();
          }}>
            Save
        </Button>
      ]
    );
  }

  render() {
    const { modalOpened, provider } = this.props;

    return (
      <Modal
        isOpen={modalOpened}
        closeModal={() => this.closeModal()}
        title={"Edit provider " + provider.name}
        customModalContent={() => this.modalContent()}
        actions={() => this.modalActions()}
        actionAlign="flex-end"
      >
      </Modal>
    );
  }
}

EditProviderModal.defaultProps = {
  currentId: null
};

EditProviderModal.propTypes = {
  currentId: PropTypes.number,

  provider: PropTypes.object.isRequired,

  modalOpened: PropTypes.bool.isRequired,
  closeEditModal: PropTypes.func.isRequired,

  currentStep: PropTypes.number,
  previousStep: PropTypes.func,
  nextStep: PropTypes.func,
  stepperComponent: PropTypes.object,
  isLastStep: PropTypes.bool,

  updateProvider: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    provider: showProvider(state, ownProps.currentId) || {},

    isProviderEditing: isLoadingSelector(state, updateProviderAction.toString())
  };
}

const mapDispatchToProps = {
  updateProvider: updateProviderAction,
  addToast: addToastAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withStepper(
  EditProviderModal, [
    "Set provider information",
    "Sumup"
  ]));
