import { createAction } from "redux-actions";

const storeEpgReports = createAction("STORE_EPG_REPORTS");
const storeEpgReportsDetails = createAction("STORE_EPG_REPORTS_DETAILS");

export const indexEpgReports = createAction("API_INDEX_EPG_REPORTS", (page, per_page) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/epg-reports?page=" + page + "&per_page=" + per_page,
  onSuccessAction: storeEpgReports,
}));

export const indexEpgReportsDetails = createAction("API_INDEX_EPG_REPORTS_DETAILS", id => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/epg-reports/" + id,
  onSuccessAction: storeEpgReportsDetails
}));

export const clearEpgReportsDetails = createAction("CLEAR_EPG_REPORTS_DETAILS");
