export const listEditorialChannels = state => state.editorialChannels.allEditorialChannels;

export const listEditorialChannelsFromOrbitalPosition = state => {
  const editorialChannelsIds = [...new Set(state.services.allServices.map(s => s.channel_id))];

  return state.editorialChannels.allEditorialChannels.filter(ec => editorialChannelsIds.includes(ec.id));
};

export const showEditorialChannel = (state, editorialChannelId) => state.editorialChannels.allEditorialChannels.find(ec => ec.id === editorialChannelId);

export const getTotalCount = state => state.editorialChannels.totalCount;

export const listEditorialChannelsFilters = state => state.editorialChannels.filters;
