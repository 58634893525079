import { handleActions } from "redux-actions";
import DynamoDB from "aws-sdk/clients/dynamodb";

const initialState = {
  exportBackups: [],
  exportBackupsCount: 0,
};

const reduce = (state, action) => ({
  ...state,
  exportBackups: action.payload.result,
  exportBackupsCount: parseInt(action.payload.headers["total-count"])
});

export default handleActions(
  {
    STORE_EXPORT_BACKUPS: reduce
  },
  initialState
);
