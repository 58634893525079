import { createAction } from "redux-actions";

/* Storage actions */
const storeServiceGenres = createAction("STORE_SERVICE_GENRES");
const storeServiceGenresFilters = createAction("STORE_SERVICE_GENRES_FILTERS");
const addServiceGenre = createAction("ADD_SERVICE_GENRE");
const modifyServiceGenre = createAction("MODIFY_SERVICE_GENRE");

/* API actions */
export const indexServiceGenres = createAction("API_INDEX_SERVICE_GENRES", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/genres",
  data,
  fullLoad,
  onSuccessAction: storeServiceGenres
}));

export const indexServiceGenresFilters = createAction("API_INDEX_SERVICE_GENRES_FILTERS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/genres/filters",
  data,
  fullLoad,
  onSuccessAction: storeServiceGenresFilters
}));

export const createServiceGenre = createAction("API_CREATE_SERVICE_GENRE", data => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "POST",
  url: "/genres",
  data,
  onSuccessAction: addServiceGenre
}));

export const updateServiceGenre = createAction("API_UPDATE_SERVICE_GENRE", (serviceGenreId, data) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "PUT",
  url: `/genres/${serviceGenreId}`,
  data,
  onSuccessAction: modifyServiceGenre
}));

export const deleteServiceGenre = createAction("API_DELETE_SERVICE_GENRE", serviceGenreId => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "DELETE",
  url: `/genres/${serviceGenreId}`,
  onSuccessAction: createAction("REMOVE_SERVICE_GENRE", () => ({ id: serviceGenreId }))
}));
