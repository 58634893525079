import { createAction } from "redux-actions";

import { addToast } from "./toasts";

const storeDrivers = createAction("STORE_DRIVERS");
const addDriver = createAction("ADD_DRIVER");
const modifyDriver = createAction("MODIFY_DRIVER");
const storeApiError = createAction("STORE_DRIVER_API_ERROR");

const ENDPOINT = process.env.REACT_APP_SATTV_ENDPOINT;
const url = "/drivers";

export const indexDrivers = createAction("API_INDEX_DRIVERS", () => ({
  method: "GET",
  baseURL: ENDPOINT,
  url,
  onSuccessAction: storeDrivers
}));

export const createDriver = createAction("API_CREATE_DRIVER", data => ({
  method: "POST",
  baseURL: ENDPOINT,
  url,
  data,
  onSuccessAction: addDriver,
  onFailureAction: storeApiError
}));

export const updateDriver = createAction("API_UPDATE_DRIVER", data => ({
  method: "PUT",
  baseURL: ENDPOINT,
  url: `${url}/${data.TargetId}`,
  data,
  onSuccessAction: modifyDriver,
  onFailureAction: storeApiError
}));

export const deleteDriver = createAction("API_DELETE_DRIVER", targetId => ({
  method: "DELETE",
  baseURL: ENDPOINT,
  url: `${url}/${targetId}`,
  onSuccessAction: createAction("REMOVE_DRIVER", () => targetId),
  onFailureAction: storeApiError
}));

export const triggerDriver = createAction("API_TRIGGER_DRIVER", (driver, options) => ({
  method: "POST",
  baseURL: ENDPOINT,
  url: `${url}/${driver.TargetId}/trigger`,
  data: options,
  onSuccessAction: () => addToast("success", "Success", "Successfully triggered driver")
}));
