/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import {
  Box,
  List,
  ListItem,
  ListItemButton,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";

import { ReactComponent as IcoHelp } from "../../assets/icons/help.svg";
import { ReactComponent as IconArrowLeft } from "../../assets/icons/arrow_left.svg";
import { ReactComponent as IconArrowRight } from "../../assets/icons/arrow_right.svg";
import "./Navigation.css";
import { showGlobalModal as showGlobalModalAction } from "../../actions/global_modal";

class Navigation extends PureComponent {
  render() {
    const {
      items,
      currentRoute,
      showGlobalModal,
      shortMenu,
      toggleMenu,
    } = this.props;
    return (
      <Box id="navigation">
        <List className="navigationList">
          {items.map(i => {
            const isActive = currentRoute && currentRoute.route === i.route;

            return (
              <Tooltip
                key={ i.title }
                PopperProps={{
                  sx: {
                    display: shortMenu ? "block" : "none"
                  }
                }}
                title={i.title}
                placement="right"
              >
                <ListItem className="item" disablePadding>
                  <Link to={i.routePath} className="itemLink">
                    <ListItemButton
                      key={i.title}
                      className={`itemButton ${i.className || ""} ${isActive ? " active" : ""}`}
                    >
                      {!!i.icon && <SvgIcon inheritViewBox={true} sx={{ color: isActive ? "secondary.main" : "brandColor" }} fontSize="large" className="navigationIcon" component={i.icon} />}
                      <Typography className="longItem">{i.title.toUpperCase()}</Typography>
                    </ListItemButton>
                  </Link>
                </ListItem>
              </Tooltip>
            );
          })}

          <Tooltip
            componentsProps={{
              popper: {
                sx: { display: shortMenu ? "block" : "none" }
              }
            }}
            title="Need help?"
            placement="right"
          >
            <ListItem className="item" onClick={() => showGlobalModal()} disablePadding>
              <ListItemButton
                className="itemButton itemLink"
              >
                <SvgIcon inheritViewBox={true} sx={{ color: "brandColor" }} fontSize="large" component={IcoHelp} className="navigationIcon" />
                <Typography className="longItem">NEED HELP?</Typography>
              </ListItemButton>
            </ListItem>
          </Tooltip>
        </List>
        <Box className="collapse-button" onClick={() => toggleMenu()}>
          <SvgIcon inheritViewBox={true} component={(shortMenu ? IconArrowRight : IconArrowLeft)} />
          <Typography variant="body1" sx={{ display: shortMenu ? "none" : "block" }}>
            Collapse sidebar
          </Typography>
        </Box>
      </Box>
    );
  }
}

Navigation.defaultProps = {
  items: [],
  currentRoute: null,
};

Navigation.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  currentRoute: PropTypes.shape({
    route: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.string,
    ]),
    private: PropTypes.bool,
    showInMeny: PropTypes.bool,
    component: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.func,
    ]).isRequired,
  }),
  shortMenu: PropTypes.bool.isRequired,
  showGlobalModal: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  showGlobalModal: showGlobalModalAction,
};

export default connect(null, mapDispatchToProps)(Navigation);
