import { createAction } from "redux-actions";

/* Storage actions */
const storeTechChannels = createAction("STORE_TECH_CHANNELS");
const storeTechChannelsFilters = createAction("STORE_TECH_CHANNELS_FILTERS");
const addTechChannel = createAction("ADD_TECH_CHANNEL");
const modifyTechChannel = createAction("MODIFY_TECH_CHANNEL");
export const modifyManyTechChannels = createAction("MODIFY_MANY_TECH_CHANNELS");

/* API actions */
export const indexTechChannels = createAction("API_INDEX_TECH_CHANNELS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/tech_channels",
  data,
  fullLoad,
  onSuccessAction: storeTechChannels
}));

export const indexTechChannelsFilters = createAction("API_INDEX_TECH_CHANNELS_FILTERS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/tech_channels/filters",
  data,
  fullLoad,
  onSuccessAction: storeTechChannelsFilters
}));

export const createTechChannel = createAction("API_CREATE_TECH_CHANNEL", data => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "POST",
  url: "/tech_channels",
  data,
  onSuccessAction: addTechChannel
}));

export const updateTechChannel = createAction("API_UPDATE_TECH_CHANNEL", (techChannelId, data) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "PUT",
  url: `/tech_channels/${techChannelId}`,
  data,
  onSuccessAction: modifyTechChannel
}));

export const deleteTechChannel = createAction("API_DELETE_TECH_CHANNEL", techChannelId => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "DELETE",
  url: `/tech_channels/${techChannelId}`,
  onSuccessAction: createAction("REMOVE_TECH_CHANNEL", () => ({ id: techChannelId }))
}));
export const exportTechChannels = createAction("API_EXPORT_TECHNICAL_CHANNELS", () => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "GET",
  url: "/exports/technical_channels.csv"
}));
