import * as Sentry from "@sentry/react";

const startSentry = () => {
  if (process.env.NODE_ENV === "development") {
    console.info("Monitoring - Sentry is disabled");

    return;
  }

  if (!process.env.REACT_APP_SENTRY_DSN) {
    console.info("Monitoring - Sentry environment variables are not define");

    return;
  }

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 0.5,
    beforeBreadcrumb(breadcrumb, hint) {
      try {
        if (breadcrumb.category.startsWith("ui")) {
          const initialTarget = hint.event.target;
          let targetName = initialTarget.tagName;
          let customMessage = initialTarget.closest("[data-sentry]")?.getAttribute("data-sentry");

          if (!customMessage) {
            const closestButton = initialTarget.closest("button");

            if (closestButton) {
              customMessage = closestButton.ariaLabel || closestButton.name || closestButton.innerText;
              targetName = "BUTTON";
            } else if (["INPUT", "TEXTAREA"].includes(initialTarget.tagName)) {
              customMessage = initialTarget.ariaLabel || initialTarget.name || initialTarget.placeholder;
              targetName = initialTarget.tagName + "-" + initialTarget.type;
            }
          }

          breadcrumb.message = customMessage ? `${targetName}(${customMessage})` : breadcrumb.message;
        }
      } catch (error) {
        Sentry.captureException(error);
      }

      return breadcrumb;
    }

  });
};

export default startSentry;
