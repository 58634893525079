/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import AceEditor from "react-ace";
import { isEqual } from "lodash";

import {
  Alert, Box, Button,
  DialogContent, Fab, FormControl, FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select, Stack,
  Table, TableBody, TableCell, TableHead, TableRow, TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { addToast as addToastAction } from "../../actions/toasts";

import { indexServices as indexServicesAction } from "../../actions/services";
import {
  createLineupsServiceWithServiceAndGenre as createLineupsServiceWithServiceAndGenreAction,
  createLineupsServiceWithGenre as createLineupsServiceWithGenreAction
} from "../../actions/lineups_services";
import { updateTechChannel as updateTechChannelAction } from "../../actions/tech_channels";
import { updateEditorialChannel as updateEditorialChannelAction } from "../../actions/editorial_channels";

import { listChannelTiers } from "../../selectors/channel_tiers";
import { listEditorialChannels } from "../../selectors/editorial_channels";
import { showLineup } from "../../selectors/lineups";
import { listLineupsServicesByLineupId } from "../../selectors/lineups_services";
import { listServices } from "../../selectors/services";
import { listServiceGenres } from "../../selectors/service_genres";
import { listTechChannelsFromOrbitalPosition } from "../../selectors/tech_channels";
import { listTransponders as listTranspondersSelector } from "../../selectors/transponders";
import { isLoading as isLoadingSelector } from "../../selectors/loaders";

import {
  sortObjectByLocale,
  toJsonString,
  capitalize,
  isValidJson,
  isValidLocaleJson,
  getObjectNameFromLocales
} from "../../helpers/utils";
import { getImageAttributes } from "../../helpers/images";
import Modal from "../Modal/Modal";
import withStepper from "../Modal/withStepper";

const initialState = {
  lcns: "",
  channel_tier_id: 0,
  channel_id: 0,
  tech_channel_ids: [],
  service_id: 0,
  genre_ids: [],
  loading: false,
  default_language: "",
  locales: toJsonString("{\"en_GB\":{\"name\":\"\"}}"),

  additionalImages: [],
  additionalImagesPreviews: [],

  globalEditing: false,
  hasFetchingService: false,

  formErrors: []
};

class AddLineupServiceModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      lcns: props.predefinedLCNS || "",
      tech_channel_ids: props.predefinedTechChannels || []
    };

    this.additionalInput = React.createRef();
    this.addAdditional = this.addAdditional.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {
      indexServices,
      orbitalPositionId,
      services,
      isLoadingServices,
      predefinedLCNS,
      predefinedTechChannels
    } = this.props;

    const {
      hasFetchingService
    } = this.state;

    if (!hasFetchingService && orbitalPositionId && services.length === 0 && !isLoadingServices) {
      indexServices({ orbital_position_id: orbitalPositionId }, true);
      this.setState({ hasFetchingService: true });
    }

    if (prevProps.predefinedLCNS !== predefinedLCNS) {
      this.setState({ lcns: predefinedLCNS });
    }

    if (!isEqual(prevProps.predefinedTechChannels.sort(), predefinedTechChannels.sort())) {
      this.setState({ tech_channel_ids: predefinedTechChannels });
    }
  }

  formatLCNS = (lcnsOverride = "") => {
    const {
      lcns
    } = this.state;

    const validLcnsArray = [];
    const lcnsToProcess = lcnsOverride.length > 0 ? lcnsOverride : lcns;

    if (lcnsToProcess.length === 0) {
      return [];
    }

    lcnsToProcess
      .split(",")
      .map(lcn => lcn.trim())
      .filter(e => e)
      .forEach(lcn => {
        const validLcn = lcn.replace(/ /gi, "");

        if (!isNaN(validLcn) && validLcnsArray.indexOf(parseInt(validLcn, 10)) === -1) {
          validLcnsArray.push(parseInt(validLcn, 10));
        }
      });

    validLcnsArray.sort((a, b) => {
      if (a < b) {
        return -1;
      }

      if (a > b) {
        return 1;
      }

      return 0;
    });

    return validLcnsArray;
  };

  changeInput(inputName, event) {
    const {
      default_language
    } = this.state;

    const { value } = event.target;

    this.setState(() => ({ [inputName]: value }), () => {
      if (inputName === "channel_id") {
        this.setState(() => ({ service_id: 0 }), () => {
          if (this.stepIsValid()) {
            this.prefillServiceData();
          }
        });
      } else if (inputName === "locales") {
        let languageFound = true;

        try {
          const localesJson = JSON.parse(value);

          languageFound = Object.keys(localesJson).indexOf(default_language) !== -1;
        } catch {
          languageFound = false;
        }

        if (!languageFound) {
          this.setState({ default_language: initialState.default_language });
        }
      }
    });
  }

  isTechChannelsSelected(techChannelId) {
    const { tech_channel_ids } = this.state;

    return tech_channel_ids.indexOf(parseInt(techChannelId, 10)) !== -1;
  }

  prefillServiceData() {
    const {
      channel_id,
      tech_channel_ids
    } = this.state;

    const {
      services,
      editorialChannels
    } = this.props;

    // Set service data
    const existingService = services.find(s => {
      const serviceTechChannelsIds = s.tech_channels.map(tc => tc.id);

      return parseInt(s.channel_id, 10) === parseInt(channel_id, 10)
        && isEqual(serviceTechChannelsIds.sort(), tech_channel_ids.sort());
    });

    // if a service already exists with selected tech_channels and editorial_channels
    // prefill default_language and locales
    const editorialChannel = editorialChannels.find(ec => ec.id === parseInt(channel_id, 10));

    if (existingService) {
      // use existing service
      // = prefill locales and default_language with service data

      const newLocales = existingService.locales ? existingService.locales : editorialChannel.locales;
      let defaultLanguage = "";

      if (newLocales[existingService.default_language]) {
        defaultLanguage = existingService.default_language;
      } else if (newLocales[editorialChannel.default_language]) {
        defaultLanguage = editorialChannel.default_language;
      } else if (initialState.locales && initialState.locales[initialState.default_language]) {
        defaultLanguage = initialState.default_language;
      }

      this.setState({
        locales: toJsonString(JSON.stringify(newLocales)),
        default_language: defaultLanguage,
        service_id: existingService.id
      });
    } else {
      // create new service
      // = prefill locales and default_language with editorial_channel data

      const newLocales = editorialChannel.locales ? editorialChannel.locales : initialState.locales;
      let defaultLanguage = "";

      if (newLocales && newLocales[editorialChannel.default_language]) {
        defaultLanguage = editorialChannel.default_language;
      } else if (newLocales && newLocales[initialState.default_language]) {
        defaultLanguage = initialState.default_language;
      }

      this.setState({
        locales: toJsonString(JSON.stringify(newLocales)),
        default_language: defaultLanguage,
        service_id: -1
      });
    }
  }

  toggleTechChannelsIds(techChannelId) {
    const { tech_channel_ids } = this.state;

    if (this.isTechChannelsSelected(techChannelId)) {
      const newTechChannelsIds = tech_channel_ids.filter(tcId => tcId !== techChannelId);

      this.setState({ tech_channel_ids: newTechChannelsIds }, () => {
        if (this.stepIsValid()) {
          this.prefillServiceData();
        }
      });
    } else {
      const newTechChannelsIds = tech_channel_ids.slice(0);

      newTechChannelsIds.push(parseInt(techChannelId, 10));

      this.setState({ tech_channel_ids: newTechChannelsIds }, () => {
        if (this.stepIsValid()) {
          this.prefillServiceData();
        }
      });
    }
  }

  isGenreSelected(genreId) {
    const { genre_ids } = this.state;

    return genre_ids.indexOf(parseInt(genreId, 10)) !== -1;
  }

  toggleGenreIds(genreId) {
    const { genre_ids } = this.state;

    if (this.isGenreSelected(genreId)) {
      const newGenreIds = genre_ids.filter(gId => gId !== genreId);

      this.setState({ genre_ids: newGenreIds });
    } else {
      const newGenreIds = genre_ids.slice(0);

      newGenreIds.push(parseInt(genreId, 10));

      this.setState({ genre_ids: newGenreIds });
    }
  }

  async addChannelToLineup() {
    const {
      default_language,
      channel_id,
      locales,

      service_id,
      channel_tier_id,
      tech_channel_ids,

      genre_ids,

      additionalImages
    } = this.state;

    const {
      currentLineupId,
      orbitalPositionId,
      services,
      techChannels,
      editorialChannels,
      createLineupsServiceWithGenre,
      createLineupsServiceWithServiceAndGenre,
      updateEditorialChannel,
      updateTechChannel,
      addToast
    } = this.props;

    this.setState({ globalEditing: true, loading: true });

    const validLcnsArray = this.formatLCNS();
    let name = "";
    let localeJson = {};

    try {
      localeJson = JSON.parse(locales);

      name = localeJson[default_language].name;

      let firstVerification = true;
      let existingService;

      while (firstVerification || existingService) {
        firstVerification = false;
        // eslint-disable-next-line
        existingService = services.some((s) => s.name === name);

        if (existingService) {
          name += " ";
        } else {
          break;
        }
      }
    // eslint-disable-next-line no-empty
    } catch {}

    const logosData = [];

    for (let i = 0; i < additionalImages.length; i += 1) {
      const imageAttributes = await getImageAttributes(additionalImages[i]);
      const logoData = new FormData();

      logoData.append("file", additionalImages[i]);
      logoData.append("delivery", "dvb");
      logoData.append("file_type", imageAttributes.fileType);
      logoData.append("width", imageAttributes.width);
      logoData.append("height", imageAttributes.height);
      logoData.append("additional", true);

      logosData.push(logoData);
    }

    let ret = { isSuccess: false };

    if (parseInt(service_id, 10) === -1) {
      // new service
      ret = await createLineupsServiceWithServiceAndGenre({
        service: {
          orbital_position_id: orbitalPositionId,
          name,
          default_language,
          channel_id,
          locales: localeJson
        },
        techChannels: {
          ids: tech_channel_ids
        },
        lineupService: {
          lineup_id: currentLineupId,
          channel_tier_id,
          lcns: validLcnsArray
        },
        logos: logosData,
        genres: {
          ids: genre_ids
        }
      });
    } else {
      ret = await createLineupsServiceWithGenre({
        lineupService: {
          service_id,
          lineup_id: currentLineupId,
          channel_tier_id,
          lcns: validLcnsArray
        },
        logos: logosData,
        genres: {
          ids: genre_ids
        }
      });
    }

    // Switch techChannels statuses if needed
    for (let i = 0; i < tech_channel_ids.length; i += 1) {
      const techChannel = techChannels.find(tc => tc.id === tech_channel_ids[i]);

      if (techChannel && techChannel.status !== "active") {
        await updateTechChannel(techChannel.id, { status: "active" });
      }
    }

    const editorialChannel = editorialChannels.find(ec => ec.id === parseInt(channel_id, 10));

    if (editorialChannel && editorialChannel.status !== "active") {
      await updateEditorialChannel(channel_id, { status: "active" });
    }

    this.setState({ globalEditing: false, loading: false });

    if (ret.isSuccess) {
      addToast("success", "Success", "Channel successfully added to the lineup!");
      this.closeModal(true, true);
    } else {
      addToast("error", "Error", "Error while adding Channel to the lineup. Try again.");
    }
  }

  closeModal(shouldClearParentState = true, shouldReload = false) {
    const { closeLineupServiceModal } = this.props;

    this.setState(initialState);
    closeLineupServiceModal(shouldClearParentState, shouldReload);
  }

  stepIsValid() {
    const { currentStep } = this.props;

    if (currentStep === 0) {
      return this.isChannelIdValid();
    } else if (currentStep === 1) {
      return this.isTechChannelIdsValid() && this.isTechChannelValid();
    } else if (currentStep === 2) {
      return this.isDefaultLanguageValid() && this.isLocalesJsonValid() && this.isLocalesEntriesValid();
    } else if (currentStep === 3) {
      return this.isLcnsValid() && !this.isLcnsTaken() && this.isChannelTierValid();
    } else if (currentStep === 4) {
      return this.isGenresValid() && this.isGenresChosenValid();
    }

    return true;
  }

  isChannelIdValid() {
    const { channel_id } = this.state;
    const { editorialChannels } = this.props;

    return channel_id && editorialChannels.find(ec => ec.id === parseInt(channel_id, 10));
  }

  isTechChannelIdsValid() {
    const { tech_channel_ids } = this.state;

    return tech_channel_ids && tech_channel_ids.length > 0;
  }

  isTechChannelValid() {
    const { tech_channel_ids } = this.state;
    const { techChannels } = this.props;

    let valid = true;

    tech_channel_ids.forEach(id => {
      if (!techChannels.find(tc => tc.id === parseInt(id, 10)) && !this.isTechChannelIdsValid()) {
        valid = false;
      }
    });

    return valid;
  }

  isDefaultLanguageValid() {
    const { default_language } = this.state;

    return default_language && default_language.length === 5;
  }

  isLocalesJsonValid() {
    const { locales } = this.state;

    return locales && locales.length > 0 && isValidJson(locales) && isValidLocaleJson(locales);
  }

  isLocalesEntriesValid() {
    const { locales, default_language } = this.state;

    if (this.isLocalesJsonValid() && this.isDefaultLanguageValid()) {
      let newDefaultLanguage = default_language;

      if (!JSON.parse(locales)[default_language]) {
        newDefaultLanguage = Object.keys(JSON.parse(locales)).find(localeKey => localeKey.length === 5);
      }

      const currentChannelName = JSON.parse(locales)[newDefaultLanguage].name || "";

      return currentChannelName && currentChannelName.length > 0 && currentChannelName.toString() === currentChannelName;
    }

    return true;
  }

  isLcnsValid() {
    const { lcns } = this.state;
    const validLcnsArray = this.formatLCNS(lcns);

    return validLcnsArray.length > 0;
  }

  isLcnsTaken() {
    const { lcns } = this.state;
    const { lineupServices } = this.props;

    return this.formatLCNS(lcns).some(lcn => lineupServices.find(ls => ls.lcns.indexOf(lcn) !== -1));
  }

  lcnsTaken() {
    const { lcns } = this.state;
    const { lineupServices } = this.props;

    const validLcnsArray = this.formatLCNS(lcns);
    let lcnsError = "";

    validLcnsArray.forEach(lcn => {
      const existingLsInLcn = lineupServices.find(ls => ls.lcns.indexOf(lcn) !== -1);

      if (existingLsInLcn) {
        if (lcnsError.length === 0) {
          lcnsError = `These LCN(s) are already taken: ${lcn}`;
        } else {
          lcnsError += `, ${lcn}`;
        }
      }
    });

    return lcnsError;
  }

  isChannelTierValid() {
    const { channel_tier_id } = this.state;
    const { channelTiers } = this.props;

    return channel_tier_id && channelTiers.find(ct => ct.id === parseInt(channel_tier_id, 10));
  }

  isGenresValid() {
    const { genre_ids } = this.state;

    return genre_ids && !!genre_ids.length;
  }

  isGenresChosenValid() {
    const { genre_ids } = this.state;
    const { serviceGenres } = this.props;

    let valid = true;

    genre_ids.forEach(id => {
      if (!serviceGenres.find(sg => sg.id === parseInt(id, 10)) && this.isGenresValid()) {
        valid = false;
      }
    });

    return valid;
  }

  addAdditional(event) {
    const { additionalImages, additionalImagesPreviews } = this.state;

    if (this.additionalInput.current.files.length > 0) {
      const file = this.additionalInput.current.files[0];

      // eslint-disable-next-line no-param-reassign
      event.target.value = null;

      const newAdditionalImages = [...additionalImages];

      file.id = new Date().valueOf();

      newAdditionalImages.push(file);

      this.setState({ additionalImages: newAdditionalImages });

      const reader = new FileReader();

      reader.onload = e => {
        const newAdditionalImagesPreviews = [...additionalImagesPreviews];

        newAdditionalImagesPreviews.push({
          id: file.id,
          url: e.target.result
        });

        this.setState({
          additionalImagesPreviews: newAdditionalImagesPreviews
        });
      };

      reader.readAsDataURL(file);
    }

    event.preventDefault();
    event.stopPropagation();
  }

  removeAdditional(logoId) {
    const { additionalImages, additionalImagesPreviews } = this.state;
    const newAdditionalImages = additionalImages.filter(l => l.id !== logoId);
    const newAdditionalImagesPreviews = additionalImagesPreviews.filter(l => l.id !== logoId);

    this.setState({
      additionalImages: newAdditionalImages,
      additionalImagesPreviews: newAdditionalImagesPreviews
    });
  }

  triggerLogoInput(event) {
    event.preventDefault();
    event.stopPropagation();

    this.additionalInput.current.click();
  }

  modalContent() {
    const {
      channelTiers,
      editorialChannels,
      serviceGenres,
      techChannels,
      transponders,
      currentStep,
      stepperComponent,
      modalOpened
    } = this.props;

    const {
      lcns,
      channel_tier_id,
      tech_channel_ids,
      service_id,
      channel_id,
      genre_ids,
      default_language,
      locales,

      additionalImagesPreviews,
    } = this.state;

    if (!modalOpened) {
      return;
    }

    const sortedChannelTiers = [...channelTiers].sort(sortObjectByLocale);
    const sortedServiceGenres = [...serviceGenres].sort(sortObjectByLocale);
    const sortedEditorialChannels = [...editorialChannels].sort(sortObjectByLocale);
    const errLcns = this.lcnsTaken();

    const sortedTechChannels = techChannels
      .filter(tc => tc.status === "active" || tc.status === "pending" || tc.status === "transfer")
      .sort((a, b) => {
        if (a.service_name.toLowerCase() < b.service_name.toLowerCase()) {
          return -1;
        }

        if (a.service_name.toLowerCase() > b.service_name.toLowerCase()) {
          return 1;
        }

        return 0;
      });

    let computedDefaultLanguages;

    try {
      computedDefaultLanguages = Object.keys(JSON.parse(locales)).filter(l => l.length === 5);
    } catch {
      computedDefaultLanguages = [];
    }

    return <DialogContent sx={{ display: "flex", gap: 3, flexDirection: "column", marginTop: 1, width: "80%", alignItems: "center", alignSelf: "center" }}>
      {stepperComponent}

      {/* STEP 1:  Editorial Channel */}
      {currentStep === 0 && <>
        <Alert severity="info">Editorial Channel contains all EPG data</Alert>
        <FormControl required error={!this.isChannelIdValid()} fullWidth>
          <InputLabel id="associated-channel-select" focused>Associated Editorial Channel</InputLabel>
          <Select
            labelId="associated-channel-select"
            label="Associated Editorial Channel"
            value={channel_id || 0}
            onChange={e => this.changeInput("channel_id", e)}
            notched
            displayEmpty
          >
            <MenuItem value={0} disabled>Select a channel</MenuItem>
            {sortedEditorialChannels.map(ec => <MenuItem key={ec.id} value={ec.id.toString()}>{getObjectNameFromLocales(ec)}</MenuItem>)}
          </Select>
          { !this.isChannelIdValid() && <FormHelperText error>Editorial channel is not valid</FormHelperText> }
        </FormControl>
      </>}

      {/* STEP 2: Tech Channels */}
      {currentStep === 1 && <Stack spacing={3}>
        {!!tech_channel_ids.length && <Stack spacing={3} alignItems="center">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Selected Tech Channels</TableCell>
                <TableCell>Transponder</TableCell>
                <TableCell>SID</TableCell>
                <TableCell>Backend Channel ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tech_channel_ids.map(tcId => {
                const techChannel = techChannels.find(tc => tc.id === tcId) || {};
                const transponder = transponders.find(t => t.id === techChannel.transponder_id);

                return (
                  <TableRow key={techChannel.id}>
                    <TableCell>
                      <CloseIcon sx={{ cursor: "pointer" }} color="text.grey" fontSize="small" onClick={() => this.toggleTechChannelsIds(techChannel.id)} />
                    </TableCell>
                    <TableCell>{techChannel.service_name}</TableCell>
                    <TableCell>{transponder.name || ""}</TableCell>
                    <TableCell>{techChannel.sid || "-"}</TableCell>
                    <TableCell>{techChannel.backend_channel_id || "-"}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Stack>}
        <FormControl required error={!this.isTechChannelValid() || !this.isTechChannelValid()} fullWidth>
          <InputLabel id="tech-channels-select" focused>Tech Channels</InputLabel>
          <Select
            sx={{ maxWidth: "100%" }}
            labelId="tech-channels-select"
            label="Tech Channels"
            value={tech_channel_ids || []}
            renderValue={() => "Select one or many tech channels"}
            multiple
            notched
            displayEmpty
          >
            <MenuItem value={0} disabled>Select a channel</MenuItem>
            {sortedTechChannels.map(tc => {
              const transponder = transponders.find(t => t.id === tc.transponder_id);
              let tech_channel_label = tc.service_name;

              if (tc.tech_channel_type === "dvb") {
                let tName = `(tsid:${tc.tsid}) (onid:${tc.onid})`;

                if (transponder) {
                  tName = `(TxP name:${transponder.name})`;
                }

                tech_channel_label += ` ${tName} (sid:${tc.sid})`;
              } else if (tc.tech_channel_type === "ott") {
                tech_channel_label += ` (backend_channel:${tc.backend_channel_id})`;
              }

              return (
                <MenuItem key={tc.id} onClick={() => this.toggleTechChannelsIds(tc.id)} value={tc.id}>
                  {tech_channel_label}
                </MenuItem>
              );
            })}
          </Select>
          { !this.isTechChannelIdsValid() && <FormHelperText error>Select at least one Tech Channel</FormHelperText> }
          { !this.isTechChannelValid() && <FormHelperText error>Tech channel is not valid</FormHelperText> }
        </FormControl>
      </Stack>}

      {/* STEP 3: Locales */}
      {currentStep === 2 && <Stack spacing={3} width="100%">
        {!!service_id && <>
          <FormControl error={!this.isDefaultLanguageValid()} fullWidth required>
            <InputLabel id="default-language-select">Default language</InputLabel>
            <Select
              labelId="default-language-select"
              label="Default language"
              onChange={e => this.changeInput("default_language", e)}
              value={default_language || ""}
            >
              {computedDefaultLanguages.map(dl => <MenuItem key={dl} value={dl}>{dl}</MenuItem>)}
            </Select>
            { !this.isDefaultLanguageValid() && <FormHelperText error>You must define locale json to select a default language</FormHelperText> }
          </FormControl>
          <div>
            <AceEditor
              width="100%"
              mode="json"
              theme="tomorrow"
              fontSize="0.9rem"
              name="lineups-services-add-locales-edit"
              height="300px"
              setOptions={{
                useWorker: false,
                showLineNumbers: false
              }}
              value={locales}
              onChange={value => this.changeInput("locales", { target: { value } })}
              editorProps={{ $blockScrolling: true }}
            />
            {!this.isLocalesJsonValid()
              && <FormHelperText error>Locales json is not valid. Locales json should contain language keys with length equals to 5 characters</FormHelperText>}
            {!this.isLocalesEntriesValid()
              && <FormHelperText error>You have to define a name entry in the locales JSON. For example: {"{\"en_GB\": {\"name\": \"Lineup\", \"description\": \"\"}}"}</FormHelperText>}
          </div>
        </>}
      </Stack>}

      {/* STEP 4: INFORMATIONS */}
      {currentStep === 3 && <Stack spacing={3}>
        <Alert severity="info">You can add several LCNs. You just have to separate them with a comma (for example: “4, 145, 23”)</Alert>
        <Box>
          <TextField label="LCNS" value={lcns || ""} error={!this.isLcnsValid() || this.isLcnsTaken()} onChange={e => this.changeInput("lcns", e)} required fullWidth />
          { !this.isLcnsValid() && <FormHelperText error>LCNs are not valid</FormHelperText> }
          { this.isLcnsTaken() && <FormHelperText error>{errLcns}</FormHelperText> }
        </Box>
        <FormControl error={!this.isChannelTierValid()} fullWidth required>
          <InputLabel id="channel-tier-select">Channel Tiers</InputLabel>
          <Select
            labelId="channel-tier-select"
            label="Channel Tiers"
            onChange={e => this.changeInput("channel_tier_id", e)}
            value={channel_tier_id || ""}
          >
            {sortedChannelTiers.map(ct => <MenuItem key={ct.id} value={ct.id}>{capitalize(ct.name)}</MenuItem>)}
          </Select>
          { !this.isChannelTierValid() && <FormHelperText error>Channel Tier is not valid</FormHelperText> }
        </FormControl>
      </Stack>}

      {/* STEP 5: GENRES */}
      {currentStep === 4 && <Stack direction="row" alignItems="center" spacing={3}>
        <FormControl required error={!this.isGenresValid() || !this.isGenresChosenValid()} fullWidth>
          <InputLabel id="genres-select" focused>Genres</InputLabel>
          <Select
            sx={{ maxWidth: "100%" }}
            labelId="genres-select"
            label="Genres"
            value={genre_ids || []}
            renderValue={() => "Select one or many genres"}
            multiple
            notched
            displayEmpty
          >
            {sortedServiceGenres.map(sg =>
              <MenuItem key={sg.id} onClick={() => this.toggleGenreIds(sg.id)} value={sg.id}>
                {getObjectNameFromLocales(sg)}
              </MenuItem>
            )}
          </Select>
          { !this.isGenresValid() && <FormHelperText error>Select at least one genre</FormHelperText> }
          { !this.isGenresChosenValid() && <FormHelperText error>Genre is not valid</FormHelperText> }
        </FormControl>
        {!!genre_ids.length && <Stack sx={{ width: "100%" }} spacing={3}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Selected Genres</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {genre_ids.map(sgId => {
                const genre = serviceGenres.find(sg => sg.id === sgId);

                return (
                  <TableRow key={genre.id}>
                    <TableCell>
                      <CloseIcon onClick={() => this.toggleGenreIds(genre.id)} />
                    </TableCell>
                    <TableCell>{getObjectNameFromLocales(genre)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Stack>}
      </Stack>}

      {/* STEP 6: ADDITIONAL RESOURCES */}
      {currentStep === 5 && <Stack spacing={3}>
        <Alert severity="info">
          <Typography variant="body2" align="center">
            Additional resource(s) can be used to provide ads. Technically, it will be exported in the Eutelsat Additional Resources file (EAR json)
          </Typography>
        </Alert>
        <Stack direction="row" spacing={3} justifyContent="center">
          {additionalImagesPreviews.map(logo => <Paper
            sx={{
              backgroundColor: "text.grey",
              width: "100px",
              height: "100px",
              position: "relative"
            }}
            key={logo.id}
            elevation={3}
          >
            <Fab
              sx={{
                position: "absolute",
                bottom: "-15px",
                right: "-15px"
              }}
              size="small"
              onClick={() => this.removeAdditional(logo.id)}>
              <DeleteIcon />
            </Fab>
            <img style={{ width: "100%", position: "absolute", top: "0", bottom: "0", margin: "auto" }} src={logo.url} alt="" />
          </Paper>)}
          <Paper
            sx={{
              backgroundColor: "text.grey",
              width: "100px",
              height: "100px",
              position: "relative"
            }}
            elevation={3}
          >
            <Fab
              sx={{
                position: "absolute",
                bottom: "-15px",
                right: "-15px"
              }}
              size="small"
              onClick={e => this.triggerLogoInput(e)}
            >
              <AddIcon />
            </Fab>
            <input
              style={{ display: "none" }}
              type="file"
              id="add-logo"
              accept="image/png, image/jpeg"
              ref={this.additionalInput}
              onChange={this.addAdditional}
            />
          </Paper>
        </Stack>
      </Stack>}

      {/* STEP 6: SUMUP */}
      {currentStep === 6 && <Stack spacing={3} alignItems="center">
        <Stack direction="row" spacing={3}>
          <Stack spacing={2}>
            <TextField sx={{ input: { cursor: "default" } }} label="Associated channel" InputLabelProps={{ focused: true }} value={getObjectNameFromLocales(sortedEditorialChannels.find(ec => ec.id === parseInt(channel_id, 10)))} fullWidth></TextField>
            <TextField sx={{ input: { cursor: "default" } }} label="Default language" InputLabelProps={{ focused: true }} value={default_language} fullWidth></TextField>
            <AceEditor
              style={{ maxWidth: "300px" }}
              mode="json"
              theme="tomorrow"
              fontSize="0.9rem"
              name="lineups-services-add-locales-edit"
              height="200px"
              setOptions={{
                useWorker: false,
                showLineNumbers: false
              }}
              value={locales}
              onChange={value => this.changeInput("locales", { target: { value } })}
              editorProps={{ $blockScrolling: true }}
            />
          </Stack>
          <Stack spacing={2}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Technical Channel</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tech_channel_ids.map(tech_channel_id => {
                  const techChannel = sortedTechChannels.find(tc => tc.id === parseInt(tech_channel_id, 10));
                  let tech_channel_label = techChannel.service_name;

                  if (techChannel.tech_channel_type === "dvb") {
                    tech_channel_label += ` (tsid:${techChannel.tsid}) (onid:${techChannel.onid}) (sid:${techChannel.sid})`;
                  } else if (techChannel.tech_channel_type === "ott") {
                    tech_channel_label += ` (backend_channel:${techChannel.backend_channel_id})`;
                  }

                  return <TableRow key={tech_channel_id}><TableCell><Typography variant="body2">{tech_channel_label}</Typography></TableCell></TableRow>;
                })}
              </TableBody>
            </Table>
            <TextField sx={{ input: { cursor: "default" } }} label="LCNS" InputLabelProps={{ focused: true }} value={lcns} fullWidth></TextField>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Genres</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {genre_ids.map(genreId => {
                  const genre = serviceGenres.find(sg => sg.id === parseInt(genreId, 10));

                  return <TableRow key={genreId}><TableCell><Typography variant="body2">{getObjectNameFromLocales(genre)}</Typography></TableCell></TableRow>;
                })}
              </TableBody>
            </Table>
            <TextField sx={{ input: { cursor: "default" } }} label="Channel Tier" InputLabelProps={{ focused: true }} value={sortedChannelTiers.find(sg => sg.id === parseInt(channel_tier_id, 10)).name} fullWidth></TextField>
          </Stack>
        </Stack>
        {additionalImagesPreviews.length > 0 && <>
          <Typography variant="body2">Additional resource(s)</Typography>
          <Stack direction="row" spacing={3}>
            {additionalImagesPreviews.map(logo => <Paper
              sx={{
                backgroundColor: "text.grey",
                width: "100px",
                height: "100px",
                position: "relative"
              }}
              key={logo.id}
              elevation={3}
            >
              <img style={{ width: "100%", position: "absolute", top: "0", bottom: "0", margin: "auto" }} src={logo.url} alt="" />
            </Paper>)}
          </Stack>
        </>}
      </Stack>}
    </DialogContent>;
  }

  modalActions() {
    const { currentStep, previousStep, nextStep, isLastStep } = this.props;
    const { loading } = this.state;
    const stepIsValid = this.stepIsValid();

    return (
      [
        <Button
          variant="outlined"
          key="cancel"
          onClick={() => {
            this.closeModal();
          }}>
          Cancel
        </Button>,
        currentStep > 0
        && <Button
          variant="outlined"
          key="previous"
          onClick={() => {
            previousStep();
          }}>
          Previous
        </Button>,
        !isLastStep
        && <Button
          variant="contained"
          color="secondary"
          key="next"
          disabled={!stepIsValid}
          onClick={() => {
            nextStep();
          }}>
          Next
        </Button>,
        isLastStep
        && <Button
          variant="contained"
          color="secondary"
          key="save"
          disabled={loading}
          onClick={() => {
            this.addChannelToLineup();
          }}>
          { loading ? "Loading..." : "Save" }
        </Button>
      ]
    );
  }

  render() {
    const {
      modalOpened,
      lineup,
    } = this.props;

    const currentLineupName = getObjectNameFromLocales(lineup);

    return (
      <Modal
        closeModal={() => this.closeModal()}
        isOpen={modalOpened}
        title={`Add channel in the lineup ${currentLineupName}`}
        customModalContent={() => this.modalContent()}
        actions={() => this.modalActions()}
        actionAlign="flex-end"
      />
    );
  }
}

AddLineupServiceModal.defaultProps = {
  currentLineupId: null,
  orbitalPositionId: null,
  predefinedLCNS: "",
  predefinedTechChannels: [],
  lineup: null,
  channelTiers: [],
  editorialChannels: [],
  lineupServices: [],
  services: [],
  serviceGenres: [],
  techChannels: [],
  transponders: [],

  switchToEditMode: null
};

AddLineupServiceModal.propTypes = {
  modalOpened: PropTypes.bool.isRequired,

  currentLineupId: PropTypes.number,
  orbitalPositionId: PropTypes.number,

  predefinedLCNS: PropTypes.string,
  predefinedTechChannels: PropTypes.arrayOf(PropTypes.number),

  lineup: PropTypes.object,

  channelTiers: PropTypes.arrayOf(PropTypes.object),
  editorialChannels: PropTypes.arrayOf(PropTypes.object),
  lineupServices: PropTypes.arrayOf(PropTypes.object),
  services: PropTypes.arrayOf(PropTypes.object),
  serviceGenres: PropTypes.arrayOf(PropTypes.object),
  techChannels: PropTypes.arrayOf(PropTypes.object),
  transponders: PropTypes.arrayOf(PropTypes.object),

  closeLineupServiceModal: PropTypes.func.isRequired,
  switchToEditMode: PropTypes.func,
  indexServices: PropTypes.func.isRequired,
  updateTechChannel: PropTypes.func.isRequired,
  updateEditorialChannel: PropTypes.func.isRequired,
  createLineupsServiceWithGenre: PropTypes.func.isRequired,
  createLineupsServiceWithServiceAndGenre: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,

  currentStep: PropTypes.number,
  previousStep: PropTypes.func,
  nextStep: PropTypes.func,
  stepperComponent: PropTypes.object,
  isLastStep: PropTypes.bool,

  isLoadingServices: PropTypes.bool.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    lineup: showLineup(state, ownProps.currentLineupId),
    channelTiers: listChannelTiers(state),
    editorialChannels: listEditorialChannels(state),
    lineupServices: listLineupsServicesByLineupId(state, ownProps.currentLineupId),
    services: listServices(state),
    serviceGenres: listServiceGenres(state),
    techChannels: listTechChannelsFromOrbitalPosition(state, ownProps.orbitalPositionId),
    transponders: listTranspondersSelector(state),

    isLoadingServices: isLoadingSelector(state, indexServicesAction.toString())
  };
}

const mapDispatchToProps = {
  createLineupsServiceWithServiceAndGenre: createLineupsServiceWithServiceAndGenreAction,
  createLineupsServiceWithGenre: createLineupsServiceWithGenreAction,
  indexServices: indexServicesAction,
  updateTechChannel: updateTechChannelAction,
  updateEditorialChannel: updateEditorialChannelAction,
  addToast: addToastAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withStepper(
  AddLineupServiceModal, [
    "Select an editorial channel",
    "Select one or many tech channels",
    "Set locales information",
    "Set informations",
    "Select one or many genres",
    "Add additional resource(s)",
    "Sumup"
  ]));
