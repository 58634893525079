import { handleActions } from "redux-actions";

const initialState = {
  allTechChannels: [],
  filters: [],
  totalCount: 0
};

const techChannelsReducer = handleActions(
  {
    STORE_TECH_CHANNELS: (state, action) => ({
      ...state,
      allTechChannels: action.payload.result,
      totalCount: action.payload.total_count || 0
    }),
    STORE_TECH_CHANNELS_FILTERS: (state, action) => ({
      ...state,
      filters: action.payload.result
    }),
    ADD_TECH_CHANNEL: (state, action) => {
      const allTechChannels = [...state.allTechChannels];

      allTechChannels.push(action.payload.result);

      return {
        ...state,
        allTechChannels,
        totalCount: state.totalCount + 1
      };
    },
    MODIFY_TECH_CHANNEL: (state, action) => {
      const newState = {
        ...state
      };

      const techChannelsIndex = newState.allTechChannels.findIndex(tc => action.payload.result.id === tc.id);

      newState.allTechChannels[techChannelsIndex] = action.payload.result;

      return newState;
    },
    MODIFY_MANY_TECH_CHANNELS: (state, { payload }) => {
      const newState = {
        ...state
      };

      payload.forEach(techChannel => {
        const techChannelsIndex = newState.allTechChannels.findIndex(tc => techChannel.id === tc.id);

        newState.allTechChannels[techChannelsIndex] = techChannel;
      });

      return newState;
    },
    REMOVE_TECH_CHANNEL: (state, action) => {
      const techChannelsIndex = state.allTechChannels.findIndex(tc => action.payload.id === tc.id);
      const allTechChannels = Array.from(state.allTechChannels);

      allTechChannels.splice(techChannelsIndex, 1);

      return {
        ...state,
        allTechChannels,
        totalCount: techChannelsIndex > -1 ? state.totalCount - 1 : state.totalCount
      };
    },
    SELECT_ORBITAL_POSITIONS: () => initialState
  },
  initialState
);

export default techChannelsReducer;
