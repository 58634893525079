import { handleActions } from "redux-actions";

const initialState = {
  allBeamsLineups: [],
  filters: [],
  totalCount: 0
};

const beamsLineupsReducer = handleActions(
  {
    STORE_BEAMS_LINEUPS: (state, action) => ({
      ...state,
      allBeamsLineups: action.payload.result,
      totalCount: action.payload.total_count || 0
    }),
    STORE_BEAMS_LINEUPS_FILTERS: (state, action) => ({
      ...state,
      filters: action.payload.result
    }),
    ADD_BEAMS_LINEUPS: (state, action) => {
      const allBeamsLineups = [...state.allBeamsLineups];

      allBeamsLineups.push(action.payload.result);

      return {
        ...state,
        allBeamsLineups,
        totalCount: state.totalCount + 1
      };
    },
    MODIFY_BEAMS_LINEUPS: (state, action) => {
      const newState = {
        ...state
      };

      const beamLineupIndex = newState.allBeamsLineups.findIndex(bl => action.payload.result.id === bl.id);

      newState.allBeamsLineups[beamLineupIndex] = action.payload.result;

      return newState;
    },
    REMOVE_BEAM_LINEUPS: (state, action) => {
      const beamLineupIndex = state.allBeamsLineups.findIndex(b => action.payload.id === b.id);
      const allBeamsLineups = Array.from(state.allBeamsLineups);

      allBeamsLineups.splice(beamLineupIndex, 1);

      return {
        ...state,
        allBeamsLineups,
        totalCount: beamLineupIndex > -1 ? state.totalCount - 1 : state.totalCount
      };
    },
    REMOVE_LINEUP: (state, action) => {
      const beamLineupIndex = state.allBeamsLineups.findIndex(l => action.payload.id === l.lineup_id);
      const allBeamsLineups = Array.from(state.allBeamsLineups);

      allBeamsLineups.splice(beamLineupIndex, 1);

      return {
        ...state,
        allBeamsLineups,
        totalCount: beamLineupIndex > -1 ? state.totalCount - 1 : state.totalCount
      };
    },
    SELECT_ORBITAL_POSITIONS: () => initialState
  },
  initialState
);

export default beamsLineupsReducer;
