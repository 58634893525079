import { handleActions } from "redux-actions";

const initialState = {
  drivers: [],
  adapters: [],
  apiError: null
};

const reducer = handleActions({
  STORE_DRIVER_API_ERROR: (state, action) => ({
    ...state,
    apiError: action.payload
  }),
  STORE_ADAPTERS: (state, action) => ({
    ...state,
    adapters: action.payload.result
  }),
  STORE_DRIVERS: (state, action) => ({
    ...state,
    drivers: action.payload.result
  }),
  ADD_DRIVER: (state, action) => ({
    ...state,
    drivers: state.drivers.concat(action.payload.result),
    apiError: null
  }),
  MODIFY_DRIVER: (state, action) => {
    const driverIndex = state.drivers.findIndex(d => d.TargetId === action.payload.result.TargetId);
    const newDrivers = [...state.drivers];

    newDrivers[driverIndex] = action.payload.result;

    return {
      ...state,
      drivers: newDrivers,
      apiError: null
    };
  },
  REMOVE_DRIVER: (state, action) => {
    const driverIndex = state.drivers.findIndex(d => d.TargetId === action.payload);
    const newDrivers = [...state.drivers];

    newDrivers.splice(driverIndex, 1);

    return {
      ...state,
      drivers: newDrivers,
      apiError: null
    };
  }
},
initialState);

export default reducer;
