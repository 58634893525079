import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Card, CardContent, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router";
import dayjs from "dayjs";

import { triggerReplication as triggerReplicationAction } from "../../actions/replications";

import FullLoader from "../../components/FullLoader";

import {
  indexDrivers as indexDriversAction
} from "../../actions/drivers";

import { listProvidersWordings as listProvidersWordingsSelector } from "../../selectors/providers_wordings";

class Dashboard extends PureComponent {
  componentDidMount() {
    this.loadDrivers();
  }

  loadDrivers() {
    const {
      indexDrivers,
      isDriversLoading
    } = this.props;

    if (!isDriversLoading) {
      indexDrivers();
    }
  }

  goToProviderWordings(e) {
    e.stopPropagation();
    e.preventDefault();

    this.props.router.navigate("/notifications/providers_wordings");
  }

  launchReplication(e) {
    e.preventDefault();
    if (window.confirm("Are you sure about this? It could break the application for mulitple hours.")) {
      const { triggerReplication } = this.props;
      triggerReplication();
    }
  }

  render() {
    const {
      drivers,
      providersWordings,
      isDriversLoading
    } = this.props;

    const sortedDrivers = drivers.drivers.filter(d => d.State === "ENABLED").sort((a, b) => a.NextExecution.localeCompare(b.NextExecution));

    const nullProvidersWordings = providersWordings.filter(pw => pw.genre_id === null);

    return (
      <div id="dashboard" className="page-content">
        {!!isDriversLoading && <FullLoader />}
        {!isDriversLoading
          && <>
            <div className="row">
              <div className="flex-grow mr1">
                <Card
                  elevation={ 12 }
                  p={ 2 }
                >
                  <CardContent>
                    <Typography variant="subtitle1" mb={ 1 }>Next Drivers Executions</Typography>
                    { sortedDrivers.length === 0
                      ? <Typography paragraph>No driver present or enabled</Typography>
                      : sortedDrivers.map((d, idx) => <div key={idx} className="row card-driver-execution-description">
                        <div className="column flex-grow flex-equal-row-size">{d.Name}</div>
                        <div className="column flex-grow flex-equal-row-size">
                          {dayjs(d.NextExecution).format("DD/MM/YYYY HH:mm") + " (your timezone)"}
                        </div>
                      </div>
                      )}
                  </CardContent>
                </Card>
              </div>
              <div className="flex-grow ml1">
                <Card
                  elevation={ 12 }
                  p={ 2 }
                  className="pointer"
                  onClick={e => this.goToProviderWordings(e)}
                >
                  <CardContent>
                    <Typography variant="subtitle1" mb={ 1 }>
                      Provider Wordings <span className="notif-badge-span">{nullProvidersWordings.length}</span>
                    </Typography>
                    <Typography paragraph>
                      { nullProvidersWordings.length === 1
                        ? "There is one provider wording to process"
                        : `There are ${nullProvidersWordings.length} provider wordings to process`
                      }
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </div>
          </>
        }
      </div>
    );
  }
}

Dashboard.propTypes = {
  drivers: PropTypes.exact({
    drivers: PropTypes.array.isRequired,
    adapters: PropTypes.array.isRequired,
    apiError: PropTypes.object
  }).isRequired,
  providersWordings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isDriversLoading: PropTypes.bool.isRequired,
  indexDrivers: PropTypes.func.isRequired,
  triggerReplication: PropTypes.func.isRequired,
  router: PropTypes.shape({
    navigate: PropTypes.func
  })
};

function mapStateToProps(state) {
  return {
    drivers: state.drivers,
    providersWordings: listProvidersWordingsSelector(state),
    isDriversLoading: !!state.loaders.actions.find(a => a === indexDriversAction.toString())
  };
}

const mapDispatchToProps = {
  indexDrivers: indexDriversAction,
  triggerReplication: triggerReplicationAction
};

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
