import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Menu, MenuItem, Stack } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { addToast as addToastAction } from "../../actions/toasts";
import {
  indexChannelTiers as indexChannelTiersAction,
  createChannelTier as createChannelTierAction,
  indexChannelTiersFilters as indexChannelTiersFiltersAction,
  updateChannelTier as updateChannelTierAction,
  deleteChannelTier as deleteChannelTierAction
} from "../../actions/channel_tiers";
import {
  listChannelTiers as listChannelTiersSelector,
  listChannelTiersFilters as listChannelTiersFiltersSelector
} from "../../selectors/channel_tiers";
import { isLoading as isLoadingSelector } from "../../selectors/loaders";

import EditChannelTiersModal from "../ChannelTiersModal/EditChannelTierModal";
import DeleteObjectsModal from "../GlobalObjectsModal/deleteObjectsModal";

import DataTable from "../DataTable";

const attributesStatus = item => item.value
  ? <CheckIcon />
  : <CloseIcon />;

class ChannelTiers extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentChannel: null,

      selectedChannelTiersIds: [],

      modalOpened: false,
      menuAnchorEl: null,
      openedMenu: null,
      deleteModalOpened: false
    };

    this.deleteChannelTier = this.deleteChannelTier.bind(this);
  }

  setSelectedChannelTiersIds = newSelection => {
    this.setState({
      selectedChannelTiersIds: newSelection,
    });
  };

  /* Menus methods */

  setMenuAnchorEl = target => {
    this.setState({
      menuAnchorEl: target
    });
  };

  setOpenedMenu = menuId => {
    this.setState({
      openedMenu: menuId
    });
  };

  openMenu = (event, menuId) => {
    this.setMenuAnchorEl(event.currentTarget);
    this.setOpenedMenu(menuId);
  };
  closeMenu = menuId => {
    this.setMenuAnchorEl(null);
    this.setOpenedMenu(menuId);
  };

  /* Edit Modal */

  openEditModal = currentChannel => {
    this.setState({
      currentChannel,
      modalOpened: true
    });
  };

  closeEditModal = () => {
    this.setState({
      currentChannel: null,
      modalOpened: false
    });
  };

  closeDeleteModal = () => {
    this.setState({
      deleteModalOpened: false
    });
  };

  /* Add Modal */

  async deleteChannelTier(selectedChannelTiers) {
    const { deleteChannelTier, addToast } = this.props;

    if (selectedChannelTiers.length > 0) {
      let deleted = 0;

      for (let i = 0; i < selectedChannelTiers.length; i += 1) {
        const selectedCt = selectedChannelTiers[i];
        const ret = await deleteChannelTier(selectedCt);

        if (ret.isSuccess) {
          deleted += 1;
        }
      }

      if (deleted === selectedChannelTiers.length) {
        addToast("success", "Success", "Channel Tier(s) successfully deleted!");
      } else if (deleted === 0) {
        addToast("error", "Error", "Channel tier(s) cannot be deleted. Try again.");
      } else {
        addToast("warning", "Warning", "Some channel tier(s) cannot be deleted. Try again.");
      }

      this.setState({
        selectedChannelTiersIds: [],
        deleteModalOpened: false
      });
    }
  }

  /* Modal */

  render() {
    const {
      currentChannel,
      modalOpened,
      selectedChannelTiersIds,
      menuAnchorEl,
      openedMenu,
      deleteModalOpened
    } = this.state;

    const {
      channelTiers
    } = this.props;

    const menuOpen = Boolean(menuAnchorEl);

    return (
      <Stack spacing={2}>
        <div>
          <Button
            id="actions-button"
            sx={{
              borderColor: "primary.smokeBorder",
            }}
            variant="outlined"
            aria-controls={menuOpen && openedMenu === "actions-menu" ? "actions-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen && openedMenu === "actions-menu" ? "true" : undefined}
            disableElevation
            onClick={e => this.openMenu(e, "actions-menu")}
            endIcon={<KeyboardArrowDownIcon />}
            disabled={ selectedChannelTiersIds.length <= 0 }
          >
            Actions
          </Button>
          <Menu
            MenuListProps={{
              "aria-labelledby": "actions-button",
            }}
            id="actions-menu"
            anchorEl={menuAnchorEl}
            open={menuOpen && openedMenu === "actions-menu"}
            onClose={() => this.closeMenu()}
          >
            <MenuItem
              sx={{
                color: "red"
              }}
              onClick={() => {
                this.closeMenu();
                this.setState({
                  deleteModalOpened: true
                });
              }}
              disableRipple
            >
              Delete selected channel tiers
            </MenuItem>
          </Menu>
        </div>
        <div style={{ height: "calc(100vh - 270px)", minHeight: "160px" }}>
          <DataTable
            cols={[
              { headerName: "Id", field: "id", flex: 1 },
              { headerName: "Channel Tier", field: "name", flex: 2 },
              { headerName: "EPG Logo Optional", field: "is_epg_logo_optional", renderCell: item => attributesStatus(item), flex: 2 },
              { headerName: "EPG Description Optional", field: "is_epg_description_optional", renderCell: item => attributesStatus(item), flex: 2 },
              { headerName: "Channel Logo Optionnal", field: "is_channel_logo_optional", renderCell: item => attributesStatus(item), flex: 2 },
              { headerName: "Min. EPG Duration", field: "min_epg_duration", flex: 2 },
              { headerName: "Modified", field: "updated_at", flex: 2 }
            ]}
            data={channelTiers}
            onRowClick={this.openEditModal}
            onRowSelectionModelChange={this.setSelectedChannelTiersIds}
            checkboxSelection
          />
        </div>

        {/* MODAL */}

        <EditChannelTiersModal
          currentId={currentChannel?.id}
          modalOpened={modalOpened}
          closeEditModal={this.closeEditModal}
        />

        <DeleteObjectsModal
          selectedIds={selectedChannelTiersIds}
          modalOpened={deleteModalOpened}
          deleteObjects={this.deleteChannelTier}
          closeDeleteModal={this.closeDeleteModal}
          name="Channel tier(s)"
        />
      </Stack>
    );
  }
}

ChannelTiers.propTypes = {
  channelTiers: PropTypes.arrayOf(PropTypes.object).isRequired,

  deleteChannelTier: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    channelTiers: listChannelTiersSelector(state),
    channelTiersFilters: listChannelTiersFiltersSelector(state),

    isChannelTiersLoading: isLoadingSelector(state, indexChannelTiersAction.toString()),
    isChannelTiersCreating: isLoadingSelector(state, createChannelTierAction.toString()),
    isChannelTiersEditing: isLoadingSelector(state, updateChannelTierAction.toString())
  };
}

const mapDispatchToProps = {
  indexChannelTiers: indexChannelTiersAction,
  indexChannelTiersFilters: indexChannelTiersFiltersAction,
  updateChannelTier: updateChannelTierAction,
  deleteChannelTier: deleteChannelTierAction,
  addToast: addToastAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelTiers);
