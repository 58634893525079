import { handleActions } from "redux-actions";

const initialState = {
  allOttBackends: [],
  filters: [],
  totalCount: 0
};

const ottBackendsReducer = handleActions(
  {
    STORE_OTT_BACKENDS: (state, action) => ({
      ...state,
      allOttBackends: action.payload.result,
      totalCount: action.payload.total_count || 0
    }),
    STORE_OTT_BACKENDS_FILTERS: (state, action) => ({
      ...state,
      filters: action.payload.result
    }),
    ADD_OTT_BACKEND: (state, action) => {
      const allOttBackends = [...state.allOttBackends];

      allOttBackends.push(action.payload.result);

      return {
        ...state,
        allOttBackends,
        totalCount: state.totalCount + 1
      };
    },
    MODIFY_OTT_BACKEND: (state, action) => {
      const newState = {
        ...state,
        allOttBackends: [...state.allOttBackends]
      };

      const ottBackendsIndex = newState.allOttBackends.findIndex(ob => action.payload.result.id === ob.id);

      newState.allOttBackends[ottBackendsIndex] = action.payload.result;

      return newState;
    },
    REMOVE_OTT_BACKEND: (state, action) => {
      const ottBackendsIndex = state.allOttBackends.findIndex(ob => action.payload.id === ob.id);
      const allOttBackends = Array.from(state.allOttBackends);

      allOttBackends.splice(ottBackendsIndex, 1);

      return {
        ...state,
        allOttBackends,
        totalCount: ottBackendsIndex > -1 ? state.totalCount - 1 : state.totalCount
      };
    }
  },
  initialState
);

export default ottBackendsReducer;
