import React from "react";
import PropTypes from "prop-types";

import "./Driver.css";
import AceEditor from "react-ace";
import { toJsonString } from "../../helpers/utils";
import Loader from "../Loader";

export default function Driver(props) {
  const {
    driver,
    onEdit,
    onTrigger,
    isTriggering,
    triggerRender
  } = props;

  return (
    <div className="flex-row driver-container">
      <div className="flex-grow-equal flex-row" id="driver-fields">
        <div className="flex-column">
          <h4 className="driver-title">{driver.Name}</h4>
          <div className="flex-row pl-1">
            <div className="flex-column mr-3">
              <div className="flex-row my-1">
                Adapter:
              </div>
              <div className="flex-row my-1">
                Schedule:
              </div>
              <div className="flex-row my-1">
                State:
              </div>
            </div>

            <div className="flex-column">
              <div className="flex-row my-1">
                { driver.Adapter }
              </div>
              <div className="flex-row my-1">
                { driver.ScheduleExpression }
              </div>
              <div className={`flex-row my-1 ${driver.State === "ENABLED" ? "status-enabled" : "status-disabled"}`}>
                { driver.State }
              </div>
            </div>
          </div>

          <div className="flex-grow-1 flex-align-end pl-1">
            {isTriggering && <Loader />}
            {!isTriggering && triggerRender}
            {!isTriggering
              && <button type="button" className="btn" onClick={() => onTrigger(driver)}>Trigger</button>}
          </div>
        </div>
      </div>
      <div className="flex-grow-equal flex-column pr-1" id="driver-input">
        <div className="mt-1-5 mb-1 flex-row flex-between">
          <div>
            Config:
          </div>
          <button
            type="button"
            className="btn"
            onClick={() => onEdit(driver)}
          >
            Edit
          </button>
        </div>

        <AceEditor
          mode="json"
          theme="tomorrow"
          fontSize="0.9rem"
          name={`ace-editor-${driver.Name}`}
          readOnly
          height="300px"
          setOptions={{
            useWorker: false,
            showLineNumbers: false
          }}
          value={toJsonString(driver.Input)}
          editorProps={{ $blockScrolling: true }}
        />
      </div>
    </div>

  );
}

Driver.defaultProps = {
  isTriggering: false,
  triggerRender: null
};

Driver.propTypes = {
  driver: PropTypes.exact({
    Name: PropTypes.string.isRequired,
    State: PropTypes.string.isRequired,
    Adapter: PropTypes.string.isRequired,
    ScheduleExpression: PropTypes.string.isRequired,
    Input: PropTypes.string.isRequired,
    TargetId: PropTypes.number.isRequired,
    NextExecution: PropTypes.string
  }).isRequired,
  triggerRender: PropTypes.element,
  onEdit: PropTypes.func.isRequired,
  onTrigger: PropTypes.func.isRequired,
  isTriggering: PropTypes.bool
};
