/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { Box, DialogContent, Typography, Button, TextField } from "@mui/material";
import { addToast as addToastAction } from "../../actions/toasts";
import Modal from "../Modal/Modal";

import { createContentGenre as createContentGenreAction } from "../../actions/content_genres";

const objectToLocale = object => {
  const locales = {};

  object.forEach(o => {
    locales[o.lang] = { name: o.name };
  });

  return locales;
};

const initialState = {
  locales: [
    { id: 0, lang: "ar_AR", name: "", errLang: false, errName: true },
    { id: 1, lang: "en_GB", name: "", errLang: false, errName: true },
    { id: 2, lang: "fr_FR", name: "", errLang: false, errName: true },
    { id: 3, lang: "ru_RU", name: "", errLang: false, errName: true },
  ]
};

class AddContentGenresModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { ...initialState };
  }

  isValidLang(lang) {
    if (!lang) {
      return false;
    } else if (lang.length !== 5) {
      return false;
    } else if (!/([a-z]{2}[_][A-Z]{2})/g.test(lang)) {
      return false;
    }
    return true;
  }

  isValidName(name) {
    if (!name) {
      return false;
    } else if (name.length === 0) {
      return false;
    }
    return true;
  }

  addLanguage() {
    const { locales } = this.state;

    const maxId = locales.reduce((acc, cur) => {
      if (cur.id > acc) {
        return cur.id;
      }

      return acc;
    }, 0);

    this.setState(() => ({
      locales: [
        ...locales,
        { id: maxId + 1, lang: "", name: "", errLang: true, errName: true }
      ]
    }));
  }

  removeLanguage(localeId) {
    const { locales } = this.state;
    const newLocales = locales.filter(l => l.id !== localeId);

    this.setState(() => ({ locales: newLocales }));
  }

  changeLanguage(type, id, value) {
    const { locales } = this.state;

    if (type === "lang") {
      const updatedLocales = locales.map(locale => {
        if (locale.id === id) {
          return { id: locale.id, lang: value, errLang: !this.isValidLang(value), name: locale.name, errName: locale.errName };
        }
        return locale;
      });
      this.setState(() => ({ locales: updatedLocales }));
    } else if (type === "name") {
      const updatedLocales = locales.map(locale => {
        if (locale.id === id) {
          return { id: locale.id, lang: locale.lang, errLang: locale.errLangname, name: value, errName: !this.isValidName(value) };
        }
        return locale;
      });
      this.setState(() => ({ locales: updatedLocales }));
    }
  }

  couldSave() {
    const { locales } = this.state;

    return locales?.length > 0 && locales.every(locale => !locale.errLang && !locale.errName);
  }

  async addGenre() {
    const { locales: object } = this.state;
    const { createContentGenre, addToast } = this.props;
    const locales = objectToLocale(object);

    const ret = await createContentGenre({
      type: "content",
      locales
    });

    if (ret.isSuccess) {
      addToast("success", "Success", "Genre successfully added!");
      this.closeModal();
    } else {
      addToast("error", "Error", "Error while adding Genre. Try again.");
    }

    this.closeModal();
  }

  closeModal() {
    const { closeAddModal } = this.props;

    this.setState(initialState);
    closeAddModal();
  }

  modalContent = () => {
    const { locales } = this.state;

    return (
      <DialogContent sx={{ display: "flex", gap: 3, flexDirection: "column", marginTop: 1, width: "80%", alignItems: "center", alignSelf: "center" }}>
        <Typography>Translations</Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", gap: 2 }}>
          <Box className="left" sx={{ display: "flex", flexDirection: "column", gap: 3, alignItems: "center", width: "100%" }}>
            <Typography>Lang code</Typography>
            {locales.map(locale =>
              <Box key={locale.id} className="langCodeFields" sx={{ display: "flex", alignItems: "center", gap: 2, width: "100%" }}>
                <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTrashAlt} onClick={() => this.removeLanguage(locale.id)}/>
                <TextField placeholder="en_GB" error={locale.errLang} value={locale.lang} sx={{ width: "100%" }} onChange={e => this.changeLanguage("lang", locale.id, e.target.value)}/>
                :
              </Box>
            )}
            <Button variant="outlined" sx={{ width: "100%" }} onClick={() => this.addLanguage()}>Add a language</Button>
          </Box>
          <Box className="right" sx={{ display: "flex", flexDirection: "column", gap: 3, alignItems: "center", width: "100%" }}>
            <Typography>Translation</Typography>
            {locales.map(locale =>
              <Box key={locale.id} className="langCodeFields" sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 3, width: "100%" }}>
                <TextField placeholder="translation..." error={locale.errName} value={locale.name} sx={{ width: "100%" }} onChange={e => this.changeLanguage("name", locale.id, e.target.value)}/>
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
    );
  };

  modalActions = () =>
    [
      <Button variant="outlined"
        key="cancel"
        onClick={() => {
          this.closeModal();
        }} >
          Cancel
      </Button>,
      <Button variant="contained"
        key="save"
        disabled={!this.couldSave()}
        color="secondary"
        onClick={() => {
          this.addGenre();
        }}>
          Save
      </Button>
    ];

  render() {
    const {
      modalOpened
    } = this.props;

    return (
      <Modal
        isOpen={modalOpened}
        title="Add content genre"
        closeModal={() => this.closeModal()}
        customModalContent={() => this.modalContent()}
        actions={() => this.modalActions()}
        actionAlign="flex-end"
      />
    );
  }
}

AddContentGenresModal.propTypes = {
  modalOpened: PropTypes.bool.isRequired,
  closeAddModal: PropTypes.func.isRequired,

  createContentGenre: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  createContentGenre: createContentGenreAction,
  addToast: addToastAction
};

export default connect(null, mapDispatchToProps)(AddContentGenresModal);
