import { handleActions } from "redux-actions";

const initialState = {
  isLogged: false,
  initialized: false
};

export default handleActions(
  {
    SET_IS_LOGGED: (state, action) => ({
      ...state,
      isLogged: action.isLogged,
      initialized: true
    })
  },
  initialState
);
