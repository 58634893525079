import { handleActions } from "redux-actions";

const initialState = [];

const reducer = handleActions({
  ADD_TOAST: (state, action) => [...state, action.payload],
  REMOVE_TOAST: (state, action) => state.filter(toast => toast.id !== action.payload)
},
initialState);

export default reducer;
