export const listQualifications = (state, options = {}) => {
  const optionsKeys = Object.keys(options);

  if (optionsKeys.length === 0) {
    return state.qualifications.allQualifications;
  }

  const qualifications = [...state.qualifications.allQualifications];

  const filteredQualifications = qualifications.filter(q => {
    let valid = true;

    optionsKeys.forEach(optKey => {
      if (q[optKey] !== options[optKey]) {
        valid = false;
      }
    });

    return valid;
  });

  return filteredQualifications;
};

export const showQualification = (state, qualificationId) => state.qualifications.allQualifications.find(q => q.id === qualificationId);

export const getTotalCount = state => state.qualifications.totalCount;

export const listQualificationsFilters = state => state.qualifications.filters;
