import { handleActions } from "redux-actions";

const initialState = {
  allTransponders: [],
  filters: [],
  totalCount: 0
};

const transpondersReducer = handleActions(
  {
    STORE_TRANSPONDERS: (state, action) => ({
      ...state,
      allTransponders: action.payload.result,
      totalCount: action.payload.total_count || 0
    }),
    STORE_TRANSPONDERS_FILTERS: (state, action) => ({
      ...state,
      filters: action.payload.result
    }),
    ADD_TRANSPONDER: (state, action) => {
      const allTransponders = [...state.allTransponders];

      allTransponders.push(action.payload.result);

      return {
        ...state,
        allTransponders,
        totalCount: state.totalCount + 1
      };
    },
    MODIFY_TRANSPONDER: (state, action) => {
      const newState = {
        ...state
      };

      const transpondersIndex = newState.allTransponders.findIndex(t => action.payload.result.id === t.id);

      newState.allTransponders[transpondersIndex] = action.payload.result;

      return newState;
    },
    REMOVE_TRANSPONDER: (state, action) => {
      const transpondersIndex = state.allTransponders.findIndex(t => action.payload.id === t.id);
      const allTransponders = Array.from(state.allTransponders);

      allTransponders.splice(transpondersIndex, 1);

      return {
        ...state,
        allTransponders,
        totalCount: transpondersIndex > -1 ? state.totalCount - 1 : state.totalCount
      };
    },
    SELECT_ORBITAL_POSITIONS: () => initialState
  },
  initialState
);

export default transpondersReducer;
