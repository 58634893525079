import { getGridStringOperators } from "@mui/x-data-grid-pro";

export const customGridOperators = [
  ...getGridStringOperators(),
  {
    label: "Includes",
    value: "includes",
    getApplyFilterFn: filterItem => {
      if (!Array.isArray(filterItem.value) || !filterItem.value.length > 1) {
        return null;
      }

      return ({ value }) =>
        typeof value !== "undefined"
          && value !== null
          && filterItem.value.includes(value.toString());
    },
  },
];
