import React from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import PropTypes from "prop-types";
import AceEditor from "react-ace";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem, Typography } from "@mui/material";
import { isValidCron } from "cron-validator";
import Loader from "../Loader";

import { toJsonString } from "../../helpers/utils";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-tomorrow";

import {
  createDriver as createDriverAction,
  updateDriver as updateDriverAction,
  deleteDriver as deleteDriverAction
} from "../../actions/drivers";

import "./DriverEditionModal.css";

Modal.setAppElement(document.querySelector("#root"));
const EMPTY_DRIVER = {
  Name: "",
  Input: "",
  ScheduleExpression: "",
  State: "DISABLED",
  Adapter: "",
  TargetId: null
};

const defaultInput = adapter => {
  if (adapter.indexOf("evmm-driver-input-epg-v2") === 0) {
    return {
      FTP_HOST: "",
      FTP_PORT: 0,
      FTP_USER: "",
      FTP_PASSWORD: ""
    };
  }

  if (adapter.indexOf("sattv-probes-driver") === 0) {
    return {
      FTP_HOST: "",
      FTP_USER: "",
      FTP_PASSWORD: ""
    };
  }

  if (adapter.indexOf("evmm-driver-output-json") === 0) {
    return {
      ORBITAL_POSITION: 0,
      MITXPERTS_ENDPOINT: "",
      MITXPERTS_PORT: 443,
      MITXPERTS_USERNAME: "",
      MITXPERTS_PASSWORD: "",
      EVMM_ENDPOINT: process.env.REACT_APP_SATTV_ENDPOINT,
      SATTV_TOKEN: process.env.REACT_APP_SATTV_TOKEN,
      SATTV_ENDPOINT: process.env.REACT_APP_SATTV_ENDPOINT,
      BACKUP_EXPORT: "true",
      SLOT_DURATION: 10800,
      EPG_DURATION: 691200,
      IMAGE_DEPTH: 259200,
      IMAGE_TO_EXPORT: "dvb",
      IMAGES_CONSTRAINTS: {
        ecl: {
          minWidth: 64
        },
        epg: {
          minWidth: 292
        },
        "ell-d": {
          minWidth: 64
        }
      },
      CAROUSEL_CONFIG: {
        masterIndex: {
          default: 0
        },
        ecf: {
          default: 0
        },
        ecl: {
          default: 0
        },
        "ell-i": {
          default: 0
        },
        ear: {
          default: 0
        },
        epg: {
          default: 0,
          rules: [
            {
              startFile: 1,
              endFile: 64,
              carouselId: 0
            }
          ]
        },
        "ell-d": {
          default: 0
        }
      },
      VERBOSE: false
    };
  }

  if (adapter.indexOf("evmm-nit-export") === 0) {
    return {
      ORBITAL_POSITION: "",
      SATTV_ENDPOINT: "",
      SATTV_TOKEN: "",
      EXPORT_BUCKET: "",
      EXPORT_TABLE: ""
    };
  }

  return {};
};

class DriverEditionModal extends React.Component {
  constructor(props) {
    super(props);

    const { driver, create, adapter } = props;

    const state = {
      Input: toJsonString(driver.Input),
      Schedule: driver.ScheduleExpression,
      Name: driver.Name,
      State: driver.State,
      TargetId: driver.TargetId
    };

    if (create) {
      state.Input = toJsonString(JSON.stringify(defaultInput(adapter)));
    }

    this.state = state;
  }

  componentDidUpdate(prevProps) {
    const {
      isDriverUpdateLoading,
      isDriverCreateLoading,
      onClose,
      apiError
    } = this.props;

    if (apiError) {
      return;
    }

    if (prevProps.isDriverUpdateLoading && !isDriverUpdateLoading) {
      onClose();

      return;
    }

    if (prevProps.isDriverCreateLoading && !isDriverCreateLoading) {
      onClose();

      return;
    }
  }

  getErrorMessage() {
    const { apiError } = this.props;

    if (!apiError) {
      return "";
    }

    if (apiError.response && apiError.response.data) {
      const { response: { data } } = apiError;

      if (data.message) {
        return data.message;
      }

      if (typeof data === "string") {
        return data;
      }
    }

    return "Error";
  }

  changeInput(field, event) {
    this.setState({ [field]: event.target.value });
  }

  saveDriver() {
    const {
      Name,
      Input,
      State,
      Schedule: ScheduleExpression,
      TargetId
    } = this.state;

    const {
      createDriver,
      updateDriver,
      adapter,
      create
    } = this.props;

    let action = updateDriver;

    if (create) {
      action = createDriver;
    }

    action({
      Name, Input, Adapter: adapter, State, ScheduleExpression, TargetId
    });
  }

  deleteDriver() {
    this.props.deleteDriver(this.state.TargetId)
      .then(() => this.props.onClose());
  }

  isNameValid() {
    const { Name } = this.state;

    if (Name?.length > 0) {
      return true;
    }
    return false;
  }

  isNameExist() {
    const { driver, drivers, create } = this.props;

    if (this.isNameValid() && create) {
      return drivers?.find(d => d.Name === this.state.Name) !== undefined;
    } else if (this.isNameValid()) {
      return drivers?.find(d => d.Name === this.state.Name && d.Name !== driver.Name) !== undefined;
    }
    return false;
  }

  isScheduleValid() {
    const { Schedule } = this.state;

    return Schedule.length > 0 ? isValidCron(Schedule) : true;
  }

  render() {
    const {
      Name,
      Schedule,
      State,
      Input
    } = this.state;

    const {
      create, onClose,
      isDriverUpdateLoading,
      isDriverCreateLoading,
      apiError
    } = this.props;

    const isLoading = isDriverUpdateLoading || isDriverCreateLoading;

    const isNameValid = this.isNameValid() && !this.isNameExist();
    const isScheduleValid = this.isScheduleValid();
    const isNameExist = this.isNameExist();
    const ErrorMessage = this.getErrorMessage();

    return (
      <Dialog
        fullWidth
        open
        maxWidth="md"
        onClose={onClose}
      >
        <>
          <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h3" component="span">
              { create ? "New driver" : Name }
            </Typography>
            <CloseIcon sx={{ color: "text.grey" }} onClick={onClose} />
          </DialogTitle>

          <DialogContent sx={{ display: "flex", gap: "20px" }}>
            <div className="dialog-inputs" style={{ paddingTop: "20px", display: "flex", flexDirection: "column", gap: "20px", flex: "1 1 0px", width: "0" }}>
              { isNameExist && <Alert severity="error">Name already exist</Alert> }
              <TextField size="s" label={"Name*"} value={Name} variant="outlined" disabled={!create} onChange={e => this.changeInput("Name", e)}/>
              { apiError && <Alert severity="error">{ErrorMessage}</Alert> }
              <TextField error={!isScheduleValid} helperText={!isScheduleValid ? "Cron schedule expression is required (ex : * 0/3 * * *)" : ""} label={"Schedule*"} value={Schedule} variant="outlined" onChange={e => this.changeInput("Schedule", e)}/>
              <FormControl fullWidth>
                <InputLabel id="dialog-dropdown">State</InputLabel>
                <Select
                  labelId="dialog-dropdown"
                  value={State || "DISABLED"}
                  label="State"
                  onChange={e => this.changeInput("State", e)}
                >
                  <MenuItem value="ENABLED">Enabled</MenuItem>
                  <MenuItem value="DISABLED">Disabled</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="dialog-codeblock" style={{ paddingTop: "20px", display: "flex", flexDirection: "column", gap: "10px", flex: "1 1 0px", width: "0" }}>
              Config:
              <AceEditor
                mode="json"
                theme="tomorrow"
                fontSize="0.9rem"
                name={`ace-editor-${Name}-edit`}
                style={{ width: "auto", minHeight: "320px", height: "100%" }}
                setOptions={{
                  useWorker: false,
                  showLineNumbers: false
                }}
                value={Input}
                onChange={value => this.changeInput("Input", { target: { value } })}
                editorProps={{ $blockScrolling: true }}
              />
            </div>
          </DialogContent>
          {isLoading && <Loader /> }
          {!isLoading
          && <DialogActions sx={{ display: "flex", justifyContent: "space-between", px: 2, py: 2 }}>
            <Button variant="contained"
              key="save"
              color="error"
              onClick={() => this.deleteDriver()}>
                Delete
            </Button>
            <div className="dialog-right-buttons" style={{ display: "flex", gap: "10px" }}>
              <Button variant="outlined" onClick={() => onClose()}>Cancel</Button>
              <Button disabled={Name.length === 0 || Schedule.length === 0 || !isScheduleValid } variant="contained" color="secondary" onClick={() => this.saveDriver()}>Save</Button>
            </div>
          </DialogActions>
          }
        </>
      </Dialog>
    );
  }
}

DriverEditionModal.defaultProps = {
  driver: EMPTY_DRIVER,
  apiError: null
};

DriverEditionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  create: PropTypes.bool.isRequired,
  adapter: PropTypes.string.isRequired,
  drivers: PropTypes.arrayOf(PropTypes.shape({
    Name: PropTypes.string.isRequired,
    Input: PropTypes.string.isRequired,
    ScheduleExpression: PropTypes.string.isRequired,
    TargetId: PropTypes.number,
    State: PropTypes.string.isRequired,
    Adapter: PropTypes.string.isRequired
  })).isRequired,
  driver: PropTypes.shape({
    Name: PropTypes.string.isRequired,
    Input: PropTypes.string.isRequired,
    ScheduleExpression: PropTypes.string.isRequired,
    TargetId: PropTypes.number,
    State: PropTypes.string.isRequired,
    Adapter: PropTypes.string.isRequired
  }),
  apiError: PropTypes.object,
  createDriver: PropTypes.func.isRequired,
  updateDriver: PropTypes.func.isRequired,
  deleteDriver: PropTypes.func.isRequired,
  isDriverUpdateLoading: PropTypes.bool.isRequired,
  isDriverCreateLoading: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    isDriverUpdateLoading: !!state.loaders.actions.find(a => a === updateDriverAction.toString()),
    isDriverCreateLoading: !!state.loaders.actions.find(a => a === createDriverAction.toString()),
  };
}

const mapDispatchToProps = {
  updateDriver: updateDriverAction,
  createDriver: createDriverAction,
  deleteDriver: deleteDriverAction
};

export default connect(mapStateToProps, mapDispatchToProps)(DriverEditionModal);
