import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Menu, MenuItem, Stack } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
  indexBeams as indexBeamsAction,
  createBeam as createBeamAction,
  indexBeamsFilters as indexBeamsFiltersAction,
  updateBeam as updateBeamAction,
  deleteBeam as deleteBeamAction
} from "../../actions/beams";
import {
  listBeams as listBeamsSelector,
  listBeamsFilters as listBeamsFiltersSelector,
} from "../../selectors/beams";
import {
  listOrbitalPositions as listOrbitalPositionsSelector
} from "../../selectors/orbital_positions";
import { isLoading as isLoadingSelector } from "../../selectors/loaders";

import EditBeamModal from "../BeamsModal/EditBeamModal";
import RemoveBeamModal from "../BeamsModal/RemoveBeamModal";

import "./Beams.css";
import FiltersInputs from "../FiltersInputs";
import DataTable from "../DataTable";

import { customGridOperators } from "../../utils/constant";

class Beams extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {

      currentBeam: null,

      filters: [],

      removeBeamOpened: false,
      selectedBeamsIds: [],

      modalOpened: false,
      menuAnchorEl: null,
      openedMenu: null
    };
  }

  componentDidMount = () => {
    const { indexBeamsFilters } = this.props;
    indexBeamsFilters();
  };

  setSelectedBeamsIds = newSelection => {
    this.setState({
      selectedBeamsIds: newSelection,
    });
  };

  /* Menus methods */

  setMenuAnchorEl = target => {
    this.setState({
      menuAnchorEl: target
    });
  };

  setOpenedMenu = menuId => {
    this.setState({
      openedMenu: menuId
    });
  };

  openMenu = (event, menuId) => {
    this.setMenuAnchorEl(event.currentTarget);
    this.setOpenedMenu(menuId);
  };
  closeMenu = menuId => {
    this.setMenuAnchorEl(null);
    this.setOpenedMenu(menuId);
  };

  /* Filter methods */
  setFilters = newFilters => {
    this.setState({
      filters: newFilters
    });
  };

  /* Edit Modal */

  openEditModal = currentBeam => {
    this.setState({
      currentBeam,
      modalOpened: true
    });
  };

  closeEditModal = () => {
    this.setState({
      currentBeam: null,
      modalOpened: false
    });
  };

  /* Remove Modal */

  deleteBeam = () => {
    this.setState({
      removeBeamOpened: true
    });
  };

  closeRemoveBeamModal = () => {
    this.setState({
      removeBeamOpened: false
    });
  };

  /* Modal */

  render() {
    const {
      currentBeam,
      selectedBeamsIds,
      removeBeamOpened,
      modalOpened,
      menuAnchorEl,
      openedMenu,
      filters
    } = this.state;

    const {
      beams,
      beamsFilters,
      orbitalPositions
    } = this.props;

    const menuOpen = Boolean(menuAnchorEl);

    const primaryFilters = [
      {
        text: "Orbital Position",
        name: "orbital_position",
        values: orbitalPositions.map(op => ({ name: op.name, value: op.name }))
      },
      {
        text: "West/East Flag",
        name: "west_east_flag",
        values: ((beamsFilters.find(f => f.name === "west_east_flags") || {}).values || []).map(v => ({ name: v, value: v }))
      }
    ];

    const formattedBeams = beams.map(b => {
      b.orbital_position = orbitalPositions ? orbitalPositions.find(o => o.id === b.orbital_position_id).name : "";
      return b;
    });

    return (
      <Stack spacing={2}>
        <Stack direction="row" spacing={3}>
          <Button
            id="actions-button"
            sx={{
              borderColor: "primary.smokeBorder",
            }}
            variant="outlined"
            aria-controls={
              menuOpen && openedMenu === "actions-menu"
                ? "actions-menu"
                : undefined
            }
            aria-haspopup="true"
            aria-expanded={
              menuOpen && openedMenu === "actions-menu" ? "true" : undefined
            }
            disableElevation
            onClick={e => this.openMenu(e, "actions-menu")}
            endIcon={<KeyboardArrowDownIcon />}
            disabled={selectedBeamsIds.length <= 0}
          >
            Actions
          </Button>
          <Menu
            MenuListProps={{
              "aria-labelledby": "actions-button",
            }}
            id="actions-menu"
            anchorEl={menuAnchorEl}
            open={menuOpen && openedMenu === "actions-menu"}
            onClose={() => this.closeMenu()}
          >
            <MenuItem
              sx={{
                color: "red",
              }}
              onClick={() => {
                this.closeMenu();
                this.deleteBeam();
              }}
              disableRipple
            >
              Delete selected beams
            </MenuItem>
          </Menu>
          <FiltersInputs
            primaryFilters={primaryFilters}
            onFiltersChange={this.setFilters}
          />
        </Stack>

        <div style={{ height: "calc(100vh - 270px)", minHeight: "160px" }}>
          <DataTable
            cols={[
              { headerName: "Id", field: "id", flex: 1 },
              { headerName: "Beam", field: "name", flex: 1 },
              {
                headerName: "W/E",
                field: "west_east_flag",
                flex: 1,
                filterOperators: customGridOperators
              },
              {
                headerName: "Orbital Position",
                field: "orbital_position",
                flex: 1,
                filterOperators: customGridOperators
              },
              { headerName: "Modified", field: "updated_at", flex: 1 },
            ]}
            data={formattedBeams}
            filters={filters}
            onRowClick={id => this.openEditModal(id)}
            onRowSelectionModelChange={this.setSelectedBeamsIds}
            checkboxSelection
          />
        </div>

        {/* MODAL */}

        <EditBeamModal
          currentId={currentBeam?.id}
          modalOpened={modalOpened}
          closeEditModal={this.closeEditModal}
        />

        <RemoveBeamModal
          currentBeamIds={selectedBeamsIds}
          modalOpened={removeBeamOpened}
          closeBeamModal={this.closeRemoveBeamModal}
        />
      </Stack>
    );
  }
}

Beams.propTypes = {
  beams: PropTypes.arrayOf(PropTypes.object).isRequired,
  beamsFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
  orbitalPositions: PropTypes.arrayOf(PropTypes.object).isRequired,

  indexBeamsFilters: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    beams: listBeamsSelector(state),
    beamsFilters: listBeamsFiltersSelector(state),
    orbitalPositions: listOrbitalPositionsSelector(state),

    isBeamsLoading: isLoadingSelector(state, indexBeamsAction.toString()),
    isBeamsCreating: isLoadingSelector(state, createBeamAction.toString()),
    isBeamsEditing: isLoadingSelector(state, updateBeamAction.toString())
  };
}

const mapDispatchToProps = {
  indexBeams: indexBeamsAction,
  indexBeamsFilters: indexBeamsFiltersAction,
  updateBeam: updateBeamAction,
  deleteBeam: deleteBeamAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Beams);
