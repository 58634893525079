import { handleActions } from "redux-actions";

const initialState = {
  allEditorialChannels: [],
  filters: [],
  totalCount: 0
};

const editorialChannelsReducer = handleActions(
  {
    STORE_EDITORIAL_CHANNELS: (state, action) => ({
      ...state,
      allEditorialChannels: action.payload.result,
      totalCount: action.payload.total_count || 0
    }),
    STORE_EDITORIAL_CHANNELS_FILTERS: (state, action) => ({
      ...state,
      filters: action.payload.result
    }),
    ADD_EDITORIAL_CHANNEL: (state, action) => {
      const allEditorialChannels = [...state.allEditorialChannels];

      allEditorialChannels.push(action.payload.result);

      return {
        ...state,
        allEditorialChannels,
        totalCount: state.totalCount + 1
      };
    },
    MODIFY_EDITORIAL_CHANNEL: (state, action) => {
      const newState = {
        ...state
      };

      const editorialChannelIndex = newState.allEditorialChannels.findIndex(cg => action.payload.result.id === cg.id);

      newState.allEditorialChannels[editorialChannelIndex] = action.payload.result;

      return newState;
    },
    MODIFY_MANY_EDITORIAL_CHANNELS: (state, { payload }) => {
      const newState = {
        ...state
      };

      payload.forEach(editorialChannel => {
        const editorialChannelIndex = newState.allEditorialChannels.findIndex(cg => editorialChannel.id === cg.id);

        newState.allEditorialChannels[editorialChannelIndex] = editorialChannel;
      });

      return newState;
    },
    REMOVE_EDITORIAL_CHANNEL: (state, action) => {
      const editorialChannelIndex = state.allEditorialChannels.findIndex(ec => action.payload.id === ec.id);
      const allEditorialChannels = Array.from(state.allEditorialChannels);

      allEditorialChannels.splice(editorialChannelIndex, 1);

      return {
        ...state,
        allEditorialChannels,
        totalCount: editorialChannelIndex > -1 ? state.totalCount - 1 : state.totalCount
      };
    },
    STORE_EDITORIAL_CHANNEL_LOGO: (state, action) => {
      const editorialChannelIndex = state.allEditorialChannels.findIndex(ec => action.payload.id === ec.id);
      const allEditorialChannels = Array.from(state.allEditorialChannels);

      allEditorialChannels[editorialChannelIndex].logos.push(action.payload.result);

      return {
        ...state,
        allEditorialChannels
      };
    },
    REMOVE_EDITORIAL_CHANNEL_LOGO: (state, action) => {
      const editorialChannelIndex = state.allEditorialChannels.findIndex(ec => action.payload.id === ec.id);
      const allEditorialChannels = Array.from(state.allEditorialChannels);

      allEditorialChannels[editorialChannelIndex].logos = allEditorialChannels[editorialChannelIndex].logos.filter(l => l.id !== action.payload.logoId);

      return {
        ...state,
        allEditorialChannels
      };
    }
  },
  initialState
);

export default editorialChannelsReducer;
