import { createAction } from "redux-actions";

/* Storage actions */
const storeBeamsLineups = createAction("STORE_BEAMS_LINEUPS");
const storeBeamsLineupsFilters = createAction("STORE_BEAMS_LINEUPS_FILTERS");
const addBeamsLineups = createAction("ADD_BEAMS_LINEUPS");
const modifyBeamsLineups = createAction("MODIFY_BEAMS_LINEUPS");

/* API actions */
export const indexBeamsLineups = createAction("API_INDEX_BEAMS_LINEUPS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/beams_lineups",
  data,
  fullLoad,
  onSuccessAction: storeBeamsLineups
}));

export const indexBeamsLineupsFilters = createAction("API_INDEX_BEAMS_LINEUPS_FILTERS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/beams_lineups/filters",
  data,
  fullLoad,
  onSuccessAction: storeBeamsLineupsFilters
}));

export const createBeamsLineups = createAction("API_CREATE_BEAMS_LINEUPS", data => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "POST",
  url: "/beams_lineups",
  data,
  onSuccessAction: addBeamsLineups
}));

export const udpateBeamsLineups = createAction("API_UPDATE_BEAMS_LINEUPS", (beamLineupId, data) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "PUT",
  url: `/beams_lineups/${beamLineupId}`,
  data,
  onSuccessAction: modifyBeamsLineups
}));

export const deleteBeamsLineups = createAction("API_DELETE_BEAMS_LINEUPS", beamLineupId => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "DELETE",
  url: `/beams_lineups/${beamLineupId}`,
  onSuccessAction: createAction("REMOVE_BEAMS_LINEUPS", () => ({ id: beamLineupId }))
}));
