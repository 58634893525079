import { createAction } from "redux-actions";
import { addTransponder } from "./transponders";

/* Storage actions */
const storeBeams = createAction("STORE_BEAMS");
const storeBeamsFilters = createAction("STORE_BEAMS_FILTERS");

export const addBeam = createAction("ADD_BEAM");

const modifyBeam = createAction("MODIFY_BEAM");

/* API actions */
export const indexBeams = createAction("API_INDEX_BEAMS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/beams",
  data,
  fullLoad,
  onSuccessAction: storeBeams
}));

export const indexBeamsFilters = createAction("API_INDEX_BEAMS_FILTERS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/beams/filters",
  data,
  fullLoad,
  onSuccessAction: storeBeamsFilters
}));

export const createBeam = createAction("API_CREATE_BEAM", data => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "POST",
  url: "/beams",
  data,
  onSuccessAction: addBeam
}));

export const createBeamAndTransponder = createAction("API_CREATE_BEAM_AND_TRANSPONDERS", data => {
  const onSuccessActions = [
    addBeam
  ];

  if (data.transponders && Array.isArray(data.transponders)) {
    data.transponders.forEach(transponder => {
      onSuccessActions.push(beamResult => createAction("API_CREATE_TRANSPONDER", () => ({
        baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
        method: "POST",
        url: "/transponders",
        data: {
          beam_id: beamResult.result.id,
          name: transponder.name,
          theoretical_onid: transponder.theoretical_onid,
          theoretical_tsid: transponder.theoretical_tsid
        },
        onSuccessAction: addTransponder
      }))());
    });
  }

  return {
    baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
    method: "POST",
    url: "/beams",
    data: {
      orbital_position_id: data.orbital_position_id,
      name: data.name
    },
    onSuccessActions
  };
});

export const updateBeam = createAction("API_UPDATE_BEAM", (beamId, data) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "PUT",
  url: `/beams/${beamId}`,
  data,
  onSuccessAction: modifyBeam
}));

export const deleteBeam = createAction("API_DELETE_BEAM", beamId => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "DELETE",
  url: `/beams/${beamId}`,
  onSuccessAction: createAction("REMOVE_BEAM", () => ({ id: beamId }))
}));
