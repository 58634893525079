import axios from "axios";

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.withCredentials = true;

const baseURL = process.env.REACT_APP_SATTV_ENDPOINT;

export const updateManyLineupsServices = lineupsServicesToUpdate => lineupsServicesToUpdate.map(ls =>
  axios
    .request({
      baseURL,
      method: "PUT",
      url: `/lineups_services/${ls.id}`,
      data: {
        lcns: ls.lcns
      },
      responseType: "json"
    })
);

export const deleteManyLineupsServices = (selectedLineupsServices, lineupsServices, services) => {
  const promises = {
    techChannels: [],
    editorialChannels: [],
    lineupsServices: [],
    services: []
  };

  for (let i = 0; i < selectedLineupsServices.length; i += 1) {
    let shouldDeleteService = false;
    let service = null;
    const lineupsService = lineupsServices.find(ls => ls.id === selectedLineupsServices[i]);
    const oldEditorialChannelId = ((lineupsService || {}).service || {}).channel_id;

    if (!lineupsService.parent_id) {
      service = lineupsService.service;

      const isUsed = lineupsServices.some(ls => ls.id !== lineupsService.id && !ls.parent_id && ls.service && ls.service.id === service.id);

      // S'il n'est pas utilisé ailleurs
      if (!isUsed) {
        // Pour chacune de ses techChannels
        const oldTechChannels = service.tech_channels;

        // eslint-disable-next-line no-restricted-syntax
        for (const oldTechChannel of oldTechChannels) {
          // Est-elle utilisée dans un autre service ?
          const otherServicesWithTC = services.find(s => s.orbital_position_id === service.orbital_position_id && s.id !== service.id && s.tech_channels.find(tc => tc.id === oldTechChannel.id));
          // Si elle n'est pas utilisée ailleurs
          if (!otherServicesWithTC) {
            // On la passe en discarded
            promises.techChannels.push(
              axios
                .request({
                  baseURL,
                  method: "PUT",
                  url: `/tech_channels/${oldTechChannel.id}`,
                  data: { status: "discarded" },
                  responseType: "json"
                })
            );
          }
        }
      }

      // on le supprime
      shouldDeleteService = true;
    }

    // Editorial Channels

    if (oldEditorialChannelId) {
      const isOldECUsed = lineupsServices.find(ls => ls.id !== lineupsService.id && ls.service && ls.service.channel_id === oldEditorialChannelId);
      if (!isOldECUsed) {
        promises.editorialChannels.push(
          axios
            .request({
              baseURL,
              method: "PUT",
              url: `/channels/${oldEditorialChannelId}`,
              data: { status: "archived" },
              responseType: "json"
            })
        );
      }
    }

    promises.lineupsServices.push(
      axios
        .request({
          baseURL,
          method: "DELETE",
          url: `/lineups_services/${selectedLineupsServices[i]}`
        })
    );

    if (shouldDeleteService) {
      promises.services.push(
        axios
          .request({
            baseURL,
            method: "DELETE",
            url: `/services/${service.id}`
          })
          .then(response => ({ ...response, serviceId: service.id }))
      );
    }
  }

  return promises;
};
