import { handleActions } from "redux-actions";

const initialState = {
  allServices: [],
  filters: [],
  totalCount: 0
};

const servicesReducer = handleActions(
  {
    STORE_SERVICES: (state, action) => ({
      ...state,
      allServices: action.payload.result,
      totalCount: action.payload.total_count || 0
    }),
    STORE_SERVICES_FILTERS: (state, action) => ({
      ...state,
      filters: action.payload.result
    }),
    STORE_SINGLE_SERVICE: (state, action) => {
      const allServices = [...state.allServices];
      let { totalCount } = state;
      const serviceIndex = allServices.findIndex(s => s.id === action.payload.result.id);

      if (serviceIndex !== -1) {
        allServices.splice(serviceIndex, 1, action.payload.result);
      } else {
        allServices.push(action.payload.result);
        totalCount += 1;
      }

      return {
        ...state,
        allServices,
        totalCount
      };
    },
    ADD_SERVICE: (state, action) => {
      const allServices = [...state.allServices];

      allServices.push(action.payload.result);

      return {
        ...state,
        allServices,
        totalCount: state.totalCount + 1
      };
    },
    MODIFY_SERVICE: (state, action) => {
      const newState = {
        ...state
      };

      const servicesIndex = newState.allServices.findIndex(s => action.payload.result.id === s.id);

      newState.allServices[servicesIndex] = action.payload.result;

      return newState;
    },
    REMOVE_SERVICE: (state, action) => {
      const servicesIndex = state.allServices.findIndex(s => action.payload.id === s.id);
      const allServices = Array.from(state.allServices);

      allServices.splice(servicesIndex, 1);

      return {
        ...state,
        allServices,
        totalCount: servicesIndex > -1 ? state.totalCount - 1 : state.totalCount
      };
    },
    REMOVE_MANY_SERVICES: (state, { payload }) => {
      const allServices = [...state.allServices];
      let { totalCount } = state;
      const deletedIds = payload.map(({ serviceId }) => serviceId);

      deletedIds.forEach(deletedId => {
        const servicesIndex = state.allServices.findIndex(s => deletedId === s.id);
        if (servicesIndex > -1) {
          allServices.splice(servicesIndex, 1);
          totalCount--;
        }
      });

      return {
        ...state,
        allServices,
        totalCount
      };
    },
    REMOVE_TC_FROM_SERVICE: (state, action) => {
      const { serviceId, techChannelId } = action.payload;
      const newServices = state.allServices.slice(0);
      const servicesIndex = newServices.findIndex(s => s.id === serviceId);

      if (servicesIndex > -1) {
        const techChannelIndex = newServices[servicesIndex].tech_channels.findIndex(tc => tc.id === techChannelId);

        if (techChannelIndex > -1) {
          newServices[servicesIndex].tech_channels.splice(techChannelIndex, 1);
        }
      }

      return {
        ...state,
        allServices: newServices
      };
    },
    SELECT_ORBITAL_POSITIONS: () => initialState
  },
  initialState
);

export default servicesReducer;
