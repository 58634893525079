export const listOttBackends = state => state.ottBackends.allOttBackends;

export const listOttBackendsFromOrbitalPosition = state => {
  const ottBackendsIds = [...new Set(state.techChannels.allTechChannels.map(tc => tc.ott_backend_id))];

  return state.ottBackends.allOttBackends.filter(ob => ottBackendsIds.includes(ob.id));
};

export const showOttBackend = (state, ottBackendId) => state.ottBackends.allOttBackends.find(ob => ob.id === ottBackendId);

export const getTotalCount = state => state.ottBackends.totalCount;

export const listOttBackendsFilters = state => state.ottBackends.filters;
