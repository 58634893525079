import React from "react";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import PropTypes from "prop-types";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";

import Sidebar from "../../components/Sidebar";
import Core from "../../components/Core";
import ToastsContainer from "../../components/ToastsContainer";

import { getCurrentLocation as getCurrentLocationSelector } from "../../selectors/routes";

import "./App.css";

function App(props) {
  const { currentLocation, routes = [], children } = props;

  // Get current routes from route declaration
  const currentRoute = routes.find(i => {
    let exact = true;

    if (i.exact === false) {
      exact = false;
    }

    return matchPath({ path: i.route, exact }, currentLocation.pathname);
  });

  // Get the page name of the current route
  const pageName = currentRoute ? currentRoute.title || "" : "";
  const pageSlug = currentRoute ? currentRoute.slug || "" : "";
  const pageIcon = currentRoute ? currentRoute.icon || faMinusCircle : faMinusCircle;

  // Filter to get only shown menu routes
  const menuRoute = routes.filter(r => r.showInMenu === true);

  return (
    <div id="app">
      <Sidebar navigationItems={menuRoute} currentRoute={currentRoute} />

      <Core pageName={pageName} pageSlug={pageSlug} pageIcon={pageIcon}>
        { children }
      </Core>
      <ToastsContainer />
    </div>
  );
}

App.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,

  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,

  currentLocation: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

function mapStateToProps(state) {
  return {
    currentLocation: getCurrentLocationSelector(state)
  };
}

export default connect(mapStateToProps)(App);
