import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { DataGridPro, GridLogicOperator } from "@mui/x-data-grid-pro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip, List, ListItem, Box } from "@mui/material";

import {
  indexEpgReports as indexEpgReportsAction,
  indexEpgReportsDetails as indexEpgReportsDetailsAction,
  clearEpgReportsDetails as clearEpgReportsDetailsAction
} from "../../actions/epg_reports";

import EpgReportModal from "../EpgReportModal";
import FiltersSearchField from "../FiltersSearchField";

class EpgReports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: [],
      modalOpened: false,
      paginationModel: {
        page: 0,
        pageSize: 500
      }
    };

    this.closeEpgReportModal = this.closeEpgReportModal.bind(this);
    this.openEpgReportModal = this.openEpgReportModal.bind(this);
    this.setEpgReportsFilters = this.setEpgReportsFilters.bind(this);
  }

  componentDidMount() {
    const { paginationModel } = this.state;

    this.loadEpgReports(paginationModel.page + 1, paginationModel.pageSize);
  }

  reportStatus({ row: report }) {
    const ok = <FontAwesomeIcon icon={faCheckCircle} className="green-icon" />;
    const ko = <Tooltip title={ report.abort_reason } arrow><FontAwesomeIcon icon={faExclamationTriangle} className="red-icon" /></Tooltip>;

    return report.aborted ? ko : ok;
  }

  loadEpgReports(page, pageSize) {
    const { indexEpgReports, isEpgReportsLoading } = this.props;

    if (!isEpgReportsLoading) {
      indexEpgReports(page, pageSize);
    }
  }

  closeEpgReportModal() {
    this.props.clearEpgReportsDetails();
    this.setState({ modalOpened: false });
  }

  openEpgReportModal(id) {
    this.props.indexEpgReportsDetails(id);
    this.setState({ modalOpened: true });
  }

  showTooltipError(record) {
    const errors = record.Report.MissingData.map(e => `Critère manquant : ${e.Field} -> ${e.ProviderResourceId}`);

    if (record.Report.Channels.length === 0) {
      errors.push("Absence de channel");
    }

    return (
      <Box sx={{ pt: 2, pl: 1 }}>
        Erreur{errors.length > 1 ? "s" : ""} concernant :
        <List>
          {
            errors.map((e, i) =>
              <ListItem key={i}>
                - {e}
              </ListItem>
            )
          }
        </List>
      </Box>
    );
  }

  handlePaginationModelChange = ({ page, pageSize }) => {
    const { paginationModel } = this.state;

    this.setState({ paginationModel: {
      page: pageSize !== paginationModel.pageSize ? 0 : page,
      pageSize: pageSize
    } }, () => {
      this.loadEpgReports(this.state.paginationModel.page + 1, this.state.paginationModel.pageSize);
    });
  };

  setEpgReportsFilters(newValue) {
    this.setState({ filters: newValue });
  }

  render() {
    const {
      modalOpened,
      filters
    } = this.state;

    const { isEpgReportsLoading, epgReports, epgReportsCount, epgReportsDetails } = this.props;
    const { paginationModel } = this.state;

    return (
      <>
        <FiltersSearchField
          searchFilterField="sync_filename"
          searchFilterLabel="File Name"
          onFiltersChange={this.setEpgReportsFilters}
        />
        <DataGridPro
          columns={[
            {
              headerName: "File",
              field: "sync_filename",
              sortable: false,
              flex: 1
            },
            {
              headerName: "Status",
              field: "status",
              renderCell: this.reportStatus,
              sortable: false,
              flex: 1
            },
            {
              headerName: "Date",
              field: "updated_at",
              sortable: false,
              flex: 1
            },
            {
              headerName: "FTP",
              field: "file_source",
              sortable: false,
              flex: 1
            },
            {
              headerName: "Channel",
              field: "channel_id",
              sortable: false,
              flex: 1
            },
          ]}
          filterModel={{ items: filters, logicOperator: GridLogicOperator.And }}
          loading={isEpgReportsLoading}
          rows={epgReports}
          rowCount={epgReportsCount}
          onRowClick={params => this.openEpgReportModal(params.row.id)}
          disableColumnFilter={true}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={this.handlePaginationModelChange}
        />

        <EpgReportModal
          modalOpened={modalOpened}
          onClose={this.closeEpgReportModal}
          currentEpgReport={epgReportsDetails}
        />

      </>
    );
  }
}

EpgReports.propTypes = {
  epgReports: PropTypes.array.isRequired,
  epgReportsCount: PropTypes.number,
  epgReportsDetails: PropTypes.object,
  indexEpgReports: PropTypes.func.isRequired,
  indexEpgReportsDetails: PropTypes.func.isRequired,
  clearEpgReportsDetails: PropTypes.func.isRequired,
  isEpgReportsLoading: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    epgReports: state.epgReports.list,
    epgReportsCount: state.epgReports.count,
    epgReportsDetails: state.epgReports.details,
    isEpgReportsLoading: !!state.loaders.actions.find(a => a === indexEpgReportsAction.toString())
  };
}

const mapDispatchToProps = {
  indexEpgReports: indexEpgReportsAction,
  indexEpgReportsDetails: indexEpgReportsDetailsAction,
  clearEpgReportsDetails: clearEpgReportsDetailsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(EpgReports);
