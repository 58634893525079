import React, { useEffect } from "react";
import { Button, Box, Typography } from "@mui/material";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import logo from "../../assets/eutelsat-logo.svg";
import "./AuthWrapper.css";

const AuthWrapper = ({ children, updateLogged, isLogged, authInitialized }) => {
  useEffect(() => {
    fetch(`${process.env.REACT_APP_SATTV_ENDPOINT}/users/alive`, { credentials: "include" })
      .then(response => {
        if (!response.ok) {
          updateLogged(false);
        }
        updateLogged(true);
      });
  }, []);

  return (
    <>
      { isLogged
        ? children
        : <Box sx={{ width: "100%" }}>
          {authInitialized
            && <div class="auth-wrapper">
              <Box sx={{ mb: 2 }}><img src={logo} width="250px" alt="" /></Box>
              <Typography variant="h3" sx={{ mb: 4, color: "white" }}>SAT TV MANAGER</Typography>
              <Button variant="contained" color="secondary" href={`${process.env.REACT_APP_SATTV_ENDPOINT}/login`}>Login</Button>
            </div>
          }
        </Box> }
    </>
  );
};

function mapStateToProps(state) {
  return {
    isLogged: state.auth.isLogged,
    authInitialized: state.auth.initialized,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateLogged: isLogged => dispatch({ type: "SET_IS_LOGGED", isLogged })
  };
}

AuthWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  isLogged: PropTypes.bool.isRequired,
  authInitialized: PropTypes.bool.isRequired,
  updateLogged: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthWrapper);
