import PropTypes from "prop-types";
import { Dialog } from "@mui/material";
import ModalTitle from "./ModalTitle";
import ModalActions from "./ModalActions";

const Modal = props => {
  const { customModalTitle, customModalContent, customModalActions, closeModal, maxWidth, isOpen, title, actions, actionAlign } = props;

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth={maxWidth || "md"}
    >
      {customModalTitle ? customModalTitle() : <ModalTitle title={title} closeModal={() => closeModal()}/>}
      {customModalContent && customModalContent()}
      {customModalActions
        ? customModalActions()
        : actions
          ? <ModalActions align={actionAlign && actionAlign} actions={() => actions()}/>
          : null
      }
    </Dialog>
  );
};

Modal.propTypes = {
  customModalTitle: PropTypes.func,
  customModalContent: PropTypes.func,
  customModalActions: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
  maxWidth: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  actionAlign: PropTypes.string,
  actions: PropTypes.func,
};

export default Modal;
