import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Toast from "../Toast";
import "./ToastsContainer.css";

function ToastContainer({ toasts }) {
  return (
    <div className="toasts-container bottom-left">
      {toasts.map(toast => <Toast
        key={toast.id}
        id={toast.id}
        time={toast.time}
        type={toast.type}
        title={toast.title}
        content={toast.content}
      />

      ) }
    </div>
  );
}

ToastContainer.propTypes = {
  toasts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired
    })
  ).isRequired
};

function mapStateToProps(state) {
  return {
    toasts: state.toasts
  };
}

export default connect(mapStateToProps)(ToastContainer);
