import { createAction } from "redux-actions";

/* Storage actions */
const storeContentGenres = createAction("STORE_CONTENT_GENRES");
const storeContentGenresFilters = createAction("STORE_CONTENT_GENRES_FILTERS");
const addContentGenre = createAction("ADD_CONTENT_GENRE");
const modifyContentGenre = createAction("MODIFY_CONTENT_GENRE");

/* API actions */
export const indexContentGenres = createAction("API_INDEX_CONTENT_GENRES", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/genres",
  data,
  fullLoad,
  onSuccessAction: storeContentGenres
}));

export const indexContentGenresFilters = createAction("API_INDEX_CONTENT_GENRES_FILTERS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/genres/filters",
  data,
  fullLoad,
  onSuccessAction: storeContentGenresFilters
}));

export const createContentGenre = createAction("API_CREATE_CONTENT_GENRE", data => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "POST",
  url: "/genres",
  data,
  onSuccessAction: addContentGenre
}));

export const updateContentGenre = createAction("API_UPDATE_CONTENT_GENRE", (genreId, data) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "PUT",
  url: `/genres/${genreId}`,
  data,
  onSuccessAction: modifyContentGenre
}));

export const deleteContentGenre = createAction("API_DELETE_CONTENT_GENRE", genreId => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "DELETE",
  url: `/genres/${genreId}`,
  onSuccessAction: createAction("REMOVE_CONTENT_GENRE", () => ({ id: genreId }))
}));
