import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Message } from "semantic-ui-react";

import { removeToast as removeToastAction } from "../../actions/toasts";

const icons = {
  success: "checkmark",
  error: "remove",
  warning: "warning circle"
};

class Toast extends React.Component {
  componentDidMount() {
    const { time } = this.props;

    setTimeout(() => {
      this.removeSelf();
    }, time);
  }

  removeSelf() {
    const { id, removeToast } = this.props;

    removeToast(id);
  }

  render() {
    const { type, content, title } = this.props;

    return (
      <Message
        className="bottom-left"
        success={type === "success"}
        error={type === "error"}
        warning={type === "warning"}
        content={content}
        header={title}
        onDismiss={e => {
          e.stopPropagation();
          this.removeSelf();
        }}
        icon={icons[type]}
      />
    );
  }
}

Toast.defaultProps = {
  time: 7000
};

Toast.propTypes = {
  id: PropTypes.number.isRequired,
  time: PropTypes.number,
  type: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  removeToast: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  removeToast: removeToastAction
};

export default connect(() => ({}), mapDispatchToProps)(Toast);
