import { handleActions } from "redux-actions";

const initialState = {
  allServiceTypes: [],
  filters: [],
  totalCount: 0
};

const serviceTypesReducer = handleActions(
  {
    STORE_SERVICE_TYPES: (state, action) => ({
      ...state,
      allServiceTypes: action.payload.result,
      totalCount: action.payload.total_count || 0
    }),
    STORE_SERVICE_TYPES_FILTERS: (state, action) => ({
      ...state,
      filters: action.payload.result
    }),
    ADD_SERVICE_TYPE: (state, action) => {
      const allServiceTypes = [...state.allServiceTypes];

      allServiceTypes.push(action.payload.result);

      return {
        ...state,
        allServiceTypes,
        totalCount: state.totalCount + 1
      };
    },
    MODIFY_SERVICE_TYPE: (state, action) => {
      const newState = {
        ...state,
        allServiceTypes: [...state.allServiceTypes]
      };

      const serviceTypesIndex = newState.allServiceTypes.findIndex(st => action.payload.result.id === st.id);

      newState.allServiceTypes[serviceTypesIndex] = action.payload.result;

      return newState;
    },
    REMOVE_SERVICE_TYPE: (state, action) => {
      const serviceTypesIndex = state.allServiceTypes.findIndex(st => action.payload.id === st.id);
      const allServiceTypes = Array.from(state.allServiceTypes);

      allServiceTypes.splice(serviceTypesIndex, 1);

      return {
        ...state,
        allServiceTypes,
        totalCount: serviceTypesIndex > -1 ? state.totalCount - 1 : state.totalCount
      };
    }
  },
  initialState
);

export default serviceTypesReducer;
