import { handleActions } from "redux-actions";

const initialState = {
  allProvidersWordings: [],
  filters: [],
  totalCount: 0
};

const providersWordingsReducer = handleActions(
  {
    STORE_PROVIDERS_WORDINGS: (state, action) => ({
      ...state,
      allProvidersWordings: action.payload.result,
      totalCount: action.payload.total_count || 0
    }),
    STORE_PROVIDERS_WORDINGS_FILTERS: (state, action) => ({
      ...state,
      filters: action.payload.result
    }),
    ADD_PROVIDERS_WORDING: (state, action) => {
      const allProvidersWordings = [...state.allProvidersWordings];

      allProvidersWordings.push(action.payload.result);

      return {
        ...state,
        allProvidersWordings,
        totalCount: state.totalCount + 1
      };
    },
    MODIFY_PROVIDERS_WORDING: (state, action) => {
      const newState = {
        ...state
      };

      const providersWordingsIndex = newState.allProvidersWordings.findIndex(t => action.payload.result.id === t.id);

      newState.allProvidersWordings[providersWordingsIndex] = action.payload.result;

      return newState;
    },
    REMOVE_PROVIDERS_WORDING: (state, action) => {
      const providersWordingsIndex = state.allProvidersWordings.findIndex(t => action.payload.id === t.id);
      const allProvidersWordings = Array.from(state.allProvidersWordings);

      allProvidersWordings.splice(providersWordingsIndex, 1);

      return {
        ...state,
        allProvidersWordings,
        totalCount: providersWordingsIndex > -1 ? state.totalCount - 1 : state.totalCount
      };
    },
    SELECT_ORBITAL_POSITIONS: () => initialState
  },
  initialState
);

export default providersWordingsReducer;
