/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AceEditor from "react-ace";
import { DialogContent, Button, Alert, Box } from "@mui/material";
import Modal from "../Modal/Modal";
import { addToast as addToastAction } from "../../actions/toasts";

import {
  updateServiceGenre as updateServiceGenreAction
} from "../../actions/service_genres";
import { showServiceGenre } from "../../selectors/service_genres";

import { isLoading as isLoadingSelector } from "../../selectors/loaders";
import withStepper from "../Modal/withStepper";

import {
  toJsonString,
  isValidJson,
  isValidLocaleJson,
  getObjectNameFromLocales
} from "../../helpers/utils";

const initialState = {
  locales: toJsonString("{\"en_GB\":{\"name\":\"My genre\"}}"),
};

class EditServiceGenreModal extends PureComponent {
  constructor(props) {
    super(props);

    const state = { ...initialState };

    if (props.serviceGenre) {
      const {
        locales
      } = props.serviceGenre;

      state.locales = toJsonString(JSON.stringify(locales));
    }

    this.state = { ...state };
  }

  componentDidUpdate(prevProps) {
    const { serviceGenre } = this.props;

    if (prevProps.serviceGenre !== serviceGenre && serviceGenre !== null) {
      const { locales } = serviceGenre;

      this.setState({ locales: toJsonString(JSON.stringify(locales)) });
    }
  }

  changeInput(inputName, event) {
    const { value } = event.target;

    this.setState(() => ({ [inputName]: value }));
  }

  async editServiceGenre() {
    const { currentId, addToast } = this.props;
    const { locales } = this.state;
    const { updateServiceGenre } = this.props;
    const ret = await updateServiceGenre(currentId, { locales: JSON.parse(locales) });

    if (ret.isSuccess) {
      addToast("success", "Success", "Channel genre successfully updated!");
      this.closeModal();
    } else {
      addToast("error", "Error", "Error while updating channel genre. Try again.");
    }
  }

  closeModal() {
    const { closeEditModal } = this.props;

    this.setState(initialState);
    closeEditModal();
  }

  isLocalesValid() {
    const { locales } = this.state;

    return locales && locales.length !== 0 && isValidJson(locales) && isValidLocaleJson(locales);
  }

  isNameValid() {
    const { locales } = this.state;

    if (locales
      && locales.length !== 0
      && isValidJson(locales)
      && isValidLocaleJson(locales)
    ) {
      const localesObject = JSON.parse(locales);
      const languages = Object.keys(localesObject);
      const validLanguages = languages.filter(localeKey => localeKey.length === 5);
      let hasValidLocale = false;

      validLanguages.forEach(language => {
        if (localesObject[language] && localesObject[language].name && (localesObject[language].name || "").length > 0) {
          hasValidLocale = true;
        }
      });

      if (!hasValidLocale) {
        return false;
      }
    }
    return true;
  }

  modalContent = () => {
    const {
      locales,
    } = this.state;

    const { stepperComponent, isLastStep } = this.props;

    const isLocalesValid = this.isLocalesValid();
    const isNameValid = this.isNameValid();

    return (
      <DialogContent sx={{ display: "flex", gap: 3, flexDirection: "column", marginTop: 1, width: "80%", alignItems: "center", alignSelf: "center" }}>
        {stepperComponent}
        <Box sx={{ width: "inherit", display: "flex", flexDirection: "column", gap: 4 }}>
          {!isLocalesValid && <Alert severity="error">
            Locales json is not valid. Locales json should contain language keys with length equals to 5 characters
          </Alert>}
          {!isNameValid && <Alert severity="error">
            You have to define a name entry in the locales JSON. For example: { "\"en_GB\" : { \"name\": \"my channel\" }" }
          </Alert>}
          <AceEditor
            width="100%"
            mode="json"
            theme="tomorrow"
            fontSize="0.9rem"
            name="service-genre-add-locales-edit"
            height="400px"
            readOnly={isLastStep}
            setOptions={{
              useWorker: false,
              showLineNumbers: false
            }}
            value={locales}
            onChange={value => this.changeInput("locales", { target: { value } })}
            editorProps={{ $blockScrolling: true }}
          />
        </Box>
      </DialogContent>
    );
  };

  modalActions = () => {
    const { currentStep, previousStep, nextStep, isLastStep } = this.props;

    const isLocalesValid = this.isLocalesValid();
    const isNameValid = this.isNameValid();

    return (
      [
        <Button variant="outlined"
          key="cancel"
          onClick={() => {
            this.closeModal();
          }} >
            Cancel
        </Button>,
        currentStep > 0 && <Button variant="outlined"
          key="previous"
          onClick={() => {
            previousStep();
          }}>
            Previous
        </Button>,
        !isLastStep && <Button variant="contained"
          key="next"
          disabled={!isLocalesValid || !isNameValid}
          color="secondary"
          onClick={() => {
            nextStep();
          }}>
            Next
        </Button>,
        isLastStep && <Button variant="contained"
          key="save"
          color="secondary"
          onClick={() => {
            this.editServiceGenre();
          }}>
            Save
        </Button>
      ]
    );
  };

  render() {
    const {
      modalOpened,
      serviceGenre,
    } = this.props;

    const currentserviceGenreName = getObjectNameFromLocales(serviceGenre);

    return (
      <Modal
        isOpen={modalOpened}
        title={"Edit service genre " + currentserviceGenreName || ""}
        customModalContent={() => this.modalContent()}
        closeModal={() => this.closeModal()}
        actions={() => this.modalActions()}
        actionAlign="flex-end"
      />
    );
  }
}

EditServiceGenreModal.defaultProps = {
  currentId: null
};

EditServiceGenreModal.propTypes = {
  currentId: PropTypes.number,

  serviceGenre: PropTypes.object.isRequired,

  modalOpened: PropTypes.bool.isRequired,
  closeEditModal: PropTypes.func.isRequired,

  currentStep: PropTypes.number,
  previousStep: PropTypes.func,
  nextStep: PropTypes.func,
  stepperComponent: PropTypes.object,
  isLastStep: PropTypes.bool,

  updateServiceGenre: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    serviceGenre: showServiceGenre(state, ownProps.currentId) || {},

    isServiceGenreEditing: isLoadingSelector(state, updateServiceGenreAction.toString())
  };
}

const mapDispatchToProps = {
  updateServiceGenre: updateServiceGenreAction,
  addToast: addToastAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withStepper(
  EditServiceGenreModal, [
    "Set channel genre information",
    "Sumup"
  ]));
