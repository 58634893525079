import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Navigate } from "react-router";
import Tabs from "../../components/Tabs";
import Driver from "../../components/Driver";

import { indexNitReports as indexNitReportsAction } from "../../actions/nit_reports";

import { getCurrentLocation } from "../../selectors/routes";
import FullLoader from "../../components/FullLoader";
import DriverEditionModal from "../../components/DriverEditionModal";
import {
  indexDrivers as indexDriversAction,
  triggerDriver as triggerDriverAction
} from "../../actions/drivers";
import "./Drivers.css";

const DRIVER_EPG_INGEST = "epg-ingest";
const DRIVER_VIA_EXPORT = "via-export";
const DRIVER_VIA_EXPORT_V3 = "via-export-v3";
const DRIVER_SATTV_PROBES = "sattv-probes";
const DRIVER_NIT_EXPORT = "nit-exports";

const ADAPTERS = {
  [DRIVER_EPG_INGEST]: "evmm-driver-input-epg-v2",
  [DRIVER_VIA_EXPORT]: "evmm-driver-output-json",
  [DRIVER_VIA_EXPORT_V3]: "evmm-driver-output-json-v3",
  [DRIVER_SATTV_PROBES]: "sattv-probes-driver",
  [DRIVER_NIT_EXPORT]: "evmm-nit-export"
};

class Drivers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentDriver: undefined,
      isEditingDriver: false,
      currentTriggerArg: "",
      paginationModel: {
        page: 1,
        per_page: 50
      }
    };
  }

  componentDidMount() {
    this.loadDrivers();
    this.loadNitReports();
  }

  loadNitReports() {
    const { indexNitReports, isNitReportsLoading } = this.props;
    const { paginationModel } = this.state;

    if (!isNitReportsLoading) {
      indexNitReports(paginationModel.page, paginationModel.per_page);
    }
  }

  openModal(driver) {
    this.setState({ currentDriver: driver, isEditingDriver: true });
  }

  closeModal() {
    this.setState({ currentDriver: undefined, isEditingDriver: false });
  }

  createDriverModal() {
    this.openModal(undefined);
  }

  loadDrivers() {
    const {
      indexDrivers,
      isDriversLoading,
    } = this.props;

    if (!isDriversLoading) {
      indexDrivers();
    }
  }

  filterDrivers() {
    const { drivers: { drivers }, currentTab } = this.props;
    return drivers.filter(driver => driver.Adapter === ADAPTERS[currentTab]);
  }

  changeInput(inputName, event) {
    const { value } = event.target;

    this.setState(() => ({ [inputName]: value }));
  }

  render() {
    const {
      isDriversLoading,
      isDriverTriggering,
      isNitReportsLoading,
      triggerDriver,
      currentTab,
      drivers: { adapters, apiError },
      nitReports
    } = this.props;

    const { currentTriggerArg } = this.state;

    if (!ADAPTERS[currentTab]) {
      return <Navigate to={`/drivers/${DRIVER_EPG_INGEST}`} />;
    }

    const tabs = [
      {
        name: <Link to="/drivers/epg-ingest">EPG Ingest</Link>,
        slug: DRIVER_EPG_INGEST,
        currentClassName: "active"
      },
      {
        name: <Link to="/drivers/via-export">VIA Export</Link>,
        slug: DRIVER_VIA_EXPORT,
        currentClassName: "active"
      },
      {
        name: <Link to="/drivers/via-export-v3">VIA Export V3</Link>,
        slug: DRIVER_VIA_EXPORT_V3,
        currentClassName: "active"
      },
      {
        name: <Link to="/drivers/sattv-probes">ProDBR Probes</Link>,
        slug: DRIVER_SATTV_PROBES,
        currentClassName: "active"
      },
      {
        name: <Link to="/drivers/nit-exports">NIT Exports</Link>,
        slug: DRIVER_NIT_EXPORT,
        currentClassName: "active"
      }
    ];

    const drivers = this.filterDrivers();
    const { isEditingDriver, currentDriver } = this.state;
    const isLoading = isDriversLoading || isNitReportsLoading;

    const currentAdapter = {
      FunctionName: ADAPTERS[currentTab]
    };

    const tabsActions = [
      {
        name: "New driver",
        className: "btn-primary",
        action: () => this.createDriverModal()
      }
    ];

    return (
      <div id="drivers" className="page-content">
        <Tabs
          changeTab={() => {}}
          currentTab={currentTab}
          tabs={tabs}
          tabsActions={tabsActions}
        />
        { isLoading && <FullLoader /> }
        { !isLoading
          && <div className="drivers-container">
            { drivers.map((d, i) => <Driver
              key={currentTab + i}
              driver={d}
              adapter={currentAdapter}
              onEdit={driver => this.openModal(driver)}
              isTriggering={isDriverTriggering}
              onTrigger={driver => {
                if (currentTab === DRIVER_NIT_EXPORT && currentTriggerArg !== "-1" && currentTriggerArg !== "") {
                  const nitReport = nitReports.find(nr => nr.id === Number(currentTriggerArg));

                  if (nitReport) {
                    return triggerDriver(driver, { last_report_id: Number(currentTriggerArg) });
                  }
                }

                return triggerDriver(driver);
              }}
              triggerRender={
                currentTab !== DRIVER_NIT_EXPORT
                  ? null
                  : <>
                    <select
                      className="select"
                      value={currentTriggerArg || ""}
                      onChange={e => this.changeInput("currentTriggerArg", e)}
                    >
                      <option value="">Select a previous report</option>
                      <option value="-1">Do not compare with previous</option>
                      {nitReports
                        .filter(nr => nr.driver_id === d.TargetId)
                        .map(nr =>
                          <option key={nr.started_at} value={nr.id}>
                            {`${nr.orbital_position} (${nr.started_at})`}
                          </option>
                        )}
                    </select>
                  </>
              }
            />
            )}
          </div>
        }
        {
          isEditingDriver
          && <DriverEditionModal
            driver={currentDriver}
            drivers={drivers}
            apiError={apiError}
            onClose={() => this.closeModal()}
            create={currentDriver === undefined}
            adapter={currentAdapter.FunctionName}
          />

        }
      </div>
    );
  }
}

Drivers.propTypes = {
  isDriversLoading: PropTypes.bool.isRequired,
  currentTab: PropTypes.string.isRequired,
  drivers: PropTypes.exact({
    drivers: PropTypes.array.isRequired,
    adapters: PropTypes.array.isRequired,
    apiError: PropTypes.object
  }).isRequired,
  indexDrivers: PropTypes.func.isRequired,
  isDriverTriggering: PropTypes.bool.isRequired,
  triggerDriver: PropTypes.func.isRequired,
  nitReports: PropTypes.array.isRequired,
  indexNitReports: PropTypes.func.isRequired,
  isNitReportsLoading: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  const route = getCurrentLocation(state).pathname.split("/");

  return {
    drivers: state.drivers,
    nitReports: state.nitReports.nitReports,
    currentTab: route[route.length - 1] || DRIVER_EPG_INGEST,
    isDriversLoading: !!state.loaders.actions.find(a => a === indexDriversAction.toString()),
    isDriverTriggering: !!state.loaders.actions.find(a => a === triggerDriverAction.toString()),
    isNitReportsLoading: !!state.loaders.actions.find(a => a === indexNitReportsAction.toString())
  };
}

const mapDispatchToProps = {
  indexDrivers: indexDriversAction,
  triggerDriver: triggerDriverAction,
  indexNitReports: indexNitReportsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Drivers);
