import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Step, Stepper, StepLabel, Typography } from "@mui/material";

const withStepper = (Component, steps) => {
  function WithStepper(props) {
    const [currentStep, setCurrentStep] = useState(0);

    const previousStep = () => {
      if (currentStep > 0) {
        setCurrentStep(currentStep - 1);
      }
    };

    useEffect(() => {
      setCurrentStep(0);
    }, [props.modalOpened]);

    const isLastStep = currentStep + 1 === steps.length;

    const nextStep = () => {
      if (!isLastStep) {
        setCurrentStep(currentStep + 1);
      }
    };

    const StepperComponent = () =>
      <>
        <Stepper sx={{ width: "100%" }} activeStep={currentStep} alternativeLabel>
          {steps.map(label =>
            <Step key={label}>
              <StepLabel></StepLabel>
            </Step>
          )}
        </Stepper>
        <Typography variant="h5" component="span">
          {steps[currentStep]}
        </Typography>
      </>;

    return (
      <Component
        {...props}
        previousStep={previousStep}
        nextStep={nextStep}
        isLastStep={isLastStep}
        currentStep={currentStep}
        stepperComponent={<StepperComponent />}
        steps={steps}
      />
    );
  }

  WithStepper.propTypes = {
    modalOpened: PropTypes.bool.isRequired,
  };

  return WithStepper;
};

export default withStepper;
