import { PureComponent } from "react";
import PropTypes from "prop-types";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

class DeleteObjectsModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { modalOpened, selectedIds, name, closeDeleteModal, deleteObjects } = this.props;
    return (
      <Dialog
        open={modalOpened}
      >
        <DialogTitle>Remove selected  {name}</DialogTitle>
        <DialogContent dividers>
          <Typography>
                  Are you sure to delete theses {name}(s) : {selectedIds.join(", ")} ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteModal}>
                  Cancel
          </Button>
          <Button onClick={() => deleteObjects(selectedIds)}>Confirm</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DeleteObjectsModal.propTypes = {
  modalOpened: PropTypes.bool.isRequired,
  selectedIds: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  closeDeleteModal: PropTypes.func.isRequired,
  deleteObjects: PropTypes.func.isRequired
};

export default DeleteObjectsModal;
