import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import {
  qualificationServiceName,
} from "../../helpers/utils";

const FormatTechChannel = props => {
  const { notifData, transponders, techChannels } = props;
  const { CREATION, UPDATE, DELETION } = props.qualificationTypes;
  const { COLOR_DELETE, COLOR_SUCCESS } = props.colors;

  const qualifType = notifData.qualification_type;
  const transponder = transponders.find(
    t => t.id === notifData.data.transponder_id
  );
  const techChannel = techChannels.find(tc => tc.id === notifData.data.id);
  let currentTechChannelStatus = "";
  let serviceName = "";
  let serviceNameChange = null;
  let sid = "";
  let sidChange = null;
  let scrambled = null;
  let scrambledChange = null;

  if (qualifType === CREATION) {
    serviceName =
      notifData.data.service_name
      || qualificationServiceName(transponder, notifData.data);
    sid = notifData.data.sid;
    scrambled = notifData.data.scrambled;
  } else if (qualifType === UPDATE && techChannel) {
    currentTechChannelStatus =
      <Box sx={{ display: "flex", gap: 1 }}>
        <Typography sx={{ fontWeight: "bold" }}>
          Current technical channel status:
        </Typography>
        {techChannel.status}
      </Box>;
    if (
      notifData.data.service_name
      && notifData.data.service_name !== techChannel.service_name
    ) {
      serviceNameChange = {
        previous: techChannel.service_name,
        current: notifData.data.service_name,
      };
    } else {
      serviceName = techChannel.service_name;
    }

    if (notifData.data.sid && notifData.data.sid !== techChannel.sid) {
      sidChange = {
        previous: techChannel.sid,
        current: notifData.data.sid,
      };
    } else {
      sid = techChannel.sid;
    }

    if (
      [true, false].indexOf(notifData.data.scrambled) !== -1
      && notifData.data.scrambled !== techChannel.scrambled
    ) {
      scrambledChange = {
        previous: techChannel.scrambled,
        current: notifData.data.scrambled,
      };
    } else {
      scrambled = techChannel.scrambled;
    }
  } else if (qualifType === DELETION && techChannel) {
    currentTechChannelStatus =
      <Box sx={{ display: "flex", gap: 1 }}>
        <Typography sx={{ fontWeight: "bold" }}>
          Current technical channel status:
        </Typography>
        {techChannel.status}
      </Box>;
    serviceName = techChannel.service_name;
    sid = techChannel.sid;
    scrambled = techChannel.scrambled;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 0.5,
      }}
    >
      {currentTechChannelStatus}
      <Box sx={{ display: "flex", gap: 1 }}>
        <Typography sx={{ fontWeight: "bold" }}>Service_name:</Typography>
        {serviceNameChange
          ? <Box sx={{ display: "flex", gap: 1 }}>
            <Typography sx={{ color: COLOR_DELETE }}>
              {serviceNameChange.previous}
            </Typography>
            {"->"}
            <Typography sx={{ color: COLOR_SUCCESS }}>
              {serviceNameChange.current}
            </Typography>
          </Box>
          : serviceName
        }
      </Box>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Typography sx={{ fontWeight: "bold" }}>Transponder:</Typography>
        {transponder
          ? transponder.name
          : `${notifData.data.transponder_id} (Transponder cannot be identified)`}
      </Box>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Typography sx={{ fontWeight: "bold" }}>SID: </Typography>
        {sidChange
          ? <Box sx={{ display: "flex", gap: 1 }}>
            <Typography sx={{ color: COLOR_DELETE }}>{sid.previous}</Typography>
            {"->"}
            <Typography sx={{ color: COLOR_SUCCESS }}>{sid.current}</Typography>
          </Box>
          : sid
        }
      </Box>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Typography sx={{ fontWeight: "bold" }}>Scrambled: </Typography>
        {scrambledChange
          ? <Box sx={{ display: "flex", gap: 1 }}>
            <Typography sx={{ color: COLOR_DELETE }}>
              {scrambledChange.previous.toString()}
            </Typography>
            {"->"}
            <Typography sx={{ color: COLOR_SUCCESS }}>
              {scrambledChange.current.toString()}
            </Typography>
          </Box>
          : scrambled?.toString()
        }
      </Box>
    </Box>
  );
};

FormatTechChannel.propTypes = {
  notifData: PropTypes.object.isRequired,
  transponders: PropTypes.array.isRequired,
  techChannels: PropTypes.array.isRequired,
  qualificationTypes: PropTypes.object.isRequired,
  colors: PropTypes.object.isRequired,
};

export default FormatTechChannel;
