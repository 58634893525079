/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Box, DialogContent, Typography, Button, Alert, LinearProgress } from "@mui/material";
import Modal from "../Modal/Modal";

import { addToast as addToastAction } from "../../actions/toasts";

import {
  updateEditorialChannel as updateEditorialChannelAction,
  deleteEditorialChannel as deleteEditorialChannelAction
} from "../../actions/editorial_channels";

import {
  indexLineups as indexLineupsAction
} from "../../actions/lineups";
import {
  deleteLineupsService as deleteLineupsServiceAction
} from "../../actions/lineups_services";
import {
  updateTechChannel as updateTechChannelAction
} from "../../actions/tech_channels";
import {
  indexServices as indexServicesAction,
  deleteService as deleteServiceAction
} from "../../actions/services";

import {
  listLineups as listLineupsSelector
} from "../../selectors/lineups";
import {
  listEditorialChannels as listEditorialChannelsSelector
} from "../../selectors/editorial_channels";
import {
  listLineupsServices as listLineupsServicesSelector
} from "../../selectors/lineups_services";
import {
  listServices as listServicesSelector
} from "../../selectors/services";
import {
  getOrbitalPositionsFromLineupId as getOrbitalPositionsFromLineupIdSelector
} from "../../selectors/orbital_positions";

import { isLoading as isLoadingSelector } from "../../selectors/loaders";

import {
  times,
  getObjectNameFromLocales
} from "../../helpers/utils";

const initialState = {
  currentStep: 0,
  steps: 0,
  hasFetchingService: false
};

class RemoveEditorialChannelModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      steps: props.currentChannelIds.length - 1
    };
  }

  componentDidMount() {
    const { indexLineups } = this.props;

    indexLineups(null, true);
  }

  componentDidUpdate(prevProps) {
    const {
      hasFetchingService
    } = this.state;

    const {
      currentChannelIds,
      services,
      indexServices,
      isLoadingServices
    } = this.props;

    if (currentChannelIds.length !== prevProps.currentChannelIds.length) {
      this.setState({
        currentStep: 0,
        steps: currentChannelIds.length - 1
      });
    }

    if (!hasFetchingService && services.length === 0 && !isLoadingServices) {
      indexServices({}, true);
      this.setState({ hasFetchingService: true });
    }
  }

  nextStep() {
    const {
      currentStep,
      steps
    } = this.state;

    const {
      addToast
    } = this.props;

    if (currentStep < steps) {
      this.setState({
        currentStep: currentStep + 1
      });
    } else {
      addToast("success", "Success", "Editorial channel removed!");
      this.closeModal();
    }
  }

  async removeChannel() {
    const {
      currentStep
    } = this.state;

    const {
      currentChannelIds,
      lineupsServices,
      lineups,
      services,

      deleteLineupsService,
      updateEditorialChannel,
      deleteEditorialChannel,
      updateTechChannel,
      deleteService,

      removeMode
    } = this.props;

    if (currentStep < currentChannelIds.length) {
      const currentEditorialChannelId = parseInt(currentChannelIds[currentStep], 10);
      const currentLineupsServices = lineupsServices.filter(ls => ls.service && ls.service.channel_id === currentEditorialChannelId);

      for (let i = 0; i < currentLineupsServices.length; i += 1) {
        const lineupService = currentLineupsServices[i];
        const currentLineup = lineups.find(l => lineupService.lineup_id === l.id);
        let shouldDeleteService = false;
        const lineupsService = lineupsServices.find(ls => ls.id === lineupService.id);
        const { service } = lineupsService;
        const isUsed = lineupsServices.some(ls => ls.id !== lineupsService.id && !ls.parent_id && ls.service && ls.service.id === service.id);

        // S'il n'est pas utilisé ailleurs
        if (!isUsed) {
          // Pour chacune de ses techChannels
          const oldTechChannels = service.tech_channels;

          // eslint-disable-next-line no-restricted-syntax
          for (const oldTechChannel of oldTechChannels) {
            // Est-elle utilisée dans un autre service ?
            const otherServicesWithTC = services.find(s => s.orbital_position_id === service.orbital_position_id && s.id !== service.id && s.tech_channels.find(tc => tc.id === oldTechChannel.id));

            // Si elle n'est pas utilisée ailleurs
            if (!otherServicesWithTC) {
              // On la passe en discarded
              await updateTechChannel(oldTechChannel.id, { status: "discarded" });
            }
          }

          // on le supprime
          shouldDeleteService = true;
        }

        await deleteLineupsService(lineupService.id, currentLineup.id);

        if (shouldDeleteService) {
          await deleteService(service.id);
        }
      }

      if (removeMode === "deleting") {
        await deleteEditorialChannel(currentEditorialChannelId);
      } else if (removeMode === "archiving") {
        await updateEditorialChannel(currentEditorialChannelId, { status: "archived" });
      }
    }

    // Go to the next channel
    // If it is the last channel,
    // nextStep() will close the modal
    this.nextStep();
  }

  closeModal() {
    const { closeRemoveChannelModal } = this.props;

    closeRemoveChannelModal();
    this.setState(initialState);
  }

  getCurrentChannel() {
    const { currentStep } = this.state;

    const {
      currentChannelIds,
      editorialChannels
    } = this.props;

    const currentEditorialChannelId = currentChannelIds[currentStep];

    return editorialChannels.find(ec => ec.id === currentEditorialChannelId);
  }

  getServices() {
    const { services } = this.props;

    const currentEditorialChannel = this.getCurrentChannel();

    return services.filter(s => s.channel_id === currentEditorialChannel?.id);
  }

  getLineupsServices() {
    const { lineupsServices } = this.props;

    const currentEditorialChannelId = this.getCurrentChannel()?.id;

    return lineupsServices.filter(ls => ls.service && ls.service.channel_id === currentEditorialChannelId);
  }

  modalContent() {
    const { currentStep, steps } = this.state;
    const { removeMode, lineups, getOrbitalPositionsFromLineupId } = this.props;

    const pitch = steps > 0 ? 100 / steps : 100;
    const currentChannel = this.getCurrentChannel();
    const currentChannelName = getObjectNameFromLocales(currentChannel);
    const currentLineupsServices = this.getLineupsServices();
    const currentLineups = lineups.filter(l => currentLineupsServices.map(ls => ls.lineup_id === l.id));

    return (
      <DialogContent sx={{ display: "flex", gap: 3, flexDirection: "column", marginTop: 1, width: "80%", alignItems: "center", alignSelf: "center" }}>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 2 }}>
          <Typography alignSelf="center">
            {`Channel ${currentStep + 1} / ${steps + 1}`}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={Math.min(currentStep * pitch, 100)}
            sx={{ borderRadius: "10px" }}
          />
        </Box>
        <Typography sx={{ textAlign: "center" }}>{currentChannelName}</Typography>
        {!currentLineupsServices.length
          ? <Alert severity="info">
            This channel is not present in any lineup.
          </Alert>
          : <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Alert severity="warning">
              {"This channel is present in the following lineups. By "
              + removeMode
              + " this channel, it will also remove the channel of these lineups."}
            </Alert>
            <Box sx={{ display: "flex", gap: 4, alignSelf: "flex-start", mt: 3 }}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                <Typography variant="h5" component="span" sx={{ mb: 2 }}>
                  Lineup
                </Typography>
                {
                  currentLineupsServices.map(ls => {
                    const orbitalPositions = getOrbitalPositionsFromLineupId(ls.lineup_id);

                    return (
                      orbitalPositions.map(op =>
                        <Typography key={ls.id}>
                          {getObjectNameFromLocales(currentLineups.find(l => l.id === ls.lineup_id) || {})}
                        </Typography>
                      )
                    );
                  })
                }
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                <Typography variant="h5" component="span" sx={{ mb: 2 }}>
                  Orbital Position
                </Typography>
                {
                  currentLineupsServices.map(ls => {
                    const orbitalPositions = getOrbitalPositionsFromLineupId(ls.lineup_id);

                    return (
                      orbitalPositions.map(op =>
                        <Typography key={ls.id}>
                          {op.name}
                        </Typography>
                      )
                    );
                  })
                }
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                <Typography variant="h5" component="span" sx={{ mb: 2 }}>
                  Service Name
                </Typography>
                {
                  currentLineupsServices.map(ls => {
                    const orbitalPositions = getOrbitalPositionsFromLineupId(ls.lineup_id);

                    return (
                      orbitalPositions.map(op =>
                        <Typography key={ls.id}>
                          {getObjectNameFromLocales(ls.service)}
                        </Typography>
                      )
                    );
                  })
                }
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                <Typography variant="h5" component="span" sx={{ mb: 2 }}>
                  LCN(s)
                </Typography>
                {
                  currentLineupsServices.map(ls => {
                    const orbitalPositions = getOrbitalPositionsFromLineupId(ls.lineup_id);

                    return (
                      orbitalPositions.map(op =>
                        <Typography key={ls.id}>
                          {ls.lcns.join(", ")}
                        </Typography>
                      )
                    );
                  })
                }
              </Box>
            </Box>
          </Box>
        }
      </DialogContent>
    );
  }

  modalActions() {
    const { removeMode } = this.props;
    return (
      [
        <Button variant="outlined"
          key="cancel"
          onClick={() => {
            this.closeModal();
          }} >
            Cancel
        </Button>,
        <Button variant="contained"
          key="next"
          disabled={ false }
          color="secondary"
          onClick={() => {
            this.nextStep();
          }}>
            Ignore this channel
        </Button>,
        <Button variant="contained"
          key="save"
          color="error"
          onClick={() => {
            this.removeChannel();
          }}>
          {removeMode === "deleting" ? "Delete" : "Archive"} it!
        </Button>
      ]
    );
  }

  render() {
    const { modalOpened, removeMode } = this.props;

    return (
      <Modal
        isOpen={modalOpened}
        closeModal={() => this.closeModal()}
        title={removeMode === "deleting" ? "Remove Editorial Channel" : "Archive Editorial Channel"}
        customModalContent={() => this.modalContent()}
        actions={() => this.modalActions()}
        actionAlign="flex-end"
      >
      </Modal>
    );
  }
}

RemoveEditorialChannelModal.defaultProps = {
  removeMode: "deleting"
};

RemoveEditorialChannelModal.propTypes = {
  currentChannelIds: PropTypes.array.isRequired,

  modalOpened: PropTypes.bool.isRequired,
  closeRemoveChannelModal: PropTypes.func.isRequired,

  editorialChannels: PropTypes.array.isRequired,
  lineups: PropTypes.array.isRequired,
  lineupsServices: PropTypes.array.isRequired,
  services: PropTypes.array.isRequired,

  getOrbitalPositionsFromLineupId: PropTypes.func.isRequired,

  updateEditorialChannel: PropTypes.func.isRequired,
  deleteEditorialChannel: PropTypes.func.isRequired,
  deleteLineupsService: PropTypes.func.isRequired,
  updateTechChannel: PropTypes.func.isRequired,
  deleteService: PropTypes.func.isRequired,
  indexServices: PropTypes.func.isRequired,
  indexLineups: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,

  removeMode: PropTypes.string,

  isLoadingServices: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired
};

const mapDispatchToProps = {
  updateEditorialChannel: updateEditorialChannelAction,
  deleteEditorialChannel: deleteEditorialChannelAction,
  deleteLineupsService: deleteLineupsServiceAction,
  updateTechChannel: updateTechChannelAction,
  deleteService: deleteServiceAction,
  indexServices: indexServicesAction,
  indexLineups: indexLineupsAction,
  addToast: addToastAction
};

function mapStateToProps(state) {
  return {
    editorialChannels: listEditorialChannelsSelector(state),
    lineupsServices: listLineupsServicesSelector(state),
    lineups: listLineupsSelector(state),
    services: listServicesSelector(state),
    getOrbitalPositionsFromLineupId: lineupId => getOrbitalPositionsFromLineupIdSelector(state, lineupId),

    isLoadingServices: isLoadingSelector(state, indexServicesAction.toString()),
    isLoading: isLoadingSelector(state, indexLineupsAction.toString())
                || isLoadingSelector(state, updateEditorialChannelAction.toString())
                || isLoadingSelector(state, deleteEditorialChannelAction.toString())
                || isLoadingSelector(state, updateTechChannelAction.toString())
                || isLoadingSelector(state, indexServicesAction.toString())
                || isLoadingSelector(state, deleteServiceAction.toString())
                || isLoadingSelector(state, deleteLineupsServiceAction.toString())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoveEditorialChannelModal);
