import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";

import Header from "../Header";
import GlobalModal from "../GlobalModal";

import "./Core.css";

class Core extends PureComponent {
  render() {
    const {
      pageName,
      pageSlug,
      pageIcon,
      children
    } = this.props;

    return (
      <div id="core" className="core">
        <Header pageName={pageName} pageIcon={pageIcon} />

        {children}

        <GlobalModal pageSlug={pageSlug} />
      </div>
    );
  }
}

Core.defaultProps = {
  pageIcon: faMinusCircle
};

Core.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  pageName: PropTypes.string.isRequired,
  pageSlug: PropTypes.string.isRequired,
  pageIcon: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string
  ])
};

export default Core;
