import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import versions from "../../containers/Changelog/versions";

import logo from "../../assets/eutelsat-logo.svg";
import eLogo from "../../assets/e-logo.svg";
import "./Logo.css";

export default function Logo() {
  return (
    <Box id="logo">
      <Box className="logoContainer">
        <img src={eLogo} alt="SatTv manager" />
        <img src={logo} alt="SatTv manager" />
      </Box>
      <Typography variant="h3" className="headTitle">SATTV - EVMM</Typography>
      <Box className="versionId">
        <Link to="/changelog">
          v
          {versions[0].version}
        </Link>
      </Box>
    </Box>
  );
}
