import { handleActions } from "redux-actions";

const initialState = {
  allContentGenres: [],
  filters: [],
  totalCount: 0
};

const contentGenresReducer = handleActions(
  {
    STORE_CONTENT_GENRES: (state, action) => ({
      ...state,
      allContentGenres: action.payload.result,
      totalCount: action.payload.total_count || 0
    }),
    STORE_CONTENT_GENRES_FILTERS: (state, action) => ({
      ...state,
      filters: action.payload.result
    }),
    ADD_CONTENT_GENRE: (state, action) => {
      const allContentGenres = [...state.allContentGenres];

      allContentGenres.push(action.payload.result);

      return {
        ...state,
        allContentGenres,
        totalCount: state.totalCount + 1
      };
    },
    MODIFY_CONTENT_GENRE: (state, action) => {
      const newState = {
        ...state,
        allContentGenres: [...state.allContentGenres]
      };

      const contentGenreIndex = newState.allContentGenres.findIndex(cg => action.payload.result.id === cg.id);

      newState.allContentGenres[contentGenreIndex] = action.payload.result;

      return newState;
    },
    REMOVE_CONTENT_GENRE: (state, action) => {
      const contentGenreIndex = state.allContentGenres.findIndex(cg => action.payload.id === cg.id);
      const allContentGenres = Array.from(state.allContentGenres);

      allContentGenres.splice(contentGenreIndex, 1);

      return {
        ...state,
        allContentGenres,
        totalCount: contentGenreIndex > -1 ? state.totalCount - 1 : state.totalCount
      };
    }
  },
  initialState
);

export default contentGenresReducer;
