import { handleActions } from "redux-actions";

const initialState = {
  allLineupsServices: [],
  filters: [],
  totalCount: 0
};

const lineupsServicesReducer = handleActions(
  {
    STORE_LINEUPS_SERVICES: (state, action) => ({
      ...state,
      allLineupsServices: action.payload.result,
      totalCount: action.payload.total_count || 0
    }),
    STORE_LINEUPS_SERVICES_FILTERS: (state, action) => ({
      ...state,
      filters: action.payload.result
    }),
    STORE_SINGLE_LINEUPS_SERVICES: (state, action) => {
      const allLineupsServices = [...state.allLineupsServices];
      let { totalCount } = state;
      const lineupServiceIndex = allLineupsServices.findIndex(ls => ls.id === action.payload.result.id);

      if (lineupServiceIndex > -1) {
        allLineupsServices[lineupServiceIndex] = action.payload.result;
      } else {
        allLineupsServices.push(action.payload.result);
      }

      totalCount += 1;

      return {
        ...state,
        allLineupsServices,
        totalCount
      };
    },
    ADD_LINEUPS_SERVICE: (state, action) => {
      const allLineupsServices = [...state.allLineupsServices];

      allLineupsServices.push(action.payload.result);

      return {
        ...state,
        allLineupsServices,
        totalCount: state.totalCount + 1
      };
    },
    MODIFY_LINEUPS_SERVICE: (state, action) => {
      const newState = {
        ...state
      };

      newState.allLineupsServices = state.allLineupsServices.map(ls => {
        if (action.payload.result.id === ls.id) {
          ls = action.payload.result;
          if (ls.service_id) {
            // Find parent service object and genres array from service_id
            const { service, genres } = state.allLineupsServices.find(({ service }) => service?.id === ls.service_id);
            ls = {
              ...ls,
              service,
              genres
            };
          }
        }
        return ls;
      });

      return newState;
    },
    MODIFY_MANY_LINEUPS_SERVICES: (state, { payload }) => {
      const newState = {
        ...state
      };

      payload.forEach(({ data: lineupServiceToUpdate }) => {
        const indexToUpdate = state.allLineupsServices.findIndex(ls => ls.id === lineupServiceToUpdate.id);
        let newLineupService = null;
        if (indexToUpdate > -1) {
          newLineupService = lineupServiceToUpdate;
          if (newLineupService.service_id) {
            // Find parent service object and genres array from service_id
            const { service, genres } = state.allLineupsServices.find(({ service }) => service?.id === newLineupService.service_id);
            newLineupService = {
              ...newLineupService,
              service,
              genres
            };
          }

          newState.allLineupsServices[indexToUpdate] = newLineupService;
        }
      });

      return newState;
    },
    REMOVE_LINEUPS_SERVICE: (state, action) => {
      const allLineupsServices = state.allLineupsServices.filter(ls => ls.id !== action.payload.id && ls.parent_id !== action.payload.id);

      return {
        ...state,
        allLineupsServices,
        totalCount: allLineupsServices.length
      };
    },

    REMOVE_MANY_LINEUPS_SERVICES: (state, { payload }) => {
      const allLineupsServices = state.allLineupsServices.filter(ls => !payload.includes(ls.id) && !payload.includes(ls.parent_id));

      return {
        ...state,
        allLineupsServices,
        totalCount: allLineupsServices.length
      };
    },

    STORE_LINEUPS_SERVICE_LOGO: (state, action) => {
      const lineupServiceIndex = state.allLineupsServices.findIndex(ec => action.payload.id === ec.id);
      const allLineupsServices = Array.from(state.allLineupsServices);

      if (lineupServiceIndex !== -1) {
        allLineupsServices[lineupServiceIndex].logos.push(action.payload.result);
      }

      return {
        ...state,
        allLineupsServices
      };
    },
    REMOVE_LINEUPS_SERVICE_LOGO: (state, action) => {
      const lineupServiceIndex = state.allLineupsServices.findIndex(ec => action.payload.id === ec.id);
      const allLineupsServices = Array.from(state.allLineupsServices);

      if (lineupServiceIndex !== -1) {
        allLineupsServices[lineupServiceIndex].logos = allLineupsServices[lineupServiceIndex].logos.filter(l => l.id !== action.payload.logoId);
      }

      return {
        ...state,
        allLineupsServices
      };
    },
    STORE_LINEUP_SERVICE_GENRE: (state, action) => {
      const lineupServiceIndex = state.allLineupsServices.findIndex(ec => action.payload.id === ec.id);
      const allLineupsServices = Array.from(state.allLineupsServices);

      if (lineupServiceIndex !== -1) {
        allLineupsServices[lineupServiceIndex].genres.push(action.payload.result);
      }

      return {
        ...state,
        allLineupsServices
      };
    },
    REMOVE_LINEUPS_SERVICE_GENRE: (state, action) => {
      const lineupServiceIndex = state.allLineupsServices.findIndex(ec => action.payload.id === ec.id);
      const allLineupsServices = Array.from(state.allLineupsServices);

      if (lineupServiceIndex !== -1) {
        allLineupsServices[lineupServiceIndex].genres = allLineupsServices[lineupServiceIndex].genres.filter(l => l.id !== action.payload.genreId);
      }

      return {
        ...state,
        allLineupsServices
      };
    },
    SELECT_ORBITAL_POSITIONS: () => initialState
  },
  initialState
);

export default lineupsServicesReducer;
