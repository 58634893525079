import React from "react";
import PropTypes from "prop-types";
import { Box, DialogContent, Typography, Alert } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import Modal from "../Modal/Modal";

import { convertISOToDate } from "../../helpers/utils";

function percentage(num) {
  return `${(num * 100).toFixed(3)}%`;
}

const ok = <FontAwesomeIcon icon={faCheckCircle} className="green-icon" />;
const ko = <FontAwesomeIcon icon={faExclamationTriangle} className="red-icon" />;

const summaryFields = [
  "Total",
  "Created",
  "Updated",
  "Deleted",
  "Errors"
];

function modalContent(currentEpgReport) {
  const { editorial_content_reports_stats, event_reports_stats, missing_fields, mandatory_fields } = currentEpgReport;

  return (
    editorial_content_reports_stats?.length !== 0
      ? <DialogContent sx={{ display: "flex", flexDirection: "column", width: "90%", alignSelf: "center", my: 3, gap: 4 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <Typography variant="h3" component="span" sx={{ mb: 2, pl: 2 }}>
          Summary
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography variant="h4" component="span" sx={{ pl: 2 }}>
            Events
            </Typography>
            <Box>
              <Box sx={{ display: "flex", justifyContent: "space-between", flex: 1, backgroundColor: "grey.grey5", py: 2 }}>
                {summaryFields.map((field, index) =>
                  <Box key={index} sx={{ display: "flex", flexDirection: "column", flex: 1, pl: 1 }}>
                    <Typography>
                      {field}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between", flex: 1, borderBottom: "0.5px grey solid", borderTop: "0.5px grey solid" }}>
                {summaryFields.map((value, index) =>
                  <Box key={index} sx={{ display: "flex", flexDirection: "column", flex: 1, pl: 1 }}>
                    <Typography sx={{ py: 0.6 }}>
                      {event_reports_stats[value.toLowerCase()] || 0}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography variant="h4" component="span" sx={{ pl: 2 }}>
            EditorialContents
            </Typography>
            <Box>
              <Box sx={{ display: "flex", justifyContent: "space-between", flex: 1, backgroundColor: "grey.grey5", py: 2 }}>
                {summaryFields.map((field, index) =>
                  <Box key={index} sx={{ display: "flex", flexDirection: "column", flex: 1, pl: 1 }}>
                    <Typography>
                      {field}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between", flex: 1, borderBottom: "0.5px grey solid", borderTop: "0.5px grey solid" }}>
                {summaryFields.map((value, index) =>
                  <Box key={index} sx={{ display: "flex", flexDirection: "column", flex: 1, pl: 1 }}>
                    <Typography sx={{ py: 0.6 }}>
                      {editorial_content_reports_stats[value.toLowerCase()] || 0}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h3" component="span" sx={{ pl: 2, mb: 2 }}>
          Mandatory Fields
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between", flex: 1, backgroundColor: "grey.grey5", py: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "column", flex: 0.2, pl: 1 }}>
              <Typography>
            Status
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", flex: 1, pl: 1 }}>
              <Typography>
            Field
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", flex: 0.3, pl: 1 }}>
              <Typography>
            Coverage
              </Typography>
            </Box>
          </Box>
          {mandatory_fields.map((field, index) =>
            <Box key={index} sx={{ display: "flex", justifyContent: "space-between", flex: 1, borderBottom: "0.5px grey solid", borderTop: !index && "0.5px grey solid" }}>
              <Box sx={{ display: "flex", flexDirection: "column", flex: 0.2, pl: 1 }}>
                <Typography sx={{ py: 0.6 }}>
                  {field?.coverage !== 1 ? ko : ok}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", flex: 1, pl: 1 }}>
                <Typography sx={{ py: 0.6 }}>
                  {field?.resource + " / " + field?.field}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", flex: 0.3, pl: 1 }}>
                <Typography sx={{ py: 0.6 }}>
                  {percentage(field?.coverage)}
                </Typography>
              </ Box>
            </Box>
          )}
        </Box>
        {missing_fields?.length > 0
      && <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h3" component="span" sx={{ pl: 2, mb: 2 }}>
          Missing Data
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", flex: 1, backgroundColor: "grey.grey5", py: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "column", flex: 0.5, pl: 1 }}>
            <Typography>
            Resource
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", flex: 0.7, pl: 1 }}>
            <Typography>
            Field
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", flex: 1, pl: 1 }}>
            <Typography>
            Provider Resource Id
            </Typography>
          </Box>
        </Box>
        {missing_fields.map((data, index) =>
          <Box key={index} sx={{ display: "flex", justifyContent: "space-between", flex: 1, borderBottom: "0.5px grey solid", borderTop: !index && "0.5px grey solid" }}>
            <Box sx={{ display: "flex", flexDirection: "column", flex: 0.5, pl: 1 }}>
              <Typography sx={{ py: 0.6 }}>
                {data.resource}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", flex: 0.7, pl: 1 }}>
              <Typography sx={{ py: 0.6 }}>
                {data.field}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", flex: 1, pl: 1 }}>
              <Typography sx={{ py: 0.6 }}>
                {data.provider_resource_id}
              </Typography>
            </ Box>
          </Box>
        )}
      </Box>
        }
      </DialogContent>
      : <DialogContent sx={{ width: "80%", my: 3, alignSelf: "center", display: "flex" }}>
        <Alert severity="warning" sx={{ width: "100%" }}>
          This file matched no channel in EVMM.
        </Alert>
      </DialogContent>
  );
}

export default function EpgReportModal(props) {
  const {
    modalOpened,
    currentEpgReport,
    onClose
  } = props;

  if (!currentEpgReport) {
    return null;
  }

  const new_missing_fields = currentEpgReport.missing_fields.flatMap(elem => elem.fields.map(f => ({ ...elem, field: f })));

  const fileName = currentEpgReport.sync_filename;
  const processedAt = currentEpgReport.created_at;
  const title = fileName + ", " + convertISOToDate(processedAt);

  return (
    <Modal
      isOpen={modalOpened}
      title={title}
      closeModal={() => onClose()}
      customModalContent={() => modalContent({ ...currentEpgReport, missing_fields: new_missing_fields })}
      maxWidth="lg"
    >
    </Modal>
  );
}

EpgReportModal.defaultProps = {
  currentEpgReport: null
};

EpgReportModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  /* eslint-disable react/forbid-prop-types */
  currentEpgReport: PropTypes.shape({
    sync_filename: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    missing_fields: PropTypes.array.isRequired
  }),
  /* eslint-enable react/forbid-prop-types */
  modalOpened: PropTypes.bool.isRequired
};
