import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Menu, MenuItem, Stack } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
  indexOrbitalPositions as indexOrbitalPositionsAction,
  createOrbitalPosition as createOrbitalPositionAction,
  indexOrbitalPositionsFilters as indexOrbitalPositionsFiltersAction,
  updateOrbitalPosition as updateOrbitalPositionAction,
  deleteOrbitalPosition as deleteOrbitalPositionAction
} from "../../actions/orbital_positions";
import {
  listOrbitalPositions as listOrbitalPositionsSelector,
  listOrbitalPositionsFilters as listOrbitalPositionsFiltersSelector,
} from "../../selectors/orbital_positions";
import { isLoading as isLoadingSelector } from "../../selectors/loaders";

import RemoveOrbitalPositionModal from "../OrbitalPositionsModal/RemoveOrbitalPositionModal";
import EditOrbitalPositionsModal from "../OrbitalPositionsModal/EditOrbitalPositionModal";

import "./OrbitalPositions.css";
import DataTable from "../DataTable";

class OrbitalPositions extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentOrbitalPosition: null,

      removeOrbitalPositionOpened: false,
      selectedOrbitalPositionsIds: [],

      modalOpened: false,
      anchorEl: null
    };
  }

  setSelectedOrbitalPositionsIds = newSelection => {
    this.setState({
      selectedOrbitalPositionsIds: newSelection,
    });
  };

  /* Action Menu */
  setAnchorEl = target => {
    this.setState({
      anchorEl: target
    });
  };

  openActionsMenu = event => {
    this.setAnchorEl(event.currentTarget);
  };
  closeActionsMenu = () => {
    this.setAnchorEl(null);
  };

  /* Edit Modal */

  openEditModal = currentOrbitalPosition => {
    this.setState({
      currentOrbitalPosition: currentOrbitalPosition,
      modalOpened: true
    });
  };

  closeEditModal = () => {
    this.setState({
      currentOrbitalPosition: null,
      modalOpened: false
    });
  };

  /* Remove Modal */

  deleteOrbitalPosition = async selectedOrbitalPositionsIds => {
    this.setState({
      selectedOrbitalPositionsIds,
      removeOrbitalPositionOpened: true
    });
  };

  closeRemoveChannelModal = () => {
    this.setState({
      selectedOrbitalPositionsIds: [],
      removeOrbitalPositionOpened: false
    });
  };

  /* Modal */

  render() {
    const {
      currentOrbitalPosition,
      selectedOrbitalPositionsIds,
      removeOrbitalPositionOpened,
      modalOpened,
      anchorEl
    } = this.state;

    const {
      orbitalPositions
    } = this.props;

    const open = Boolean(anchorEl);

    return (
      <Stack spacing={2}>
        <Button
          id="actions-button"
          sx={{
            width: "5%",
            minWidth: 120,
            borderColor: "primary.smokeBorder",
            borderRadius: "7px"
          }}
          variant="outlined"
          aria-controls={open ? "actions-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          disableElevation
          onClick={e => this.openActionsMenu(e)}
          endIcon={<KeyboardArrowDownIcon />}
          disabled={ selectedOrbitalPositionsIds.length <= 0 }
        >
          Actions
        </Button>
        <Menu
          MenuListProps={{
            "aria-labelledby": "actions-button",
          }}
          id="actions-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => this.closeActionsMenu()}
        >
          <MenuItem
            sx={{
              color: "red"
            }}
            onClick={() => {
              this.closeActionsMenu();
              this.deleteOrbitalPosition(selectedOrbitalPositionsIds);
            }}
            disableRipple
          >
            Delete selected orbital positions
          </MenuItem>
        </Menu>
        <div style={{ height: "calc(100vh - 270px)", minHeight: "160px" }}>
          <DataTable
            cols={[
              { headerName: "Id", field: "id", flex: 1 },
              { headerName: "Orbital Position", field: "name", flex: 1 },
              { headerName: "ProDBR Id", field: "prodbr_id", flex: 1 },
              { headerName: "Modified", field: "updated_at", flex: 1 },
            ]}
            data={orbitalPositions}
            onRowClick={id => this.openEditModal(id)}
            onRowSelectionModelChange={this.setSelectedOrbitalPositionsIds}
            checkboxSelection
          />
        </div>

        {/* MODAL */}

        <EditOrbitalPositionsModal
          currentId={currentOrbitalPosition?.id}
          modalOpened={modalOpened}
          closeEditModal={this.closeEditModal}
        />

        <RemoveOrbitalPositionModal
          currentOrbitalPositionIds={selectedOrbitalPositionsIds}
          modalOpened={removeOrbitalPositionOpened}
          closeOrbitalPositionModal={this.closeRemoveChannelModal}
          orbitalPositions={orbitalPositions}
          checkboxSelection={false}
        />
      </Stack>
    );
  }
}

OrbitalPositions.propTypes = {
  orbitalPositions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

function mapStateToProps(state) {
  return {
    orbitalPositions: listOrbitalPositionsSelector(state),
    orbitalPositionsFilters: listOrbitalPositionsFiltersSelector(state),

    isOrbitalPositionsLoading: isLoadingSelector(state, indexOrbitalPositionsAction.toString()),
    isOrbitalPositionsCreating: isLoadingSelector(state, createOrbitalPositionAction.toString()),
    isOrbitalPositionsEditing: isLoadingSelector(state, updateOrbitalPositionAction.toString())
  };
}

const mapDispatchToProps = {
  indexOrbitalPositions: indexOrbitalPositionsAction,
  indexOrbitalPositionsFilters: indexOrbitalPositionsFiltersAction,
  updateOrbitalPosition: updateOrbitalPositionAction,
  deleteOrbitalPosition: deleteOrbitalPositionAction
};

export default connect(mapStateToProps, mapDispatchToProps)(OrbitalPositions);
