import { handleActions } from "redux-actions";

const initialState = {
  allImportances: [],
  filters: [],
  totalCount: 0
};

const importancesReducer = handleActions(
  {
    STORE_IMPORTANCES: (state, action) => ({
      ...state,
      allImportances: action.payload.result,
      totalCount: action.payload.total_count || 0
    }),
    STORE_IMPORTANCES_FILTERS: (state, action) => ({
      ...state,
      filters: action.payload.result
    }),
    ADD_IMPORTANCE: (state, action) => {
      const allImportances = [...state.allImportances];

      allImportances.push(action.payload.result);

      return {
        ...state,
        allImportances,
        totalCount: state.totalCount + 1
      };
    },
    MODIFY_IMPORTANCE: (state, action) => {
      const newState = {
        ...state,
        allImportances: [...state.allImportances]
      };

      const importancesIndex = newState.allImportances.findIndex(ob => action.payload.result.id === ob.id);

      newState.allImportances[importancesIndex] = action.payload.result;

      return newState;
    },
    REMOVE_IMPORTANCE: (state, action) => {
      const importancesIndex = state.allImportances.findIndex(ob => action.payload.id === ob.id);
      const allImportances = Array.from(state.allImportances);

      allImportances.splice(importancesIndex, 1);

      return {
        ...state,
        allImportances,
        totalCount: importancesIndex > -1 ? state.totalCount - 1 : state.totalCount
      };
    }
  },
  initialState
);

export default importancesReducer;
