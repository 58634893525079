import { handleActions } from "redux-actions";

const initialState = {
  allQualifications: [],
  filters: [],
  totalCount: 0
};

const qualificationsReducer = handleActions(
  {
    STORE_QUALIFICATIONS: (state, action) => ({
      ...state,
      allQualifications: action.payload.result,
      totalCount: action.payload.total_count || 0
    }),
    STORE_QUALIFICATIONS_FILTERS: (state, action) => ({
      ...state,
      filters: action.payload.result
    }),
    ADD_QUALIFICATION: (state, action) => {
      const allQualifications = [...state.allQualifications];

      allQualifications.push(action.payload.result);

      return {
        ...state,
        allQualifications,
        totalCount: state.totalCount + 1
      };
    },
    MODIFY_QUALIFICATION: (state, action) => {
      const newState = {
        ...state
      };

      const qualificationIndex = newState.allQualifications.findIndex(q => action.payload.result.id === q.id);

      newState.allQualifications[qualificationIndex] = action.payload.result;

      return newState;
    },
    REMOVE_QUALIFICATION: (state, action) => {
      const qualificationIndex = state.allQualifications.findIndex(q => action.payload.id === q.id);
      const allQualifications = Array.from(state.allQualifications);

      allQualifications.splice(qualificationIndex, 1);

      return {
        ...state,
        allQualifications,
        totalCount: qualificationIndex > -1 ? state.totalCount - 1 : state.totalCount
      };
    }
  },
  initialState
);

export default qualificationsReducer;
