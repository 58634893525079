/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AceEditor from "react-ace";
import { isEqual } from "lodash";

import {
  Alert, Box,
  Button,
  DialogContent, Fab, FormControl,
  FormControlLabel, FormHelperText,
  InputLabel, MenuItem, Paper, Select,
  Stack,
  Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { addToast as addToastAction } from "../../actions/toasts";

import {
  indexServices as indexServicesAction,
  updateService as updateServiceAction,
  disassociateTechChannelsToService as disassociateTechChannelsToServiceAction,
  associateTechChannelsToService as associateTechChannelsToServiceAction,
  deleteService as deleteServiceAction
} from "../../actions/services";
import {
  updateLineupsService as updateLineupsServiceAction,
  updateLineupsServiceWithNewService as updateLineupsServiceWithNewServiceAction,
  createLineupsServiceLogo as createLineupsServiceLogoAction,
  removeLineupsServiceLogo as removeLineupsServiceLogoAction,
  showLineupsServices as showLineupsServicesAction
} from "../../actions/lineups_services";
import {
  addGenreToLineupService as addGenreToLineupServiceAction,
  removeGenreFromLineupService as removeGenreFromLineupServiceAction
} from "../../actions/lineups_services_genres";
import { updateTechChannel as updateTechChannelAction } from "../../actions/tech_channels";
import { updateEditorialChannel as updateEditorialChannelAction } from "../../actions/editorial_channels";

import { listChannelTiers } from "../../selectors/channel_tiers";
import { listEditorialChannels } from "../../selectors/editorial_channels";
import { showLineup } from "../../selectors/lineups";
import withStepper from "../Modal/withStepper";
import {
  listLineupsServicesByLineupId,
  listLineupsServices, showLineupsService
} from "../../selectors/lineups_services";
import { listServices } from "../../selectors/services";
import { listServiceGenres } from "../../selectors/service_genres";
import { listTechChannelsFromOrbitalPosition } from "../../selectors/tech_channels";
import { listTranspondersFromOrbitalPosition } from "../../selectors/transponders";
import { isLoading as isLoadingSelector } from "../../selectors/loaders";

import {
  sortObjectByLocale,
  toJsonString,
  capitalize,
  isValidJson,
  isValidLocaleJson,
  getObjectNameFromLocales
} from "../../helpers/utils";
import { getImageAttributes } from "../../helpers/images";

import FullLoader from "../FullLoader";
import Modal from "../Modal/Modal";

const initialState = {
  step: 0,

  lcns: "",
  channel_tier_id: 0,
  channel_id: 0,
  tech_channel_ids: [],
  service_id: 0,
  genre_ids: [],
  default_language: "",
  locales: toJsonString("{\"en_GB\":{\"name\":\"\"}}"),
  editingService: true,

  additionalImages: [],
  additionalImagesPreviews: [],

  isSaving: false,
  hasFetchingService: false,

  formErrors: []
};

class EditLineupServiceModal extends PureComponent {
  constructor(props) {
    super(props);

    let state = {
      ...initialState,
      step: props.editLineupServiceStep > -1 ? props.editLineupServiceStep : initialState.step
    };

    if (props.lineupService) {
      const {
        channel_tier_id,
        lcns = [],
        genres = [],
        logos = [],
        service: {
          id,
          channel_id,
          locales = {},
          default_language,
          tech_channels = []
        } = {}
      } = props.lineupService;

      const realAdditionalsImages = (logos || []).filter(logo => logo.additional === true);

      const additionalImagesPreviews = realAdditionalsImages.map(logo => ({
        id: logo.id,
        url: logo.file_url
      }));

      state = {
        ...state,

        lcns: lcns.join(","),
        channel_tier_id,
        channel_id,
        tech_channel_ids: tech_channels.map(tc => tc.id),
        service_id: id,
        genre_ids: genres.map(g => g.id),
        default_language,
        locales: toJsonString(JSON.stringify(locales)),
        editingService: true,
        additionalImages: realAdditionalsImages,
        additionalImagesPreviews
      };
    }

    this.state = { ...state };

    this.additionalInput = React.createRef();

    this.addAdditional = this.addAdditional.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {
      indexServices,
      editLineupServiceStep,
      orbitalPositionId,
      services,
      isLoadingServices,
      lineupService,
      modalOpened
    } = this.props;

    const {
      hasFetchingService,
    } = this.state;

    if (!hasFetchingService && orbitalPositionId && services.length === 0 && !isLoadingServices) {
      indexServices({ orbital_position_id: orbitalPositionId }, true);
      this.setState({ hasFetchingService: true });
    }

    if (prevProps.modalOpened !== modalOpened) {
      const {
        channel_tier_id,
        lcns = [],
        logos = [],
        genres = [],
        service: {
          id,
          channel_id,
          locales = {},
          default_language,
          tech_channels = []
        } = {}
      } = lineupService;

      const realAdditionalsImages = (logos || []).filter(logo => logo.additional === true);

      const additionalImagesPreviews = realAdditionalsImages.map(logo => ({
        id: logo.id,
        url: logo.file_url
      }));

      this.setState({
        lcns: lcns.join(","),
        channel_tier_id,
        channel_id,
        tech_channel_ids: tech_channels.map(tc => tc.id),
        service_id: id,
        genre_ids: genres.map(g => g.id),
        default_language,
        locales: toJsonString(JSON.stringify(locales)),
        editingService: true,
        additionalImages: realAdditionalsImages,
        additionalImagesPreviews,
      });
    }

    if (prevProps.editLineupServiceStep !== parseInt(editLineupServiceStep, 10)
      && parseInt(editLineupServiceStep, 10) > -1
      && parseInt(editLineupServiceStep, 10) < 6) {
      this.setState({ step: parseInt(editLineupServiceStep, 10) });
    }
  }

  formatLCNS = (lcnsOverride = "") => {
    const {
      lcns
    } = this.state;

    const validLcnsArray = [];
    const lcnsToProcess = lcnsOverride.length > 0 ? lcnsOverride : lcns;

    if (lcnsToProcess.length === 0) {
      return [];
    }

    lcnsToProcess
      .split(",")
      .map(lcn => lcn.trim())
      .filter(e => e)
      .forEach(lcn => {
        const validLcn = lcn.replace(/ /gi, "");

        if (!isNaN(validLcn) && validLcnsArray.indexOf(parseInt(validLcn, 10)) === -1) {
          validLcnsArray.push(parseInt(validLcn, 10));
        }
      });

    validLcnsArray.sort((a, b) => {
      if (a < b) {
        return -1;
      }

      if (a > b) {
        return 1;
      }

      return 0;
    });

    return validLcnsArray;
  };

  changeInput(inputName, event) {
    const {
      default_language,
      editingService
    } = this.state;

    const { value } = event.target;

    this.setState(() => ({ [inputName]: value }), () => {
      if (inputName === "channel_id") {
        if (editingService) {
          this.prefillLanguageAndLocales();
        } else {
          this.setState(() => ({ service_id: 0 }), () => {
            if (this.stepIsValid()) {
              this.prefillServiceData();
            }
          });
        }
      } else if (inputName === "locales") {
        let languageFound = true;

        try {
          const localesJson = JSON.parse(value);

          languageFound = Object.keys(localesJson).indexOf(default_language) !== -1;
        } catch {
          languageFound = false;
        }

        if (!languageFound) {
          this.setState(() => ({ default_language: initialState.default_language }));
        }
      }
    });
  }

  toggleInput(inputName) {
    const {
      lineupService
    } = this.props;

    const {
      service: {
        id,
        channel_id,
        locales = {},
        default_language,
        tech_channels = []
      } = {}
    } = lineupService;

    const { [inputName]: inputValue } = this.state;

    this.setState({ [inputName]: !inputValue }, () => {
      if (inputName === "editingService") {
        this.setState({
          channel_id,
          tech_channel_ids: tech_channels.map(tc => tc.id),
          service_id: id,
          default_language,
          locales: toJsonString(JSON.stringify(locales))
        });
      }
    });
  }

  isTechChannelsSelected(techChannelId) {
    const { tech_channel_ids } = this.state;

    return tech_channel_ids.indexOf(parseInt(techChannelId, 10)) !== -1;
  }

  prefillLanguageAndLocales() {
    const { channel_id } = this.state;
    const { editorialChannels } = this.props;

    // prefill default_language and locales
    const editorialChannel = editorialChannels.find(ec => ec.id === parseInt(channel_id, 10)) || {};
    const newLocales = editorialChannel.locales ? editorialChannel.locales : initialState.locales;
    let defaultLanguage = "";

    if (newLocales && newLocales[editorialChannel.default_language]) {
      defaultLanguage = editorialChannel.default_language;
    } else if (newLocales && newLocales[initialState.default_language]) {
      defaultLanguage = initialState.default_language;
    }

    this.setState(() => ({
      locales: toJsonString(JSON.stringify(newLocales)),
      default_language: defaultLanguage
    }));
  }

  prefillServiceData() {
    const {
      channel_id,
      tech_channel_ids
    } = this.state;

    const {
      services,
      editorialChannels
    } = this.props;

    // Set service data
    const existingService = services.find(s => {
      const serviceTechChannelsIds = s.tech_channels.map(tc => tc.id);

      if (parseInt(s.channel_id, 10) === parseInt(channel_id, 10)
        && isEqual(serviceTechChannelsIds.sort(), tech_channel_ids.sort())) {
        return true;
      }

      return false;
    });

    // if a service already exists with selected tech_channels and editorial_channels
    // prefill default_language and locales
    const editorialChannel = editorialChannels.find(ec => ec.id === parseInt(channel_id, 10));

    if (existingService) {
      // use existing service
      // = prefill locales and default_language with service data

      const newLocales = existingService.locales ? existingService.locales : editorialChannel.locales;
      let defaultLanguage = "";

      if (newLocales[existingService.default_language]) {
        defaultLanguage = existingService.default_language;
      } else if (newLocales[editorialChannel.default_language]) {
        defaultLanguage = editorialChannel.default_language;
      } else if (initialState.locales && initialState.locales[initialState.default_language]) {
        defaultLanguage = initialState.default_language;
      }

      this.setState(() => ({
        locales: toJsonString(JSON.stringify(newLocales)),
        default_language: defaultLanguage,
        service_id: existingService.id
      }));
    } else {
      // create new service
      // = prefill locales and default_language with editorial_channel data

      const newLocales = editorialChannel.locales ? editorialChannel.locales : initialState.locales;
      let defaultLanguage = "";

      if (newLocales && newLocales[editorialChannel.default_language]) {
        defaultLanguage = editorialChannel.default_language;
      } else if (newLocales && newLocales[initialState.default_language]) {
        defaultLanguage = initialState.default_language;
      }

      this.setState(() => ({
        locales: toJsonString(JSON.stringify(newLocales)),
        default_language: defaultLanguage,
        service_id: -1
      }));
    }
  }

  toggleTechChannelsIds(techChannelId) {
    const { tech_channel_ids, editingService } = this.state;

    if (this.isTechChannelsSelected(techChannelId)) {
      const newTechChannelsIds = tech_channel_ids.filter(tcId => tcId !== techChannelId);

      this.setState({ tech_channel_ids: newTechChannelsIds }, () => {
        if (this.stepIsValid() && !editingService) {
          this.prefillServiceData();
        }
      });
    } else {
      const newTechChannelsIds = tech_channel_ids.slice(0);

      newTechChannelsIds.push(parseInt(techChannelId, 10));

      this.setState({ tech_channel_ids: newTechChannelsIds }, () => {
        if (this.stepIsValid() && !editingService) {
          this.prefillServiceData();
        }
      });
    }
  }

  isGenreSelected(genreId) {
    const { genre_ids } = this.state;

    return genre_ids.indexOf(parseInt(genreId, 10)) !== -1;
  }

  toggleGenreIds(genreId) {
    const { genre_ids } = this.state;

    if (this.isGenreSelected(genreId)) {
      const newGenreIds = genre_ids.filter(gId => gId !== genreId);

      this.setState({ genre_ids: newGenreIds });
    } else {
      const newGenreIds = genre_ids.slice(0);

      newGenreIds.push(parseInt(genreId, 10));

      this.setState({ genre_ids: newGenreIds });
    }
  }

  async updateChannelInLineup() {
    const {
      service_id,
      channel_tier_id,

      default_language,
      channel_id,
      locales,

      additionalImages,

      tech_channel_ids,

      genre_ids
    } = this.state;

    const {
      orbitalPositionId,

      lineupService,
      services,
      lineupServices,
      editorialChannels,

      updateLineupsService,
      showLineupsServices,
      updateService,
      deleteService,
      updateTechChannel,
      updateEditorialChannel,
      disassociateTechChannelsToService,
      associateTechChannelsToService,
      addGenreToLineupService,
      removeGenreFromLineupService,
      updateLineupsServiceWithNewService,
      createLineupsServiceLogo,
      removeLineupsServiceLogo,
      addToast
    } = this.props;

    this.setState({ isSaving: true });

    const validLcnsArray = this.formatLCNS();
    let name = "";
    let localeJson = {};

    try {
      localeJson = JSON.parse(locales);

      name = localeJson[default_language].name;

      let firstVerification = true;
      let existingService;

      while (firstVerification || existingService) {
        firstVerification = false;
        // eslint-disable-next-line
        existingService = services.some((s) => s.id !== parseInt(service_id, 10) && s.name === name);

        if (existingService) {
          name += " ";
        } else {
          break;
        }
      }
    // eslint-disable-next-line no-empty
    } catch {}

    if (parseInt(service_id, 10) === -1) {
      // Create new service
      const data = {
        service: {
          orbital_position_id: orbitalPositionId,
          name,
          default_language,
          channel_id,
          locales: localeJson
        },
        techChannels: {
          ids: tech_channel_ids
        },
        lineupService: {
          id: lineupService.id,
          channel_tier_id,
          lcns: validLcnsArray
        }
      };

      await updateLineupsServiceWithNewService(data);
    } else {
      // Existing service

      // Update service
      await updateService(parseInt(service_id, 10), {
        default_language,
        channel_id,
        name,
        locales: localeJson
      });

      // Update Lineup Service
      // service_id could change
      await updateLineupsService(lineupService.id, {
        channel_tier_id,
        service_id,
        lcns: validLcnsArray
      });
    }

    // SI on crée un nouveau service OU le service_id a changé
    //   => On traite l'ancien service
    //     SI il est utilisé ailleurs => on ne fait rien
    //     SI il n'est pas utilisé ailleurs
    //       => chacune de ses techChannels
    //          => Si elle est utilisée dans un autre service, on ne fait rien
    //             Si elle n'est pas utilisée dans un autre service, on la passe en pending
    //       => on le supprime
    //
    // SI le service_id n'a pas changé
    //   => On déassocie les techChannels plus utilisées de ce service
    //   => Puis Pour chacune d'entre elle
    //        SI elle est utilisée dans un autre service, on ne fait rien
    //        SI elle n'est pas utilisée dans un autre service, on la passe en pending
    //   => Puis pour les tech_channels_ids
    //        => on les associe
    //
    // Puis
    //   => on passe les tech_channel_ids en active

    // Nouveau service ou changement de service
    if (parseInt(service_id, 10) === -1 || parseInt(service_id, 10) !== lineupService.service.id) {
      // On traite l'ancien service
      const oldService = lineupService.service;

      // Est-il utilisé ailleurs ?
      const isUsed = lineupServices.some(ls => ls.id !== lineupService.id && !ls.parent_id && ls.service.id === oldService.id);

      // S'il n'est pas utilisé ailleurs
      if (!isUsed) {
        // Pour chacune de ses techChannels
        const oldTechChannels = oldService.tech_channels;

        // eslint-disable-next-line no-restricted-syntax
        for (const oldTechChannel of oldTechChannels) {
          // Est-elle utilisée dans un autre service ?
          const otherServicesWithTC = services.find(s => s.id !== oldService.id && s.tech_channels.find(tc => tc.id === oldTechChannel.id));

          // Si elle n'est pas utilisée ailleurs
          if (!otherServicesWithTC) {
            // On la passe en discarded
            await updateTechChannel(oldTechChannel.id, { status: "discarded" });
          }
        }

        // on le supprime
        await deleteService(oldService.id);
      }
    } else {
      // Pas de changement de service
      // => service_id === lineupService.service.id

      // Nouvelle techChannels = tech_channel_ids

      // Ancienne techChannels
      const oldTcIds = lineupService.service.tech_channels.map(tc => tc.id);

      // tcIds a désassocier
      const tcIdsToRemove = oldTcIds.filter(curTcId => tech_channel_ids.indexOf(curTcId) === -1);

      // On déassocie les techChannels plus utilisées de ce service
      // eslint-disable-next-line no-restricted-syntax
      for (const tcId of tcIdsToRemove) {
        await disassociateTechChannelsToService(lineupService.service.id, tcId);

        // Est-elle utilisée dans un autre service ?
        const otherServicesWithTC = services.find(s => s.id !== lineupService.service.id && s.tech_channels.find(tc => tc.id === tcId));

        // Si elle n'est pas utilisée ailleurs
        if (!otherServicesWithTC) {
          // On la passe en discarded
          await updateTechChannel(tcId, { status: "discarded" });
        }
      }

      // On associe les nouvelle chaines
      const tcIdsToAdd = tech_channel_ids.filter(curTcId => oldTcIds.indexOf(curTcId) === -1);

      // eslint-disable-next-line no-restricted-syntax
      for (const tcId of tcIdsToAdd) {
        await associateTechChannelsToService(service_id, tcId);
      }
    }

    // On passe les tech_channel_ids en active
    // eslint-disable-next-line no-restricted-syntax
    for (const tcId of tech_channel_ids) {
      await updateTechChannel(tcId, { status: "active" });
    }

    // Update genres
    const currentGenreIds = lineupService.genres.map(g => g.id);
    const genresToRemove = currentGenreIds.filter(curGenreId => genre_ids.indexOf(curGenreId) === -1);
    const genresToAdd = genre_ids.filter(curGenreId => currentGenreIds.indexOf(curGenreId) === -1);

    // eslint-disable-next-line no-restricted-syntax
    for (const genreId of genresToRemove) {
      await removeGenreFromLineupService(lineupService.id, genreId);
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const genreId of genresToAdd) {
      await addGenreToLineupService(lineupService.id, genreId);
    }

    // Update additional
    // Additional to remove
    const additionalToRemove = lineupService.logos.filter(logo => logo.additional === true && !additionalImages.find(l => l.id === logo.id));
    // Additional to add
    const logosData = [];

    for (let i = 0; i < additionalImages.length; i += 1) {
      if (additionalImages[i].isNew) {
        const imageAttributes = await getImageAttributes(additionalImages[i]);
        const logoData = new FormData();

        logoData.append("file", additionalImages[i]);
        logoData.append("delivery", "dvb");
        logoData.append("file_type", imageAttributes.fileType);
        logoData.append("width", imageAttributes.width);
        logoData.append("height", imageAttributes.height);
        logoData.append("additional", true);

        logosData.push(logoData);
      }
    }

    // Remove additional
    for (let i = 0; i < additionalToRemove.length; i += 1) {
      await removeLineupsServiceLogo(lineupService.id, additionalToRemove[i].id);
    }

    // Add logos
    for (let i = 0; i < logosData.length; i += 1) {
      await createLineupsServiceLogo(lineupService.id, logosData[i]);
    }

    // Editorial Channel

    const oldEditorialChannelId = ((lineupService || {}).service || {}).channel_id;

    if (oldEditorialChannelId !== parseInt(channel_id, 10)) {
      // If old exist, it checks it is used elsewhere
      // If not, switch this editorial_channel to the archived status
      if (oldEditorialChannelId) {
        const isOldECUsed = lineupServices.find(ls => ls.id !== lineupService.id && ls.service && ls.service.channel_id === oldEditorialChannelId);

        if (!isOldECUsed) {
          await updateEditorialChannel(oldEditorialChannelId, { status: "archived" });
        }
      }

      // Check the status of the current editorial_channel
      // and switch it to active if needed
      const currentEditorialChannel = editorialChannels.find(ec => ec.id === parseInt(channel_id, 10));

      if (currentEditorialChannel && currentEditorialChannel.status !== "active") {
        await updateEditorialChannel(channel_id, { status: "active" });
      }
    }

    if (parseInt(service_id, 10) !== -1 && parseInt(service_id, 10) === lineupService.service.id) {
      // On fetch les lineups_services qui contiennent ce service pour mettre a jour le state
      const toFetchLineupsServices = lineupServices.filter(ls => ls.service && ls.service.id === lineupService.service.id);

      // eslint-disable-next-line no-restricted-syntax
      for (const ls of toFetchLineupsServices) {
        await showLineupsServices(ls.id);
      }
    }

    this.setState({ isSaving: false });

    addToast("success", "Success", "Channel successfully updated!");
    this.closeModal(true);
  }

  addAdditional(event) {
    const { additionalImages, additionalImagesPreviews } = this.state;

    if (this.additionalInput.current.files.length > 0) {
      const file = this.additionalInput.current.files[0];

      // eslint-disable-next-line no-param-reassign
      event.target.value = null;

      const newAdditionalImages = [...additionalImages];

      file.id = new Date().valueOf();
      file.isNew = true;

      newAdditionalImages.push(file);

      this.setState({ additionalImages: newAdditionalImages });

      const reader = new FileReader();

      reader.onload = e => {
        const newAdditionalImagesPreviews = [...additionalImagesPreviews];

        newAdditionalImagesPreviews.push({
          id: file.id,
          url: e.target.result
        });

        this.setState({
          additionalImagesPreviews: newAdditionalImagesPreviews
        });
      };

      reader.readAsDataURL(file);
    }

    event.preventDefault();
    event.stopPropagation();
  }

  removeAdditional(logoId) {
    const { additionalImages, additionalImagesPreviews } = this.state;
    const newAdditionalImages = additionalImages.filter(l => l.id !== logoId);
    const newAdditionalImagesPreviews = additionalImagesPreviews.filter(l => l.id !== logoId);

    this.setState({ additionalImages: newAdditionalImages, additionalImagesPreviews: newAdditionalImagesPreviews });
  }

  closeModal(reload = false) {
    const { closeLineupServiceModal } = this.props;

    closeLineupServiceModal(reload);
    this.setState(initialState);
  }

  stepIsValid() {
    const { currentStep } = this.props;

    if (currentStep === 0) {
      return this.isChannelIdValid() && this.isTechChannelIdsValid() && this.isTechChannelValid();
    } else if (currentStep === 1) {
      return this.isDefaultLanguageValid() && this.isLocalesJsonValid() && this.isLocalesEntriesValid();
    } else if (currentStep === 2) {
      return this.isLcnsValid() && !this.isLcnsTaken() && this.isChannelTierValid();
    } else if (currentStep === 3) {
      return this.isGenresValid() && this.isGenresChosenValid();
    }

    return true;
  }

  isChannelIdValid() {
    const { channel_id } = this.state;
    const { editorialChannels } = this.props;

    return channel_id && editorialChannels.find(ec => ec.id === parseInt(channel_id, 10));
  }

  isTechChannelValid() {
    const { tech_channel_ids } = this.state;

    return tech_channel_ids && tech_channel_ids.length > 0;
  }

  isTechChannelIdsValid() {
    let valid = true;

    if (this.isTechChannelValid()) {
      const { tech_channel_ids } = this.state;
      const { techChannels } = this.props;

      tech_channel_ids.forEach(id => {
        if (!techChannels.find(tc => tc.id === parseInt(id, 10))) {
          valid = false;
        }
      });
    }
    return valid;
  }

  isDefaultLanguageValid() {
    const { default_language } = this.state;
    return default_language && default_language.length === 5;
  }

  isLocalesJsonValid() {
    const { locales } = this.state;
    return locales && locales.length > 0 && isValidJson(locales) && isValidLocaleJson(locales);
  }

  isLocalesEntriesValid() {
    if (this.isLocalesJsonValid()) {
      const { locales, default_language } = this.state;

      let newDefaultLanguage = default_language;

      if (!JSON.parse(locales)[default_language]) {
        newDefaultLanguage = Object.keys(JSON.parse(locales)).find(localeKey => localeKey.length === 5);
      }

      const currentServiceName = JSON.parse(locales)[newDefaultLanguage].name || "";

      return currentServiceName && currentServiceName.length > 0 && currentServiceName.toString() === currentServiceName;
    }
    return true;
  }

  isLcnsValid() {
    const { lcns } = this.state;
    const validLcnsArray = this.formatLCNS(lcns);

    return validLcnsArray.length > 0;
  }

  isLcnsTaken() {
    const { lcns } = this.state;
    const { currentLineupServices, lineupService } = this.props;

    const validLcnsArray = this.formatLCNS(lcns);
    let lcnsError = "";

    validLcnsArray.forEach(lcn => {
      const existingLsInLcn = currentLineupServices.find(ls => ls.lcns.indexOf(lcn) !== -1);

      if (existingLsInLcn && existingLsInLcn.id !== lineupService.id) {
        if (lcnsError.length === 0) {
          lcnsError = `These LCN(s) are already taken: ${lcn}`;
        } else {
          lcnsError += `, ${lcn}`;
        }
      }
    });

    return lcnsError;
  }

  isChannelTierValid() {
    const { channel_tier_id } = this.state;
    const { channelTiers } = this.props;

    return channel_tier_id && channelTiers.find(ct => ct.id === parseInt(channel_tier_id, 10));
  }

  isGenresValid() {
    const { genre_ids } = this.state;

    return genre_ids && !!genre_ids.length;
  }

  isGenresChosenValid() {
    const { genre_ids } = this.state;
    const { serviceGenres } = this.props;

    let valid = true;

    genre_ids.forEach(id => {
      if (!serviceGenres.find(sg => sg.id === parseInt(id, 10)) && this.isGenresValid()) {
        valid = false;
      }
    });

    return valid;
  }

  triggerLogoInput(event) {
    event.preventDefault();
    event.stopPropagation();

    this.additionalInput.current.click();
  }

  modalContent() {
    const {
      channelTiers,
      editorialChannels,
      serviceGenres,
      techChannels,
      transponders,

      currentStep,
      stepperComponent,
      modalOpened
    } = this.props;

    const {
      lcns,
      channel_tier_id,
      tech_channel_ids,
      channel_id,
      genre_ids,
      default_language,
      locales,
      editingService,
      additionalImagesPreviews,
    } = this.state;

    if (!modalOpened) {
      return;
    }

    const sortedChannelTiers = [...channelTiers].sort(sortObjectByLocale);
    const sortedServiceGenres = [...serviceGenres].sort(sortObjectByLocale);
    const sortedEditorialChannels = [...editorialChannels].sort(sortObjectByLocale);

    const sortedTechChannels = techChannels
      .filter(tc => tc.status === "active" || tc.status === "pending" || tc.status === "transfer")
      .sort((a, b) => {
        if (a.service_name.toLowerCase() < b.service_name.toLowerCase()) {
          return -1;
        }

        if (a.service_name.toLowerCase() > b.service_name.toLowerCase()) {
          return 1;
        }

        return 0;
      });

    let computedDefaultLanguages;

    try {
      computedDefaultLanguages = Object.keys(JSON.parse(locales)).filter(l => l.length === 5);
    } catch {
      computedDefaultLanguages = [];
    }

    return <DialogContent sx={{ display: "flex", gap: 3, flexDirection: "column", marginTop: 1, width: "80%", alignItems: "center", alignSelf: "center" }}>
      {stepperComponent}

      {/* STEP: 1 EDITORIAL AND TECH CHANNELS */}
      {currentStep === 0 && <Stack spacing={3} alignItems="center">
        {editingService && <Alert severity="warning">Service id won't change, so it won't break the user favorite choice in set-top boxes.</Alert>}
        {!editingService && <Alert sx={{ textAlign: "center" }} severity="warning">
          You are editing the channel in this lineup. Depending on your choices, service can change and it can break the user favorite choice in set-top boxes.
        </Alert>}
        <FormControlLabel
          control={<Switch checked={!!editingService} onClick={() => this.toggleInput("editingService")} />}
          label={!editingService ? "NO" : "YES"}
        />
        <Typography variant="h5">Select an editorial channel</Typography>
        <Alert severity="info">Editorial Channel contains all EPG data</Alert>
        <FormControl required error={!this.isChannelIdValid()} fullWidth>
          <InputLabel id="editorial-channel-select">Associated Editorial Channel</InputLabel>
          <Select
            labelId="editorial-channel-select"
            label="Associated Editorial Channel"
            value={channel_id || ""}
            onChange={e => this.changeInput("channel_id", e)}
          >
            {sortedEditorialChannels.map(ec => <MenuItem key={ec.id} value={ec.id}>{getObjectNameFromLocales(ec)}</MenuItem>)}
          </Select>
          { !this.isChannelIdValid() && <FormHelperText error>Editorial channel is not valid</FormHelperText> }
        </FormControl>
        <Typography variant="h5">Select one or many tech channels</Typography>
        {!!tech_channel_ids.length && <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Selected Tech Channels</TableCell>
              <TableCell>Transponder</TableCell>
              <TableCell>SID</TableCell>
              <TableCell>Backend Channel ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tech_channel_ids.map(tcId => {
              const techChannel = techChannels.find(tc => tc.id === tcId);

              if (!techChannel) {
                return null;
              }

              const transponder = transponders.find(t => t.id === techChannel.transponder_id) || {};

              return (
                <TableRow key={techChannel.id}>
                  <TableCell>
                    <CloseIcon size="small" onClick={() => this.toggleTechChannelsIds(techChannel.id)} />
                  </TableCell>
                  <TableCell>{techChannel.service_name}</TableCell>
                  <TableCell>{transponder.name || ""}</TableCell>
                  <TableCell>{techChannel.sid || "-"}</TableCell>
                  <TableCell>{techChannel.backend_channel_id || "-"}</TableCell>
                </TableRow>
              );
            }).filter(e => e)}
          </TableBody>
        </Table>}
        <FormControl required error={!this.isTechChannelValid() && !this.isTechChannelIdsValid()} fullWidth>
          <InputLabel id="tech-channels-select" focused>Tech Channels</InputLabel>
          <Select
            sx={{ maxWidth: "100%" }}
            labelId="tech-channels-select"
            label="Tech Channels"
            value={tech_channel_ids || []}
            renderValue={() => "Select one or many tech channels"}
            multiple
            notched
            displayEmpty
          >
            <MenuItem value={0} disabled>Select a channel</MenuItem>
            {sortedTechChannels.map(tc => {
              const transponder = transponders.find(t => t.id === tc.transponder_id);
              let tech_channel_label = tc.service_name;

              if (tc.tech_channel_type === "dvb") {
                let tName = `(tsid:${tc.tsid}) (onid:${tc.onid})`;

                if (transponder) {
                  tName = `(TxP name:${transponder.name})`;
                }

                tech_channel_label += ` ${tName} (sid:${tc.sid})`;
              } else if (tc.tech_channel_type === "ott") {
                tech_channel_label += ` (backend_channel:${tc.backend_channel_id})`;
              }

              return <MenuItem key={tc.id} onClick={() => this.toggleTechChannelsIds(tc.id)} value={tc.id}>
                {tech_channel_label}
              </MenuItem>;
            })}
          </Select>
          { !this.isTechChannelValid() && <FormHelperText error>Select at least one Tech Channel</FormHelperText> }
          { !this.isTechChannelIdsValid() && <FormHelperText error>Tech channel is not valid</FormHelperText> }
        </FormControl>
      </Stack>}

      {/* STEP 2: LOCALES */}
      {currentStep === 1 && <Stack spacing={3} width="100%">
        <FormControl required error={!this.isDefaultLanguageValid()} fullWidth>
          <InputLabel id="default-language-select">Default language</InputLabel>
          <Select
            labelId="default-language-select"
            label="Default language"
            value={default_language || ""}
            onChange={e => this.changeInput("default_language", e)}
          >
            {computedDefaultLanguages.map(dl => <MenuItem key={dl} value={dl}>{dl}</MenuItem>)}
          </Select>
          { !this.isDefaultLanguageValid() && <FormHelperText error>You must define locale json to select a default language</FormHelperText> }
        </FormControl>
        <div>
          <AceEditor
            width="100%"
            mode="json"
            theme="tomorrow"
            fontSize="0.9rem"
            name="lineups-services-edit-locales-edit"
            height="300px"
            setOptions={{
              useWorker: false,
              showLineNumbers: false
            }}
            value={locales}
            onChange={value => this.changeInput("locales", { target: { value } })}
            editorProps={{ $blockScrolling: true }}
          />
          {!this.isLocalesJsonValid()
            && <FormHelperText error>Locales json is not valid. Locales json should contain language keys with length equals to 5 characters</FormHelperText>}
          {!this.isLocalesEntriesValid()
            && <FormHelperText error>You have to define a name entry in the locales JSON. For example: {"{\"en_GB\": {\"name\": \"Lineup\", \"description\": \"\"}}"}</FormHelperText>}
        </div>
      </Stack>}

      {/* STEP 3: INFOS */}
      {currentStep === 2 && <Stack spacing={3}>
        <Alert severity="info">
          You can add several LCNs. You just have to separate them with a comma (for example: “4, 145, 23”)
        </Alert>
        <Box>
          <TextField
            label="LCNS"
            value={lcns || ""}
            error={!this.isLcnsValid() && this.isLcnsTaken()}
            onChange={e => this.changeInput("lcns", e)}
            required
            fullWidth
          />
          { !this.isLcnsValid() && <FormHelperText error>LCNs are not valid</FormHelperText> }
          { !!this.isLcnsTaken() && <FormHelperText error>{this.isLcnsTaken()}</FormHelperText> }
        </Box>
        <FormControl required error={!this.isChannelTierValid()} fullWidth>
          <InputLabel id="channel-tiers-select">Channel Tiers</InputLabel>
          <Select
            labelId="channel-tiers-select"
            label="Channel Tiers"
            value={channel_tier_id || ""}
            onChange={e => this.changeInput("channel_tier_id", e)}
          >
            {sortedChannelTiers.map(ct => <MenuItem key={ct.id} value={ct.id}>{capitalize(ct.name)}</MenuItem>)}
          </Select>
          { !this.isChannelTierValid() && <FormHelperText error>Channel Tier is not valid</FormHelperText> }
        </FormControl>
      </Stack>}

      {/* STEP 4: GENRES */}
      {currentStep === 3 && <Stack spacing={3}>
        {!!genre_ids.length && <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Selected Genres</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {genre_ids.map(sgId => {
              const genre = serviceGenres.find(sg => sg.id === sgId);

              return (
                <TableRow key={genre.id}>
                  <TableCell>
                    <CloseIcon size="small" onClick={() => this.toggleGenreIds(genre.id)} />
                  </TableCell>
                  <TableCell>{getObjectNameFromLocales(genre)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>}
        <FormControl required error={!this.isGenresValid() && !this.isGenresChosenValid()} fullWidth>
          <InputLabel id="genres-select" focused>Genres</InputLabel>
          <Select
            sx={{ maxWidth: "100%" }}
            labelId="genres-select"
            label="Genres"
            value={genre_ids || []}
            renderValue={() => "Select one or many genres"}
            multiple
            notched
            displayEmpty
          >
            <MenuItem value={0} disabled>Select a channel</MenuItem>
            {sortedServiceGenres.map(sg => <MenuItem key={sg.id} onClick={() => this.toggleGenreIds(sg.id)} value={sg.id}>
              {getObjectNameFromLocales(sg)}
            </MenuItem>)}
          </Select>
          { !this.isGenresValid() && <FormHelperText error>Select at least one genre</FormHelperText> }
          { !this.isGenresChosenValid() && <FormHelperText error>Genre is not valid</FormHelperText> }
        </FormControl>
      </Stack>}

      {/* STEP 5: ADDITIONAL RESOURCES */}
      {currentStep === 4 && <Stack spacing={3}>
        <Alert severity="info">
          <Typography variant="body2" align="center">
            Additional resource(s) can be used to provide ads. Technically, it will be exported in the Eutelsat Additional Resources file (EAR json)
          </Typography>
        </Alert>
        <Stack direction="row" spacing={3} justifyContent="center">
          {additionalImagesPreviews.map(logo => <Paper
            sx={{
              backgroundColor: "text.grey",
              width: "100px",
              height: "100px",
              position: "relative"
            }}
            key={logo.id}
            elevation={3}
          >
            <Fab
              sx={{
                position: "absolute",
                bottom: "-15px",
                right: "-15px"
              }}
              size="small"
              onClick={() => this.removeAdditional(logo.id)}>
              <DeleteIcon />
            </Fab>
            <img style={{ width: "100%", position: "absolute", top: "0", bottom: "0", margin: "auto" }} src={logo.url} alt="" />
          </Paper>)}
          <Paper
            sx={{
              backgroundColor: "text.grey",
              width: "100px",
              height: "100px",
              position: "relative"
            }}
            elevation={3}
          >
            <Fab
              sx={{
                position: "absolute",
                bottom: "-15px",
                right: "-15px"
              }}
              size="small"
              onClick={e => this.triggerLogoInput(e)}
            >
              <AddIcon />
            </Fab>
            <input
              style={{ display: "none" }}
              type="file"
              id="add-logo"
              accept="image/png, image/jpeg"
              ref={this.additionalInput}
              onChange={this.addAdditional}
            />
          </Paper>
        </Stack>
      </Stack>}

      {/* STEP 6: SUMUP */}
      {currentStep === 5 && <Stack spacing={3} alignItems="center">
        <Stack direction="row" spacing={3}>
          <Stack spacing={2}>
            <TextField sx={{ input: { cursor: "default" } }} label="Associated channel" InputLabelProps={{ focused: true }} value={getObjectNameFromLocales(sortedEditorialChannels.find(ec => ec.id === parseInt(channel_id, 10)))} fullWidth></TextField>
            <TextField sx={{ input: { cursor: "default" } }} label="Default language" InputLabelProps={{ focused: true }} value={default_language} fullWidth></TextField>
            <AceEditor
              style={{ maxWidth: "300px" }}
              mode="json"
              theme="tomorrow"
              fontSize="0.9rem"
              name="lineups-services-add-locales-edit"
              height="200px"
              setOptions={{
                useWorker: false,
                showLineNumbers: false
              }}
              value={locales}
              onChange={value => this.changeInput("locales", { target: { value } })}
              editorProps={{ $blockScrolling: true }}
            />
          </Stack>
          <Stack spacing={2}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Technical Channel</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tech_channel_ids.map(tech_channel_id => {
                  const techChannel = sortedTechChannels.find(tc => tc.id === parseInt(tech_channel_id, 10));
                  let tech_channel_label = techChannel.service_name;

                  if (techChannel.tech_channel_type === "dvb") {
                    tech_channel_label += ` (tsid:${techChannel.tsid}) (onid:${techChannel.onid}) (sid:${techChannel.sid})`;
                  } else if (techChannel.tech_channel_type === "ott") {
                    tech_channel_label += ` (backend_channel:${techChannel.backend_channel_id})`;
                  }

                  return <TableRow key={tech_channel_id}><TableCell><Typography variant="body2">{tech_channel_label}</Typography></TableCell></TableRow>;
                })}
              </TableBody>
            </Table>
            <TextField sx={{ input: { cursor: "default" } }} label="LCNS" InputLabelProps={{ focused: true }} value={lcns} fullWidth></TextField>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Genres</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {genre_ids.map(genreId => {
                  const genre = serviceGenres.find(sg => sg.id === parseInt(genreId, 10));

                  return <TableRow key={genreId}><TableCell><Typography variant="body2">{getObjectNameFromLocales(genre)}</Typography></TableCell></TableRow>;
                })}
              </TableBody>
            </Table>
            <TextField sx={{ input: { cursor: "default" } }} label="Channel Tier" InputLabelProps={{ focused: true }} value={sortedChannelTiers.find(sg => sg.id === parseInt(channel_tier_id, 10)).name} fullWidth></TextField>
          </Stack>
        </Stack>
        {additionalImagesPreviews.length > 0 && <>
          <Typography variant="body2">Additional resource(s)</Typography>
          <Stack direction="row" spacing={3}>
            {additionalImagesPreviews.map(logo => <Paper
              sx={{
                backgroundColor: "text.grey",
                width: "100px",
                height: "100px",
                position: "relative"
              }}
              key={logo.id}
              elevation={3}
            >
              <img style={{ width: "100%", position: "absolute", top: "0", bottom: "0", margin: "auto" }} src={logo.url} alt="" />
            </Paper>)}
          </Stack>
        </>}
      </Stack>}
    </DialogContent>;
  }

  modalActions() {
    const { currentStep, previousStep, nextStep, isLastStep } = this.props;
    const { isSaving } = this.state;

    return (
      [
        <Button
          variant="outlined"
          key="cancel"
          onClick={() => {
            this.closeModal();
          }}>
          Cancel
        </Button>,
        currentStep > 0
        && <Button
          variant="outlined"
          key="previous"
          onClick={() => {
            previousStep();
          }}>
          Previous
        </Button>,
        !isLastStep
        && <Button
          variant="contained"
          color="secondary"
          key="next"
          disabled={!this.stepIsValid()}
          onClick={() => {
            nextStep();
          }}>
          Next
        </Button>,
        isLastStep
        && <Button
          variant="contained"
          color="secondary"
          key="save"
          disabled={isSaving}
          onClick={() => {
            this.updateChannelInLineup();
          }}>
          { isSaving ? "Loading..." : "Save" }
        </Button>
      ]
    );
  }

  render() {
    const {
      modalOpened,
      lineup,
      lineupService,
    } = this.props;

    const currentLineupName = getObjectNameFromLocales(lineup);
    const currentLineupServiceName = (lineupService && lineupService.service && getObjectNameFromLocales(lineupService.service)) || "";

    return (
      <Modal
        closeModal={() => this.closeModal()}
        isOpen={modalOpened}
        title={`Edit channel ${currentLineupServiceName} in the lineup ${currentLineupName}`}
        customModalContent={() => this.modalContent()}
        actions={() => this.modalActions()}
        actionAlign="flex-end"
      />
    );
  }
}

EditLineupServiceModal.defaultProps = {
  currentLineupId: null,
  currentLineupServiceId: null,
  orbitalPositionId: null,
  editLineupServiceStep: 0,
  lineup: null,
  lineupService: null,
  channelTiers: [],
  editorialChannels: [],
  lineupServices: [],
  currentLineupServices: [],
  services: [],
  serviceGenres: [],
  techChannels: [],
  transponders: []
};

EditLineupServiceModal.propTypes = {
  modalOpened: PropTypes.bool.isRequired,

  currentStep: PropTypes.number,
  previousStep: PropTypes.func,
  nextStep: PropTypes.func,
  stepperComponent: PropTypes.object,
  isLastStep: PropTypes.bool,

  // Use in mapStateToProps
  // eslint-disable-next-line react/no-unused-prop-types
  currentLineupId: PropTypes.number,
  // eslint-disable-next-line react/no-unused-prop-types
  currentLineupServiceId: PropTypes.number,
  orbitalPositionId: PropTypes.number,

  lineup: PropTypes.object,
  lineupService: PropTypes.object,

  channelTiers: PropTypes.arrayOf(PropTypes.object),
  editorialChannels: PropTypes.arrayOf(PropTypes.object),
  lineupServices: PropTypes.arrayOf(PropTypes.object),
  currentLineupServices: PropTypes.arrayOf(PropTypes.object),
  services: PropTypes.arrayOf(PropTypes.object),
  serviceGenres: PropTypes.arrayOf(PropTypes.object),
  techChannels: PropTypes.arrayOf(PropTypes.object),
  transponders: PropTypes.arrayOf(PropTypes.object),

  editLineupServiceStep: PropTypes.number,

  closeLineupServiceModal: PropTypes.func.isRequired,
  showLineupsServices: PropTypes.func.isRequired,
  updateLineupsService: PropTypes.func.isRequired,
  indexServices: PropTypes.func.isRequired,
  updateService: PropTypes.func.isRequired,
  deleteService: PropTypes.func.isRequired,
  updateTechChannel: PropTypes.func.isRequired,
  updateEditorialChannel: PropTypes.func.isRequired,
  disassociateTechChannelsToService: PropTypes.func.isRequired,
  associateTechChannelsToService: PropTypes.func.isRequired,
  addGenreToLineupService: PropTypes.func.isRequired,
  removeGenreFromLineupService: PropTypes.func.isRequired,
  updateLineupsServiceWithNewService: PropTypes.func.isRequired,
  createLineupsServiceLogo: PropTypes.func.isRequired,
  removeLineupsServiceLogo: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,

  isLoadingServices: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    lineup: showLineup(state, ownProps.currentLineupId) || {},
    lineupService: showLineupsService(state, ownProps.currentLineupServiceId) || {},
    channelTiers: listChannelTiers(state),
    editorialChannels: listEditorialChannels(state),
    lineupServices: listLineupsServices(state),
    currentLineupServices: listLineupsServicesByLineupId(state, ownProps.currentLineupId),
    services: listServices(state),
    serviceGenres: listServiceGenres(state),
    techChannels: listTechChannelsFromOrbitalPosition(state, ownProps.orbitalPositionId),
    transponders: listTranspondersFromOrbitalPosition(state, ownProps.orbitalPositionId),

    isLoadingServices: isLoadingSelector(state, indexServicesAction.toString()),
    isEditing: isLoadingSelector(state, updateLineupsServiceAction.toString())
                || isLoadingSelector(state, updateServiceAction.toString())
                || isLoadingSelector(state, deleteServiceAction.toString())
                || isLoadingSelector(state, disassociateTechChannelsToServiceAction.toString())
                || isLoadingSelector(state, associateTechChannelsToServiceAction.toString())
                || isLoadingSelector(state, addGenreToLineupServiceAction.toString())
                || isLoadingSelector(state, removeGenreFromLineupServiceAction.toString())
                || isLoadingSelector(state, updateLineupsServiceWithNewServiceAction.toString())
                || isLoadingSelector(state, createLineupsServiceLogoAction.toString())
                || isLoadingSelector(state, removeLineupsServiceLogoAction.toString())
                || isLoadingSelector(state, showLineupsServicesAction.toString())
  };
}

const mapDispatchToProps = {
  showLineupsServices: showLineupsServicesAction,
  updateLineupsService: updateLineupsServiceAction,
  updateService: updateServiceAction,
  indexServices: indexServicesAction,
  deleteService: deleteServiceAction,
  updateTechChannel: updateTechChannelAction,
  updateEditorialChannel: updateEditorialChannelAction,
  disassociateTechChannelsToService: disassociateTechChannelsToServiceAction,
  associateTechChannelsToService: associateTechChannelsToServiceAction,
  addGenreToLineupService: addGenreToLineupServiceAction,
  removeGenreFromLineupService: removeGenreFromLineupServiceAction,
  updateLineupsServiceWithNewService: updateLineupsServiceWithNewServiceAction,
  createLineupsServiceLogo: createLineupsServiceLogoAction,
  removeLineupsServiceLogo: removeLineupsServiceLogoAction,
  addToast: addToastAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withStepper(
  EditLineupServiceModal, [
    "Would you want to edit the service configuration ?",
    "Set locales information",
    "Set information",
    "Select one or many genres",
    "Edit additional resources",
    "Sumup"
  ]
));
