import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Chip, ListItemIcon, Menu, MenuItem, Stack, TextField, Typography, FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon, CheckBox as CheckBoxIcon } from "@mui/icons-material";
import PropTypes from "prop-types";

function FiltersInputs({ primaryFilters, defaultFilters, searchFilterField, searchFilterLabel, onFiltersChange }) {
  const [filters, setFilters] = useState(defaultFilters);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [openedMenu, setOpenedMenu] = useState(null);

  const handleFiltersChange = currentFiltersArray => {
    onFiltersChange(currentFiltersArray);
  };

  useEffect(() => {
    setFilters(defaultFilters);
    handleFiltersChange(defaultFilters);
  }, [defaultFilters]);

  const searchFilter = searchInput => {
    const value = searchInput.target.value;
    const filterId = "search-filter";

    const newFiltersArray = filters.filter(f => f.id !== filterId);

    newFiltersArray.push({
      field: searchFilterField,
      value: value.toString(),
      operator: "contains",
      id: filterId,
      name: "search"
    });

    setFilters(newFiltersArray);
    handleFiltersChange(newFiltersArray);
  };

  const addFilter = (filterName, name, value) => {
    let newFiltersArray = filters.slice();
    const filterToUpdate = filters.find(({ field }) => field === filterName);

    if (filterToUpdate) {
      newFiltersArray = newFiltersArray.map(filter => {
        if (filter.field === filterToUpdate.field) {
          filterToUpdate.name.push(name);
          filterToUpdate.value.push(value.toString());
          return filterToUpdate;
        }

        return filter;
      });
    } else {
      newFiltersArray.push({
        field: filterName,
        value: [value.toString()],
        operator: "includes",
        id: filterName,
        name: [name]
      });
    }

    setFilters(newFiltersArray);
    handleFiltersChange(newFiltersArray);
  };

  const hasFilterSelected = (filterName, value) => filters.find(filter => filter.field === filterName)?.value.includes(value.toString());

  const removeFilter = (filterName, removedName, removedValue) => {
    const newFiltersArray = filters.slice()
    // Remove selected value and name filter and delete empty filter
      .reduce((acc, curr) => {
      // Keep unmodified filter
        if (curr.field !== filterName) {
          acc.push(curr);
        }

        if (curr.field === filterName && curr.name.length > 1 && curr.value.length > 1) {
          acc.push({
            ...curr,
            name: curr.name.filter(currentName => currentName !== removedName),
            value: curr.value.filter(currentValue => currentValue !== removedValue.toString())
          });
        }

        return acc;
      }, []);

    setFilters(newFiltersArray);
    handleFiltersChange(newFiltersArray);
  };

  const toggleFilter = (filterName, name, value) => {
    if (hasFilterSelected(filterName, value)) {
      removeFilter(filterName, name, value);
    } else {
      addFilter(filterName, name, value);
    }
  };

  /* Menus methods */
  const openMenu = (event, menuId) => {
    setMenuAnchorEl(event.currentTarget);
    setOpenedMenu(menuId);
  };

  const closeMenu = menuId => {
    setMenuAnchorEl(null);
    setOpenedMenu(menuId);
  };

  const menuOpen = Boolean(menuAnchorEl);

  return (
    <Stack spacing={1} sx={{ marginBottom: 2 }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="body2">Filter by&nbsp;:</Typography>
        <ButtonGroup
          variant="outlined"
        >
          {!!primaryFilters.length && primaryFilters.map(f =>
            <React.Fragment key={ f.text || f.name }>
              <Button
                sx={{
                  borderColor: "primary.smokeBorder",
                }}
                id={`${f.text || f.name}-button`}
                aria-controls={ menuOpen && openedMenu === `${f.text || f.name}-menu` ? `${f.text || f.name}-menu` : undefined }
                aria-haspopup="true"
                aria-expanded={ menuOpen && openedMenu === `${f.text || f.name}-menu` ? "true" : undefined }
                disableElevation
                onClick={e => openMenu(e, `${f.text || f.name}-menu`)}
                endIcon={<KeyboardArrowDownIcon />}
                disabled={f.values.length < 1}
              >
                { f.text || f.name }
              </Button>
              <Menu
                MenuListProps={{
                  "aria-labelledby": `${f.text || f.name}-menu`,
                }}
                id={`${f.text || f.name}-menu`}
                anchorEl={menuAnchorEl}
                open={menuOpen && openedMenu === `${f.text || f.name}-menu`}
                onClose={() => closeMenu()}
              >
                {f.values.map(v =>
                  <MenuItem
                    key={`${v.name || v.value}-filter`}
                    onClick={() => {
                      toggleFilter(f.name, v.name, v.value);
                    }}
                    selected={hasFilterSelected(f.name, v.value)}
                    disableRipple
                  >
                    <ListItemIcon>
                      { hasFilterSelected(f.name, v.value)
                        ? <CheckBoxIcon sx={{ color: "brandColor" }} fontSize="small" />
                        : <CheckBoxOutlineBlankIcon fontSize="small" /> }
                    </ListItemIcon>
                    {v.name}
                  </MenuItem>
                )}
              </Menu>
            </React.Fragment>
          )}
        </ButtonGroup>
        { searchFilterField && <TextField type="text" size="small" label={searchFilterLabel} onChange={searchFilter} />}
      </Stack>
      <Stack direction="row" spacing={1}>
        {filters.reduce((acc, curr) => {
          if (curr.field !== "search" && Array.isArray(curr.name)) {
            return acc.concat(curr.name.map((name, idx) => {
              const { value, field } = curr;
              return <Chip
                key={name}
                label={name}
                sx={{ mb: 1 }}
                onDelete={() => removeFilter(field, name, value[idx])}
                size="small"
              />;
            }
            ));
          }

          return acc;
        }
        , [])}
      </Stack>
    </Stack>
  );
}

FiltersInputs.defaultProps = {
  primaryFilters: null,
  defaultFilters: [],
  searchFilterField: null
};

FiltersInputs.propTypes = {
  primaryFilters: PropTypes.arrayOf(PropTypes.object),
  defaultFilters: PropTypes.arrayOf(PropTypes.object),
  searchFilterField: PropTypes.string,
  searchFilterLabel: PropTypes.string,
  onFiltersChange: PropTypes.func
};

export default FiltersInputs;
