import { Stack, TextField } from "@mui/material";
import PropTypes from "prop-types";

function FiltersSearchField({ searchFilterField, searchFilterLabel, onFiltersChange }) {
  const searchFilter = searchInput => {
    const value = searchInput.target.value;
    const filterId = "search-filter";

    onFiltersChange([{
      field: searchFilterField,
      value: value.toString(),
      operator: "contains",
      id: filterId,
      name: "search"
    }]);
  };

  return (
    <Stack direction="row" sx={{ marginBottom: 2 }} alignItems="center" spacing={1}>
      { searchFilterField && <TextField type="text" size="small" label={searchFilterLabel} onChange={searchFilter} />}
    </Stack>
  );
}

FiltersSearchField.defaultProps = {
  searchFilterField: null
};

FiltersSearchField.propTypes = {
  searchFilterField: PropTypes.string,
  searchFilterLabel: PropTypes.string,
  onFiltersChange: PropTypes.func
};

export default FiltersSearchField;
