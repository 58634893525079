import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { createRouterMiddleware } from "@lagunovsky/redux-react-router";
import promiseMiddleware from "redux-promise";
import createRootReducer from "./reducers";
import apiMiddleware from "./middlewares/api";

export const history = createBrowserHistory();

export default function configureStore(preloadedState) {
  /* eslint-disable no-underscore-dangle */
  const devTools = process.env.NODE_ENV === "development" && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true });
  /* eslint-enable */

  const args = [
    applyMiddleware(
      createRouterMiddleware(history), // for dispatching history actions
      apiMiddleware,
      promiseMiddleware

      // ... other middlewares ...
    )
  ];

  if (devTools) {
    args.push(devTools);
  }

  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      ...args
    )
  );

  return store;
}
