import { listBeams } from "./beams";
import { showBeamLineupsFromLineupId } from "./beams_lineups";

export const listOrbitalPositions = state => state.orbitalPositions.allOrbitalPositions;

export const getOrbitalPosition = (state, id) => state.orbitalPositions.allOrbitalPositions.find(op => op.id === id);

export const currentOrbitalPosition = state => {
  const { currentOrbitalPositionId } = state.orbitalPositions;

  if (currentOrbitalPositionId) {
    return state.orbitalPositions.allOrbitalPositions.find(op => op.id === currentOrbitalPositionId);
  }

  return null;
};

export const getOrbitalPositionsFromLineupId = (state, lineupId) => {
  const beamsLineups = showBeamLineupsFromLineupId(state, lineupId);
  const beamsIds = beamsLineups.map(bl => bl.beam_id);
  const beams = listBeams(state);
  const orbitalPositions = listOrbitalPositions(state);
  const orbitalPositionIds = [];

  beamsIds.forEach(beamId => {
    const beam = beams.find(b => b.id === beamId);

    if (beam && orbitalPositionIds.indexOf(beam.orbital_position_id) === -1) {
      orbitalPositionIds.push(beam.orbital_position_id);
    }
  });

  const filteredOrbitalPositions = orbitalPositions.filter(op => orbitalPositionIds.indexOf(op.id) !== -1);

  return filteredOrbitalPositions;
};

export const showOrbitalPosition = (state, orbitalPositionId) => state.orbitalPositions.allOrbitalPositions.find(op => op.id === orbitalPositionId);

export const getTotalCount = state => state.orbitalPositions.totalCount;

export const listOrbitalPositionsFilters = state => state.orbitalPositions.filters;
