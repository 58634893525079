/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import { Box, DialogContent, Typography, Button, Alert, LinearProgress } from "@mui/material";
import Modal from "../Modal/Modal";

import { addToast as addToastAction } from "../../actions/toasts";

import {
  deleteTransponder as deleteTransponderAction
} from "../../actions/transponders";

import {
  listTechChannels as listTechChannelsSelector
} from "../../selectors/tech_channels";

import {
  listTransponders as listTranspondersSelector
} from "../../selectors/transponders";

import { isLoading as isLoadingSelector } from "../../selectors/loaders";
import FullLoader from "../FullLoader";

const initialState = {
  currentStep: 0,
  steps: 0,
};

class RemoveTransponderModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      steps: props.currentTransponderIds.length - 1
    };
  }

  componentDidUpdate(prevProps) {
    const {
      currentTransponderIds
    } = this.props;

    if (currentTransponderIds !== null && !isEqual(currentTransponderIds, prevProps.currentTransponderIds)) {
      this.setState({
        currentStep: 0,
        steps: currentTransponderIds.length - 1
      });
    }
  }

  nextStep() {
    const {
      currentStep,
      steps
    } = this.state;

    const {
      addToast
    } = this.props;

    if (currentStep < steps) {
      this.setState({
        currentStep: currentStep + 1
      });
    } else {
      addToast("success", "Success", "Transponder removed!");
      this.closeModal();
    }
  }

  async removeTransponder() {
    const {
      currentStep
    } = this.state;

    const {
      currentTransponderIds,
      transponders,

      deleteTransponder
    } = this.props;

    if (currentStep < currentTransponderIds.length) {
      const currentTransponderId = parseInt(currentTransponderIds[currentStep], 10);

      // Filter transponders
      const currentTransponders = transponders.filter(t => currentTransponderId === t.id);

      // 1. Delete transponders
      for (let i = 0; i < currentTransponders.length; i += 1) {
        const currentTransponder = currentTransponders[i];

        await deleteTransponder(currentTransponder.id);
      }
    }

    // Go to the next transponder
    // If it is the last transponder,
    // nextStep() will close the modal
    this.nextStep();
  }

  closeModal() {
    const { closeTransponderModal } = this.props;

    closeTransponderModal();
    this.setState(initialState);
  }

  getCurrentTransponder() {
    const { currentStep } = this.state;
    const { transponders, currentTransponderIds } = this.props;

    return transponders.find(op => op.id === currentTransponderIds[currentStep]);
  }

  getCurrentTechChannels() {
    const { techChannels } = this.props;

    const currentTransponder = this.getCurrentTransponder();

    return techChannels.filter(tc => tc.transponder_id === currentTransponder?.id);
  }

  modalContent() {
    const { currentStep, steps } = this.state;

    const pitch = steps > 0 ? 100 / steps : 100;
    const currentTransponder = this.getCurrentTransponder();
    const currentTechChannels = this.getCurrentTechChannels();

    return (
      <DialogContent sx={{ display: "flex", gap: 3, flexDirection: "column", marginTop: 1, width: "80%", alignItems: "center", alignSelf: "center" }}>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 2 }}>
          <Typography alignSelf="center">
            {`Transponder ${currentStep + 1} / ${steps + 1}`}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={Math.min(currentStep * pitch, 100)}
            sx={{ borderRadius: "10px" }}
          />
        </Box>
        <Typography sx={{ textAlign: "center" }}>{currentTransponder?.name}</Typography>
        {!currentTechChannels?.length
          ? <Alert severity="info">
            This transponder is not related to any technical channel. You can delete it safely
          </Alert>
          : <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Alert severity="warning">
              This transponder is related to the following technical channels. By removing this transponder, it will let this (these) technical channel(s) without any associated transponder
            </Alert>
            <Box sx={{ display: "flex", gap: 4, alignSelf: "flex-start", mt: 3 }}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                <Typography variant="h5" component="span" sx={{ mb: 2 }}>
                  Tech Channel
                </Typography>
                {
                  currentTechChannels.map(ctc =>
                    <Typography key={ctc.id}>
                      {ctc.service_name}
                    </Typography>
                  )
                }
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                <Typography variant="h5" component="span" sx={{ mb: 2 }}>
                  SID
                </Typography>
                {
                  currentTechChannels.map(ctc =>
                    <Typography key={ctc.id}>
                      {ctc.sid}
                    </Typography>
                  )
                }
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                <Typography variant="h5" component="span" sx={{ mb: 2 }}>
                  Status
                </Typography>
                {
                  currentTechChannels.map(ctc =>
                    <Typography key={ctc.id}>
                      {ctc.status}
                    </Typography>
                  )
                }
              </Box>
            </Box>
          </Box>
        }
      </DialogContent>
    );
  }

  modalActions() {
    return (
      [
        <Button variant="outlined"
          key="cancel"
          onClick={() => {
            this.closeModal();
          }} >
            Cancel
        </Button>,
        <Button variant="contained"
          key="next"
          disabled={ false }
          color="secondary"
          onClick={() => {
            this.nextStep();
          }}>
            Ignore this beam
        </Button>,
        <Button variant="contained"
          key="save"
          color="error"
          onClick={() => {
            this.removeTransponder();
          }}>
            Delete it!
        </Button>
      ]
    );
  }

  render() {
    const { modalOpened } = this.props;

    return (
      <Modal
        isOpen={modalOpened}
        closeModal={() => this.closeModal()}
        title="Remove Transponder"
        customModalContent={() => this.modalContent()}
        actions={() => this.modalActions()}
        actionAlign="flex-end"
      >
      </Modal>
    );
  }
}

RemoveTransponderModal.propTypes = {
  currentTransponderIds: PropTypes.array.isRequired,

  modalOpened: PropTypes.bool.isRequired,
  closeTransponderModal: PropTypes.func.isRequired,

  transponders: PropTypes.array.isRequired,
  techChannels: PropTypes.array.isRequired,

  addToast: PropTypes.func.isRequired,
  deleteTransponder: PropTypes.func.isRequired,

  isLoading: PropTypes.bool.isRequired
};

const mapDispatchToProps = {
  addToast: addToastAction,
  deleteTransponder: deleteTransponderAction
};

function mapStateToProps(state) {
  return {
    transponders: listTranspondersSelector(state),
    techChannels: listTechChannelsSelector(state),

    isLoading: isLoadingSelector(state, deleteTransponderAction.toString())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoveTransponderModal);
