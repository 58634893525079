import { createAction } from "redux-actions";

const id = () => {
  let i = 0;

  return () => {
    i += 1;

    return i;
  };
};

const nextId = id();

export const addToast = createAction("ADD_TOAST", (type, title, content, time) => {
  const toastId = nextId();

  return {
    id: toastId,
    title,
    content,
    type,
    time: time || 5000
  };
});

export const removeToast = createAction("REMOVE_TOAST", toastId => toastId);
