import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import loaderLogo from "../../assets/loader.gif";

import "./Loader.css";

class Loader extends PureComponent {
  render() {
    const { mini } = this.props;

    return (
      <img className={`full-loader-image btn-loader${mini ? " mini-loader" : ""}`} src={loaderLogo} alt="Loading" />
    );
  }
}

Loader.defaultProps = {
  mini: false
};

Loader.propTypes = {
  mini: PropTypes.bool
};

export default Loader;
