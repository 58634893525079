export const listServiceGenres = state => state.serviceGenres.allServiceGenres;

export const listServiceGenresFromOrbitalPosition = state => {
  const serviceGenresIds = state.lineupsServices.allLineupsServices.reduce((acc, ls) => {
    (ls.genres || []).forEach(g => {
      if (!acc.includes(g.id)) {
        acc.push(g.id);
      }
    });

    return acc;
  }, []);

  return state.serviceGenres.allServiceGenres.filter(sg => serviceGenresIds.includes(sg.id));
};

export const showServiceGenre = (state, serviceGenreId) => state.serviceGenres.allServiceGenres.find(sg => sg.id === serviceGenreId);

export const getTotalCount = state => state.serviceGenres.allServiceGenres.filter(serviceGenre => serviceGenre.genre_type === null).length;

export const listServiceGenresFilters = state => state.serviceGenres.filters;
