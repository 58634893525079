import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import LanguageIcon from "@mui/icons-material/Language";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import HourglassTopRoundedIcon from "@mui/icons-material/HourglassTopRounded";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";

import { Navigate, useNavigate } from "react-router";
import { Button, Menu, MenuItem, Stack, Tab, Tabs } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { addToast as addToastAction } from "../../actions/toasts";

import {
  indexTechChannels as indexTechChannelsAction,
  createTechChannel as createTechChannelAction,
  updateTechChannel as updateTechChannelAction,
  exportTechChannels as exportTechChannelsAction
} from "../../actions/tech_channels";

import { listTransponders as listTranspondersSelector } from "../../selectors/transponders";
import { listBeams as listBeamsSelector } from "../../selectors/beams";
import { listOrbitalPositions as listOrbitalPositionsSelector } from "../../selectors/orbital_positions";
import { listLineupsByTechChannelId as listLineupsByTechChannelIdSelector } from "../../selectors/lineups";
import { listLineupsServicesByTechChannelId as listLineupsServicesByTechChannelIdSelector } from "../../selectors/lineups_services";
import {
  listTechChannels as listTechChannelsSelector
} from "../../selectors/tech_channels";
import { isLoading as isLoadingSelector } from "../../selectors/loaders";

import FullLoader from "../../components/FullLoader";
import AddTechChannelModal from "../../components/TechChannelModal/AddTechChannelModal";
import EditTechChannelModal from "../../components/TechChannelModal/EditTechChannelModal";

// import RemoveTechChannelModal from '../../components/TechChannelModal/RemoveTechChannelModal';

import { getObjectNameFromLocales, exportAsFile } from "../../helpers/utils";

import { getCurrentLocation } from "../../selectors/routes";

import DataTable from "../../components/DataTable";
import FiltersInputs from "../../components/FiltersInputs";

import { customGridOperators } from "../../utils/constant";

const attributesScrambled = isOk => isOk
  ? <CheckIcon />
  : <CloseIcon />;

const attributesStatus = tc => {
  if (tc.value === "discarded") {
    return <NotInterestedIcon sx={{ color: "red" }} />;
  }

  if (tc.value === "transfer") {
    return <ErrorIcon sx={{ color: "orange" }} />;
  }

  if (tc.value === "pending") {
    return <HourglassTopRoundedIcon sx={{ color: "orange" }} />;
  }

  if (tc.value === "active") {
    return <CheckIcon sx={{ color: "green" }} />;
  }

  return "";
};

function TechChannels({
  isTechChannelCreating,
  isTechChannelEditing,
  indexTechChannels,
  isTechChannelsLoading,
  isTechChannelExporting,
  updateTechChannel,
  exportTechChannels,
  addToast,
  techChannels,
  currentTab,
  orbitalPositions,
  beams,
  transponders,
  listLineupsByTechChannelId,
  listLineupsServicesByTechChannelId
}) {
  /* filters */
  const [filters, setFilters] = useState([]);

  /* selected channels */
  const [currentChannelId, setCurrentChannelId] = useState(null);
  const [selectedChannelsIds, setSelectedChannelsIds] = useState([]);

  /* menus */
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [openedMenu, setOpenedMenu] = useState(null);

  /* modals */
  const [addChannelModalOpened, setAddChannelModalOpened] = useState(false);
  const [editChannelModalOpened, setEditChannelModalOpened] = useState(false);

  const [globalLoading, setGlobalLoading] = useState(false);

  /* data  */

  // eslint-disable-next-line react/sort-comp
  const loadTechChannels = () => {
    if (!isTechChannelsLoading) {
      indexTechChannels({}, true);
    }
  };

  const changeTechChannelStatus = async (techChannelIds, status) => {
    setGlobalLoading(true);

    let success = true;

    for (let i = 0; i < techChannelIds.length; i += 1) {
      const ret = await updateTechChannel(techChannelIds[i], { status });

      if (!ret.isSuccess) {
        success = false;
      }
    }

    setGlobalLoading(false);

    if (success) {
      addToast("success", "Success", `Channel(s) successfully switched to "${status}"!`);
      setSelectedChannelsIds([]);
      loadTechChannels();
    } else {
      addToast("error", "Error", `Error while switching channels to "${status}". Try again.`);
    }
  };

  useEffect(() => {
    loadTechChannels();
  }, []);

  useEffect(() => {
    const isLoading = !!isTechChannelCreating || !!isTechChannelEditing;

    if (!isLoading) {
      loadTechChannels();
    }
  }, [isTechChannelCreating, isTechChannelEditing]);

  /* Menus methods */

  const openMenu = (event, menuId) => {
    setMenuAnchorEl(event.currentTarget);
    setOpenedMenu(menuId);
  };

  const closeMenu = menuId => {
    setMenuAnchorEl(null);
    setOpenedMenu(menuId);
  };

  /* Tabs */

  const navigate = useNavigate();

  const changeRoute = to => {
    navigate(to);
  };

  /* Edit Modal */

  const openEditChannelModal = (currentChannelId, field) => {
    if (field === "edit") {
      setCurrentChannelId(currentChannelId);
      setEditChannelModalOpened(true);
    }
  };

  const closeEditChannelModal = (shouldFetchData = true) => {
    if (shouldFetchData) {
      loadTechChannels();
    }

    setCurrentChannelId(null);
    setEditChannelModalOpened(false);
  };

  /* Add Modal */

  const openAddChannelModal = () => {
    setCurrentChannelId(null);
    setAddChannelModalOpened(true);
  };

  const closeAddChannelModal = (shouldFetchData = true) => {
    if (shouldFetchData) {
      loadTechChannels();
    }

    setAddChannelModalOpened(false);
  };

  const menuOpen = Boolean(menuAnchorEl);

  if (currentTab === "technical_channels") {
    return <Navigate to="/technical_channels/all" />;
  }

  const transpondersFilters = transponders
    .map(t => {
      const beam = beams.find(b => b.id === t.beam_id) || {};
      const orbitalPosition = orbitalPositions.find(op => op.id === beam.orbital_position_id) || {};
      let { name } = t;

      if (orbitalPosition && orbitalPosition.name) {
        name = `${orbitalPosition.name} - ${name}`;
      }

      return { name, value: name };
    })
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }

      if (a.name > b.name) {
        return 1;
      }

      return 0;
    });

  const primaryFilters = [
    {
      text: "Orbital Position",
      name: "orbital_position",
      values: [{ name: "No Orbital Position", value: false }, ...orbitalPositions.map(op => ({ name: op.name, value: op.name }))]
    },
    {
      text: "TxP Name",
      name: "transponder",
      values: transpondersFilters
    },
    {
      text: "Type",
      name: "tech_channel_type",
      values: [{ name: "DVB", value: "dvb" }, { name: "OTT", value: "ott" }]
    },
    {
      text: "Scrambled",
      name: "scrambled",
      values: [{ name: "Not Scrambled", value: false }, { name: "Scrambled", value: true }]
    }
  ];

  // process status

  const statuedTechChannels = techChannels.filter(tc => currentTab === "all" || tc.status === currentTab);

  // process filters

  const grouppedTags = filters.reduce((acc, filter) => {
    const existingTag = acc.find(t => t.filterName === filter.filterName);

    if (!existingTag) {
      acc.push({
        filterName: filter.filterName,
        values: [filter.value]
      });
    } else {
      existingTag.values.push(filter.value);
    }

    return acc;
  }, []);

  const taggedTechChannels = statuedTechChannels.reduce((acc, tc) => {
    let isMatching = true;

    grouppedTags.forEach(filter => {
      if (filter.filterName === "orbital_position_id") {
        const transponderId = tc.transponder_id;
        const currentTransponder = transponders.find(t => t.id === transponderId) || {};
        const currentBeam = beams.find(b => b.id === currentTransponder.beam_id) || {};
        const currentOrbitalPosition = orbitalPositions.find(op => op.id === currentBeam.orbital_position_id) || {};

        if (filter.values.indexOf(currentOrbitalPosition.id || null) === -1) {
          isMatching = false;
        }
      }

      if (filter.filterName === "txp_name") {
        const transponderId = tc.transponder_id;
        const currentTransponder = transponders.find(t => t.id === transponderId) || {};

        if (filter.values.indexOf(currentTransponder.id) === -1) {
          isMatching = false;
        }
      }

      if (filter.filterName === "type" && filter.values.indexOf(tc.tech_channel_type) === -1) {
        isMatching = false;
      }

      if (filter.filterName === "scrambled" && filter.values.indexOf(tc.scrambled) === -1) {
        isMatching = false;
      }
    });

    // If the current techChannel does not match the current criteria, it does not retain it
    if (!isMatching) {
      return acc.filter(t => t.id !== tc.id);
    }

    return acc;
  }, statuedTechChannels);

  const totalItemsCount = taggedTechChannels.length;

  const tabs = [
    {
      name: <>
        {" All Tech Channels "}
        {currentTab === "all"
            && <span className="tab-nav-number">({totalItemsCount})</span>
        }
      </>,
      slug: "all",
      icon: <LanguageIcon />,
      currentClassName: "active"
    },
    {
      name: <>
        {" Active "}
        {currentTab === "active"
            && <span className="tab-nav-number">({totalItemsCount})</span>
        }
      </>,
      slug: "active",
      icon: <CheckIcon sx={{ color: "green" }} />,
      currentClassName: "active"
    },
    {
      name: <>
        {" To Transfer "}
        {currentTab === "transfer"
            && <span className="tab-nav-number">({totalItemsCount})</span>
        }
      </>,
      slug: "transfer",
      icon: <ErrorIcon sx={{ color: "orange" }} />,
      currentClassName: "active"
    },
    {
      name: <>
        {" Pending "}
        {currentTab === "pending"
            && <span className="tab-nav-number">({totalItemsCount})</span>
        }
      </>,
      slug: "pending",
      icon: <HourglassTopRoundedIcon sx={{ color: "orange" }} />,
      currentClassName: "active"
    },
    {
      name: <>
        {" Discarded "}
        {currentTab === "discarded"
            && <span className="tab-nav-number">({totalItemsCount})</span>
        }
      </>,
      slug: "discarded",
      icon: <NotInterestedIcon sx={{ color: "red" }} />,
      currentClassName: "active"
    }
  ];

  const childrenData = techChannel => {
    const transponderId = techChannel.row.transponder_id;
    const currentTransponder = transponders.find(t => t.id === transponderId) || {};
    const lineupServices = listLineupsServicesByTechChannelId(techChannel.id);
    const lineups = listLineupsByTechChannelId(techChannel.id);

    if (techChannel.row.tech_channel_type === "dvb") {
      const data = [
        {
          id: 0,
          tech_channel_type: "dvb",
          tsid: currentTransponder.theoretical_tsid || "N/A",
          onid: currentTransponder.theoretical_onid || "N/A",
          sid: techChannel.row.sid || "N/A",
          serviceType: techChannel.row.service_type_id || "N/A",
          option: "theoretical"
        },
        {
          id: 1,
          tech_channel_type: "",
          tsid: currentTransponder.broadcast_tsid || "N/A",
          onid: currentTransponder.broadcast_onid || "N/A",
          sid: "",
          serviceType: "",
          option: "broadcast"
        }
      ];

      if (lineups.length > 0) {
        lineups.map((l, i) => {
          data.push({
            id: 3 + i,
            tech_channel_type: i === 0 ? "Lineups associated:" : "",
            tsid: getObjectNameFromLocales(l),
            onid: lineupServices.filter(ls => ls.lineup_id === l.id).map(ls => ls.service ? ls.service.name : "").join(", ")
          });
          return data;
        });
      }

      return data;
    }

    if (techChannel.row.tech_channel_type === "ott") {
      const data = [
        {
          id: 0,
          tech_channel_type: "ott",
          ottBackend: techChannel.row.ott_backend_id || "N/A",
          backendChannelId: techChannel.row.backend_channel_id || "N/A",
          serviceType: techChannel.row.service_type_id || "N/A"
        }
      ];

      if (lineups.length > 0) {
        lineups.map((l, i) => {
          data.push({
            id: 1 + i,
            tech_channel_type: i === 0 ? "Lineups associated:" : "",
            ottbackend: getObjectNameFromLocales(l),
            bakcendChannelId: lineupServices.filter(ls => ls.lineup_id === l.id).map(ls => ls.service ? ls.service.name : "").join(", ")
          });
          return data;
        });
      }

      return data;
    }
  };

  const childrenCols = techChannel => {
    if (techChannel.row.tech_channel_type === "dvb") {
      return [
        {
          headerName: "",
          field: "tech_channel_type",
          renderCell: params => (params.value !== "Lineups associated:" && params.value !== "" && params.row.option === "theoretical" && <><span style={{ fontWeight: "bold" }}>Type:&nbsp;</span>{params.value}</>) || <span style={{ fontWeight: "bold" }}>{params.value}</span>,
          flex: 1
        },
        {
          headerName: "",
          field: "tsid",
          renderCell: params => (!!params.row.option && ((params.row.option === "theoretical" && <><span style={{ fontWeight: "bold" }}>Theoretical Tsid:&nbsp;</span>{params.value}</>) || (params.row.option === "broadcast" && <><span style={{ fontWeight: "bold" }}>Broadcast Tsid:&nbsp;</span>{params.value}</>))) || params.value,
          flex: 1
        },
        {
          headerName: "",
          field: "onid",
          renderCell: params => (!!params.row.option && ((params.row.option === "theoretical" && <><span style={{ fontWeight: "bold" }}>Theoretical Onid:&nbsp;</span>{params.value}</>) || (params.row.option === "broadcast" && <><span style={{ fontWeight: "bold" }}>Broadcast Onid:&nbsp;</span>{params.value}</>))) || `(Services: ${params.value})`,
          flex: 1
        },
        {
          headerName: "",
          field: "sid",
          renderCell: params => !!params.row.option && params.row.option === "theoretical" && <><span style={{ fontWeight: "bold" }}>Sid:&nbsp;</span>{params.value}</>,
          flex: 1
        },
        {
          headerName: "",
          field: "serviceType",
          renderCell: params => !!params.row.option && params.row.option === "theoretical" && <><span style={{ fontWeight: "bold" }}>Service Type:&nbsp;</span>{params.value}</>,
          flex: 1
        }
      ];
    }

    if (techChannel.row.tech_channel_type === "ott") {
      return [
        {
          headerName: "",
          field: "tech_channel_type",
          renderCell: params => (params.value !== "Lineups associated:" && params.value !== "" && <><span style={{ fontWeight: "bold" }}>Type:&nbsp;</span>{params.value}</>) || <span style={{ fontWeight: "bold" }}>{params.value}</span>,
          flex: 1
        },
        {
          headerName: "",
          field: "ottBackend",
          renderCell: params => (!!params.row.serviceType && <><span style={{ fontWeight: "bold" }}>OTT Backend:&nbsp;</span>{params.value}</>) || params.value,
          flex: 1
        },
        {
          headerName: "",
          field: "backendChannelId",
          renderCell: params => (!!params.row.serviceType && <><span style={{ fontWeight: "bold" }}>Backend Channel Id:&nbsp;</span>{params.value}</>) || params.value,
          flex: 2
        },
        {
          headerName: "",
          field: "serviceType",
          renderCell: params => !!params.row.serviceType && <><span style={{ fontWeight: "bold" }}>Service Type:&nbsp;</span>{params.value}</>,
          flex: 1
        }
      ];
    }
  };

  const exportCSV = async () => {
    const response = await exportTechChannels();

    if (!response.isSuccess) {
      addToast("error", "Error", "Error while exporting Channels. Try again.");
      return;
    }

    exportAsFile(response.data, "technical_channels.csv");
  };

  return (
    <div id="tech_channels" className="page-content">
      {(!!isTechChannelsLoading || !!isTechChannelCreating || !!globalLoading) && <FullLoader />}

      {!isTechChannelsLoading && !isTechChannelCreating && !globalLoading
        && <>
          <Tabs
            sx={{
              marginBottom: "15px",
              ".MuiTabs-indicator": {
                display: "none"
              },
            }}
            value={currentTab}
          >
            {tabs.map(tab => <Tab
              sx={{
                minHeight: "auto",
                marginRight: "20px",
                borderBottom: "solid 3px",
                borderColor: "secondary.main",
                "&.Mui-selected": {
                  borderColor: "text.primary"
                }
              }}
              key={tab.slug}
              value={tab.slug}
              label={tab.name}
              icon={tab.icon}
              iconPosition="start"
              onClick={() => changeRoute(`/technical_channels/${tab.slug}`)}
            />)}
          </Tabs>
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between" spacing={3}>
              <Stack direction="row" spacing={3}>
                <div>
                  <Button
                    id="actions-button"
                    sx={{
                      borderColor: "primary.smokeBorder",
                    }}
                    variant="outlined"
                    aria-controls={menuOpen && openedMenu === "actions-menu" ? "actions-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={menuOpen && openedMenu === "actions-menu" ? "true" : undefined}
                    disableElevation
                    onClick={e => openMenu(e, "actions-menu")}
                    endIcon={<KeyboardArrowDownIcon />}
                    disabled={ !selectedChannelsIds.length }
                  >
                    Actions
                  </Button>
                  <Menu
                    MenuListProps={{
                      "aria-labelledby": "actions-button",
                    }}
                    id="actions-menu"
                    anchorEl={menuAnchorEl}
                    open={menuOpen && openedMenu === "actions-menu"}
                    onClose={() => closeMenu()}
                  >
                    {(currentTab === "all" || currentTab === "discarded")
                      && <div>
                        <MenuItem
                          onClick={() => {
                            closeMenu();
                            changeTechChannelStatus(selectedChannelsIds, "transfer");
                          }}
                          disableRipple
                        >
                          Switch selected channels to transfer
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            closeMenu();
                            changeTechChannelStatus(selectedChannelsIds, "pending");
                          }}
                          disableRipple
                        >
                          Switch selected channels to pending
                        </MenuItem>
                      </div>
                    }
                    {(currentTab === "transfer" || currentTab === "pending")
                      && <MenuItem
                        sx={{ color: "red" }}
                        onClick={() => {
                          closeMenu();
                          changeTechChannelStatus(selectedChannelsIds, "discarded");
                        }}
                        disableRipple
                      >
                        Switch selected channels to discarded
                      </MenuItem>
                    }
                  </Menu>
                </div>
                <FiltersInputs primaryFilters={primaryFilters} onFiltersChange={setFilters} searchFilterField="service_name" searchFilterLabel="Tech Channel"/>
              </Stack>
              <div>
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="outlined"
                    onClick={() => exportCSV()}
                    disabled={ isTechChannelExporting }
                  >
                    Export CSV { isTechChannelExporting && " (loading...)"}
                  </Button>
                  <Button variant="contained" color="secondary" onClick={() => openAddChannelModal()}>New channel</Button>
                </Stack>
              </div>
            </Stack>

            <div style={{ height: "calc(100vh - 270px)", minHeight: "160px" }}>
              <DataTable
                sx={{
                  ".MuiDataGrid-row": {
                    cursor: "pointer"
                  },
                  ".MuiDataGrid-detailPanel .MuiDataGrid-row": {
                    cursor: "default"
                  }
                }}
                cols={[
                  {
                    headerName: "",
                    field: "edit",
                    renderCell: () => <EditIcon />,
                    flex: 1
                  },
                  {
                    headerName: "Id", field: "id", sortable: true, searchable: true, flex: 1
                  },
                  {
                    headerName: "Status", field: "status", renderCell: attributesStatus, sortable: true, flex: 1
                  },
                  {
                    headerName: "Tech Channel", field: "service_name", sortable: true, searchable: true, flex: 2
                  },
                  {
                    headerName: "Type", field: "tech_channel_type", flex: 1, filterOperators: customGridOperators
                  },
                  {
                    headerName: "Orbital Position",
                    field: "orbital_position",
                    valueGetter: item => {
                      const transponderId = item.row.transponder_id;
                      const currentTransponder = transponders.find(t => t.id === transponderId) || {};
                      const currentBeam = beams.find(b => b.id === currentTransponder.beam_id) || {};
                      const currentOrbitalPosition = orbitalPositions.find(op => op.id === currentBeam.orbital_position_id);

                      return currentOrbitalPosition?.name ?? false;
                    },
                    renderCell: params => {
                      if (!params.value) {
                        return <CloseIcon />;
                      }
                    },
                    flex: 1,
                    filterOperators: customGridOperators
                  },
                  {
                    headerName: "Beam",
                    field: "beam",
                    sortSlug: "beam_id",
                    valueGetter: item => {
                      const transponderId = item.row.transponder_id;
                      const currentTransponder = transponders.find(t => t.id === transponderId) || {};
                      const currentBeam = beams.find(b => b.id === currentTransponder.beam_id) || {};

                      return currentBeam?.name ?? false;
                    },
                    renderCell: params => {
                      if (!params.value) {
                        return <CloseIcon />;
                      }
                    },
                    sortable: true,
                    flex: 1
                  },
                  {
                    headerName: "Transponder",
                    field: "transponder",
                    valueGetter: item => {
                      const transponderId = item.row.transponder_id;
                      const currentTransponder = transponders.find(t => t.id === transponderId) || {};
                      const currentBeam = beams.find(b => b.id === currentTransponder.beam_id) || {};
                      const currentOrbitalPosition = orbitalPositions.find(op => op.id === currentBeam.orbital_position_id);

                      return currentTransponder?.name ? `${currentOrbitalPosition?.name} - ${currentTransponder?.name}` : false;
                    },
                    renderCell: params => {
                      if (!params.value) {
                        return <CloseIcon />;
                      }
                      return params.value.split(" ")[2];
                    },
                    sortable: true,
                    flex: 1,
                    filterOperators: customGridOperators
                  },
                  { headerName: "SID", field: "sid", sortable: true, flex: 1 },
                  { headerName: "Scrambled", field: "scrambled", renderCell: item => attributesScrambled(item.value), flex: 1, filterOperators: customGridOperators },
                  { headerName: "Modified", field: "updated_at", sortable: true, flex: 2 },
                ]}
                data={taggedTechChannels}
                invisibleCols={{ __detail_panel_toggle__: false }}
                filters={filters}
                onCellClick={params => openEditChannelModal(params.id, params.field)}
                disableRowClick
                disableOnCellClick={false}
                toggleDetailDisabledRow={["edit"]}
                checkboxSelection
                isRowSelectable={params => params.row.status !== "active"}
                onRowSelectionModelChange={setSelectedChannelsIds}
                getDetailPanelContent={techChannel =>
                  <DataTable
                    sx={{
                      ".MuiDataGrid-row": {
                        backgroundColor: "rgba(0,0,0,.03)"
                      },
                      ".MuiDataGrid-cell": {
                        padding: "10px 5px"
                      }
                    }}
                    cols={childrenCols(techChannel)}
                    headerHeight={0}
                    data={childrenData(techChannel)}
                    hideFooter
                  />
                }
                pagination
              />
            </div>
          </Stack>
        </>
      }

      {/* MODAL */}

      <AddTechChannelModal
        modalOpened={addChannelModalOpened}
        closeAddChannelModal={closeAddChannelModal}
      />

      <EditTechChannelModal
        currentChannelId={currentChannelId}
        modalOpened={editChannelModalOpened}
        closeEditChannelModal={closeEditChannelModal}
      />
    </div>
  );
}

TechChannels.propTypes = {
  currentTab: PropTypes.string.isRequired,

  techChannels: PropTypes.arrayOf(PropTypes.object).isRequired,
  orbitalPositions: PropTypes.arrayOf(PropTypes.object).isRequired,
  beams: PropTypes.arrayOf(PropTypes.object).isRequired,
  transponders: PropTypes.arrayOf(PropTypes.object).isRequired,

  isTechChannelsLoading: PropTypes.bool.isRequired,
  isTechChannelCreating: PropTypes.bool.isRequired,
  isTechChannelEditing: PropTypes.bool.isRequired,
  isTechChannelExporting: PropTypes.bool.isRequired,

  listLineupsByTechChannelId: PropTypes.func.isRequired,
  listLineupsServicesByTechChannelId: PropTypes.func.isRequired,

  indexTechChannels: PropTypes.func.isRequired,
  updateTechChannel: PropTypes.func.isRequired,
  exportTechChannels: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const route = getCurrentLocation(state).pathname.split("/");

  return {
    currentTab: route[route.length - 1] || "all",
    techChannels: listTechChannelsSelector(state),
    orbitalPositions: listOrbitalPositionsSelector(state),
    beams: listBeamsSelector(state),
    transponders: listTranspondersSelector(state),

    listLineupsByTechChannelId: techChannelId => listLineupsByTechChannelIdSelector(state, techChannelId),
    listLineupsServicesByTechChannelId: techChannelId => listLineupsServicesByTechChannelIdSelector(state, techChannelId),

    isTechChannelsLoading: isLoadingSelector(state, indexTechChannelsAction.toString()),
    isTechChannelCreating: isLoadingSelector(state, createTechChannelAction.toString()),
    isTechChannelEditing: isLoadingSelector(state, updateTechChannelAction.toString()),
    isTechChannelExporting: isLoadingSelector(state, exportTechChannelsAction.toString())
  };
}

const mapDispatchToProps = {
  indexTechChannels: indexTechChannelsAction,
  updateTechChannel: updateTechChannelAction,
  exportTechChannels: exportTechChannelsAction,
  addToast: addToastAction
};

export default connect(mapStateToProps, mapDispatchToProps)(TechChannels);
