import { handleActions } from "redux-actions";

const initialState = {
  nitReports: [],
  nitReportsCount: 0
};

export default handleActions({
  STORE_NIT_REPORTS: (state, action) => ({
    ...state,
    nitReports: action.payload.result,
    nitReportsCount: parseInt(action.payload.headers["total-count"])
  })
}, initialState);
