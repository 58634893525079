import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DataGridPro, GridLogicOperator } from "@mui/x-data-grid-pro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faCheckCircle,
  faQuestion
} from "@fortawesome/free-solid-svg-icons";
import { Box, Tooltip, Typography } from "@mui/material";

import { indexExportBackups as indexExportBackupsAction } from "../../actions/export_backups";

const reportStatus = (value, reason = null, opts = {}) => {
  if (value === true) {
    return <FontAwesomeIcon icon={faCheckCircle} className="green-icon" title={opts.hintTrue || ""} />;
  } else if (value === false) {
    return (
      <Tooltip title={reason} placement="top">
        <FontAwesomeIcon icon={faExclamationTriangle} className="red-icon" title={opts.hintFalse || ""} />
      </Tooltip>
    );
  }
  return <FontAwesomeIcon icon={faQuestion} title={opts.hintElse || ""} />;
};

class ExportBackups extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paginationModel: {
        page: 0,
        pageSize: 25
      }
    };
  }

  componentDidMount() {
    const { paginationModel } = this.state;

    this.loadExportBackups(paginationModel.page + 1, paginationModel.pageSize, this.props.version);
  }

  loadExportBackups(page, pageSize, version) {
    const { indexExportBackups, isExportBackupsLoading } = this.props;

    if (!isExportBackupsLoading) {
      indexExportBackups(page, pageSize, version);
    }
  }

  backupFilesMaxHeight(height) {
    return 53 * height + "px";
  }

  handlePaginationModelChange = ({ page, pageSize }) => {
    const { paginationModel } = this.state;

    this.setState({ paginationModel: {
      page: pageSize !== paginationModel.pageSize ? 0 : page,
      pageSize: pageSize
    } }, () => {
      this.loadExportBackups(this.state.paginationModel.page + 1, this.state.paginationModel.pageSize, this.props.version);
    });
  };

  render() {
    const { isExportBackupsLoading, exportBackups } = this.props;
    const { paginationModel } = this.state;
    const backups = exportBackups.exportBackups;

    const columns = [
      {
        field: "position",
        headerName: "Position",
        sortable: false,
        flex: 0.5,
      },
      {
        field: "date",
        headerName: "Date",
        sortable: false,
        flex: 0.7,
      },
      {
        field: "success",
        headerName: "Status",
        renderCell: params => reportStatus(params.value, params.row.reason),
        sortable: false,
        flex: 0.2,
      },
      {
        field: "imux",
        headerName: "IMux",
        sortable: false,
        flex: 1
      },
    ];

    return (
      <DataGridPro
        loading={isExportBackupsLoading}
        rows={backups}
        columns={columns}
        rowCount={exportBackups.exportBackupsCount}
        disableColumnFilter={true}
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={this.handlePaginationModelChange}
        getDetailPanelHeight={() => "auto"}
        getDetailPanelContent={({ row }) => {
          const currentBackupFiles = row.files;

          if (currentBackupFiles.length === 0) {
            return <Typography p={2}>No files</Typography>;
          }

          const carouselSizeLimitMB = parseInt(row.max_zip_mb_size || 0, 10);
          const carouselSizeLimit = carouselSizeLimitMB * 1000 * 1000; // In octets

          return <Box sx={{ height: this.backupFilesMaxHeight(currentBackupFiles.length), backgroundColor: "primary.grey2" }}>
            <DataGridPro
              sx={{ backgroundColor: "#fff" }}
              hideFooter={true}
              hideHeader={true}
              sortable={false}
              columnHeaderHeight={0}
              columns={[
                {
                  field: "name",
                  renderCell: ({ value }) =>
                    <a target="_blank"
                      rel="noopener noreferrer"
                      href={`https://s3.console.aws.amazon.com/s3/object/${row.bucket}/${value.path}?region=eu-west-1`}>{value.filename}</a>,
                },
                { field: "size" },
                {
                  field: "status",
                  renderCell: ({ value }) => reportStatus(value < carouselSizeLimit),
                },
                {
                  field: "msg", flex: 1
                },
              ]}
              rows={currentBackupFiles.map(file => ({
                id: file.id,
                name: { filename: file.filename, path: file.path },
                size: (file.size && `${(file.size / 1000000).toFixed(2) < 0.1 ? (file.size / 1000).toFixed(2) + "kB" : (file.size / 1000000).toFixed(2) + "MB"}`) || "",
                status: file.size,
                msg: (file.size >= carouselSizeLimit && `This carousel is too heavy (limit was: ${carouselSizeLimitMB}MB)`) || "",
              }))}
            />
          </Box>;
        }}
      />
    );
  }
}

ExportBackups.propTypes = {
  isExportBackupsLoading: PropTypes.bool.isRequired,
  indexExportBackups: PropTypes.func.isRequired,
  exportBackups: PropTypes.exact({
    exportBackups: PropTypes.array.isRequired,
    exportBackupsCount: PropTypes.number.isRequired
  }).isRequired,
  version: PropTypes.number
};

function mapStateToProps(state) {
  return {
    exportBackups: state.exportBackups,
    isExportBackupsLoading: !!state.loaders.actions.find(a => a === indexExportBackupsAction.toString())
  };
}

const mapDispatchToProps = {
  indexExportBackups: indexExportBackupsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportBackups);
