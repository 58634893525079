import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import camelcase from "camelcase";
import { Stack } from "@mui/material";

import { Navigate } from "react-router";
import OrbitalPositions from "../../components/OrbitalPositions";
import Beams from "../../components/Beams";
import Transponders from "../../components/Transponders";
import Tabs from "../../components/Tabs";

import AddOrbitalPositionModal from "../../components/OrbitalPositionsModal/AddOrbitalPositionModal";
import AddBeamModal from "../../components/BeamsModal/AddBeamModal";
import AddTransponderModal from "../../components/TranspondersModal/AddTransponderModal";

import { getCurrentLocation } from "../../selectors/routes";

import { getTotalCount as getTotalCountOrbitalPositionsSelector } from "../../selectors/orbital_positions";
import { getTotalCount as getTotalCountBeamsSelector } from "../../selectors/beams";
import { getTotalCount as getTotalCountTranspondersSelector } from "../../selectors/transponders";

class SpaceObjects extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      orbitalPositionsModalOpened: false,
      beamsModalOpened: false,
      transpondersModalOpened: false
    };
  }

  /* Tabs */

  openAddModal = type => {
    this.setState({ [`${type}ModalOpened`]: true });
  };

  closeAddModal = type => {
    this.setState({ [`${type}ModalOpened`]: false });
  };

  render() {
    const {
      orbitalPositionsModalOpened,
      beamsModalOpened,
      transpondersModalOpened
    } = this.state;

    const {
      currentTab,
      totalCountOrbitalPositions,
      totalCountBeams,
      totalCountTransponders
    } = this.props;

    if (currentTab === "space_objects") {
      return <Navigate to="/space_objects/orbital_positions" />;
    }

    const tabs = [
      {
        name:
  <Link to="/space_objects/orbital_positions">
    Orbital Positions (
    {totalCountOrbitalPositions}
    )
  </Link>,
        slug: "orbital_positions",
        currentClassName: "active",
        allowCreation: true
      },
      {
        name:
  <Link to="/space_objects/beams">
    Beams (
    {totalCountBeams}
    )
  </Link>,
        slug: "beams",
        currentClassName: "active",
        allowCreation: true
      },
      {
        name:
  <Link to="/space_objects/transponders">
    Transponders (
    {totalCountTransponders}
    )
  </Link>,
        slug: "transponders",
        currentClassName: "active",
        allowCreation: true
      }
    ];

    const currentTabObject = tabs.find(t => t.slug === currentTab);
    const tabsActions = [];

    if (currentTabObject && currentTabObject.allowCreation) {
      const singularTabName = pluralize.singular(currentTabObject.slug.replace("_", " "));
      const camelSlug = camelcase(currentTabObject.slug);

      tabsActions.push({
        name: `New ${singularTabName}`,
        className: "btn-primary",
        action: () => this.openAddModal(camelSlug)
      });
    }

    return (
      <Stack id="space_objects" className="page-content">
        <Tabs
          changeTab={() => {}}
          currentTab={currentTab}
          tabs={tabs}
          tabsActions={tabsActions}
          smallTabs
        />

        {currentTab === "orbital_positions"
          && <OrbitalPositions />}

        {currentTab === "beams"
          && <Beams />}

        {currentTab === "transponders"
          && <Transponders />}

        <AddOrbitalPositionModal
          modalOpened={orbitalPositionsModalOpened}
          closeAddModal={() => this.closeAddModal("orbitalPositions")}
        />

        <AddBeamModal
          modalOpened={beamsModalOpened}
          closeAddModal={() => this.closeAddModal("beams")}
        />

        <AddTransponderModal
          modalOpened={transpondersModalOpened}
          closeAddModal={() => this.closeAddModal("transponders")}
        />
      </Stack>
    );
  }
}

SpaceObjects.propTypes = {
  currentTab: PropTypes.string.isRequired,
  totalCountOrbitalPositions: PropTypes.number.isRequired,
  totalCountBeams: PropTypes.number.isRequired,
  totalCountTransponders: PropTypes.number.isRequired
};

function mapStateToProps(state) {
  const route = getCurrentLocation(state).pathname.split("/");

  return {
    currentTab: route[route.length - 1] || "orbital_positions",
    totalCountOrbitalPositions: getTotalCountOrbitalPositionsSelector(state),
    totalCountBeams: getTotalCountBeamsSelector(state),
    totalCountTransponders: getTotalCountTranspondersSelector(state)
  };
}

export default connect(mapStateToProps)(SpaceObjects);
