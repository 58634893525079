import { uniq } from "lodash";

import {
  showBeamsLineupsFromBeamId,
  showBeamsLineupsFromBeamIds
} from "./beams_lineups";

import { listLineupsServicesByTechChannelId } from "./lineups_services";

export const listLineups = state => state.lineups.allLineups;

export const listLineupsFromBeamId = (state, beamId) => {
  const beamsLineups = showBeamsLineupsFromBeamId(state, beamId);
  const lineupsIds = beamsLineups.map(bl => bl.lineup_id);
  const lineups = state.lineups.allLineups.filter(l => lineupsIds.indexOf(l.id) !== -1);

  return lineups;
};

export const listOrbitalPositionsLineupsFromBeamId = (state, beamId) => {
  const beam = state.beams.allBeams.find(b => b.id === beamId);
  const orbitalPositionId = beam.orbital_position_id;
  const beams = state.beams.allBeams.filter(b => b.orbital_position_id === orbitalPositionId);
  const beamIds = beams.map(b => b.id);
  const beamsLineups = showBeamsLineupsFromBeamIds(state, beamIds);
  const lineupsIds = beamsLineups.map(bl => bl.lineup_id);
  const lineups = state.lineups.allLineups.filter(l => lineupsIds.indexOf(l.id) !== -1);

  return lineups;
};

export const listLineupsByTechChannelId = (state, techChannelId) => {
  const lineupsServices = listLineupsServicesByTechChannelId(state, techChannelId);
  const lineupsIds = uniq(lineupsServices.map(ls => ls.lineup_id));
  const lineups = state.lineups.allLineups.filter(l => lineupsIds.indexOf(l.id) !== -1);

  return lineups;
};

export const showLineup = (state, lineupId) => state.lineups.allLineups.find(l => l.id === lineupId);

export const getTotalCount = state => state.lineups.totalCount;

export const listLineupsFilters = state => state.lineups.filters;
