import React, { PureComponent } from "react";
import { Card } from "semantic-ui-react";

import versions from "./versions";

import "./Changelog.css";

class Changelog extends PureComponent {
  render() {
    return (
      <div id="changelog" className="page-content">
        {
          versions
            .sort((a, b) => b.version.localeCompare(a.version, undefined, { numeric: true }))
            .map(version => <Card
              key={version.version}
              fluid
              header={(
                <Card.Header>
                  {`v${version.version} - ${version.title}`}
                </Card.Header>
              )}
              meta={version.date}
              description={(
                <ul id="changes">
                  {version.changes.map((c, i) => <li key={i}>{c}</li>)}
                </ul>
              )}
            />

            )
        }
      </div>
    );
  }
}

export default Changelog;
