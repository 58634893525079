export const listChannelTiers = state => state.channelTiers.allChannelTiers;

export const listChannelTiersFromOrbitalPosition = state => {
  const channelTiersIds = [...new Set(state.lineupsServices.allLineupsServices.map(tc => tc.channel_tier_id))];

  return state.channelTiers.allChannelTiers.filter(ct => channelTiersIds.includes(ct.id));
};

export const showChannelTier = (state, channelTierId) => state.channelTiers.allChannelTiers.find(ct => ct.id === channelTierId);

export const getTotalCount = state => state.channelTiers.totalCount;

export const listChannelTiersFilters = state => state.channelTiers.filters;
