/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Alert, Button, DialogContent, Box, FormControl, Select, InputLabel, MenuItem, TextField } from "@mui/material";
import Modal from "../Modal/Modal";
import withStepper from "../Modal/withStepper";

import { addToast as addToastAction } from "../../actions/toasts";

import {
  createTechChannel as createTechChannelAction
} from "../../actions/tech_channels";

import { listOrbitalPositions as listOrbitalPositionsSelector } from "../../selectors/orbital_positions";
import { listBeams as listBeamsSelector } from "../../selectors/beams";
import { listTransponders as listTranspondersSelector } from "../../selectors/transponders";
import { listOttBackends as listOttBackendsSelector } from "../../selectors/ott_backends";

const techChannelTypes = ["dvb", "ott"];
const ottBackendToString = ob => `${ob.id || 0} - ${ob.type || ""} - ${ob.endpoint_url || ""}`;

const initialState = {
  tech_channel_type: "",

  orbital_position_id: "",
  beam_id: "",
  transponder_id: "",

  name: "",

  onid: "",
  tsid: "",
  sid: "",

  ott_backend_id: "",
  backend_channel: "",

  globalLoading: false,
};

class AddTechChannelModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { ...initialState };
  }

  changeInput(inputName, event, type = "") {
    const { value } = event.target;
    const { transponders } = this.props;

    if (type === "int") {
      if (value === "" || /^\d+$/.test(value)) {
        this.setState({ [inputName]: value });
      }
      return;
    }

    if (inputName === "orbital_position_id") {
      this.setState(() => ({ beam_id: "", transponder_id: "" }));
    } else if (inputName === "beam_id") {
      this.setState(() => ({ transponder_id: "" }));
    } else if (inputName === "transponder_id") {
      const transponder = transponders.find(t => t.id === parseInt(value, 10));

      if (transponder) {
        this.setState({ tsid: transponder.theoretical_tsid.toString(), onid: transponder.theoretical_onid.toString() });
      }
    }

    this.setState({ [inputName]: value });
  }

  async addTechChannel() {
    const {
      tech_channel_type,
      transponder_id,
      name,
      onid,
      tsid,
      sid,
      ott_backend_id,
      backend_channel
    } = this.state;

    this.setState({ globalLoading: true });

    const {
      createTechChannel,
      addToast
    } = this.props;

    const techChannelParam = {
      transponder_id,
      service_name: name,
      tech_channel_type,
      status: "pending"
    };

    if (tech_channel_type === "dvb") {
      techChannelParam.onid = onid;
      techChannelParam.tsid = tsid;
      techChannelParam.sid = sid;
    } else if (tech_channel_type === "ott") {
      techChannelParam.ott_backend_id = ott_backend_id;
      techChannelParam.backend_channel = backend_channel;
    }

    const ret = await createTechChannel(techChannelParam);

    this.setState({ globalLoading: false });

    if (ret.isSuccess) {
      addToast("success", "Success", "Technical channel successfully added!");
      this.closeModal();
    } else {
      addToast("error", "Error", "Error while adding the technical channel. Try again.");
    }
  }

  closeModal(shouldFetchData = true) {
    const { closeAddChannelModal } = this.props;

    this.setState(initialState);
    closeAddChannelModal(shouldFetchData);
  }

  isNextPossible() {
    const { currentStep } = this.props;
    const { transponder_id, tech_channel_type } = this.state;

    if (currentStep === 0) {
      if (transponder_id) {
        return true;
      }
    } else if (currentStep === 1) {
      if (this.isValid("tech_channel_type") && this.isValid("name")) {
        return true;
      }
    } else if (currentStep === 2) {
      if (tech_channel_type === "dvb" && this.isValid("onid") && this.isValid("tsid") && this.isValid("sid")) {
        return true;
      } else if (tech_channel_type === "ott" && this.isValid("ott_backend_id") && this.isValid("backend_channel")) {
        return true;
      }
    }
    return false;
  }

  isValid(inputName) {
    if (inputName instanceof Array) {
      return inputName.every(i => this.isValid(i));
    }
    return !!this.state[inputName];
  }

  step1() {
    const { orbitalPositions, beams, transponders, isLastStep } = this.props;
    const { orbital_position_id, beam_id, transponder_id } = this.state;

    const filteredBeams = beams.filter(b => b.orbital_position_id === orbital_position_id);
    const filteredTransponders = transponders.filter(t => t.beam_id === beam_id);

    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 3, width: "100%" }}>
        <FormControl fullWidth>
          <InputLabel id="orbital-label">Orbital Position*</InputLabel>
          <Select
            value={orbital_position_id}
            inputProps={{ readOnly: isLastStep }}
            labelId="orbital-label"
            label="Orbital Position*"
            onChange={
              e => {
                this.changeInput("orbital_position_id", e);
              }
            }
          >
            <MenuItem key="none" value="">
              Select an orbital position
            </MenuItem>
            {orbitalPositions.map(position =>
              <MenuItem key={position.id} value={position.id}>
                {position.name}
              </MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="beam-label">Beam*</InputLabel>
          <Select
            value={beam_id}
            inputProps={{ readOnly: isLastStep }}
            disabled={!orbital_position_id}
            labelId="beam-label"
            label="Beam*"
            onChange={
              e => {
                this.changeInput("beam_id", e);
              }
            }
          >
            <MenuItem key="none" value="">
              Select a beam
            </MenuItem>
            {filteredBeams.map(beam =>
              <MenuItem key={beam.id} value={beam.id}>
                {beam.name}
              </MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="transponder-label">Transponder*</InputLabel>
          <Select
            value={transponder_id}
            inputProps={{ readOnly: isLastStep }}
            disabled={!beam_id}
            labelId="transponder-label"
            label="Transponder*"
            onChange={
              e => {
                this.changeInput("transponder_id", e);
              }
            }
          >
            <MenuItem key="none" value="">
              Select a transponder
            </MenuItem>
            {filteredTransponders.map(transponder =>
              <MenuItem key={transponder.id} value={transponder.id}>
                {transponder.name}
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </Box>
    );
  }

  step2() {
    const { tech_channel_type, name } = this.state;
    const { isLastStep } = this.props;

    return (
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="type-label">Technical Channel type*</InputLabel>
          <Select
            value={tech_channel_type}
            inputProps={{ readOnly: isLastStep }}
            labelId="type-label"
            label="Technical Channel type*"
            onChange={
              e => {
                this.changeInput("tech_channel_type", e);
              }
            }
          >
            <MenuItem key="none" value="">
              Select a technical channel type
            </MenuItem>
            {techChannelTypes.map(type =>
              <MenuItem key={type} value={type}>
                {type.toUpperCase()}
              </MenuItem>
            )}
          </Select>
        </FormControl>
        <TextField
          value={name}
          error={!this.isValid("name")}
          inputProps={{ readOnly: isLastStep }}
          label="Name*"
          onChange={
            e => {
              this.changeInput("name", e);
            }
          }
        />
      </Box>
    );
  }

  step3() {
    const { tech_channel_type, onid, tsid, sid, ott_backend_id, backend_channel } = this.state;
    const { ottBackends, isLastStep } = this.props;

    return (
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2 }}>
        {!isLastStep && <Alert sx={{ my: 2 }} >TSID and ONID are prefill with transponder information</Alert>}
        {tech_channel_type === "dvb"
          && <>
            <TextField
              value={onid}
              disabled={!isLastStep}
              inputProps={{ readOnly: isLastStep }}
              label="ONID*"
              onChange={
                e => {
                  this.changeInput("onid", e, "int");
                }
              }
            />
            <TextField
              value={tsid}
              disabled={!isLastStep}
              inputProps={{ readOnly: isLastStep }}
              label="TSID*"
              onChange={
                e => {
                  this.changeInput("tsid", e, "int");
                }
              }
            />
            <TextField
              value={sid}
              error={!this.isValid("sid")}
              inputProps={{ readOnly: isLastStep }}
              label="SID*"
              onChange={
                e => {
                  this.changeInput("sid", e, "int");
                }
              }
            />
          </>
        }
        {tech_channel_type === "ott"
          && <>
            <FormControl fullWidth>
              <InputLabel id="ott-backend-label">OTT Backend*</InputLabel>
              <Select
                value={ott_backend_id}
                inputProps={{ readOnly: isLastStep }}
                labelId="ott-backend-label"
                label="OTT Backend*"
                onChange={
                  e => {
                    this.changeInput("ott_backend_id", e);
                  }
                }
              >
                <MenuItem key="none" value="">
                  Select an OTT Backend
                </MenuItem>
                {ottBackends.map(ottBackend =>
                  <MenuItem key={ottBackend.id} value={ottBackend.id}>
                    {ottBackendToString(ottBackend)}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            <TextField
              value={backend_channel}
              error={!this.isValid("backend_channel")}
              inputProps={{ readOnly: isLastStep }}
              label="Backend Channel Id*"
              onChange={
                e => {
                  this.changeInput("backend_channel", e);
                }
              }
            />
          </>
        }
      </Box>
    );
  }

  modalContent() {
    const { currentStep, isLastStep, stepperComponent } = this.props;

    return (
      <DialogContent sx={{ display: "flex", gap: 3, flexDirection: "column", marginTop: 1, width: "80%", alignItems: "center", alignSelf: "center" }}>
        {stepperComponent}
        {(currentStep === 0 || isLastStep) && this.step1() }
        <Box sx={{ width: "100%", display: "flex", gap: 3 }}>
          {(currentStep === 1 || isLastStep) && this.step2() }
          {(currentStep === 2 || isLastStep) && this.step3() }
        </Box>
      </DialogContent>
    );
  }

  modalActions() {
    const { currentStep, isLastStep, previousStep, nextStep } = this.props;
    const isNextPossible = this.isNextPossible();

    return (
      [
        <Button variant="outlined"
          key="cancel"
          onClick={() => {
            this.closeModal();
          }} >
            Cancel
        </Button>,
        currentStep > 0 && <Button variant="outlined"
          key="previous"
          onClick={() => {
            previousStep();
          }}>
            Previous
        </Button>,
        !isLastStep && <Button variant="contained"
          key="next"
          disabled={!isNextPossible}
          color="secondary"
          onClick={() => {
            nextStep();
          }}>
            Next
        </Button>,
        isLastStep && <Button variant="contained"
          key="save"
          color="secondary"
          onClick={() => {
            this.addTechChannel();
          }}>
            Save
        </Button>
      ]
    );
  }

  render() {
    const { modalOpened } = this.props;

    return (
      <Modal
        isOpen={modalOpened}
        closeModal={() => this.closeModal()}
        title="Add technical channel"
        customModalContent={() => this.modalContent()}
        actions={() => this.modalActions()}
        actionAlign="flex-end"
      >
      </Modal>
    );
  }
}

AddTechChannelModal.propTypes = {
  modalOpened: PropTypes.bool.isRequired,
  closeAddChannelModal: PropTypes.func.isRequired,

  orbitalPositions: PropTypes.array.isRequired,
  beams: PropTypes.array.isRequired,
  transponders: PropTypes.array.isRequired,

  currentStep: PropTypes.number,
  previousStep: PropTypes.func,
  nextStep: PropTypes.func,
  stepperComponent: PropTypes.object,
  isLastStep: PropTypes.bool,

  ottBackends: PropTypes.array.isRequired,

  createTechChannel: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    orbitalPositions: listOrbitalPositionsSelector(state),
    beams: listBeamsSelector(state),
    transponders: listTranspondersSelector(state),
    ottBackends: listOttBackendsSelector(state)
  };
}

const mapDispatchToProps = {
  createTechChannel: createTechChannelAction,
  addToast: addToastAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withStepper(
  AddTechChannelModal, [
    "Orbital Position information",
    "Set Technical Channel information",
    "Set Technical information",
    "Sumup"
  ]));
