const getObjectNameFromLocales = object => {
  if (!(object instanceof Object) || !(object.locales instanceof Object)) {
    return "";
  }

  if (object.locales.en_GB instanceof Object && object.locales.en_GB.name) {
    return object.locales.en_GB.name;
  }

  if (object.default_language && object.locales[object.default_language] instanceof Object && object.locales[object.default_language].name) {
    return object.locales[object.default_language].name;
  }

  const languages = Object.keys(object.locales);

  for (let i = 0; i < languages.length; i += 1) {
    const language = languages[i];

    if (object.locales[language] instanceof Object && object.locales[language].name) {
      return object.locales[language].name;
    }
  }

  return "";
};

const convertISOToDate = dateISO => {
  const date = new Date(dateISO);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${day}/${month}/${year} at ${hours}:${minutes}`;
};

const times = time => {
  const array = [];
  let i = 0;

  while (i < time) {
    array.push(i);
    i += 1;
  }

  return array;
};

const toJsonString = str => {
  try {
    return JSON.stringify(JSON.parse(str), null, 2);
  } catch (err) {
    return str;
  }
};

const tagsArrayToRequestParams = tagsArray => tagsArray.reduce((acc, tag) => {
  const tagValues = (acc[tag.filterName] || "").split(",").filter(e => e);

  tagValues.push(tag.value);

  acc[tag.filterName] = tagValues.join(",");

  return acc;
}, {});

const sortObjectByLocale = (a, b, key = null) => {
  if (key) {
    if (!a[key] || !a[key].locales || !b[key] || !b[key].locales) {
      if (a.id > b.id) {
        return 1;
      }

      if (a.id < b.id) {
        return -1;
      }

      return 0;
    }

    if (Object.keys(a[key].locales).length === 0 || Object.keys(b[key].locales).length === 0) {
      return 0;
    }
  } else {
    if (!a.locales || !b.locales) {
      if (a.id > b.id) {
        return 1;
      }

      if (a.id < b.id) {
        return -1;
      }

      return 0;
    }

    if (Object.keys(a.locales).length === 0 || Object.keys(b.locales).length === 0) {
      return 0;
    }
  }

  // Card 209 = remove space and dash form sort function
  const aObject = key ? a[key] : a;
  const bObject = key ? b[key] : b;
  const aName = getObjectNameFromLocales(aObject).toLowerCase().replace(/[ -]/gi, "");
  const bName = getObjectNameFromLocales(bObject).toLowerCase().replace(/[ -]/gi, "");

  if (aName > bName) {
    return 1;
  }

  if (aName < bName) {
    return -1;
  }

  return 0;
};

const capitalize = s => {
  if (typeof s !== "string") {
    return "";
  }

  return s.charAt(0).toUpperCase() + s.slice(1);
};

const isValidJson = json => {
  try {
    JSON.parse(json);

    return true;
  } catch {
    return false;
  }
};

const isValidLocaleJson = localeJsonString => {
  try {
    const localeJson = JSON.parse(localeJsonString);

    if (Object.keys(localeJson).filter(k => k.length === 5).length === 0) {
      return false;
    }

    return true;
  } catch {
    return false;
  }
};

const stringifyQualificationType = type => {
  const strType = {
    techchannel: "technical channel"
  };

  const typeKey = type?.toLowerCase() ?? "";

  // eslint-disable-next-line no-prototype-builtins
  if (strType.hasOwnProperty(typeKey)) {
    return strType[typeKey];
  }

  return type;
};

const truncate = (str, max = 15, withEllipsis = true) => {
  if (str.length > max) {
    if (withEllipsis) {
      return `${str.slice(0, max - 3)} ...`;
    }
    return str.slice(0, max);
  }
  return str;
};

const qualificationServiceName = (transponder, qualification) => (transponder && qualification && `ONID ${transponder.theoretical_onid} - TSID ${transponder.theoretical_tsid} - SID ${qualification.sid}`) || "unidentified technical channel";

const groupBy = (input, key) => input.reduce((acc, currentValue) => {
  const groupKey = currentValue[key];
  if (!acc[groupKey]) {
    acc[groupKey] = [];
  }
  acc[groupKey].push(currentValue);
  return acc;
}, {});

const exportAsFile = (data, fileName) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");

  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export {
  times,
  toJsonString,
  tagsArrayToRequestParams,
  sortObjectByLocale,
  capitalize,
  isValidJson,
  isValidLocaleJson,
  getObjectNameFromLocales,
  stringifyQualificationType,
  qualificationServiceName,
  groupBy,
  convertISOToDate,
  exportAsFile,
  truncate,
};
