const getImageAttributes = async image => new Promise(resolve => {
  const fileReader = new FileReader();

  fileReader.onload = e => {
    // Get image attribute
    const newImg = new Image();

    newImg.src = e.target.result;

    newImg.onload = function onload() {
      const fileType = image
        .name
        .split(".")
        .pop()
        .split("?")
        .shift()
        .toLowerCase();

      const attributes = {
        fileType,
        width: this.width,
        height: this.height
      };

      resolve(attributes);
    };
  };

  fileReader.readAsDataURL(image);
});

export {
  getImageAttributes
};
