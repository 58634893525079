import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Menu, MenuItem, Stack } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { addToast as addToastAction } from "../../actions/toasts";
import {
  indexServiceGenres as indexServiceGenresAction,
  deleteServiceGenre as deleteServiceGenreAction
} from "../../actions/service_genres";
import {
  listServiceGenres as listServiceGenresSelector,
  listServiceGenresFilters as listServiceGenresFiltersSelector,
} from "../../selectors/service_genres";

import EditServiceGenreModal from "../ServiceGenresModal/EditServiceGenreModal";
import DeleteObjectsModal from "../GlobalObjectsModal/deleteObjectsModal";

import { getObjectNameFromLocales } from "../../helpers/utils";
import DataTable from "../DataTable";

class ServiceGenres extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentServiceGenre: null,

      selectedServiceGenresIds: [],

      modalOpened: false,
      menuAnchorEl: null,
      openedMenu: null,
      deleteModalOpened: false
    };

    this.deleteServiceGenre = this.deleteServiceGenre.bind(this);
  }

  setSelectedServiceGenresIds = newSelection => {
    this.setState({
      selectedServiceGenresIds: newSelection,
    });
  };

  /* Menus methods */

  setMenuAnchorEl = target => {
    this.setState({
      menuAnchorEl: target
    });
  };

  setOpenedMenu = menuId => {
    this.setState({
      openedMenu: menuId
    });
  };

  openMenu = (event, menuId) => {
    this.setMenuAnchorEl(event.currentTarget);
    this.setOpenedMenu(menuId);
  };
  closeMenu = menuId => {
    this.setMenuAnchorEl(null);
    this.setOpenedMenu(menuId);
  };

  /* Edit Modal */

  openEditModal = currentServiceGenre => {
    this.setState({
      currentServiceGenre,
      modalOpened: true
    });
  };

  closeEditModal = () => {
    this.setState({
      currentServiceGenre: null,
      modalOpened: false
    });
  };

  closeDeleteModal = () => {
    this.setState({
      deleteModalOpened: false
    });
  };

  /* Add Modal */

  async deleteServiceGenre(selectedServiceGenres) {
    const { deleteServiceGenre, addToast } = this.props;

    if (selectedServiceGenres.length > 0) {
      let deleted = 0;

      for (let i = 0; i < selectedServiceGenres.length; i += 1) {
        const selectedSg = selectedServiceGenres[i];
        const ret = await deleteServiceGenre(selectedSg);

        if (ret.isSuccess) {
          deleted += 1;
        }
      }

      if (deleted === selectedServiceGenres.length) {
        addToast("success", "Success", "Channel genre(s) successfully deleted!");
      } else if (deleted === 0) {
        addToast("error", "Error", "Channel genre(s) cannot be deleted. Try again.");
      } else {
        addToast("warning", "Warning", "Some service genre(s) cannot be deleted. Try again.");
      }

      this.setState({
        selectedServiceGenresIds: [],
        deleteModalOpened: false
      });
    }
  }

  /* Modal */

  render() {
    const {
      currentServiceGenre,
      modalOpened,
      selectedServiceGenresIds,
      menuAnchorEl,
      openedMenu,
      deleteModalOpened
    } = this.state;

    const {
      serviceGenres
    } = this.props;

    const serviceGenresFilter = serviceGenres.filter(serviceGenre => serviceGenre.genre_type === null);

    const menuOpen = Boolean(menuAnchorEl);

    const genreName = params => getObjectNameFromLocales(params.row);

    return (
      <Stack spacing={2}>
        <div>
          <Button
            id="actions-button"
            sx={{
              borderColor: "primary.smokeBorder",
            }}
            variant="outlined"
            aria-controls={menuOpen && openedMenu === "actions-menu" ? "actions-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen && openedMenu === "actions-menu" ? "true" : undefined}
            disableElevation
            onClick={e => this.openMenu(e, "actions-menu")}
            endIcon={<KeyboardArrowDownIcon />}
            disabled={ selectedServiceGenresIds.length <= 0 }
          >
            Actions
          </Button>
          <Menu
            MenuListProps={{
              "aria-labelledby": "actions-button",
            }}
            id="actions-menu"
            anchorEl={menuAnchorEl}
            open={menuOpen && openedMenu === "actions-menu"}
            onClose={() => this.closeMenu()}
          >
            <MenuItem
              sx={{
                color: "red"
              }}
              onClick={() => {
                this.closeMenu();
                this.setState({
                  deleteModalOpened: true
                });
              }}
              disableRipple
            >
              Delete selected channel genres
            </MenuItem>
          </Menu>
        </div>

        <div style={{ height: "calc(100vh - 270px)", minHeight: "160px" }}>
          <DataTable
            cols={[
              { headerName: "Id", field: "id", flex: 1 },
              { headerName: "Genre", field: "locales", valueGetter: genreName, flex: 1 },
              { headerName: "Modified", field: "updated_at", flex: 1 }
            ]}
            data={serviceGenresFilter}
            onRowClick={id => this.openEditModal(id)}
            onRowSelectionModelChange={this.setSelectedServiceGenresIds}
            checkboxSelection
          />
        </div>

        {/* MODAL */}

        <EditServiceGenreModal
          currentId={currentServiceGenre?.id}
          modalOpened={modalOpened}
          closeEditModal={this.closeEditModal}
        />

        <DeleteObjectsModal
          selectedIds={selectedServiceGenresIds}
          modalOpened={deleteModalOpened}
          deleteObjects={this.deleteServiceGenre}
          closeDeleteModal={this.closeDeleteModal}
          name="Channel genre(s)"
        />
      </Stack>
    );
  }
}

ServiceGenres.propTypes = {
  serviceGenres: PropTypes.arrayOf(PropTypes.object).isRequired,

  deleteServiceGenre: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    serviceGenres: listServiceGenresSelector(state),
    serviceGenresFilters: listServiceGenresFiltersSelector(state),
  };
}

const mapDispatchToProps = {
  indexServiceGenres: indexServiceGenresAction,
  deleteServiceGenre: deleteServiceGenreAction,
  addToast: addToastAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceGenres);
