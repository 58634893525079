import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import "./Tabs.css";

class Tabs extends PureComponent {
  render() {
    const {
      currentTab,
      tabs,
      changeTab,
      tabsActions,
      smallTabs
    } = this.props;

    return (
      <div className="tabs">
        <div className={`tabs-navbar ${smallTabs ? "tabs-navbar-small" : ""}`}>
          {!!tabs.length && tabs.map((t, idx) =>
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div key={idx} className={`tab-nav ${t.slug === currentTab ? t.currentClassName : ""}`} onClick={() => changeTab(t.slug)}>{t.name}</div>
          )}
        </div>
        {!!tabsActions.length
          && <div className="tabs-optional-actions">
            {tabsActions.map((ta, idx) => <button key={idx} type="button" className={`btn ${ta.className ? ta.className : ""}`} onClick={ta.action}>{ta.name}</button>
            )}
          </div>
        }
      </div>
    );
  }
}

Tabs.defaultProps = {
  tabs: [],
  tabsActions: [],
  smallTabs: false
};

Tabs.propTypes = {
  currentTab: PropTypes.string.isRequired,
  changeTab: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.object),
  tabsActions: PropTypes.arrayOf(PropTypes.object),
  smallTabs: PropTypes.bool
};

export default Tabs;
