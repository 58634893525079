/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Box, FormControl, InputLabel, Select, MenuItem, DialogContent, Button, Alert, TextField } from "@mui/material";
import Modal from "../Modal/Modal";
import withStepper from "../Modal/withStepper";

import { addToast as addToastAction } from "../../actions/toasts";

import {
  createBeam as createBeamAction,
  indexBeamsFilters as indexBeamsFiltersAction
} from "../../actions/beams";

import {
  listOrbitalPositions as listOrbitalPositionsSelector
} from "../../selectors/orbital_positions";
import {
  listBeams as listBeamsSelector,
  listBeamsFilters as listBeamsFiltersSelector
} from "../../selectors/beams";

import { capitalize } from "../../helpers/utils";

const initialState = {
  name: "",
  orbital_position_id: "",
  westEastFlag: "",

  globalEditing: false,

};

class AddBeamModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { ...initialState };
  }

  changeInput(inputName, event) {
    const { value } = event.target;

    this.setState(() => ({ [inputName]: value }));
  }

  async addBeam() {
    const { name, orbital_position_id, westEastFlag } = this.state;
    const { createBeam, addToast } = this.props;

    this.setState({ globalEditing: true });

    const ret = await createBeam({ orbital_position_id, name: name.trim(), west_east_flag: westEastFlag });

    this.setState({ globalEditing: false }, () => {
      if (ret.isSuccess) {
        addToast("success", "Success", "Beam successfully added!");
        this.closeModal();
      } else {
        addToast("error", "Error", "Error while adding Beam. Try again.");
      }
    });
  }

  closeModal() {
    const { closeAddModal } = this.props;
    const { globalEditing } = this.state;

    if (globalEditing) {
      return;
    }

    this.setState(initialState);
    closeAddModal();
  }

  isValid(inputName) {
    if (inputName instanceof Array) {
      return inputName.every(i => this.isValid(i));
    }
    return !!this.state[inputName];
  }

  isNameExist() {
    const { name, orbital_position_id } = this.state;
    const { beams } = this.props;

    return this.isValid("name") && beams.some(op => op.orbital_position_id === parseInt(orbital_position_id) && op.name.toLowerCase() === name.toLowerCase().trim());
  }

  modalContent() {
    const { orbital_position_id, westEastFlag, name } = this.state;
    const { orbitalPositions, beamsFilters, isLastStep, stepperComponent } = this.props;

    const isNameValid = this.isValid("name") && !this.isNameExist();
    const isNameExist = this.isNameExist();
    const isOrbitalIdValid = this.isValid("orbital_position_id");
    const isWestEastValid = this.isValid("westEastFlag");

    return (
      <DialogContent sx={{ display: "flex", gap: 3, flexDirection: "column", marginTop: 1, width: "80%", alignItems: "center", alignSelf: "center" }}>
        {stepperComponent}
        <FormControl fullWidth>
          <InputLabel id="orbital-label">
            Orbital position
          </InputLabel>
          <Select
            value={orbital_position_id}
            labelId="orbital-label"
            inputProps={{ readOnly: isLastStep }}
            label="Orbital position"
            onChange={
              e => {
                this.changeInput("orbital_position_id", e);
              }
            }
          >
            {orbitalPositions.map(op =>
              <MenuItem key={op.id} value={op.id}>
                {op.name}
              </MenuItem>
            )}
          </Select>
        </FormControl>
        {isOrbitalIdValid
            && <Box sx={{ width: "80%", display: "flex", flexDirection: "column", gap: 3 }}>
              {isNameExist && <Alert severity="error">
                An beam already exists with this name
              </Alert>}
              <TextField label="Name"
                error={!isNameValid}
                inputProps={{ readOnly: isLastStep }}
                value={name}
                onChange={e => {
                  this.changeInput("name", e);
                }}/>
              <FormControl error={!isWestEastValid} fullWidth>
                <InputLabel id="west-east-label">
                  West / East
                </InputLabel>
                <Select
                  inputProps={{ readOnly: isLastStep }}
                  value={westEastFlag}
                  error={!isWestEastValid}
                  labelId="west-east-label"
                  label="West / East"
                  onChange={
                    e => {
                      this.changeInput("westEastFlag", e);
                    }
                  }
                >
                  <MenuItem value="">
                    Select West or East
                  </MenuItem>
                  {(beamsFilters?.find(t => t.name === "west_east_flags")?.values || []).map(v =>
                    <MenuItem key={v} value={v}>{capitalize(v)}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
        }
      </DialogContent>
    );
  }

  modalActions() {
    const { currentStep, previousStep, nextStep, isLastStep } = this.props;
    const isValid = this.isValid(["name", "orbital_position_id", "westEastFlag"]) && !this.isNameExist();

    return (
      [
        <Button variant="outlined"
          key="cancel"
          onClick={() => {
            this.closeModal();
          }} >
            Cancel
        </Button>,
        currentStep > 0 && <Button variant="outlined"
          key="previous"
          onClick={() => {
            previousStep();
          }}>
            Previous
        </Button>,
        !isLastStep && <Button variant="contained"
          key="next"
          disabled={!isValid}
          color="secondary"
          onClick={() => {
            nextStep();
          }}>
            Next
        </Button>,
        isLastStep && <Button variant="contained"
          key="save"
          color="secondary"
          onClick={() => {
            this.addBeam();
          }}>
            Save
        </Button>
      ]
    );
  }

  render() {
    const {
      modalOpened,
    } = this.props;

    return (
      <Modal
        isOpen={modalOpened}
        closeModal={() => this.closeModal()}
        title="Add beam"
        customModalContent={() => this.modalContent()}
        actions={() => this.modalActions()}
        actionAlign="flex-end"
      >
      </Modal>
    );
  }
}

AddBeamModal.propTypes = {
  modalOpened: PropTypes.bool.isRequired,
  closeAddModal: PropTypes.func.isRequired,

  orbitalPositions: PropTypes.arrayOf(PropTypes.object).isRequired,
  beams: PropTypes.arrayOf(PropTypes.object).isRequired,
  beamsFilters: PropTypes.arrayOf(PropTypes.object).isRequired,

  currentStep: PropTypes.number,
  previousStep: PropTypes.func,
  nextStep: PropTypes.func,
  stepperComponent: PropTypes.object,
  isLastStep: PropTypes.bool,

  createBeam: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    orbitalPositions: listOrbitalPositionsSelector(state),
    beams: listBeamsSelector(state),
    beamsFilters: listBeamsFiltersSelector(state)
  };
}

const mapDispatchToProps = {
  createBeam: createBeamAction,
  indexBeamsFilters: indexBeamsFiltersAction,
  addToast: addToastAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withStepper(
  AddBeamModal, [
    "Set beam information",
    "Sumup"
  ]));
