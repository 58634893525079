/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { Box, Checkbox, ListItemText, InputAdornment, List, ListItem, ListItemButton, ListItemIcon, DialogContent, Typography, Button, TextField } from "@mui/material";
import { isEqual } from "lodash";
import Modal from "../Modal/Modal";
import { addToast as addToastAction } from "../../actions/toasts";

import { updateContentGenre as updateContentGenreAction } from "../../actions/content_genres";
import { updateProvidersWording as updateProvidersWordingAction } from "../../actions/providers_wordings";

import { showContentGenre as showContentGenreSelector } from "../../selectors/content_genres";
import { listProviders as listProvidersSelector } from "../../selectors/providers";
import { listProvidersWordings as listProvidersWordingsSelector } from "../../selectors/providers_wordings";
import { isLoading as isLoadingSelector } from "../../selectors/loaders";
import withStepper from "../Modal/withStepper";

import { getObjectNameFromLocales
} from "../../helpers/utils";
import "./EditContentGenresModal.css";

const localesToObject = locales => {
  const langs = Object.keys(locales);

  const object = langs.map((lang, i) => ({
    id: i + 1,
    lang,
    name: locales[lang].name,
    errLang: false,
    errName: false,
    wordingToUpdate: [],
  }));

  return object;
};

const objectToLocale = object => {
  const locales = {};

  object.forEach(o => {
    locales[o.lang] = { name: o.name };
  });

  return locales;
};

const initialState = {
  locales: [],
  search: "",
};

class EditContentGenresModal extends PureComponent {
  constructor(props) {
    super(props);

    const state = { ...initialState };

    if (props.genre) {
      const {
        locales
      } = props.genre;

      state.locales = localesToObject(locales);
    }

    state.providersWordings = props.providersWordings;

    this.state = { ...state };
  }

  componentDidUpdate(prevProps) {
    const { genre, providersWordings } = this.props;

    if (genre && !isEqual(prevProps.genre, genre)) {
      const { locales } = genre;

      this.setState({ locales: localesToObject(locales) });
    }

    if (providersWordings && !isEqual(prevProps.providersWordings, providersWordings)) {
      this.setState({ providersWordings });
    }
  }

  changeInput(inputName, event) {
    const { value } = event.target;

    this.setState(() => ({ [inputName]: value }));
  }

  isValidLang(lang) {
    if (!lang) {
      return false;
    } else if (lang.length !== 5) {
      return false;
    } else if (!/([a-z]{2}[_][A-Z]{2})/g.test(lang)) {
      return false;
    }
    return true;
  }

  isValidName(name) {
    if (!name) {
      return false;
    } else if (name.length === 0) {
      return false;
    }
    return true;
  }

  addLanguage() {
    const { locales } = this.state;

    const maxId = locales.reduce((acc, cur) => {
      if (cur.id > acc) {
        return cur.id;
      }

      return acc;
    }, 0);

    this.setState(() => ({
      locales: [
        ...locales,
        { id: maxId + 1, lang: "", name: "", errLang: true, errName: true }
      ]
    }));
  }

  removeLanguage(localeId) {
    const { locales } = this.state;
    const newLocales = locales.filter(l => l.id !== localeId);

    this.setState(() => ({ locales: newLocales }));
  }

  changeLanguage(type, id, value) {
    const { locales } = this.state;

    if (type === "lang") {
      const updatedLocales = locales.map(locale => {
        if (locale.id === id) {
          return { id: locale.id, lang: value, errLang: !this.isValidLang(value), name: locale.name, errName: locale.errName };
        }
        return locale;
      });
      this.setState(() => ({ locales: updatedLocales }));
    } else if (type === "name") {
      const updatedLocales = locales.map(locale => {
        if (locale.id === id) {
          return { id: locale.id, lang: locale.lang, errLang: locale.errLangname, name: value, errName: !this.isValidName(value) };
        }
        return locale;
      });
      this.setState(() => ({ locales: updatedLocales }));
    }
  }

  couldSave() {
    const { locales } = this.state;

    return locales?.length > 0 && locales.every(locale => !locale.errLang && !locale.errName);
  }

  async editGenre() {
    const { locales: object } = this.state;
    const { genre, updateContentGenre, addToast } = this.props;
    const locales = objectToLocale(object);

    const ret = await updateContentGenre(genre.id, { locales });

    if (ret.isSuccess) {
      addToast("success", "Success", "Genre successfully Edited!");
      this.closeModal();
    } else {
      addToast("error", "Error", "Error while editing Genre. Try again.");
    }

    this.closeModal();
  }

  async toggleWording(wordingId) {
    const {
      genre,
      updateProvidersWording
    } = this.props;

    const {
      providersWordings
    } = this.state;

    const newProvidersWordings = [...providersWordings];
    const providerWording = newProvidersWordings.find(pw => pw.id === wordingId);

    if (providerWording) {
      let genreId = null;

      if (providerWording.genre_id === null) {
        genreId = genre.id;
      }

      await updateProvidersWording(providerWording.id, { genre_id: genreId });

      providerWording.genre_id = genreId;

      this.setState({ providersWordings: newProvidersWordings });
    }
  }

  closeModal() {
    const { closeEditModal } = this.props;

    this.setState(initialState);
    closeEditModal();
  }

  sortWordings(a, b) {
    const { genre } = this.props;

    if (a.genre_id === genre.id && b.genre_id === null) {
      return -1;
    }

    if (b.genre_id === genre.id && a.genre_id === null) {
      return 1;
    }

    if (a.word < b.word) {
      return -1;
    }

    if (a.word > b.word) {
      return 1;
    }

    return 0;
  }

  search(search) {
    this.setState({ search });
  }

  clearSearch() {
    this.setState({ search: "" });
  }

  couldNext() {
    const { locales } = this.state;

    return locales?.length > 0 && locales.every(locale => !locale.errLang && !locale.errName);
  }

  modalContent() {
    const { providers, providersWordings, genre, isLastStep, stepperComponent } = this.props;
    const { locales, search } = this.state;

    return (
      <DialogContent sx={{ display: "flex", gap: 3, flexDirection: "column", marginTop: 1, width: !isLastStep ? "80%" : "100%", height: isLastStep && "100vh", alignItems: "center", alignSelf: "center" }}>
        {stepperComponent}
        { !isLastStep
        && <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", gap: 2 }}>
          <Box className="left" sx={{ display: "flex", flexDirection: "column", gap: 3, alignItems: "center", width: "100%" }}>
            <Typography>Lang code</Typography>
            {locales.map(locale =>
              <Box key={locale.id} className="langCodeFields" sx={{ display: "flex", alignItems: "center", gap: 2, width: "100%" }}>
                <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTrashAlt} onClick={() => this.removeLanguage(locale.id)}/>
                <TextField placeholder="en_GB" error={locale.errLang} value={locale.lang} sx={{ width: "100%" }} onChange={e => this.changeLanguage("lang", locale.id, e.target.value)}/>
                :
              </Box>
            )}
            <Button variant="outlined" sx={{ width: "100%" }} onClick={() => this.addLanguage()}>Add a language</Button>
          </Box>
          <Box className="right" sx={{ display: "flex", flexDirection: "column", gap: 3, alignItems: "center", width: "100%" }}>
            <Typography>Translation</Typography>
            {locales.map(locale =>
              <Box key={locale.id} className="langCodeFields" sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 3, width: "100%" }}>
                <TextField placeholder="translation..." error={locale.errName} value={locale.name} sx={{ width: "100%" }} onChange={e => this.changeLanguage("name", locale.id, e.target.value)}/>
              </Box>
            )}
          </Box>
        </Box>
        }
        { isLastStep
          && <Box sx={{ display: "flex", flexDirection: "column", gap: 5, width: "100%" }}>
            <Box sx={{ alignSelf: "center" }}>
              <TextField label="Search word"
                value={search}
                onChange={e => this.search(e.target.value)}
                InputProps={{
                  endAdornment:
            <InputAdornment position="end">
              <Button
                onClick={e => {
                  this.search(e.target.value);
                }}
              >
                Clear
              </Button>
            </InputAdornment>
                }} />
            </Box>
            <Box sx={{ display: "flex" }}>
              {providers.map(provider =>
                <Box key={provider.id} sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 2 }}>
                  <Typography variant="h5" component="span" key={provider.id}>{provider.name}</Typography>
                  <List sx={{ padding: 0, margin: 0 }}>
                    {providersWordings
                      .filter(pw => pw.provider_id === provider.id && (pw.genre_id === null || pw.genre_id === genre.id))
                      .filter(pw => search.trim().length === 0 || pw.word.includes(search.trim()))
                      .sort((a, b) => this.sortWordings(a, b)).map(pw =>
                        <ListItem
                          key={pw.id.toString()}
                          disablePadding
                          sx={{ padding: 0, margin: 0 }}
                        >
                          <ListItemButton sx={{ padding: 0, margin: 0 }} role={undefined} onClick={() => this.toggleWording(pw.id)}>
                            <ListItemIcon sx={{ minWidth: 0 }}>
                              <Checkbox
                                edge="start"
                                checked={pw.genre_id === genre.id}
                                tabIndex={-1}
                                disableRipple
                                sx={{ padding: 1 }}
                                inputProps={{ "aria-labelledby": pw.id }}
                              />
                            </ListItemIcon>
                            <ListItemText id={pw.id} primary={pw.word} />
                          </ListItemButton>
                        </ListItem>
                      )}
                  </List>
                </Box>
              )}
            </Box>
          </Box>
        }
      </DialogContent>
    );
  }

  modalActions() {
    const { currentStep, isLastStep, previousStep, nextStep } = this.props;

    const couldNext = this.couldNext();

    return (
      [
        <Button variant="outlined"
          key="cancel"
          onClick={() => {
            this.closeModal();
          }}>
            Cancel
        </Button>,
        currentStep > 0 && <Button variant="outlined"
          key="previous"
          onClick={() => {
            previousStep();
          }}>
          Previous
        </Button>,
        !isLastStep && <Button variant="contained"
          key="next"
          disabled={!couldNext}
          color="secondary"
          onClick={() => {
            nextStep();
          }}>
          Next
        </Button>,
        isLastStep && <Button variant="contained"
          key="save"
          color="secondary"
          onClick={() => {
            this.editGenre();
          }}>
            Save
        </Button>
      ]
    );
  }

  render() {
    const {
      modalOpened,
      genre,
      isLastStep
    } = this.props;

    const currentserviceGenreName = getObjectNameFromLocales(genre);
    return (
      <Modal
        isOpen={modalOpened}
        title={"Edit content genre " + currentserviceGenreName}
        closeModal={() => this.closeModal()}
        actionAlign="flex-end"
        customModalContent={() => this.modalContent()}
        actions={() => this.modalActions()}
        maxWidth={isLastStep ? "xl" : "md"}
      >
      </Modal>
    );
  }
}

EditContentGenresModal.defaultProps = {
  genreId: null
};

EditContentGenresModal.propTypes = {
  // Used in mapStateToProps
  // eslint-disable-next-line react/no-unused-prop-types
  genreId: PropTypes.number,

  genre: PropTypes.object.isRequired,
  providers: PropTypes.arrayOf(PropTypes.object).isRequired,
  providersWordings: PropTypes.arrayOf(PropTypes.object).isRequired,

  isContentGenreEditing: PropTypes.bool.isRequired,
  isWordEditing: PropTypes.bool.isRequired,

  currentStep: PropTypes.number,
  previousStep: PropTypes.func,
  nextStep: PropTypes.func,
  isLastStep: PropTypes.bool,
  stepperComponent: PropTypes.object,

  modalOpened: PropTypes.bool.isRequired,
  closeEditModal: PropTypes.func.isRequired,

  updateContentGenre: PropTypes.func.isRequired,
  updateProvidersWording: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    genre: showContentGenreSelector(state, ownProps.genreId) || { id: null, locales: {} },
    providers: listProvidersSelector(state),
    providersWordings: listProvidersWordingsSelector(state),
    isContentGenreEditing: isLoadingSelector(state, updateContentGenreAction.toString()),
    isWordEditing: isLoadingSelector(state, updateProvidersWordingAction.toString())
  };
}

const mapDispatchToProps = {
  updateContentGenre: updateContentGenreAction,
  updateProvidersWording: updateProvidersWordingAction,
  addToast: addToastAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withStepper(
  EditContentGenresModal, [
    "Translations",
    "Provider Wording"
  ]));
