export const listServiceTypes = state => state.serviceTypes.allServiceTypes;

export const listServiceTypesFromOrbitalPosition = state => {
  const serviceTypesIds = [...new Set(state.techChannels.allTechChannels.map(tc => tc.service_type_id))];

  return state.serviceTypes.allServiceTypes.filter(st => serviceTypesIds.includes(st.id));
};

export const showServiceType = (state, serviceTypeId) => state.serviceTypes.allServiceTypes.find(st => st.id === serviceTypeId);

export const getTotalCount = state => state.serviceTypes.totalCount;

export const listServiceTypesFilters = state => state.serviceTypes.filters;
