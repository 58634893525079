import { createAction } from "redux-actions";

/* Storage actions */
const storeServices = createAction("STORE_SERVICES");
const storeServicesFilters = createAction("STORE_SERVICES_FILTERS");
const storeSingleService = createAction("STORE_SINGLE_SERVICE");
const addService = createAction("ADD_SERVICE");
const modifyService = createAction("MODIFY_SERVICE");
const addTcToService = createAction("ADD_TC_TO_SERVICE");
const removeTcFromService = createAction("REMOVE_TC_FROM_SERVICE");
export const removeManyServices = createAction("REMOVE_MANY_SERVICES");

/* API actions */
export const indexServices = createAction("API_INDEX_SERVICES", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/services",
  data,
  fullLoad,
  onSuccessAction: storeServices
}));

export const indexServicesFilters = createAction("API_INDEX_SERVICES_FILTERS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/services/filters",
  data,
  fullLoad,
  onSuccessAction: storeServicesFilters
}));

export const showService = createAction("API_SHOW_SERVICE", serviceId => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "GET",
  url: `/services/${serviceId}`,
  onSuccessAction: storeSingleService
}));

export const createService = createAction("API_CREATE_SERVICE", data => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "POST",
  url: "/services",
  data,
  onSuccessAction: addService
}));

export const updateService = createAction("API_UPDATE_SERVICE", (serviceId, data) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "PUT",
  url: `/services/${serviceId}`,
  data,
  onSuccessAction: modifyService
}));

export const deleteService = createAction("API_DELETE_SERVICE", serviceId => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "DELETE",
  url: `/services/${serviceId}`,
  onSuccessAction: createAction("REMOVE_SERVICE", () => ({ id: serviceId }))
}));

/* Services-TechChannels actions */

// eslint-disable-next-line camelcase
export const associateTechChannelsToService = createAction("API_ASSOCIATE_TC_TO_SERVICE", (serviceId, tech_channel_id) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "POST",
  url: `/services/${serviceId}/tech_channels`,
  data: { tech_channel_id },
  onSuccessAction: addTcToService({ serviceId, techChannelId: tech_channel_id })
}));

export const disassociateTechChannelsToService = createAction("API_DISASSOCIATE_TC_TO_SERVICE", (serviceId, techChannelId) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "DELETE",
  url: `/services/${serviceId}/tech_channels/${techChannelId}`,
  onSuccessAction: removeTcFromService({ serviceId, techChannelId })
}));
