/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Box, DialogContent, Button, Alert, TextField } from "@mui/material";
import Modal from "../Modal/Modal";
import withStepper from "../Modal/withStepper";

import { addToast as addToastAction } from "../../actions/toasts";

import {
  updateImportance as updateImportanceAction
} from "../../actions/importances";
import {
  showImportance,
  listImportances as listImportancesSelector
} from "../../selectors/importances";

import { isLoading as isLoadingSelector } from "../../selectors/loaders";

const initialState = {
  name: "",
};

class EditImportanceModal extends PureComponent {
  constructor(props) {
    super(props);

    let state = { ...initialState };

    if (props.importance) {
      const {
        name
      } = props.importance;

      state = {
        ...state,
        name
      };
    }

    this.state = { ...state };
  }

  componentDidUpdate(prevProps) {
    const { importance } = this.props;

    if (prevProps.importance !== importance && importance !== null) {
      const {
        name
      } = importance;

      this.setState({
        name: name || initialState.name
      });
    }
  }

  changeInput(inputName, event) {
    const { value } = event.target;

    this.setState(() => ({ [inputName]: value }), this.validateStep);
  }

  async editImportance() {
    const { currentId, updateImportance, addToast } = this.props;

    const {
      name
    } = this.state;

    const ret = await updateImportance(currentId, {
      name: name.trim()
    });

    if (ret.isSuccess) {
      addToast("success", "Success", "Importance successfully updated!");
      this.closeModal();
    } else {
      addToast("error", "Error", "Error while updating importance. Try again.");
    }
  }

  closeModal() {
    const { closeEditModal } = this.props;

    this.setState(initialState);
    closeEditModal();
  }

  isNameValid() {
    const { name } = this.state;

    return name?.length !== 0;
  }

  isNameExist() {
    const { name } = this.state;
    const { importances, importance } = this.props;

    if (this.isNameValid() && importances && importance) {
      return importances.find(i => i.id !== importance?.id && (i.name || "").toLowerCase().trim() === (name || "").toLowerCase().trim());
    }
    return false;
  }

  modalContent() {
    const { name } = this.state;
    const { stepperComponent, isLastStep } = this.props;

    const isNameValid = this.isNameValid() && !this.isNameExist();
    const isNameExist = this.isNameExist();

    return (
      <DialogContent sx={{ display: "flex", gap: 3, flexDirection: "column", marginTop: 1, width: "80%", alignItems: "center", alignSelf: "center" }}>
        {stepperComponent}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          {isNameExist && <Alert severity="error">
            Importance with this name already exists
          </Alert>}
          <TextField label="Name" inputProps={{ readOnly: isLastStep }} error={!isNameValid} value={name} onChange={e => this.changeInput("name", e)}/>
        </Box>
      </DialogContent>
    );
  }

  modalActions() {
    const { currentStep, previousStep, nextStep, isLastStep } = this.props;
    const isNameValid = this.isNameValid() && !this.isNameExist();

    return (
      [
        <Button variant="outlined"
          key="cancel"
          onClick={() => {
            this.closeModal();
          }} >
            Cancel
        </Button>,
        currentStep > 0 && <Button variant="outlined"
          key="previous"
          onClick={() => {
            previousStep();
          }}>
            Previous
        </Button>,
        !isLastStep && <Button variant="contained"
          key="next"
          disabled={!isNameValid}
          color="secondary"
          onClick={() => {
            nextStep();
          }}>
            Next
        </Button>,
        isLastStep && <Button variant="contained"
          key="save"
          color="secondary"
          onClick={() => {
            this.editImportance();
          }}>
            Save
        </Button>
      ]
    );
  }

  render() {
    const { modalOpened, importance } = this.props;

    return (
      <Modal
        isOpen={modalOpened}
        closeModal={() => this.closeModal()}
        title={"Edit importance " + importance?.name}
        customModalContent={() => this.modalContent()}
        actions={() => this.modalActions()}
        actionAlign="flex-end"
      >
      </Modal>
    );
  }
}

EditImportanceModal.defaultProps = {
  currentId: null
};

EditImportanceModal.propTypes = {
  currentId: PropTypes.number,

  importances: PropTypes.arrayOf(PropTypes.object).isRequired,
  importance: PropTypes.object.isRequired,

  modalOpened: PropTypes.bool.isRequired,
  closeEditModal: PropTypes.func.isRequired,

  currentStep: PropTypes.number,
  previousStep: PropTypes.func,
  nextStep: PropTypes.func,
  stepperComponent: PropTypes.object,
  isLastStep: PropTypes.bool,

  updateImportance: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    importances: listImportancesSelector(state),
    importance: showImportance(state, ownProps.currentId) || {},

    isImportanceEditing: isLoadingSelector(state, updateImportanceAction.toString())
  };
}

const mapDispatchToProps = {
  updateImportance: updateImportanceAction,
  addToast: addToastAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withStepper(
  EditImportanceModal, [
    "Set importance information",
    "Sumup"
  ]));
