import { createAction } from "redux-actions";

/* Storage actions */
const storeProviders = createAction("STORE_PROVIDERS");
const storeProvidersFilters = createAction("STORE_PROVIDERS_FILTERS");

export const addProvider = createAction("ADD_PROVIDER");

const modifyProvider = createAction("MODIFY_PROVIDER");

/* API actions */
export const indexProviders = createAction("API_INDEX_PROVIDERS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/providers",
  data,
  fullLoad,
  onSuccessAction: storeProviders
}));

export const indexProvidersFilters = createAction("API_INDEX_PROVIDERS_FILTERS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/providers/filters",
  data,
  fullLoad,
  onSuccessAction: storeProvidersFilters
}));

export const createProvider = createAction("API_CREATE_PROVIDER", data => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "POST",
  url: "/providers",
  data,
  onSuccessAction: addProvider
}));

export const updateProvider = createAction("API_UPDATE_PROVIDER", (providerId, data) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "PUT",
  url: `/providers/${providerId}`,
  data,
  onSuccessAction: modifyProvider
}));

export const deleteProvider = createAction("API_DELETE_PROVIDER", providerId => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "DELETE",
  url: `/providers/${providerId}`,
  onSuccessAction: createAction("REMOVE_PROVIDER", () => ({ id: providerId }))
}));
