/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Box, DialogContent, Typography, Button, Alert, LinearProgress } from "@mui/material";
import Modal from "../Modal/Modal";

import { addToast as addToastAction } from "../../actions/toasts";

import {
  deleteOrbitalPosition as deleteOrbitalPositionAction
} from "../../actions/orbital_positions";

import {
  deleteBeam as deleteBeamAction
} from "../../actions/beams";

import {
  deleteBeamsLineups as deleteBeamsLineupsAction
} from "../../actions/beams_lineups";

import {
  indexLineups as indexLineupsAction
} from "../../actions/lineups";

import {
  deleteTransponder as deleteTransponderAction
} from "../../actions/transponders";

import {
  listLineups as listLineupsSelector
} from "../../selectors/lineups";

import {
  listOrbitalPositions as listOrbitalPositionsSelector
} from "../../selectors/orbital_positions";

import {
  listBeams as listBeamsSelector
} from "../../selectors/beams";

import {
  listBeamsLineups as listBeamsLineupsSelector
} from "../../selectors/beams_lineups";

import {
  listTransponders as listTranspondersSelector
} from "../../selectors/transponders";

import { isLoading as isLoadingSelector } from "../../selectors/loaders";

import {
  getObjectNameFromLocales
} from "../../helpers/utils";

const initialState = {
  currentStep: 0,
  steps: 0,
};

class RemoveOrbitalPositionModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      steps: props.currentOrbitalPositionIds.length - 1,
    };
  }

  componentDidMount() {
    const { indexLineups } = this.props;

    indexLineups(null, true);
  }

  componentDidUpdate(prevProps) {
    const {
      currentOrbitalPositionIds
    } = this.props;

    if (currentOrbitalPositionIds.length !== prevProps.currentOrbitalPositionIds.length) {
      this.setState({
        currentStep: 0,
        steps: currentOrbitalPositionIds.length - 1
      });
    }
  }

  nextStep() {
    const {
      currentStep,
      steps
    } = this.state;

    const {
      addToast
    } = this.props;

    if (currentStep < steps) {
      this.setState({
        currentStep: currentStep + 1
      });
    } else {
      addToast("success", "Success", "Orbital Position removed!");
      this.closeModal();
    }
  }

  async removeOrbitalPosition() {
    const {
      currentStep
    } = this.state;

    const {
      currentOrbitalPositionIds,
      beams,
      beamsLineups,
      transponders,

      deleteBeamsLineups,
      deleteTransponder,
      deleteBeam,
      deleteOrbitalPosition
    } = this.props;

    if (currentStep < currentOrbitalPositionIds.length) {
      const currentOrbitalPositionId = parseInt(currentOrbitalPositionIds[currentStep], 10);

      // Filter beams
      const currentBeams = beams.filter(b => b.orbital_position_id === currentOrbitalPositionId);
      // Filter transponders
      const currentTransponders = transponders.filter(t => currentBeams.find(b => b.id === t.beam_id));
      // Filter BeamsLineups
      const currentBeamsLineups = beamsLineups.filter(bl => currentBeams.find(b => b.id === bl.beam_id));

      // 1. Delete beams_lineups
      for (let i = 0; i < currentBeamsLineups.length; i += 1) {
        const currentBeamLineup = currentBeamsLineups[i];

        await deleteBeamsLineups(currentBeamLineup.id);
      }

      // 2. Delete transponders
      for (let i = 0; i < currentTransponders.length; i += 1) {
        const currentTransponder = currentTransponders[i];

        await deleteTransponder(currentTransponder.id);
      }

      // 3. Delete beam
      for (let i = 0; i < currentBeams.length; i += 1) {
        const currentBeam = currentBeams[i];

        await deleteBeam(currentBeam.id);
      }

      // 4. Delete orbital position
      await deleteOrbitalPosition(currentOrbitalPositionId);
    }

    // Go to the next orbital position
    // If it is the last orbital positon,
    // nextStep() will close the modal
    this.nextStep();
  }

  closeModal() {
    const { closeOrbitalPositionModal } = this.props;

    closeOrbitalPositionModal();
    this.setState(initialState);
  }

  getCurrentOrbitalPosition() {
    const { currentStep } = this.state;
    const { currentOrbitalPositionIds, orbitalPositions } = this.props;

    return orbitalPositions.find(op => op.id === currentOrbitalPositionIds[currentStep]);
  }

  getBeams() {
    const { beams } = this.props;

    const currentOrbitalPositionId = this.getCurrentOrbitalPosition()?.id;

    return beams.filter(b => b.orbital_position_id === currentOrbitalPositionId);
  }

  getBeamsLineups() {
    const { beamsLineups } = this.props;

    const currentBeams = this.getBeams();

    return beamsLineups.filter(bl => currentBeams.map(b => b.id).indexOf(bl.beam_id) !== -1);
  }

  modalContent() {
    const { currentStep, steps } = this.state;
    const { lineups } = this.props;

    const pitch = steps > 0 ? 100 / steps : 100;
    const currentOrbital = this.getCurrentOrbitalPosition();
    const currentBeams = this.getBeams();
    const currentBeamsLineups = this.getBeamsLineups();

    return (
      <DialogContent sx={{ display: "flex", gap: 3, flexDirection: "column", marginTop: 1, width: "80%", alignItems: "center", alignSelf: "center" }}>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 2 }}>
          <Typography alignSelf="center">
            {`Orbital position ${currentStep + 1} / ${steps + 1}`}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={Math.min(currentStep * pitch, 100)}
            sx={{ borderRadius: "10px" }}
          />
        </Box>
        <Typography sx={{ textAlign: "center" }}>{currentOrbital?.name}</Typography>
        {!currentBeamsLineups?.length
          ? <Alert severity="info">
            This orbital position has not any lineup. Deleting will only remove its own beams and transponders
          </Alert>

          : <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Alert severity="warning">
              This orbital position owns the following lineups. By removing this orbital position, it will delete its own beams, transponders, lineups, etc...
            </Alert>
            <Box sx={{ display: "flex", gap: 4, alignSelf: "flex-start", mt: 3 }}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                <Typography variant="h5" component="span" sx={{ mb: 2 }}>
                  Beam
                </Typography>
                {
                  currentBeamsLineups.map((cbl, index) =>
                    <Typography key={index}>
                      {currentBeams.find(cb =>
                        cb.id === cbl.beam_id
                      )?.name}
                    </Typography>
                  )
                }
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                <Typography variant="h5" component="span" sx={{ mb: 2 }}>
              Lineup
                </Typography>
                {
                  currentBeamsLineups.map((cbl, index) =>
                    <Typography key={index}>
                      {getObjectNameFromLocales(lineups.find(lineup =>
                        lineup.id === cbl.lineup_id
                      ))}
                    </Typography>
                  )
                }
              </Box>
            </Box>
          </Box>
        }
      </DialogContent>
    );
  }

  modalActions() {
    return (
      [
        <Button variant="outlined"
          key="cancel"
          onClick={() => {
            this.closeModal();
          }} >
            Cancel
        </Button>,
        <Button variant="contained"
          key="next"
          disabled={ false }
          color="secondary"
          onClick={() => {
            this.nextStep();
          }}>
            Ignore this orbital position
        </Button>,
        <Button variant="contained"
          key="save"
          color="error"
          onClick={() => {
            this.removeOrbitalPosition();
          }}>
            Delete it!
        </Button>
      ]
    );
  }

  render() {
    const { modalOpened } = this.props;

    return (
      <Modal
        isOpen={modalOpened}
        closeModal={() => this.closeModal()}
        title="Remove Orbital Position"
        customModalContent={() => this.modalContent()}
        actions={() => this.modalActions()}
        actionAlign="flex-end"
      >
      </Modal>
    );
  }
}

RemoveOrbitalPositionModal.propTypes = {
  currentOrbitalPositionIds: PropTypes.array.isRequired,

  modalOpened: PropTypes.bool.isRequired,
  closeOrbitalPositionModal: PropTypes.func.isRequired,

  orbitalPositions: PropTypes.array.isRequired,
  beams: PropTypes.array.isRequired,
  beamsLineups: PropTypes.array.isRequired,
  transponders: PropTypes.array.isRequired,
  lineups: PropTypes.array.isRequired,

  addToast: PropTypes.func.isRequired,
  indexLineups: PropTypes.func.isRequired,
  deleteBeamsLineups: PropTypes.func.isRequired,
  deleteTransponder: PropTypes.func.isRequired,
  deleteBeam: PropTypes.func.isRequired,
  deleteOrbitalPosition: PropTypes.func.isRequired,

  isLoading: PropTypes.bool.isRequired
};

const mapDispatchToProps = {
  addToast: addToastAction,
  deleteOrbitalPosition: deleteOrbitalPositionAction,
  deleteBeamsLineups: deleteBeamsLineupsAction,
  deleteTransponder: deleteTransponderAction,
  deleteBeam: deleteBeamAction,
  indexLineups: indexLineupsAction
};

function mapStateToProps(state) {
  return {
    orbitalPositions: listOrbitalPositionsSelector(state),
    beams: listBeamsSelector(state),
    beamsLineups: listBeamsLineupsSelector(state),
    transponders: listTranspondersSelector(state),
    lineups: listLineupsSelector(state),

    isLoading: isLoadingSelector(state, deleteOrbitalPositionAction.toString())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoveOrbitalPositionModal);
