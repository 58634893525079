import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { createRoot } from "react-dom/client";
import { LicenseInfo } from "@mui/x-license-pro";

import { theme } from "./_theme";
import configureStore, { history } from "./configureStore";
import startSentry from "./startSentry";

import "semantic-ui-css/semantic.min.css";

import "./styles/reset.css";
import "./styles/global.css";
import "./styles/modal.css";
import "./styles/form.css";
import "react-step-progress-bar/styles.css";

import AppRoutes from "./appRoutes";

startSentry();

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY || "");

const store = configureStore();

createRoot(document.getElementById("root")).render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <AppRoutes store={store} history={history} />
  </ThemeProvider>
);
