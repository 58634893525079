import { useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import qs from "qs";

export default function useQueryAsState() {
  const location = useLocation();
  const navigate = useNavigate();

  const params = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const updateQuery = useCallback((updatedParams, options = {}) => {
    navigate(location.pathname + "?" + qs.stringify(
      updatedParams,
    ), options);
  }, [navigate, location]);

  return [params, updateQuery];
}
