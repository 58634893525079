import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

const FormatTransponder = props => {
  const { notifData, transponders, techChannels } = props;
  const { UPDATE, DELETION } = props.qualificationTypes;
  const { COLOR_DELETE, COLOR_PENDING, COLOR_SUCCESS } = props.colors;

  const qualifType = notifData.qualification_type;
  const transponder = transponders.find(
    t => t.id === notifData.data.transponder_id
  );

  const transponderItem = (label, previous, current) => !!previous && !!current
      && <Box sx={{ display: "flex", gap: 1 }}>
        <Typography sx={{ fontWeight: "bold" }}>{label}:</Typography>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Typography sx={{ color: COLOR_DELETE }}>
            {previous || "null"}
          </Typography>
          {"->"}
          <Typography sx={{ color: COLOR_SUCCESS }}>{current}</Typography>
        </Box>
      </Box>;

  if (qualifType === UPDATE && transponder) {
    return (
      <>
        <Box sx={{ display: "flex", gap: 1 }}>
          Transponder:
          <Typography sx={{ color: COLOR_DELETE }}>
            {transponder.name}
          </Typography>
        </Box>
        {transponderItem(
          "Frequency",
          transponder.frequency,
          notifData.data.frequency
        )}
        {transponderItem(
          "Modulation Type",
          transponder.modulation_type,
          notifData.data.modulation_type
        )}
        {transponderItem(
          "Modulation System",
          transponder.modulation_system,
          notifData.data.modulation_system
        )}
        {transponderItem(
          "Polarization",
          transponder.polarization,
          notifData.data.polarization
        )}
        {transponderItem(
          "Symbol Rate",
          transponder.symbol_rate,
          notifData.data.symbol_rate
        )}
        {transponderItem(
          "FEC Inner",
          transponder.fec_inner,
          notifData.data.fec_inner
        )}
        {transponderItem(
          "Roll Off",
          transponder.roll_off,
          notifData.data.roll_off
        )}
      </>
    );
  }

  if (qualifType === DELETION && transponder) {
    const currentTechChannels = techChannels.filter(
      tc => tc.transponder_id === transponder.id
    );
    const activeTCs = currentTechChannels.filter(
      tc => tc.status === "active"
    );
    const pendingTCs = currentTechChannels.filter(
      tc => tc.status === "pending"
    );
    const discardedTCs = currentTechChannels.filter(
      tc => tc.status === "discarded"
    );

    return (
      <>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Typography sx={{ fontWeight: "bold" }}>Transponder:</Typography>
          {transponder.name}
        </Box>
        {activeTCs.length > 0
          && <>
            <Typography sx={{ fontWeight: "bold", color: COLOR_SUCCESS }}>
              Associated active technical channels:
            </Typography>
            {activeTCs
              .map(tc => tc.service_name)
              .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
              .join(", ")}
          </>
        }
        {pendingTCs.length > 0
          && <>
            <Typography sx={{ fontWeight: "bold", color: COLOR_PENDING }}>
              Associated pending technical channels":
            </Typography>
            {pendingTCs
              .map(tc => tc.service_name)
              .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
              .join(", ")}
          </>
        }
        {discardedTCs.length > 0
          && <>
            <Typography sx={{ fontWeight: "bold", color: COLOR_DELETE }}>
              Associated discarded technical channels:
            </Typography>
            {discardedTCs
              .map(tc => tc.service_name)
              .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
              .join(", ")}
          </>
        }
        {currentTechChannels.length === 0
          && <>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Typography sx={{ fontWeight: "bold" }}>
                Associated technical channels:
              </Typography>
              none
            </Box>
          </>
        }
      </>
    );
  }
};

FormatTransponder.propTypes = {
  notifData: PropTypes.object.isRequired,
  transponders: PropTypes.array.isRequired,
  techChannels: PropTypes.array.isRequired,
  qualificationTypes: PropTypes.object.isRequired,
  colors: PropTypes.object.isRequired,
};

export default FormatTransponder;
