import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faInfoCircle, faTimes, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LockIcon from "@mui/icons-material/Lock";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-pro";

import {
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  SvgIcon
} from "@mui/material";
import { addToast as addToastAction } from "../../actions/toasts";
import { getObjectNameFromLocales, exportAsFile, truncate } from "../../helpers/utils";

import {
  indexEditorialChannels as indexEditorialChannelsAction,
  updateEditorialChannel as updateEditorialChannelAction,
  modifyManyEditorialChannels as updateManyEditorialChannelsAction
} from "../../actions/editorial_channels";
import {
  indexLineups as indexLineupsAction,
  indexLineupsFilters as indexLineupsFiltersAction,
  exportLineups as exportLineupsAction,
  exportLineupById as exportLineupByIdAction,
} from "../../actions/lineups";
import {
  deleteLineupsService as deleteLineupsServiceAction,
  indexLineupsServices as indexLineupsServicesAction,
  updateLineupsService as updateLineupsServiceAction,
  modifyManyLineupsServices as modifyManyLineupsServicesAction,
  removeManyLineupsServices as removeManyLineupsServicesAction,
} from "../../actions/lineups_services";
import {
  updateTechChannel as updateTechChannelAction,
  modifyManyTechChannels as updateManyTechChannelsAction
} from "../../actions/tech_channels";
import {
  deleteService as deleteServiceAction,
  indexServices as indexServicesAction,
  removeManyServices as removeManyServicesAction
} from "../../actions/services";

import { listBeams as listBeamsSelector } from "../../selectors/beams";
import { listBeamsLineups as listBeamsLineupsSelector } from "../../selectors/beams_lineups";
import { listEditorialChannels as listEditorialChannelsSelector } from "../../selectors/editorial_channels";
import { listLineups as listLineupsSelector } from "../../selectors/lineups";
import { listLineupsServices as listLineupsServicesSelector, listLineupsServicesByLineup as listLineupsServicesByLineupSelector } from "../../selectors/lineups_services";
import { listServices as listServicesSelector } from "../../selectors/services";
import { listOrbitalPositions as listOrbitalPositionsSelector } from "../../selectors/orbital_positions";
import { listImportances as listImportancesSelector } from "../../selectors/importances";

import { isLoading as isLoadingSelector } from "../../selectors/loaders";

import FullLoader from "../../components/FullLoader";
import AddLineupModal from "../../components/LineupModal/AddLineupModal";
import EditLineupModal from "../../components/LineupModal/EditLineupModal";
import LineupMetricsModal from "../../components/LineupModal/LineupMetricsModal";
import AddLineupServiceModal from "../../components/LineupServiceModal/AddLineupServiceModal";
import AddInheritedLineupServiceModal from "../../components/LineupServiceModal/AddInheritedLineupServiceModal";
import EditLineupServiceModal from "../../components/LineupServiceModal/EditLineupServiceModal";
import EditInheritedLineupServiceModal from "../../components/LineupServiceModal/EditInheritedLineupServiceModal";
import LineupServiceMoveToActionModal from "../../components/LineupServiceModal/LineupServiceMoveToActionModal";
import ScrollToLcnModal from "../../components/LineupServiceModal/ScrollToLcnModal";

import "./Lineups.css";
import DataTable from "../../components/DataTable";
import FiltersInputs from "../../components/FiltersInputs";
import FiltersSearchField from "../../components/FiltersSearchField";

import { customGridOperators } from "../../utils/constant";

import { updateManyLineupsServices, deleteManyLineupsServices } from "../../api/lineupsServices";

import { ReactComponent as IconScroll } from "../../assets/icons/scroll.svg";

class Lineups extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      /* filters */
      filters: [],
      serviceFilters: [],

      /* menus */
      menuAnchorEl: null,
      openedMenu: null,

      selectedServicesIds: [],

      /* editing child */

      editingChildren: [],

      predefinedLCNS: "",

      isLineupOpened: false,

      openedLineupId: null,
      currentLineupId: null,
      parentLineupId: null,
      orbitalPositionId: null,
      lineupModalOpened: false,
      lineupMetricsModalOpened: false,
      addLineupModalOpened: false,
      isActionErrorModalOpened: false,
      actionErrorMessage: {},
      isMoveToActionModalOpened: false,
      isSortActionModalOpened: false,
      sortActionModalParams: null,
      isDeleteActionModalOpened: false,

      hasFetchingService: false,

      currentLineupParams: null,
      currentLineupServiceId: null,
      parentLineupServiceId: null,
      lineupServiceModalOpened: false,
      addInheritedLineupServiceModalOpened: false,
      editLineupServiceModalOpened: false,
      editInheritedLineupServiceModalOpened: false,
      editLineupServiceStep: -1,
      exportOptions: false,
      exportLineupId: -1,

      lcnToScrollTo: -1,
      isScrollToLcnModalOpened: false
    };

    this.table = React.createRef();
  }

  getActionErrorTitle = action => `Unable to ${action} selected item(s)`;

  componentDidMount() {
    const {
      indexLineupsFilters,
      indexEditorialChannels
    } = this.props;
    indexLineupsFilters();
    indexEditorialChannels({}, true);
    this.loadLineups();
  }

  componentDidUpdate(prevProps) {
    const {
      hasFetchingService
    } = this.state;

    const {
      isLineupsCreating,
      isBeamsLineupsCreating,
      services,
      isLoadingServices,
      indexServices
    } = this.props;

    const isLoading = !!isLineupsCreating
      || !!isBeamsLineupsCreating;

    const prevIsLoading = !!prevProps.isLineupsCreating
      || !!prevProps.isBeamsLineupsCreating;

    if (prevIsLoading && !isLoading) {
      this.loadLineups();
    }

    if (!hasFetchingService && services.length === 0 && !isLoadingServices) {
      indexServices({}, true);
      this.setState({ hasFetchingService: true });
    }
  }

  setLcnToScrollTo = newSelection => {
    this.setState({
      lcnToScrollTo: newSelection,
    });
  };

  setSelectedServicesIds = newSelection => {
    this.setState({
      selectedServicesIds: newSelection,
    });
  };

  setCurrentLineupParams = lineupParams => {
    this.setState({
      currentLineupParams: lineupParams,
    });
  };

  handleActionErrorModalOpen = (title, contentText) => {
    this.setState({
      isActionErrorModalOpened: true,
      actionErrorMessage: { title, contentText }
    });
  };

  handleActionErrorModalClose = () => {
    this.setState({
      isActionErrorModalOpened: false,
      actionErrorMessage: {}
    });
  };

  handleMoveToActionModalOpen = () => {
    this.setState({
      isMoveToActionModalOpened: true,
    });
  };

  handleMoveToActionModalClose = () => {
    this.setState({
      isMoveToActionModalOpened: false
    });
  };

  handleSortActionModalOpen = sortActionModalParams => {
    this.setState({
      isSortActionModalOpened: true,
      sortActionModalParams
    });
  };

  handleSortActionModalClose = () => {
    this.setState({
      isSortActionModalOpened: false,
      sortActionModalParams: null
    });
  };

  handleDeleteActionModalOpen = () => {
    this.setState({
      isDeleteActionModalOpened: true
    });
  };

  handleDeleteActionModalClose = () => {
    this.setState({
      isDeleteActionModalOpened: false
    });
  };

  handleScrollToLcnModalOpen = () => {
    this.setState({
      isScrollToLcnModalOpened: true,
      lcnToScrollTo: -1
    });
  };

  handleScrollToLcnModalClose = () => {
    this.setState({
      isScrollToLcnModalOpened: false
    });
  };

  /* data  */

  // eslint-disable-next-line react/sort-comp
  loadLineups = () => {
    const {
      indexLineups,
      isLineupsLoading
    } = this.props;

    if (!isLineupsLoading) {
      indexLineups({}, true);
    }
  };

  loadLineupServices = () => {
    const { indexLineupsServices } = this.props;

    indexLineupsServices({}, true);
  };

  editLineupModal = currentLineupId => {
    this.setState({ currentLineupId });
    this.openLineupModal();
  };

  createLineupModal = () => {
    this.setState({ currentLineupId: null }, () => {
      this.openLineupModal();
    });
  };

  /* filter methods */
  setFilters = newFilters => {
    this.setState({
      filters: newFilters
    });
  };

  setServiceFilters = newFilters => {
    this.setState({
      serviceFilters: newFilters
    });
  };

  addFilter = (filterName, name, value) => {
    const { filters } = this.state;
    const filterId = "selected";

    if (!filters.find(t => t.id === filterId)) {
      const newFiltersArray = filters.slice(0);

      newFiltersArray.push({
        field: filterName,
        value: value,
        operator: "equals",
        filterOperator: "equals",
        id: filterId,
        name
      });

      this.setState({ filters: newFiltersArray });
    }
  };

  hasFilterSelected = (filterName, value) => {
    const { filters } = this.state;
    const filterId = "selected";

    return !!filters.find(t => t.id === filterId);
  };

  removeFilter = (filterName, value) => {
    const { filters } = this.state;
    const filterId = "selected";
    const newFiltersArray = filters.slice(0).filter(t => t.id !== filterId);

    this.setState({ filters: newFiltersArray });
  };

  toggleFilter = (filterName, name, value) => {
    if (this.hasFilterSelected(filterName, value)) {
      this.removeFilter(filterName, value);
    } else {
      this.addFilter(filterName, name, value);
    }
  };

  /* Menus methods */

  setMenuAnchorEl = target => {
    this.setState({
      menuAnchorEl: target
    });
  };

  setOpenedMenu = menuId => {
    this.setState({
      openedMenu: menuId
    });
  };

  openMenu = (event, menuId) => {
    this.setMenuAnchorEl(event.currentTarget);
    this.setOpenedMenu(menuId);
  };

  closeMenu = menuId => {
    this.setMenuAnchorEl(null);
    this.setOpenedMenu(menuId);
  };

  /* Lineup Modal */

  openLineupModal = () => {
    this.setState({ lineupModalOpened: true });
  };

  closeLineupModal = (refresh = false) => {
    const { isLineupOpened } = this.state;

    this.setState({
      lineupModalOpened: false
    });

    if (!isLineupOpened) {
      this.setState({
        currentLineupId: null
      });
    }

    if (refresh) {
      this.forceUpdate();
    }
  };

  openLineupMetricsModal = currentLineupId => {
    this.setState({ currentLineupId, lineupMetricsModalOpened: true });
  };

  closeLineupMetricsModal = () => {
    const { isLineupOpened } = this.state;

    this.setState({ lineupMetricsModalOpened: false });

    if (!isLineupOpened) {
      this.setState({
        currentLineupId: null
      });
    }
  };

  /* Add Lineup Modal */

  openAddLineupModal = () => {
    this.setState({ addLineupModalOpened: true });
  };

  closeAddLineupModal = () => {
    const {
      indexLineupsFilters
    } = this.props;

    indexLineupsFilters();
    this.setState({ addLineupModalOpened: false });
  };

  /* add LineupService Modal */
  openLineupServiceModal = (params, lineupParams, predefinedLCNS = "") => {
    const { orbitalPositions, beams, beamsLineups } = this.props;
    const currentBeamsLineups = beamsLineups.filter(bl => bl.lineup_id === (lineupParams || params).id);
    const beamIds = currentBeamsLineups.map(bl => bl.beam_id);
    const currentBeams = beams.filter(b => beamIds.indexOf(b.id) !== -1);
    const orbitalPositionsIds = currentBeams.map(b => b.orbital_position_id);
    const currentOrbitalPositions = orbitalPositions.filter(op => orbitalPositionsIds.indexOf(op.id) !== -1);
    this.setState({
      currentLineupId: (lineupParams || params).id,
      orbitalPositionId: currentOrbitalPositions[0].id,
      lineupServiceModalOpened: true,
      predefinedLCNS
    });
  };

  closeLineupServiceModal = () => {
    // const {
    //   currentLineupId,
    //   orbitalPositionId,
    // } = this.state;

    this.setState({
      // currentLineupId: shouldClearState ? null : currentLineupId,
      // orbitalPositionId: shouldClearState ? null : orbitalPositionId,
      lineupServiceModalOpened: false,
      predefinedLCNS: ""
    });
  };

  /* add inheritedLineupService Modal */

  openAddInheritedLineupServiceModal = (currentLineupId, parentLineupId, predefinedLCNS = "", parentLineupServiceId = null) => {
    this.setState({
      currentLineupId,
      parentLineupId,
      parentLineupServiceId,
      addInheritedLineupServiceModalOpened: true,
      predefinedLCNS
    });
  };

  exportCSV = async () => {
    const { exportLineups, addToast } = this.props;
    const response = await exportLineups();

    if (!response.isSuccess) {
      addToast("error", "Error", "Error while exporting Channels. Try again.");
      return;
    }

    exportAsFile(response.data, "lineups.csv");
  };

  exportCSVById = async () => {
    const { exportLineupById, addToast, lineups } = this.props;
    const { exportLineupId } = this.state;

    const response = await exportLineupById(exportLineupId);

    if (!response.isSuccess) {
      addToast("error", "Error", "Error while exporting Lineup details. Try again.");
      return;
    }

    const ln = lineups.find(ln => ln.id === exportLineupId);
    const name = ln.locales[ln.default_language]?.name ?? "no_name";
    exportAsFile(response.data, "lineup_" + name.replace(/\s/g, "") + ".csv");
  };

  closeAddInheritedLineupServiceModal = (shouldClearState = true) => {
    const {
      // currentLineupId,
      parentLineupId,
      parentLineupServiceId
    } = this.state;

    this.setState({
      // currentLineupId: shouldClearState ? null : currentLineupId,
      parentLineupId: shouldClearState ? null : parentLineupId,
      parentLineupServiceId: shouldClearState ? null : parentLineupServiceId,
      addInheritedLineupServiceModalOpened: false,
      predefinedLCNS: ""
    });
  };

  /* edit inheritedLineupService Modal */

  openEditInheritedLineupServiceModal = (params, lineupParams) => {
    this.setState({
      currentLineupId: lineupParams.row.id,
      parentLineupId: lineupParams.row.parent_id,
      currentLineupServiceId: params.id,
      editInheritedLineupServiceModalOpened: true
    });
  };

  closeEditInheritedLineupServiceModal = (shouldClearState = true) => {
    const {
      // currentLineupId,
      parentLineupId,
      currentLineupServiceId
    } = this.state;

    this.setState({
      // currentLineupId: shouldClearState ? null : currentLineupId,
      parentLineupId: shouldClearState ? null : parentLineupId,
      currentLineupServiceId: shouldClearState ? null : currentLineupServiceId,
      editInheritedLineupServiceModalOpened: false
    });
  };

  /* edit LineupService Modal */

  openEditLineupServiceModal = (params, editLineupServiceStep = -1) => {
    this.setState({
      currentLineupId: params.row.lineup_id,
      currentLineupServiceId: params.id,
      orbitalPositionId: params.row.service.orbital_position_id,
      editLineupServiceModalOpened: true,
      editLineupServiceStep
    });
  };

  closeEditLineupServiceModal = () => {
    this.setState({
      // currentLineupId: null,
      currentLineupServiceId: null,
      orbitalPositionId: null,
      editLineupServiceModalOpened: false,
      editLineupServiceStep: -1
    });
  };

  /* Child methods */

  handleCellClick = params => {
    const { openedLineupId, isLineupOpened, selectedServicesIds } = this.state;

    if (params.field === "edit") {
      return this.editLineupModal(params.id);
    }
    if (params.field === "info") {
      return this.openLineupMetricsModal(params.id);
    }
    this.setState({
      openedLineupId: openedLineupId ? null : params.id,
      selectedServicesIds: openedLineupId ? [] : selectedServicesIds,
      isLineupOpened: !isLineupOpened
    });
  };

  selectCurrentLineupsService = lineupId => {
    const { lineupsServices } = this.props;

    return lineupsServices.filter(ls => ls.lineup_id === lineupId)
      .sort((a, b) => Math.min(a.lcns) - Math.min(b.lcns));
  };

  // eslint-disable-next-line class-methods-use-this
  isAdjacentBlock = (block, cur) => !!block.find(ls => ls.idx + 1 === cur.idx || ls.idx - 1 === cur.idx);

  generateSelectedBlocks = selectedChildrenItemsIds => {
    const sortedSelected = selectedChildrenItemsIds.sort((a, b) => a.idx - b.idx);

    const selectedBlocks = sortedSelected.reduce((acc, cur) => {
      const blockIdx = acc.findIndex(block => this.isAdjacentBlock(block, cur));

      if (blockIdx === -1) {
        acc.push([cur]);
      } else {
        acc[blockIdx].push(cur);
      }

      return acc;
    }, []);

    return selectedBlocks;
  };

  getLineupsServicesToUpdate = (isSwappingMode, servicesToMove, selectedServicesIds, shift) => {
    const swapFactor = servicesToMove.length > 1 ? servicesToMove.length - 1 : 1;

    return servicesToMove.map(({ id, lcns, idx }) => {
      let updatedLcns;
      if (isSwappingMode && !selectedServicesIds.includes(id)) {
        updatedLcns = lcns.map(lcn => lcn === idx ? lcn - shift * swapFactor : lcn);
      } else {
        updatedLcns = lcns.map(lcn => lcn === idx ? lcn + shift : lcn);
      }
      return { id, lcns: updatedLcns };
    });
  };

  updateLineupsServices = (lineupsServicesToUpdate, loadLineupServices) => {
    const { addToast, modifyManyLineupsServices } = this.props;

    Promise.all(updateManyLineupsServices(lineupsServicesToUpdate))
      .then(data => {
        addToast("success", "Success", "Service successfully moved!");
        modifyManyLineupsServices(data);
      })
      .catch(() => {
        addToast("error", "Error", "Error while moving service. Try again.");
        loadLineupServices();
      });
  };

  isSelectedItemsAdjacent = selectedServicesIdxs => selectedServicesIdxs
    .sort((a, b) => a - b)
    .every((itemIdx, arrayIdx) => arrayIdx !== 0 ? itemIdx - selectedServicesIdxs[arrayIdx - 1] === 1 : true);

  moveSelectedLineupsServices = async (lineupParams, selectedServicesIds, shift, options = null) => {
    const { lineupsServicesByLineup } = this.props;
    const allCurrentLineupServices = lineupsServicesByLineup[lineupParams.id];
    const selectedServices = selectedServicesIds.map(id => allCurrentLineupServices.find(service => service.id === id));
    const selectedServicesIdxs = selectedServices.map(({ idx }) => idx);

    // Check selected services adjacency
    if (selectedServicesIdxs.length > 1 && !this.isSelectedItemsAdjacent(selectedServicesIdxs)) {
      this.handleActionErrorModalOpen(
        this.getActionErrorTitle("move"),
        "You have selected services that are not adjacent."
      );
      return;
    }

    // Check locked selected services
    if (selectedServices.some(service => service.locked)) {
      this.handleActionErrorModalOpen(
        this.getActionErrorTitle("move"),
        "Your selection contains locked service(s)."
      );
      return;
    }

    // Prevent moving services outside of the table range
    if (selectedServicesIdxs.includes(0) && shift < 0) {
      this.handleActionErrorModalOpen(
        this.getActionErrorTitle("move"),
        "You can't move your selection outside of the table range."
      );
      return;
    }

    let servicesToMove = [];
    let isSwappingMode = false;

    if (options?.moveToMode) {
      const { idxTarget } = options;
      // Determine relative shift
      shift = idxTarget - Math.min(...selectedServicesIdxs);

      // Check if targeted services block is empty
      const serviceTargets = allCurrentLineupServices.filter(({ idx }) => idx >= idxTarget && idx < idxTarget + selectedServicesIds.length);
      if (!serviceTargets.every(ls => ls.empty)) {
        this.handleActionErrorModalOpen(
          this.getActionErrorTitle("move"),
          "The selected service(s) will collide other service(s)."
        );
        return;
      }

      servicesToMove = allCurrentLineupServices.filter(ls => selectedServicesIdxs.includes(ls.idx));
    } else {
      // Handle swapping here
      const serviceItemsToCheck = [
        ...selectedServicesIdxs,
        shift > 0
          ? selectedServicesIdxs[selectedServicesIdxs.length - 1] + 1
          : selectedServicesIdxs[0] - 1
      ];

      // Check swappingMode (can't swap with parent services)
      servicesToMove = allCurrentLineupServices.filter(ls => serviceItemsToCheck.includes(ls.idx));
      isSwappingMode = servicesToMove.every(ls => !ls.empty && (ls.parent_id || ls.isOrphan || !lineupParams.row.parent_id));
      // Check locked swapping services
      if (servicesToMove.some(service => service.locked)) {
        this.handleActionErrorModalOpen(
          this.getActionErrorTitle("move"),
          "You are trying to swap your selection with a locked service."
        );
        return;
      }

      if (lineupParams.row.parent_id) {
        // Remove empty services and parent services
        servicesToMove = servicesToMove.filter(ls => !ls.empty && (ls.parent_id || ls.isOrphan));
      } else {
        // Remove empty services
        servicesToMove = servicesToMove.filter(ls => !ls.empty);
      }

      servicesToMove.sort((a, b) => a.idx - b.idx);
    }

    const lineupsServicesToUpdate = this.getLineupsServicesToUpdate(isSwappingMode, servicesToMove, selectedServicesIds, shift);

    this.updateLineupsServices(lineupsServicesToUpdate, this.loadLineupServices);
    this.setState({ editingChildren: [] });
  };

  goUpSelectedServices = async (lineupParams, selectedServicesIds) => {
    this.moveSelectedLineupsServices(
      lineupParams,
      selectedServicesIds,
      -1
    );
  };

  goDownSelectedServices = async (lineupParams, selectedServicesIds) => {
    this.moveSelectedLineupsServices(
      lineupParams,
      selectedServicesIds,
      1
    );
  };

  moveSomewhereSelectedServices = async (lineupParams, selectedServicesIds, idxTarget) => {
    this.moveSelectedLineupsServices(
      lineupParams,
      selectedServicesIds,
      0,
      {
        moveToMode: true,
        idxTarget
      }
    );
  };

  handleSortServices = async (sortType, lineupId, selectedServicesIds) => {
    const { lineupsServicesByLineup } = this.props;
    const allCurrentLineupServices = lineupsServicesByLineup[lineupId];
    const selectedServices = selectedServicesIds.map(id => allCurrentLineupServices.find(service => service.id === id));
    const selectedServicesIdxs = selectedServices.map(({ idx }) => idx);

    // Check selected services adjacency
    if (selectedServicesIdxs.length > 1 && !this.isSelectedItemsAdjacent(selectedServicesIdxs)) {
      this.handleActionErrorModalOpen(
        this.getActionErrorTitle("sort"),
        "You have selected services that are not adjacent."
      );
      return;
    }

    // Check locked selected services
    if (selectedServices.some(service => service.locked)) {
      this.handleActionErrorModalOpen(
        this.getActionErrorTitle("sort"),
        "Your selection contains locked service(s)."
      );
      return;
    }

    this.handleSortActionModalOpen({
      allCurrentLineupServices,
      selectedServices,
      selectedServicesIdxs,
      sortType
    });
  };

  sortServices = async ({ allCurrentLineupServices, selectedServices, selectedServicesIdxs, sortType }) => {
    const lineupsServicesToSort = selectedServices.map(service => {
      const lineupsService = service.parent_id ? allCurrentLineupServices.find(lineupService => lineupService.id === service.parent_id) : service;
      return {
        ...service,
        service: lineupsService.service,
        editorialChannel: this.getEditorialChannel(lineupsService)
      };
    })
      .sort((a, b) => {
        const curr = sortType === "channelname" ? getObjectNameFromLocales(a.editorialChannel) : a.editorialChannel.media_group;
        const next = sortType === "channelname" ? getObjectNameFromLocales(b.editorialChannel) : b.editorialChannel.media_group;

        return curr.localeCompare(next, "en", { sensitivity: "base" });
      });

    const lineupsServicesToUpdate = lineupsServicesToSort.map(({ id, lcns, idx: serviceIdx }, idx) => ({ id,
      lcns: lcns.map(lcn => lcn === serviceIdx ? selectedServicesIdxs[idx] : lcn) }));

    this.updateLineupsServices(lineupsServicesToUpdate, this.loadLineupServices);
    this.handleSortActionModalClose();
  };

  toggleLockedLineupsService = async (e, lineupServiceId) => {
    e.preventDefault();
    e.stopPropagation();

    const { lineupsServices, updateLineupsService, addToast } = this.props;
    const lineupsService = lineupsServices.find(ls => ls.id === lineupServiceId);

    if (lineupsService) {
      await this.setState({ editingChildren: lineupsService.lcns });

      try {
        const ret = await updateLineupsService(lineupsService.id, { locked: !lineupsService.locked });

        if (ret.isSuccess) {
          addToast("success", "Success", `Service successfully ${lineupsService.locked ? "unlocked" : "locked"}!`);
        } else {
          throw new Error();
        }
      } catch {
        addToast("error", "Error", `Error while ${lineupsService.locked ? "unlocking" : "locking"} the service.`);
      }

      this.setState({ editingChildren: [] });
    }
  };

  handleDeleteServices = async selectedLineupsServices => {
    const { lineupsServices } = this.props;
    if (selectedLineupsServices.length === 0) {
      return;
    }

    const selectedServices = selectedLineupsServices.map(id => lineupsServices.find(service => service.id === id));
    if (selectedServices.some(service => service.locked)) {
      this.handleActionErrorModalOpen(
        this.getActionErrorTitle("delete"),
        "Your selection contains locked service(s)."
      );
      return;
    }

    this.handleDeleteActionModalOpen();
  };

  deleteChannelsFromLineup = async selectedLineupsServices => {
    const {
      lineupsServices,
      services,
      addToast,
      updateManyTechChannels,
      updateManyEditorialChannels,
      deleteManyServices
    } = this.props;

    if (selectedLineupsServices.length > 0) {
      const newLCNS = selectedLineupsServices.map(selectedLs => {
        const lineupsService = lineupsServices.find(ls => ls.id === selectedLs);

        return lineupsService.lcns[0];
      });

      this.setState({ editingChildren: newLCNS });

      const deletePromisesByEndpoints = deleteManyLineupsServices(selectedLineupsServices, lineupsServices, services);
      if (deletePromisesByEndpoints?.techChannels.length > 0) {
        Promise.all(deletePromisesByEndpoints.techChannels)
          .then(data => {
            updateManyTechChannels(data);
          })
          .catch(() => {
            addToast("error", "Error", "Failed to discard selected service(s) tech channels");
          });
      }

      if (deletePromisesByEndpoints?.editorialChannels.length > 0) {
        Promise.all(deletePromisesByEndpoints.editorialChannels)
          .then(data => {
            updateManyEditorialChannels(data);
          })
          .catch(() => {
            addToast("error", "Error", "Failed to discard selected service(s) editorial channels");
          });
      }

      if (deletePromisesByEndpoints?.services.length > 0) {
        Promise.all(deletePromisesByEndpoints.services)
          .then(data => {
            deleteManyServices(data);
          })
          .catch(() => {
            addToast("error", "Error", "Failed to delete selected service(s)");
          });
      }

      if (deletePromisesByEndpoints?.lineupsServices.length > 0) {
        Promise.all(deletePromisesByEndpoints.lineupsServices)
          .then(() => {
            this.props.deleteManyLineupsServices(selectedLineupsServices);
            addToast("success", "Success", "Service(s) successfully deleted from the lineup!");
          })
          .catch(() => {
            addToast("error", "Error", "Failed to delete selected service(s) from the lineup");
          });
      }

      this.handleDeleteActionModalClose();

      this.setState({ editingChildren: [] });
    }
  };

  getLogo = params => params.row.logos && params.row.logos.length > 0;

  getEditorialChannel = params => {
    const { editorialChannels } = this.props;
    return editorialChannels.find(ec => ec.id === (params?.service || params?.row?.service || {})?.channel_id);
  };

  getImportance = params => {
    const { importances } = this.props;

    const editorialChannel = this.getEditorialChannel(params);
    if (editorialChannel?.importance_id) {
      return importances.find(i => i.id === editorialChannel.importance_id);
    }
    return "";
  };

  renderId = (params, lineupHasParent) => {
    const { lineupsServicesByLineup, lineups } = this.props;
    if (params.row.empty) {
      return "";
    }
    if (lineupHasParent) {
      if (params.row.parent_id) {
        const lineupService = lineupsServicesByLineup[params.row.lineup_id].find(ls => ls.id === params.row.parent_id);
        const tooltipText = `This service is inherited from the parent lineup service "${lineupService?.service?.name}".
        Parent LCN(s)[${lineupService?.lcns?.join(", ")}]`;
        return (
          <Stack direction="row" alignItems="center" spacing={1} sx={{ width: "100%" }}>
            <Stack direction="row" sx={{ width: "42px" }}>
              <Tooltip title={tooltipText} placement="right-start">
                <Box className="familyLineup inheritedLineupLight">inherit</Box>
              </Tooltip>
              {params.row.overriddenServiceName
              && <Tooltip title={`Overrided parent service name ${params.row.overriddenServiceName}`} placement="right-start">
                <Box className="familyLineup overrideLineup">override</Box>
              </Tooltip>
              }
            </Stack>
            <span>{params.row.parent_id}<br />Child<br />id:{params.id}</span>
          </Stack>
        );
      }

      const currentLineup = lineups.find(l => l.id === params.row.lineup_id);
      const tooltipText = `This service is exported from the parent lineup: “${currentLineup?.locales?.en_GB?.name}”.`;
      return (
        params.row.isOrphan
          ? <Stack direction="row" alignItems="center" spacing={1} sx={{ height: "75px" }}>
            <Box sx={{ width: "42px" }}>
              {params.row.overriddenServiceName
              && <Tooltip title={`Overrided parent service name ${params.row.overriddenServiceName}`} placement="right-start">
                <Box className="familyLineup overrideLineup">override</Box>
              </Tooltip>
              }
            </Box>
            <span>{params.id}</span>
          </Stack>
          : <Stack direction="row" alignItems="center" spacing={1}>
            <Box sx={{ width: "42px" }}>
              <Tooltip title={tooltipText} placement="right-start">
                <Box className="familyLineup parentLineupLight">parent</Box>
              </Tooltip>
            </Box>
            <span>{params.id}</span>
          </Stack>
      );
    }
    return <span>{params.id}</span>;
  };

  renderLogo = params => {
    const { lineupsServicesByLineup } = this.props;
    let editorialChannel = null;

    if (params.row.empty) {
      return "";
    }
    if (params.row.parent_id) {
      const lineupService = lineupsServicesByLineup[params.row.lineup_id].find(ls => ls.id === params.row.parent_id);
      editorialChannel = this.getEditorialChannel({ ...params, row: lineupService });
    } else {
      editorialChannel = this.getEditorialChannel(params);
    }
    return <Stack justifyContent="center" className="item-logo"><img src={editorialChannel.logos[0].file_url} alt="logo" style={{ maxWidth: "100%" }} /></Stack>;
  };

  renderServiceName = params => {
    const { lineupsServicesByLineup } = this.props;

    if (params.row.empty) {
      return;
    }
    if (params.row.parent_id) {
      const lineupService = lineupsServicesByLineup[params.row.lineup_id].find(ls => ls.id === params.row.parent_id);
      return lineupService?.service?.name;
    }
    return params.row.service?.name;
  };

  renderEditorialChannel = params => {
    const { lineupsServicesByLineup } = this.props;

    if (params.row.empty) {
      return;
    }
    if (params.row.parent_id) {
      const lineupService = lineupsServicesByLineup[params.row.lineup_id].find(ls => ls.id === params.row.parent_id);
      const editorialChannel = this.getEditorialChannel(lineupService);
      return getObjectNameFromLocales(editorialChannel);
    }
    return getObjectNameFromLocales(this.getEditorialChannel(params));
  };

  renderGenre = params => {
    const { lineupsServicesByLineup } = this.props;

    if (params.row.empty) {
      return;
    }
    if (params.row.parent_id) {
      const lineupService = lineupsServicesByLineup[params.row.lineup_id].find(ls => ls.id === params.row.parent_id);
      if (lineupService) {
        return lineupService.genres ? getObjectNameFromLocales(lineupService.genres[0]) : "";
      }
    }
    return params.value?.length > 0 ? getObjectNameFromLocales(params.value[0]) : "";
  };

  renderLanguage = params => {
    const { lineupsServicesByLineup } = this.props;

    if (params.row.empty) {
      return;
    }
    if (params.row.parent_id) {
      const lineupService = lineupsServicesByLineup[params.row.lineup_id].find(ls => ls.id === params.row.parent_id);
      const editorialChannel = this.getEditorialChannel(lineupService);
      return editorialChannel?.audio_language;
    }
    return this.getEditorialChannel(params)?.audio_language;
  };

  renderImportance = params => {
    const { lineupsServicesByLineup } = this.props;

    if (params.row.empty) {
      return;
    }
    if (params.row.parent_id) {
      const lineupService = lineupsServicesByLineup[params.row.lineup_id].find(ls => ls.id === params.row.parent_id);
      const importance = this.getImportance(lineupService);
      return importance.name;
    }
    return this.getImportance(params).name;
  };

  renderMedia = params => {
    const { lineupsServicesByLineup } = this.props;

    if (params.row.empty) {
      return;
    }
    if (params.row.parent_id) {
      const lineupService = lineupsServicesByLineup[params.row.lineup_id].find(ls => ls.id === params.row.parent_id);
      const editorialChannel = this.getEditorialChannel(lineupService);
      return editorialChannel?.media_group;
    }
    return this.getEditorialChannel(params)?.media_group;
  };

  renderCountry = params => {
    const { lineupsServicesByLineup } = this.props;

    if (params.row.empty) {
      return;
    }
    if (params.row.parent_id) {
      const lineupService = lineupsServicesByLineup[params.row.lineup_id].find(ls => ls.id === params.row.parent_id);
      const editorialChannel = this.getEditorialChannel(lineupService);
      return editorialChannel?.country;
    }
    return this.getEditorialChannel(params)?.country;
  };

  renderActions = (params, lineupParams, lineupHasParent) => {
    if (lineupHasParent) {
      return (
        (params.row.empty
          && <ButtonGroup>
            <Tooltip title={"Add a service"} placement={"bottom"}>
              <Button
                sx={{ height: "35px", minWidth: "auto", width: "42px" }}
                onClick={() =>
                  this.openLineupServiceModal(
                    params,
                    lineupParams,
                    params.row.lcns[0].toString()
                  )
                }
              >
                <AddIcon fontSize={"small"} />
              </Button>
            </Tooltip>
            <Tooltip
              title={"Extend a parent service into this LCN"}
              placement={"bottom"}
            >
              <Button
                sx={{ height: "35px", minWidth: "auto", width: "42px" }}
                onClick={() =>
                  this.openAddInheritedLineupServiceModal(
                    lineupParams.id,
                    lineupParams.row.parent_id,
                    params.row.lcns[0].toString()
                  )
                }
                variant="outlined"
              >
                <KeyboardReturnIcon
                  sx={{ rotate: "-90deg" }}
                  fontSize={"small"}
                />
              </Button>
            </Tooltip>
          </ButtonGroup>
        )
        || ((params.row.parent_id || params.row.isOrphan)
          && <Tooltip title={"Edit the inherited service"} placement={"bottom"}>
            <Button
              sx={{ height: "35px", minWidth: "auto", width: "42px" }}
              onClick={() =>
                params.row.isOrphan ? this.openEditLineupServiceModal(params) : this.openEditInheritedLineupServiceModal(params, lineupParams)
              }
              variant="outlined"
            >
              <BorderColorIcon fontSize={"small"} />
            </Button>
          </Tooltip>
        )
          || <>
            <ButtonGroup>
              <Tooltip
                title={"Extend this parent service into another LCN"}
                placement={"bottom"}
              >
                <Button
                  sx={{ height: "35px", minWidth: "auto", width: "42px" }}
                  onClick={() =>
                    this.openAddInheritedLineupServiceModal(
                      lineupParams.id,
                      lineupParams.row.parent_id,
                      undefined,
                      params.id
                    )
                  }
                  variant="outlined"
                >
                  <OpenInNewIcon fontSize={"small"} />
                </Button>
              </Tooltip>
              <Tooltip title={"Add a new service"} placement={"bottom"}>
                <Button sx={{ height: "35px", minWidth: "auto", width: "42px" }}>
                  <AddIcon
                    onClick={() =>
                      this.openLineupServiceModal(
                        params,
                        lineupParams,
                        params.row.lcns[0].toString()
                      )
                    }
                    fontSize={"small"}
                  />
                </Button>
              </Tooltip>
              <Tooltip
                title={"Extend a different parent service into this LCN"}
                placement={"bottom"}
              >
                <Button
                  sx={{ height: "35px", minWidth: "auto", width: "42px" }}
                  onClick={() =>
                    this.openAddInheritedLineupServiceModal(
                      lineupParams.id,
                      lineupParams.row.parent_id,
                      params.row.lcns[0].toString()
                    )
                  }
                  variant="outlined"
                >
                  <KeyboardReturnIcon
                    sx={{ rotate: "-90deg" }}
                    fontSize={"small"}
                  />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </>

      );
    }
    return (
      (params.row.empty
        && <Tooltip title={"Add a service"} placement={"bottom"}>
          <Button
            sx={{ height: "35px", minWidth: "auto", width: "42px" }}
            onClick={() => this.openLineupServiceModal(params, lineupParams, params.row.lcns[0].toString())}
            variant="outlined">
            <AddIcon fontSize={"small"} />
          </Button>
        </Tooltip>
      )
      || <Tooltip title={"Edit the service"} placement={"bottom"}>
        <Button
          sx={{ height: "35px", minWidth: "auto", width: "42px" }}
          onClick={() => this.openEditLineupServiceModal(params)}
          variant="outlined">
          <BorderColorIcon fontSize={"small"} />
        </Button>
      </Tooltip>
    );
  };

  getExportLineupName(sortedLineups, lineupId) {
    const lineup = sortedLineups.find(ln => ln.id === lineupId);
    if (lineup) {
      const name = lineup.locales[lineup.default_language]?.name ?? lineup.updated_at.toString();
      return truncate(name);
    }
    return null;
  }

  renderLcns = params => {
    if (params.row.empty) {
      return params.row.lcns[0];
    }

    if (params.row.lcnsDetails) {
      return `${params.row.lcns.join(", ")} \n ${params.row.lcnsDetails}`;
    }

    return params.row.lcns.join(", ");
  };

  render() {
    const {
      filters,
      currentLineupId,
      parentLineupId,
      currentLineupServiceId,
      parentLineupServiceId,
      predefinedLCNS,
      orbitalPositionId,
      lineupModalOpened,
      lineupMetricsModalOpened,
      addLineupModalOpened,
      lineupServiceModalOpened,
      addInheritedLineupServiceModalOpened,
      editInheritedLineupServiceModalOpened,
      editLineupServiceModalOpened,
      editLineupServiceStep,
      menuAnchorEl,
      openedMenu,
      selectedServicesIds,
      isActionErrorModalOpened,
      actionErrorMessage,
      isMoveToActionModalOpened,
      currentLineupParams,
      isSortActionModalOpened,
      sortActionModalParams,
      openedLineupId,
      isDeleteActionModalOpened,
      exportOptions,
      exportLineupId,
      isScrollToLcnModalOpened,
      isLineupOpened
    } = this.state;

    const {
      beams,
      beamsLineups,
      lineups: untaggedLineups,
      lineupsServices,
      orbitalPositions,

      isLineupsLoading,
      isLineupsServicesLoading,
      isLineupsCreating,
      isLineupsExporting,
      isLineupByIdExporting,
      isLineupLogoCreating,
      isBeamsLineupsCreating,
      isEditorialChannelsLoading
    } = this.props;

    const menuOpen = Boolean(menuAnchorEl);

    const primaryFilters = [
      {
        text: "Orbital Position",
        name: "orbital_positions",
        values: orbitalPositions.map(op => ({ name: op.name, value: op.name }))
      },
      {
        text: "Exported",
        name: "isExported",
        values: [{ name: "Exported", value: false }, { name: "Not exported", value: true }]
      },
      {
        text: "Family",
        name: "family",
        values: [
          { name: "Parents", value: "parents" },
          { name: "Children", value: "children" },
          { name: "Orphans", value: "orphans" }
        ]
      }
    ];

    const tabsActions = [
      {
        name: "New lineup",
        variant: "contained",
        color: "secondary",
        display: true,
        action: () => this.openAddLineupModal()
      },
      {
        name: "Export Lineups" + (isLineupsExporting || isLineupByIdExporting ? " (loading...)" : ""),
        variant: "outlined",
        color: "primary",
        display: !exportOptions,
        action: () => this.setState({ exportOptions: true })
      },
      {
        name: "Export all lineups" + (isLineupsExporting || isLineupByIdExporting ? " (loading...)" : ""),
        variant: "outlined",
        color: "primary",
        display: exportOptions,
        action: () => this.exportCSV()
      },
    ];

    const isLoading = !!isLineupsLoading
      || !!isLineupsServicesLoading
      || !!isLineupsCreating
      || !!isBeamsLineupsCreating
      || !!isLineupLogoCreating;

    const generateLineupLogoForTitle = () => {
      const currentLineup = untaggedLineups.find(l => l.id === openedLineupId) || {};

      if (currentLineup.logos && currentLineup.logos.length && currentLineup.logos[0].file_url) {
        return (
          <div className="item-logo">
            <img src={currentLineup.logos[0].file_url} alt="Logo" width="50px" />
          </div>
        );
      }

      return "";
    };

    const headerActions = (lineupParams, lineupHasParent) =>
      <>
        <Button
          id="actions-button"
          sx={{
            height: "30px",
            borderColor: "primary.smokeBorder",
          }}
          variant="outlined"
          aria-controls={menuOpen && openedMenu === "actions-menu" ? "actions-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen && openedMenu === "actions-menu" ? "true" : undefined}
          disableElevation
          onClick={e => this.openMenu(e, "actions-menu")}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Actions
        </Button>
        <Menu
          sx={{ zIndex: "500" }}
          MenuListProps={{
            "aria-labelledby": "actions-button",
          }}
          id="actions-menu"
          anchorEl={menuAnchorEl}
          open={menuOpen && openedMenu === "actions-menu"}
          onClose={() => this.closeMenu()}
        >
          <MenuItem
            onClick={() => {
              this.closeMenu();
              this.openLineupServiceModal(lineupParams);
            }}
            disableRipple
          >
            <AddIcon />&nbsp;Add a service
          </MenuItem>
          { lineupHasParent && <MenuItem
            onClick={() => {
              this.closeMenu();
              this.openAddInheritedLineupServiceModal(
                lineupParams.id,
                lineupParams.row.parent_id,
              );
            }}
            disableRipple
          >
            <ArrowDownwardIcon />&nbsp;Extend a parent service
          </MenuItem> }
          <MenuItem
            onClick={() => {
              this.closeMenu();
              this.handleScrollToLcnModalOpen();
            }}
            disableRipple
          >
            <SvgIcon inheritViewBox={true} sx={{ color: "primary" }} component={IconScroll} />&nbsp;Scroll to a specific LCN
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              this.closeMenu();
              this.goUpSelectedServices(lineupParams, selectedServicesIds);
            }}
            disabled={selectedServicesIds.length < 1}
            disableRipple
          >
            Go up selected services (LCN)
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.closeMenu();
              this.goDownSelectedServices(lineupParams, selectedServicesIds);
            }}
            disabled={selectedServicesIds.length < 1}
            disableRipple
          >
            Go down selected services (LCN)
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.closeMenu();
              this.setCurrentLineupParams(lineupParams);
              this.handleMoveToActionModalOpen();
            }}
            disabled={selectedServicesIds.length < 1}
            disableRipple
          >
            Move selected services (LCN)
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.closeMenu();
              this.handleSortServices("mediaGroup", lineupParams.id, selectedServicesIds);
            }}
            disabled={selectedServicesIds.length < 1 || isEditorialChannelsLoading}
            disableRipple
          >
            Sort selected service alphabetically with the media group
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.closeMenu();
              this.handleSortServices("channelname", lineupParams.id, selectedServicesIds);
            }}
            disabled={selectedServicesIds.length < 1 || isEditorialChannelsLoading}
            disableRipple
          >
            Sort selected service alphabetically with the english channel name
          </MenuItem>
          <Divider />
          <MenuItem
            sx={{ color: "red" }}
            onClick={() => {
              this.closeMenu();
              this.handleDeleteServices(selectedServicesIds);
            }}
            disabled={selectedServicesIds.length < 1}
            disableRipple
          >
            Remove selected services from the lineup
          </MenuItem>
        </Menu>
      </>;

    const childrenTable = params => {
      const { lineupsServicesByLineup } = this.props;
      const { lcnToScrollTo, serviceFilters } = this.state;

      const currentLineupServices = lineupsServicesByLineup[params.id];
      const lineupParams = params;
      const lineupHasParent = !!params.row.parent_id;
      return (
        <div style={{ height: "calc(100vh - 360px)", minHeight: "160px", paddingLeft: "20px" }}>
          <DataTable
            sx={{
              ".MuiDataGrid-cell[data-field='id']": {
                padding: "0"
              }
            }}
            cols={[
              {
                headerName: "Id",
                field: "id",
                renderCell: params => this.renderId(params, lineupHasParent),
                flex: 2
              },
              {
                headerName: "",
                field: "lock",
                renderCell: params => !params.row.empty
                && <Stack direction={"row"} alignItems={"center"}>
                  <Checkbox checked={params.row.locked} onChange={e => this.toggleLockedLineupsService(e, params.row.id)}/>
                  {params.row.locked
                    && <LockIcon size="small" />
                  }
                </Stack>,
                flex: 1
              },
              {
                headerName: "LCN",
                field: "lcns",
                valueGetter: this.renderLcns,
                flex: 1
              },
              {
                headerName: "",
                field: "logo",
                valueGetter: this.getLogo,
                renderCell: params => {
                  try {
                    return this.renderLogo(params);
                  } catch (e) {
                    return !params.row.empty && <span style={{ color: "darkgray", fontStyle: "italic" }}>Data not found</span>;
                  }
                },
                flex: 1
              },
              {
                headerName: "Service Name",
                field: "service_name",
                valueGetter: this.renderServiceName,
                flex: 1
              },
              {
                headerName: "Editorial Channel",
                field: "editorial_channel",
                valueGetter: this.renderEditorialChannel,
                flex: 1
              },
              {
                headerName: "Genre(s)",
                field: "genres",
                valueGetter: this.renderGenre,
                flex: 1
              },
              {
                headerName: "Language",
                field: "language",
                valueGetter: this.renderLanguage,
                flex: 1
              },
              {
                headerName: "Importance",
                field: "importance",
                valueGetter: this.renderImportance,
                flex: 1
              },
              {
                headerName: "Media Group",
                field: "media_group",
                valueGetter: this.renderMedia,
                flex: 1
              },
              {
                headerName: "Country",
                field: "country",
                valueGetter: this.renderCountry,
                flex: 1
              },
              {
                renderHeader: () => headerActions(lineupParams, lineupHasParent),
                field: "actions",
                renderCell: params => this.renderActions(params, lineupParams, lineupHasParent),
                sortable: false,
                disableColumnMenu: true,
                flex: 2
              },
              {
                ...GRID_CHECKBOX_SELECTION_COL_DEF
              },
              {
                headerName: `(${selectedServicesIds.length})`,
                field: "selection_counter",
                flex: 1
              }
            ]}
            data={currentLineupServices}
            headerHeight={40}
            hideFooter
            disableRowClick
            checkboxSelection
            onRowSelectionModelChange={this.setSelectedServicesIds}
            // Prevent moving parent lineup services in child lineups
            isRowSelectable={params => !params.row.empty && ((lineupHasParent && params.row.parent_id) || !lineupHasParent || params.row.isOrphan)}
            rowHeight={76}
            lcnToScrollTo={lcnToScrollTo}
            setLcnToScrollTo={this.setLcnToScrollTo}
            filters={serviceFilters}
          />
        </div>
      );
    };

    const sortedLineups = [...untaggedLineups].sort((a, b) => {
      const nameA = a.locales[a.default_language]?.name || "";
      const nameB = b.locales[b.default_language]?.name || "";

      return nameA.localeCompare(nameB);
    });

    return (
      <div id="lineups" className="page-content">
        {isLoading && <FullLoader />}

        {!isLoading
          && <>
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
              {isLineupOpened
                ? <FiltersSearchField
                  searchFilterField="service_name"
                  searchFilterLabel="Service Name"
                  onFiltersChange={this.setServiceFilters}
                />
                : <FiltersInputs
                  primaryFilters={primaryFilters}
                  onFiltersChange={this.setFilters}
                />
              }
              {!!tabsActions.length
                && <Stack direction="row" alignItems="center" sx={{ mb: 2 }} gap={1}>
                  {tabsActions.map((ta, idx) =>
                    <Button
                      sx={{ display: !ta.display && "none", minWidth: "fit-content" }}
                      key={idx}
                      variant={ta.variant}
                      color={ta.color}
                      onClick={ta.action}>
                      {ta.name}
                    </Button>
                  )}
                  <FormControl fullWidth sx={{ display: !exportOptions ? "none" : "flex", flexDirection: "row", gap: 1, ml: 4 }}>
                    <InputLabel id="demo-simple-select-label">Select a lineup</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={exportLineupId}
                      label="Select a lineup"
                      onChange={e => {
                        this.setState({
                          exportLineupId: e.target.value
                        });
                      }}
                    >
                      <MenuItem value={-1}>Select a lineup</MenuItem>
                      {
                        sortedLineups.map(lineup =>
                          <MenuItem value={lineup.id} key={lineup.id}>{lineup.locales[lineup.default_language]?.name || lineup.updated_at}</MenuItem>
                        )
                      }
                    </Select>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={exportLineupId === -1}
                      onClick={() => this.exportCSVById()}
                    >
                      Export Lineup {this.getExportLineupName(sortedLineups, exportLineupId)}
                    </Button>
                  </FormControl>
                </Stack>
              }
            </Stack>

            <div style={{ height: "calc(100vh - 180px)", minHeight: "160px" }}>
              <DataTable
                sx={{
                  ".MuiDataGrid-cell[data-field='childParent']": {
                    padding: "0"
                  }
                }}
                cols={[
                  {
                    headerName: " ",
                    field: "childParent",
                    renderCell: params => {
                      const isParent = untaggedLineups.find(l => l.parent_id === params.id);

                      if (isParent) {
                        return <div className="familyLineup parentLineup">parent</div>;
                      }

                      if (params.row.parent_id) {
                        // === isChildren
                        return <div className="familyLineup childLineup">child</div>;
                      }

                      return <div className="familyLineup orhpanLineup">lineup</div>;
                    },
                    flex: 1
                  },
                  {
                    headerName: "",
                    field: "edit",
                    renderCell: params => <EditIcon fontSize="small" />,
                    flex: 1
                  },
                  {
                    headerName: " ",
                    field: "logo",
                    renderCell: params => {
                      if (!params.row.logos || !params.row.logos.length || !params.row.logos[0].file_url) {
                        return "";
                      }

                      return <Stack justifyContent="center" className="item-logo"><img src={params.row.logos[0].file_url} alt="" style={{ maxWith: "100%" }} /></Stack>;
                    },
                    flex: 1
                  },
                  {
                    headerName: "Lineups",
                    field: "name",
                    valueGetter: params => getObjectNameFromLocales(params.row),
                    flex: 2,
                    sortable: true,
                    searchable: true
                  },
                  {
                    headerName: "Parent",
                    field: "parent",
                    valueGetter: params => {
                      if (!params.row.parent_id) {
                        return "-";
                      }

                      const parent = untaggedLineups.find(l => l.id === params.row.parent_id);

                      if (!parent) {
                        return "-";
                      }

                      return getObjectNameFromLocales(parent);
                    },
                    flex: 2
                  },
                  {
                    headerName: "Exported?",
                    field: "isExported",
                    valueGetter: params => !!untaggedLineups.find(l => l.parent_id === params.id),
                    renderCell: params => params.value ? <FontAwesomeIcon className="red-icon" icon={faTimes} /> : <FontAwesomeIcon className="green-icon" icon={faCheck} />,
                    flex: 1,
                    filterOperators: customGridOperators
                  },
                  {
                    headerName: "Parent Services",
                    field: "parentServices",
                    valueGetter: params => {
                      if (!params.row.parent_id) {
                        return "-";
                      }

                      const currentLineupServices = lineupsServices.filter(ls => ls.lineup_id === params.id);
                      const parentLineupServices = lineupsServices.filter(ls => ls.lineup_id === params.row.parent_id);

                      return parentLineupServices.reduce((acc, parentLs) => {
                        let shouldCount = false;

                        parentLs.lcns.forEach(lcn => {
                          if (!currentLineupServices.find(ls => ls.lcns.indexOf(lcn) !== -1)) {
                            shouldCount = true;
                          }
                        });

                        return shouldCount ? acc + 1 : acc;
                      }, 0);
                    },
                    flex: 1
                  },
                  {
                    headerName: "Lineup Services",
                    field: "lineupServices",
                    valueGetter: params => {
                      const childLS = lineupsServices.filter(ls => ls.lineup_id === params.id);

                      return childLS.length;
                    },
                    flex: 1
                  },
                  {
                    headerName: "Orbital Positions",
                    field: "orbital_positions",
                    valueGetter: params => {
                      const currentBeamsLineups = beamsLineups.filter(bl => bl.lineup_id === params.id);
                      const beamIds = currentBeamsLineups.map(bl => bl.beam_id);
                      const currentBeams = beams.filter(b => beamIds.indexOf(b.id) !== -1);
                      const orbitalPositionsIds = currentBeams.map(b => b.orbital_position_id);
                      const currentOrbitalPositions = orbitalPositions.filter(op => orbitalPositionsIds.indexOf(op.id) !== -1);

                      return currentOrbitalPositions[0]?.name;
                    },
                    renderCell: params => {
                      const currentBeamsLineups = beamsLineups.filter(bl => bl.lineup_id === params.id);
                      const beamIds = currentBeamsLineups.map(bl => bl.beam_id);
                      const currentBeams = beams.filter(b => beamIds.indexOf(b.id) !== -1);
                      const orbitalPositionsIds = currentBeams.map(b => b.orbital_position_id);
                      const currentOrbitalPositions = orbitalPositions.filter(op => orbitalPositionsIds.indexOf(op.id) !== -1);

                      if (currentOrbitalPositions.length === 0) {
                        return <FontAwesomeIcon icon={faTimes} />;
                      }

                      return currentOrbitalPositions.map(op => `${op.name} (Beam ${currentBeams.map(b => b.name).join(", ")})`).join(", ");
                    },
                    flex: 2,
                    searchable: true,
                    filterOperators: customGridOperators
                  },
                  {
                    headerName: "Modified",
                    field: "updated_at",
                    flex: 2,
                    sortable: true
                  },
                  {
                    headerName: "",
                    field: "info",
                    alignItems: "center",
                    renderCell: () => <FontAwesomeIcon icon={faInfoCircle} />,
                    onClick: (e, item) => {
                      e.preventDefault();
                      e.stopPropagation();

                      this.openLineupMetricsModal(e, item.id);
                    },
                    flex: 0
                  },
                  {
                    headerName: "",
                    field: "see_childs",
                    renderCell: () => <Box sx={{ cursor: "pointer", display: "flex", justifyContent: "center", width: "100%", height: "100%", alignItems: "center" }}>
                      <FontAwesomeIcon icon={this.state.isLineupOpened ? faMinus : faPlus} size="lg"/>
                    </Box>
                  },
                  // Hidden field only required for filtering
                  {
                    headerName: "Family",
                    field: "family",
                    valueGetter: params => {
                      const isParent = untaggedLineups.find(l => l.parent_id === params.id);

                      if (isParent) {
                        return "parents";
                      }

                      if (params.row.parent_id) {
                        return "children";
                      }

                      return "orphans";
                    },
                    filterOperators: customGridOperators
                  }
                ]}
                data={openedLineupId ? untaggedLineups.filter(l => l.id === openedLineupId) : untaggedLineups}
                filters={filters}
                disableRowClick
                disableOnCellClick={false}
                onCellClick={params => this.handleCellClick(params)}
                getDetailPanelContent={params => childrenTable(params)}
                toggleDetailDisabledRow={["edit", "info"]}
                rowHeight={75}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "name", sort: "asc" }]
                  },
                  pagination: { paginationModel: { pageSize: 50 } }
                }}
                invisibleCols={{
                  family: false,
                  __detail_panel_toggle__: false
                }}
              />
            </div>

            <AddLineupModal
              modalOpened={addLineupModalOpened}
              closeAddLineupModal={this.closeAddLineupModal}
            />

            <EditLineupModal
              currentLineupId={currentLineupId}
              modalOpened={lineupModalOpened}
              closeEditLineupModal={this.closeLineupModal}
            />

            <LineupMetricsModal
              currentLineupId={currentLineupId}
              modalOpened={lineupMetricsModalOpened}
              closeLineupMetricsModal={this.closeLineupMetricsModal}
            />

            <AddLineupServiceModal
              modalOpened={lineupServiceModalOpened}
              predefinedLCNS={predefinedLCNS}
              currentLineupId={currentLineupId}
              orbitalPositionId={orbitalPositionId}
              closeLineupServiceModal={this.closeLineupServiceModal}
              switchToEditMode={this.openEditLineupServiceModal}
            />

            <AddInheritedLineupServiceModal
              modalOpened={addInheritedLineupServiceModalOpened}
              predefinedLCNS={predefinedLCNS}
              currentLineupId={currentLineupId}
              parentLineupId={parentLineupId}
              parentLineupServiceId={parentLineupServiceId}
              closeLineupServiceModal={this.closeAddInheritedLineupServiceModal}
            />

            <EditInheritedLineupServiceModal
              modalOpened={editInheritedLineupServiceModalOpened}
              currentLineupId={currentLineupId}
              parentLineupId={parentLineupId}
              currentLineupServiceId={currentLineupServiceId}
              closeLineupServiceModal={this.closeEditInheritedLineupServiceModal}
            />

            <EditLineupServiceModal
              modalOpened={editLineupServiceModalOpened}
              currentLineupId={currentLineupId}
              currentLineupServiceId={currentLineupServiceId}
              orbitalPositionId={orbitalPositionId}
              closeLineupServiceModal={this.closeEditLineupServiceModal}
              editLineupServiceStep={editLineupServiceStep}
            />

            <LineupServiceMoveToActionModal
              modalOpened={isMoveToActionModalOpened}
              onClose={this.handleMoveToActionModalClose}
              moveSomewhereSelectedServicesTest={this.moveSomewhereSelectedServices}
              currentLineupParams={currentLineupParams}
              selectedServicesIds={selectedServicesIds}
            />

            <ScrollToLcnModal
              modalOpened={isScrollToLcnModalOpened}
              onClose={this.handleScrollToLcnModalClose}
              setLcnToScrollTo={this.setLcnToScrollTo}
            />

            <Dialog
              open={isActionErrorModalOpened}
              onClose={this.handleActionErrorModalClose}
            >
              <DialogTitle>
                {actionErrorMessage.title}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {actionErrorMessage.contentText}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="secondary" onClick={this.handleActionErrorModalClose} autoFocus>
                  Back
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={isSortActionModalOpened}
            >
              <DialogTitle>Sort selected service(s)</DialogTitle>
              <DialogContent dividers>
                <Typography>
                Are you sure to alphabetically sort selected services by {sortActionModalParams?.sortType === "channelname" ? "channel names" : "media group names"}?
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleSortActionModalClose}>
                  Cancel
                </Button>
                <Button onClick={() => this.sortServices(sortActionModalParams)}>Confirm</Button>
              </DialogActions>
            </Dialog>

            {/* // Delete channel from lineup modal */}
            <Dialog
              open={isDeleteActionModalOpened}
            >
              <DialogTitle>Remove selected service(s)</DialogTitle>
              <DialogContent dividers>
                <Typography>
                  Are you sure to delete selected service(s) from this lineup ?
                  This will delete the service(s) and all the same children with different LCN(s)
                  (if the service is inherited from a parent, it won't delete the parent service).
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleDeleteActionModalClose}>
                  Cancel
                </Button>
                <Button onClick={() => this.deleteChannelsFromLineup(selectedServicesIds)}>Confirm</Button>
              </DialogActions>
            </Dialog>
          </>
        }
      </div>
    );
  }
}

Lineups.defaultProps = {
  beams: [],
  beamsLineups: [],
  editorialChannels: [],
  lineupsServices: [],
  orbitalPositions: []
};

Lineups.propTypes = {
  beams: PropTypes.arrayOf(PropTypes.object),
  beamsLineups: PropTypes.arrayOf(PropTypes.object),
  editorialChannels: PropTypes.arrayOf(PropTypes.object),
  services: PropTypes.arrayOf(PropTypes.object).isRequired,
  lineups: PropTypes.arrayOf(PropTypes.object).isRequired,
  lineupsServices: PropTypes.arrayOf(PropTypes.object),
  lineupsServicesByLineup: PropTypes.object,
  orbitalPositions: PropTypes.arrayOf(PropTypes.object),
  importances: PropTypes.arrayOf(PropTypes.object).isRequired,

  isLoadingServices: PropTypes.bool.isRequired,
  isLineupsLoading: PropTypes.bool.isRequired,
  isLineupsServicesLoading: PropTypes.bool.isRequired,
  isLineupsCreating: PropTypes.bool.isRequired,
  isLineupLogoCreating: PropTypes.bool.isRequired,
  isBeamsLineupsCreating: PropTypes.bool.isRequired,
  isLineupsServicesCreating: PropTypes.bool.isRequired,
  isLineupsServicesDeleting: PropTypes.bool.isRequired,
  isLineupsExporting: PropTypes.bool.isRequired,
  isLineupByIdExporting: PropTypes.bool.isRequired,
  isServiceUpdating: PropTypes.bool.isRequired,
  isEditorialChannelsLoading: PropTypes.bool.isRequired,

  indexLineups: PropTypes.func.isRequired,
  indexServices: PropTypes.func.isRequired,
  updateTechChannel: PropTypes.func.isRequired,
  updateManyTechChannels: PropTypes.func.isRequired,
  updateEditorialChannel: PropTypes.func.isRequired,
  updateManyEditorialChannels: PropTypes.func.isRequired,
  deleteService: PropTypes.func.isRequired,
  deleteManyServices: PropTypes.func.isRequired,
  indexLineupsFilters: PropTypes.func.isRequired,
  indexEditorialChannels: PropTypes.func.isRequired,
  indexLineupsServices: PropTypes.func.isRequired,
  updateLineupsService: PropTypes.func.isRequired,
  modifyManyLineupsServices: PropTypes.func.isRequired,
  deleteManyLineupsServices: PropTypes.func.isRequired,
  deleteLineupsService: PropTypes.func.isRequired,
  exportLineups: PropTypes.func.isRequired,
  exportLineupById: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    beams: listBeamsSelector(state),
    beamsLineups: listBeamsLineupsSelector(state),
    editorialChannels: listEditorialChannelsSelector(state),
    services: listServicesSelector(state),
    lineups: listLineupsSelector(state),
    lineupsServices: listLineupsServicesSelector(state),
    lineupsServicesByLineup: listLineupsServicesByLineupSelector(state),
    orbitalPositions: listOrbitalPositionsSelector(state),
    importances: listImportancesSelector(state),

    isLoadingServices: isLoadingSelector(state, indexServicesAction.toString()),
    isLineupsLoading: isLoadingSelector(state, indexLineupsAction.toString()),
    isLineupsCreating: isLoadingSelector(state, "API_CREATE_LINEUP"),
    isLineupLogoCreating: isLoadingSelector(state, "API_CREATE_LINEUP_LOGO"),
    isLineupsServicesLoading: isLoadingSelector(state, indexLineupsServicesAction.toString()),
    isBeamsLineupsCreating: isLoadingSelector(state, "API_CREATE_BEAMS_LINEUPS"),
    isEditorialChannelsLoading: isLoadingSelector(state, indexEditorialChannelsAction.toString()),

    isLineupsServicesCreating: isLoadingSelector(state, "API_CREATE_LINEUPS_SERVICE") || isLoadingSelector(state, "API_CREATE_LINEUPS_SERVICES_WITH_SERVICE_AND_GENRE"),
    isLineupsServicesDeleting: isLoadingSelector(state, "API_DELETE_LINEUPS_SERVICE"),
    isLineupsExporting: isLoadingSelector(state, exportLineupsAction.toString()),
    isLineupByIdExporting: isLoadingSelector(state, exportLineupByIdAction.toString()),
    isServiceUpdating: isLoadingSelector(state, "API_UPDATE_SERVICE") || isLoadingSelector(state, "API_ASSOCIATE_TC_TO_SERVICE") || isLoadingSelector(state, "API_DISASSOCIATE_TC_TO_SERVICE")
  };
}

const mapDispatchToProps = {
  indexEditorialChannels: indexEditorialChannelsAction,
  updateTechChannel: updateTechChannelAction,
  updateManyTechChannels: updateManyTechChannelsAction,
  updateEditorialChannel: updateEditorialChannelAction,
  updateManyEditorialChannels: updateManyEditorialChannelsAction,
  deleteService: deleteServiceAction,
  indexServices: indexServicesAction,
  deleteManyServices: removeManyServicesAction,
  indexLineups: indexLineupsAction,
  indexLineupsFilters: indexLineupsFiltersAction,
  indexLineupsServices: indexLineupsServicesAction,
  updateLineupsService: updateLineupsServiceAction,
  modifyManyLineupsServices: modifyManyLineupsServicesAction,
  deleteManyLineupsServices: removeManyLineupsServicesAction,
  deleteLineupsService: deleteLineupsServiceAction,
  exportLineups: exportLineupsAction,
  exportLineupById: exportLineupByIdAction,
  addToast: addToastAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Lineups);
