import { listBeamsFromOrbitalPosition } from "./beams";
import { listTranspondersFromBeams } from "./transponders";

export const listTechChannels = state => state.techChannels.allTechChannels;

export const listTechChannelsFromOrbitalPosition = (state, orbitalPositionId) => {
  const beams = listBeamsFromOrbitalPosition(state, orbitalPositionId);
  const transponders = listTranspondersFromBeams(state, beams.map(b => b.id));
  const transpondersIds = transponders.map(t => t.id);
  const techChannels = state.techChannels.allTechChannels.filter(tc => transpondersIds.indexOf(tc.transponder_id) !== -1);

  return techChannels;
};

export const showTechChannel = (state, techChannelId) => state.techChannels.allTechChannels.find(tc => tc.id === techChannelId);

export const getTotalCount = state => state.techChannels.totalCount;

export const listTechChannelsFilters = state => state.techChannels.filters;
