import { handleActions } from "redux-actions";

const initialState = {
  actions: []
};

const loaderReducer = handleActions(
  {
    ADD_LOADER: (state, action) => {
      if (state.actions.indexOf(action.payload.actionName) === -1) {
        state.actions.push(action.payload.actionName);
      }

      return { ...state };
    },
    REMOVE_LOADER: (state, action) => {
      const actions = state.actions.filter(e => e !== action.payload.actionName);

      return {
        ...state,
        actions
      };
    }
  },
  initialState
);

export default loaderReducer;
