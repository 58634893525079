import { createSelector } from "reselect";

import { selectCurrentLineupsService } from "../utils/lineupsService";

// TODO Technical debt, find better way to use already existing listLineups selector
const listLineups = state => state.lineups.allLineups;

export const listLineupsServices = state => state.lineupsServices.allLineupsServices;

export const listLineupsServicesByLineup = createSelector([listLineups, listLineupsServices, state => state], (allLineups, allLineupsServices, state) => allLineups.reduce((acc, curr) => {
  const parentLineupsServices = curr.parent_id ? selectCurrentLineupsService(allLineupsServices, curr.parent_id) : [];
  const currentLineupsServices = selectCurrentLineupsService(allLineupsServices, curr.id);

  const lcns = parentLineupsServices.concat(currentLineupsServices).map(l => l.lcns).flat();

  // +1 to count to LCN 0 : create one entry for empty lineup
  const numberOfLCNToAppear = lcns.length > 0 ? Math.max(...lcns) + 1 : 1;

  const emptyServices = [...Array(numberOfLCNToAppear).keys()]
    .map(id => ({ id: "empty-" + id, lcns: [id], empty: true, lineup: curr }));

  acc[curr.id] = emptyServices.map((fs, idx) => {
    // Handle services overriding parent service
    const currentParentService = parentLineupsServices.find(ls => ls.lcns.includes(fs.lcns[0]));
    const currentLineupsService = currentLineupsServices.find(ls => ls.lcns.includes(fs.lcns[0]));

    let currentServiceClone = null;

    // Handle services overriding parent service
    if (currentParentService && currentLineupsService) {
      currentServiceClone = {
        ...currentLineupsService,
        overriddenServiceName: currentParentService.service.name
      };
    } else {
      currentServiceClone = currentLineupsService ? { ...currentLineupsService } : currentParentService ? { ...currentParentService } : null;
    }

    if (!currentServiceClone) {
      return fs;
    }

    if (currentServiceClone.lcns.length > 1) {
      currentServiceClone.id += `-${idx}`;
    }

    // Ignore parent lineups services process
    if (!fs.lineup.parent_id) {
      return { ...currentServiceClone, idx };
    }

    // Only process child lineup services
    if (currentServiceClone.parent_id) {
      // Determine parent lcns of children services
      const parentLineupService = parentLineupsServices.find(ls => ls.id === currentServiceClone.parent_id);
      currentServiceClone["lcnsDetails"] = `Parent : ${parentLineupService?.lcns[0]}`;
    } else {
      // Determine children lcns of parent services
      const childrenLcns = currentLineupsServices.reduce((acc, curr) => {
        if (curr.parent_id === currentServiceClone.id) {
          acc = acc.concat(curr.lcns);
        }

        return acc;
      }, []);

      if (childrenLcns.length > 0) {
        currentServiceClone["lcnsDetails"] = `Children : ${childrenLcns.join(", ")}`;
      }

      // Check if the service belongs to parent lineup
      currentServiceClone["isOrphan"] = !parentLineupsServices.find(ls => ls.id === currentServiceClone.id);
    }

    return { ...currentServiceClone, idx };
  });

  return acc;
}, {}));

export const listLineupsServicesByLineupId = (state, lineupId) => state.lineupsServices.allLineupsServices.filter(ls => ls.lineup_id === lineupId);

export const listLineupsServicesByOrbitalPosition = (state, orbitalPositionId) => state.lineupsServices.allLineupsServices.filter(ls => ls.service && parseInt(ls.service.orbital_position_id, 10) === parseInt(orbitalPositionId, 10));

export const listLineupsServicesByTechChannelId = (state, techChannelId) => state.lineupsServices.allLineupsServices.filter(ls => ls.service && ls.service.tech_channels && ls.service.tech_channels.length > 0 && !!ls.service.tech_channels.find(tc => tc.id === techChannelId));

export const showLineupsService = (state, lineupsServiceId) => state.lineupsServices.allLineupsServices.find(ls => ls.id === lineupsServiceId);

export const getTotalCount = state => state.lineupsServices.totalCount;

export const listLineupsServicesFilters = state => state.lineupsServices.filters;
