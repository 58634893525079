import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  DataGridPro,
  gridExpandedRowCountSelector,
  GridLogicOperator,
  useGridApiRef
} from "@mui/x-data-grid-pro";

function DataTable({
  cols,
  headerHeight,
  invisibleCols,
  data,
  rowHeight,
  getDetailPanelContent,
  checkboxSelection,
  isRowSelectable,
  filters,
  pagination,
  hideFooter,
  onRowClick,
  disableRowClick,
  onCellClick,
  disableOnCellClick,
  toggleDetailDisabledRow,
  onRowSelectionModelChange,
  enableAutoHeight,
  initialState,
  sx,
  lcnToScrollTo,
  setLcnToScrollTo,
  toReset,
  selectionToReset
}) {
  const [viewHeight, setViewHeight] = useState(document.documentElement.clientHeight);
  const [visibleRows, setVisibleRows] = useState(0);

  useEffect(() => {
    window.addEventListener("resize", () => setViewHeight(document.documentElement.clientHeight));
  }, []);

  const apiRef = useGridApiRef();

  useEffect(() => {
    apiRef.current.subscribeEvent("stateChange", () => {
      if (apiRef.current !== null) {
        const count = gridExpandedRowCountSelector(apiRef.current.state, apiRef.current.instanceId);
        setVisibleRows(count);
      }
    });
  }, [apiRef]);

  useEffect(() => {
    if (lcnToScrollTo > -1 && apiRef && setLcnToScrollTo) {
      apiRef.current.scrollToIndexes({ rowIndex: lcnToScrollTo, colIndex: 2 });
      setLcnToScrollTo(-1);
    }
  }, [lcnToScrollTo]);

  useEffect(() => {
    if (toReset) {
      apiRef.current.selectRows(selectionToReset, false);
    }
  }, [toReset]);

  const handleRowClick = params => {
    if (disableRowClick) {
      return;
    }
    onRowClick(params);
  };

  const handleCellClick = params => {
    if (disableOnCellClick) {
      return;
    }
    const toggleIsDisabled = toggleDetailDisabledRow.includes(params.field);
    onCellClick(params, toggleIsDisabled);

    if (!toggleIsDisabled && params.field !== "__detail_panel_toggle__" && params.field !== "__check__") {
      apiRef.current.toggleDetailPanel(params.id);
    }
  };

  const setSelectedDataIds = newSelection => {
    onRowSelectionModelChange(newSelection);
  };

  const isAutoHeightEnabled = enableAutoHeight || (visibleRows * 52) <= (viewHeight - 340);

  return <DataGridPro
    sx={sx}
    columns={cols}
    columnHeaderHeight={headerHeight}
    columnVisibilityModel={invisibleCols}
    rows={data}
    getRowHeight={() => "auto"}
    onRowClick={handleRowClick}
    onCellClick={handleCellClick}
    onRowSelectionModelChange={setSelectedDataIds}
    filterModel={{ items: filters, logicOperator: GridLogicOperator.And }}
    checkboxSelection={checkboxSelection}
    isRowSelectable={isRowSelectable}
    autoHeight={isAutoHeightEnabled}
    disableColumnFilter
    apiRef={apiRef}
    pagination={pagination}
    hideFooter={hideFooter}
    getDetailPanelContent={getDetailPanelContent}
    rowHeight={rowHeight}
    getDetailPanelHeight={() => "auto"}
    initialState={initialState}
  />;
}

DataTable.defaultProps = {
  cols: [],
  headerHeight: 52,
  invisibleCols: {},
  data: [],
  rowHeight: 52,
  getDetailPanelContent: null,

  filters: [],

  pagination: false,
  hideFooter: false,
  enableAutoHeight: false,

  checkboxSelection: false,
  isRowSelectable: null,

  onRowClick: null,
  disableRowClick: false,
  onCellClick: null,
  disableOnCellClick: true,
  onRowSelectionModelChange: null,
  toggleDetailDisabledRow: [],
  initialState: null,
  sx: {},
  lcnToScrollTo: -1,
  setLcnToScrollTo: null,
  toReset: false,
  selectionToReset: []
};

DataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  rowHeight: PropTypes.number,
  cols: PropTypes.arrayOf(PropTypes.object),
  headerHeight: PropTypes.number,
  invisibleCols: PropTypes.object,
  getDetailPanelContent: PropTypes.func,

  filters: PropTypes.arrayOf(PropTypes.object),

  pagination: PropTypes.bool,
  hideFooter: PropTypes.bool,
  enableAutoHeight: PropTypes.bool,

  checkboxSelection: PropTypes.bool,
  isRowSelectable: PropTypes.func,

  onRowClick: PropTypes.func,
  disableRowClick: PropTypes.bool,
  onCellClick: PropTypes.func,
  disableOnCellClick: PropTypes.bool,
  onRowSelectionModelChange: PropTypes.func,
  toggleDetailDisabledRow: PropTypes.arrayOf(PropTypes.string),
  initialState: PropTypes.object,

  sx: PropTypes.object,

  lcnToScrollTo: PropTypes.number,
  setLcnToScrollTo: PropTypes.func,
  toReset: PropTypes.bool,
  selectionToReset: PropTypes.arrayOf(PropTypes.number)
};

export default DataTable;
