import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Button, Menu, MenuItem, Stack } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckIcon from "@mui/icons-material/Check";
import {
  indexTransponders as indexTranspondersAction,
  createTransponder as createTransponderAction,
  indexTranspondersFilters as indexTranspondersFiltersAction,
  updateTransponder as updateTransponderAction,
  deleteTransponder as deleteTransponderAction
} from "../../actions/transponders";
import {
  listTransponders as listTranspondersSelector,
  listTranspondersFilters as listTranspondersFiltersSelector,
} from "../../selectors/transponders";
import {
  listOrbitalPositions as listOrbitalPositionsSelector
} from "../../selectors/orbital_positions";
import {
  listBeams as listBeamsSelector
} from "../../selectors/beams";
import { isLoading as isLoadingSelector } from "../../selectors/loaders";

import EditTransponderModal from "../TranspondersModal/EditTransponderModal";
import RemoveTransponderModal from "../TranspondersModal/RemoveTransponderModal";

import "./Transponders.css";
import FiltersInputs from "../FiltersInputs";
import DataTable from "../DataTable";

import { customGridOperators } from "../../utils/constant";

const polarizationName = pol => {
  switch (pol) {
    case "lh":
      return "linear - horizontal";

    case "lv":
      return "linear - vertical";

    case "cl":
      return "circular - left";

    case "cr":
      return "circular - right";

    default:
      return "";
  }
};

const rollOffToString = floatRollOff => {
  switch (floatRollOff) {
    case 0.2:
      return "0.20";

    default:
      return (floatRollOff || "").toString();
  }
};

class Transponders extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {

      currentTransponder: null,

      filters: [],

      removeTransponderOpened: false,
      selectedTranspondersIds: [],

      menuAnchorEl: null,
      openedMenu: null,
      modalOpened: false
    };
  }

  componentDidMount() {
    const { indexTranspondersFilters } = this.props;
    indexTranspondersFilters();
  }

  setSelectedTranspondersIds = newSelection => {
    this.setState({
      selectedTranspondersIds: newSelection,
    });
  };

  /* Menus methods */

  setMenuAnchorEl = target => {
    this.setState({
      menuAnchorEl: target
    });
  };

  setOpenedMenu = menuId => {
    this.setState({
      openedMenu: menuId
    });
  };

  openMenu = (event, menuId) => {
    this.setMenuAnchorEl(event.currentTarget);
    this.setOpenedMenu(menuId);
  };

  closeMenu = menuId => {
    this.setMenuAnchorEl(null);
    this.setOpenedMenu(menuId);
  };

  /* filter methods */
  setFilters = newFilters => {
    this.setState({
      filters: newFilters
    });
  };

  /* Edit Modal */

  openEditModal = currentTransponder => {
    this.setState({
      currentTransponder,
      modalOpened: true
    });
  };

  closeEditModal = () => {
    this.setState({
      currentTransponder: null,
      modalOpened: false
    });
  };

  /* Remove Modal */

  deleteTransponder = () => {
    this.setState({
      removeTransponderOpened: true
    });
  };

  closeRemoveTransponderModal = () => {
    this.setState({
      removeTransponderOpened: false
    });
  };

  /* Modal */

  render() {
    const {
      filters,
      currentTransponder,
      selectedTranspondersIds,
      removeTransponderOpened,
      modalOpened,
      menuAnchorEl,
      openedMenu
    } = this.state;

    const {
      orbitalPositions,
      beams,
      transponders,
      transpondersFilters
    } = this.props;

    const menuOpen = Boolean(menuAnchorEl);

    const grouppedFilters = filters.reduce((acc, filter) => {
      const existingFilter = acc.find(t => t.filterName === filter.filterName);

      if (!existingFilter) {
        acc.push({
          filterName: filter.filterName,
          values: [filter.value]
        });
      } else {
        existingFilter.values.push(filter.value);
      }

      return acc;
    }, []);

    const primaryFilters = [
      {
        text: "HomeTS",
        name: "homets",
        values: [{ name: "On HomeTS", value: true }, { name: "Not on HomeTS", value: false }]
      },
      {
        text: "Polarization",
        name: "polarization_name",
        values: ((transpondersFilters.find(f => f.name === "polarization") || {}).values || []).map(v => ({ name: v, value: polarizationName(v) }))
      },
      {
        text: "Modulation Type",
        name: "modulation_type",
        values: ((transpondersFilters.find(f => f.name === "modulation_type") || {}).values || []).map(v => ({ name: v, value: v }))
      },
      {
        text: "Modulation System",
        name: "modulation_system",
        values: ((transpondersFilters.find(f => f.name === "modulation_system") || {}).values || []).map(v => ({ name: v, value: v }))
      },
      {
        text: "FEC",
        name: "fec_inner",
        values: ((transpondersFilters.find(f => f.name === "fec_inner") || {}).values || []).map(v => ({ name: v, value: v }))
      }
    ];

    if (!grouppedFilters.find(t => t.filterName === "orbital_position_id")) {
      primaryFilters.unshift({
        text: "Beam",
        name: "beam",
        values: beams.filter(b => {
          const orbitalPositionFilter = grouppedFilters.find(t => t.filterName === "orbital_position_id");

          if (!orbitalPositionFilter) {
            return true;
          }

          return orbitalPositionFilter.values.indexOf(b.orbital_position_id) !== -1;
        })
          .map(b => {
            const orbitalPosition = orbitalPositions.find(op => op.id === b.orbital_position_id);

            if (!orbitalPosition) {
              return { name: b.name, value: b.name };
            }

            return { name: `${orbitalPosition.name} - ${b.name}`, value: `${orbitalPosition.name} - ${b.name}` };
          })
          .sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }

            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }

            return 0;
          })
      });
    }

    if (!grouppedFilters.find(t => t.filterName === "beam_id")) {
      primaryFilters.unshift({
        text: "Orbital Position",
        name: "orbital_position",
        values: orbitalPositions
          .map(op => ({ name: op.name, value: op.name }))
          .sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }

            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }

            return 0;
          })
      });
    }

    const formattedTransponders = transponders.map(transponder => {
      transponder.polarization_name = polarizationName(transponder.polarization);
      transponder.roll_off_name = rollOffToString(transponder.roll_off);
      const beam = beams.find(b => b.id === transponder.beam_id);

      if (!beam) {
        transponder.orbital_position = "No Orbital Position";
        transponder.beam = transponder.beam_id;
        return transponder;
      }
      transponder.beam = beam.name;

      const orbitalPosition = orbitalPositions.find(op => op.id === beam.orbital_position_id);

      if (orbitalPosition) {
        transponder.orbital_position = orbitalPosition.name;
        return transponder;
      }
      transponder.orbital_position = "No Orbital Position";

      return transponder;
    });

    const beamFullName = params => `${params.row.orbital_position} - ${params.value}`;

    return (
      <Stack spacing={2}>
        <Stack direction="row" spacing={3}>
          <Button
            id="actions-button"
            sx={{
              borderColor: "primary.smokeBorder",
            }}
            variant="outlined"
            aria-controls={
              menuOpen && openedMenu === "actions-menu"
                ? "actions-menu"
                : undefined
            }
            aria-haspopup="true"
            aria-expanded={
              menuOpen && openedMenu === "actions-menu" ? "true" : undefined
            }
            disableElevation
            onClick={e => this.openMenu(e, "actions-menu")}
            endIcon={<KeyboardArrowDownIcon />}
            disabled={!selectedTranspondersIds.length}
          >
            Actions
          </Button>
          <Menu
            MenuListProps={{
              "aria-labelledby": "actions-button",
            }}
            id="actions-menu"
            anchorEl={menuAnchorEl}
            open={menuOpen && openedMenu === "actions-menu"}
            onClose={() => this.closeMenu()}
          >
            <MenuItem
              sx={{
                color: "red",
              }}
              onClick={() => {
                this.closeMenu();
                this.deleteTransponder();
              }}
              disableRipple
            >
              Delete selected Transponders
            </MenuItem>
          </Menu>
          <FiltersInputs
            primaryFilters={primaryFilters}
            onFiltersChange={this.setFilters}
          />
        </Stack>

        <div style={{ height: "calc(100vh - 270px)", minHeight: "160px" }}>
          <DataTable
            cols={[
              { headerName: "Id", field: "id", flex: 1 },
              { headerName: "Name", field: "name", flex: 2 },
              {
                headerName: "HomeTS",
                field: "homets",
                renderCell: item => item.value ? <CheckIcon /> : "",
                flex: 2,
                filterOperators: customGridOperators,
              },
              {
                headerName: "Orbital Position",
                field: "orbital_position",
                flex: 2,
                filterOperators: customGridOperators,
              },
              {
                headerName: "Beam",
                field: "beam",
                valueGetter: beamFullName,
                renderCell: params =>
                  <span>{params.value.split(" ")[2]}</span>,
                flex: 2,
                filterOperators: customGridOperators,
              },
              { headerName: "OP", field: "nit_orbital_position", flex: 1 },
              { headerName: "Theoretical ONID", field: "theoretical_onid", flex: 2 },
              { headerName: "Theoretical TSID", field: "theoretical_tsid", flex: 2 },
              { headerName: "Broadcst ONID", field: "broadcast_onid", flex: 2 },
              { headerName: "Broadcast TSID", field: "broadcast_tsid", flex: 2 },
              { headerName: "Freq.", field: "frequency", flex: 2 },
              {
                headerName: "Polarization",
                field: "polarization_name",
                flex: 2,
                filterOperators: customGridOperators,
              },
              { headerName: "Symbol Rate", field: "symbol_rate", flex: 2 },
              {
                headerName: "Mod. Sys.",
                field: "modulation_system",
                flex: 2,
                filterOperators: customGridOperators,
              },
              {
                headerName: "Mod. Type",
                field: "modulation_type",
                flex: 2,
                filterOperators: customGridOperators,
              },
              {
                headerName: "FEC",
                field: "fec_inner",
                flex: 2,
                filterOperators: customGridOperators,
              },
              { headerName: "Roll-off", field: "roll_off_name", flex: 2 },
            ]}
            data={formattedTransponders}
            onRowClick={id => this.openEditModal(id)}
            checkboxSelection
            onRowSelectionModelChange={this.setSelectedTranspondersIds}
            filters={filters}
            pagination
          />
        </div>

        {/* MODAL */}

        <EditTransponderModal
          currentId={currentTransponder?.id}
          modalOpened={modalOpened}
          closeEditModal={this.closeEditModal}
        />

        <RemoveTransponderModal
          currentTransponderIds={selectedTranspondersIds}
          modalOpened={removeTransponderOpened}
          closeTransponderModal={this.closeRemoveTransponderModal}
        />
      </Stack>
    );
  }
}

Transponders.propTypes = {
  transponders: PropTypes.arrayOf(PropTypes.object).isRequired,
  transpondersFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
  beams: PropTypes.arrayOf(PropTypes.object).isRequired,
  orbitalPositions: PropTypes.arrayOf(PropTypes.object).isRequired,

  indexTranspondersFilters: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    transponders: listTranspondersSelector(state),
    transpondersFilters: listTranspondersFiltersSelector(state),
    beams: listBeamsSelector(state),
    orbitalPositions: listOrbitalPositionsSelector(state),

    isTranspondersLoading: isLoadingSelector(state, indexTranspondersAction.toString()),
    isTranspondersCreating: isLoadingSelector(state, createTransponderAction.toString()),
    isTranspondersEditing: isLoadingSelector(state, updateTransponderAction.toString())
  };
}

const mapDispatchToProps = {
  indexTransponders: indexTranspondersAction,
  indexTranspondersFilters: indexTranspondersFiltersAction,
  updateTransponder: updateTransponderAction,
  deleteTransponder: deleteTransponderAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Transponders);
