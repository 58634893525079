/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { listChannelTiers } from "../../selectors/channel_tiers";
import { listEditorialChannels } from "../../selectors/editorial_channels";
import { showLineup } from "../../selectors/lineups";
import {
  listLineupsServicesByLineupId,
  listLineupsServices
} from "../../selectors/lineups_services";

import {
  sortObjectByLocale,
  getObjectNameFromLocales
} from "../../helpers/utils";
import Modal from "../Modal/Modal";

class LineupMetricsModal extends PureComponent {
  modalContent() {
    const {
      lineup,
      channelTiers,
      lineupServices,
    } = this.props;

    const sortedChannelTiers = [...channelTiers].sort(sortObjectByLocale);
    const currentLineupsServices = lineupServices.filter(ls => ls.lineup_id === lineup.id);

    const grouppedChannelTiers = sortedChannelTiers.reduce((acc, cur) => {
      if (Object.keys(acc).indexOf(cur.name) === -1) {
        acc[cur.name] = [];
      }

      acc[cur.name] = acc[cur.name].concat(
        currentLineupsServices
          .filter(ls => ls.channel_tier_id === cur.id)
          .map(ls => ls.service ? ls.service.name : ""),
      );

      if (lineup.parent_id) {
        acc[cur.name] = acc[cur.name].concat(
          lineupServices
            .filter(ls => ls.lineup_id === lineup.parent_id && ls.channel_tier_id === cur.id)
            .map(ls => ls.service ? ls.service.name : ""),
        );
      }

      return acc;
    }, {});

    return !!grouppedChannelTiers
      && <Stack sx={{ marginBottom: "30px" }} spacing={3} alignItems="center">
        <Typography variant="h5">Channel Tiers Metrics</Typography>
        <Table sx={{ maxWidth: "80%" }} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Standard</TableCell>
              <TableCell>Platinum</TableCell>
              <TableCell>Premium</TableCell>
            </TableRow>
          </TableHead>
        </Table>
        <div style={{ maxWidth: "80%", maxHeight: "25em", overflow: "auto", marginTop: "5px" }}>
          <Table style={{ tableLayout: "fixed" }}>
            <TableBody>
              <TableRow>
                <TableCell>
                  {!!grouppedChannelTiers.standard
                      && <ul>{grouppedChannelTiers.standard.map(channel => <li key={channel}>{channel}</li>)}</ul>}
                </TableCell>
                <TableCell>
                  {!!grouppedChannelTiers.platinum
                      && <ul>{grouppedChannelTiers.platinum.map(channel => <li key={channel}>{channel}</li>)}</ul>}
                </TableCell>
                <TableCell>
                  {!!grouppedChannelTiers.premium
                      && <ul>{grouppedChannelTiers.premium.map(channel => <li key={channel}>{channel}</li>)}</ul>}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </Stack>;
  }

  render() {
    const {
      modalOpened,
      lineup,
      closeLineupMetricsModal
    } = this.props;

    const currentLineupName = getObjectNameFromLocales(lineup);

    return (
      <Modal
        closeModal={() => closeLineupMetricsModal()}
        isOpen={modalOpened}
        title={`Metrics of lineup ${currentLineupName}`}
        customModalContent={() => this.modalContent()}
      />
    );
  }
}

LineupMetricsModal.defaultProps = {
  currentLineupId: null,
  lineup: null,
  channelTiers: [],
  lineupServices: []
};

LineupMetricsModal.propTypes = {
  modalOpened: PropTypes.bool.isRequired,

  closeLineupMetricsModal: PropTypes.func.isRequired,

  // Use in mapStateToProps
  // eslint-disable-next-line react/no-unused-prop-types
  currentLineupId: PropTypes.number,

  lineup: PropTypes.object,

  channelTiers: PropTypes.arrayOf(PropTypes.object),
  lineupServices: PropTypes.arrayOf(PropTypes.object)
};

function mapStateToProps(state, ownProps) {
  return {
    lineup: showLineup(state, ownProps.currentLineupId) || {},
    channelTiers: listChannelTiers(state),
    editorialChannels: listEditorialChannels(state),
    lineupServices: listLineupsServices(state),
    currentLineupServices: listLineupsServicesByLineupId(state, ownProps.currentLineupId)
  };
}

export default connect(mapStateToProps)(LineupMetricsModal);
