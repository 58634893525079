import { handleActions } from "redux-actions";

const initialState = {
  list: [],
  count: 0,
  details: null
};

export default handleActions(
  {
    STORE_EPG_REPORTS: (state, action) => ({
      ...state,
      list: action.payload.result,
      count: parseInt(action.payload.headers["total-count"])
    }),
    STORE_EPG_REPORTS_DETAILS: (state, action) => ({
      ...state,
      details: action.payload.result
    }),
    CLEAR_EPG_REPORTS_DETAILS: state => ({
      ...state,
      details: null
    })
  },
  initialState
);
