import { listBeamsFromOrbitalPosition } from "./beams";

export const listTransponders = state => state.transponders.allTransponders;

export const listTranspondersFromBeams = (state, beamsIds) => state.transponders.allTransponders.filter(t => beamsIds.indexOf(t.beam_id) !== -1);

export const listTranspondersFromOrbitalPosition = (state, orbitalPositionId) => {
  const beams = listBeamsFromOrbitalPosition(state, orbitalPositionId);
  const beamsIds = beams.map(b => b.id);

  return state.transponders.allTransponders.filter(t => beamsIds.indexOf(t.beam_id) !== -1);
};

export const showTransponder = (state, transponderId) => state.transponders.allTransponders.find(t => t.id === transponderId);

export const getTotalCount = state => state.transponders.totalCount;

export const listTranspondersFilters = state => state.transponders.filters;
