import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Autocomplete, TextField } from "@mui/material";
import { addToast as addToastAction } from "../../actions/toasts";

import {
  updateProvidersWording as updateProvidersWordingAction
} from "../../actions/providers_wordings";
import { listContentGenres as listContentGenresSelector } from "../../selectors/content_genres";
import { listProvidersWordings as listProvidersWordingsSelector } from "../../selectors/providers_wordings";
import { listProviders as listProvidersSelector } from "../../selectors/providers";

import DataTable from "../DataTable";
import FiltersInputs from "../FiltersInputs";

import loaderLogo from "../../assets/loader.gif";

import {
  sortObjectByLocale,
  getObjectNameFromLocales
} from "../../helpers/utils";

import { customGridOperators } from "../../utils/constant";

function ProvidersWordings({
  contentGenres,
  providers,
  providersWordings,
  updateProvidersWording,
  addToast
}) {
  const [assigments, setAssignements] = useState({});
  const [filters, setFilters] = useState([]);
  const [currentRequest, setCurrentRequest] = useState(null);

  const changeAssigment = (pwId, genreId) => {
    const newAssigments = { ...assigments };

    newAssigments[pwId] = genreId;

    setAssignements(newAssigments);
  };

  async function assignProvidersWording(pwId) {
    setCurrentRequest(pwId);

    const genreId = parseInt(assigments[pwId], 10);
    const genre = contentGenres.find(cg => cg.id === genreId);

    if (genreId && genre) {
      try {
        await updateProvidersWording(pwId, { genre_id: genreId });

        addToast("success", "Success", `Wording sucessfully assigned to the genre ${getObjectNameFromLocales(genre)}`);
      } catch (_) {
        addToast("error", "Error", "Wording cannot be associated. Try again.");
      }
    }

    setCurrentRequest(null);
  }

  const primaryFilters = [
    {
      text: "Provider",
      name: "provider_id",
      values: providers.map(p => ({ name: p.name, value: p.id }))
    }
  ];

  const nullProvidersWordings = providersWordings.filter(pw => pw.genre_id === null);
  const sortedContentGenres = contentGenres.sort((a, b) => sortObjectByLocale(a, b));

  return <>
    <FiltersInputs
      primaryFilters={primaryFilters}
      defaultFilters={filters}
      onFiltersChange={setFilters}
    />
    <div style={{ height: "calc(100vh - 270px)", minHeight: "160px" }}>
      <DataTable
        pagination
        disableRowClick
        filters={filters}
        data={nullProvidersWordings}
        cols={[
          {
            headerName: "Provider",
            field: "provider_id",
            sortable: true,
            searchable: true,
            flex: 0.5,
            renderCell: item => providers.find(p => p.id === item.value)?.name || "",
            filterOperators: customGridOperators
          },
          {
            headerName: "Word",
            field: "word",
            sortable: true,
            searchable: true,
            flex: 1,
          },
          {
            headerName: "Assign to genre",
            field: "genre",
            align: "left",
            flex: 1,

            renderCell: item => {
              const currentId = item.row.id;
              return <div className="row basline" style={{ width: "100%", height: "100%", gap: "10px", padding: "10px 0" }}>
                {currentRequest === currentId
                    && <img className="btn-loader" src={loaderLogo} alt="Loading" style={{ width: "30px", height: "30px" }} />
                }
                {currentRequest !== currentId
                    && <>
                      <div className="descriptor-field no-border">
                        <Autocomplete
                          sx={{ flex: 1 }}
                          options={ sortedContentGenres }
                          getOptionLabel={option => getObjectNameFromLocales(option)}
                          renderOption={(props, option) =>
                            <li {...props} key={option.id}>
                              {getObjectNameFromLocales(option)}
                            </li>
                          }
                          onChange={(e, newValue) => {
                            newValue ? changeAssigment(currentId, newValue.id) : changeAssigment(currentId, null);
                          }}
                          renderInput={params => <TextField {...params} label="Genre" />}
                        />
                      </div>
                      <button style={{ alignSelf: "center", height: "100%", flex: "0.18" }} type="button" className="btn btn-primary" onClick={() => assignProvidersWording(currentId)}>Save</button>
                    </>
                }
              </div>;
            }
          }
        ]}
      />
    </div>
  </>;
}

ProvidersWordings.propTypes = {
  contentGenres: PropTypes.arrayOf(PropTypes.object).isRequired,
  providers: PropTypes.arrayOf(PropTypes.object).isRequired,
  providersWordings: PropTypes.arrayOf(PropTypes.object).isRequired,

  updateProvidersWording: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    contentGenres: listContentGenresSelector(state),
    providers: listProvidersSelector(state),
    providersWordings: listProvidersWordingsSelector(state)
  };
}

const mapDispatchToProps = {
  updateProvidersWording: updateProvidersWordingAction,
  addToast: addToastAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ProvidersWordings);
