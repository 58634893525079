import { handleActions } from "redux-actions";
import { omit } from "lodash";

const initialState = {
  allLineups: [],
  filters: [],
  totalCount: 0
};

const lineupsReducer = handleActions(
  {
    STORE_LINEUPS: (state, action) => ({
      ...state,
      allLineups: action.payload.result.map(l => omit(l, ["lineups_services_count"])),
      totalCount: action.payload.total_count || 0
    }),
    STORE_LINEUPS_FILTERS: (state, action) => ({
      ...state,
      filters: action.payload.result
    }),
    ADD_LINEUP: (state, action) => {
      const allLineups = [...state.allLineups];

      allLineups.push(omit(action.payload.result, ["lineups_services_count"]));

      return {
        ...state,
        allLineups,
        totalCount: state.totalCount + 1
      };
    },
    MODIFY_LINEUP: (state, action) => {
      const newState = {
        ...state
      };

      const lineupIndex = newState.allLineups.findIndex(l => action.payload.result.id === l.id);

      newState.allLineups[lineupIndex] = { ...newState.allLineups[lineupIndex], ...omit(action.payload.result, ["lineups_services_count"]) };

      return newState;
    },
    STORE_SINGLE_LINEUP: (state, action) => {
      const allLineups = [...state.allLineups];
      let { totalCount } = state;
      const lineupIndex = allLineups.findIndex(l => l.id === action.payload.result.id);
      const lineup = omit(action.payload.result, ["lineups_services_count"]);

      if (lineupIndex !== -1) {
        allLineups.splice(lineupIndex, 1, lineup);
      } else {
        allLineups.push(lineup);
        totalCount += 1;
      }

      return {
        ...state,
        allLineups,
        totalCount
      };
    },
    REMOVE_LINEUP: (state, action) => {
      const lineupIndex = state.allLineups.findIndex(l => action.payload.id === l.id);
      const allLineups = Array.from(state.allLineups);

      allLineups.splice(lineupIndex, 1);

      return {
        ...state,
        allLineups,
        totalCount: lineupIndex > -1 ? state.totalCount - 1 : state.totalCount
      };
    },
    SELECT_ORBITAL_POSITIONS: () => initialState,
    STORE_LINEUP_LOGO: (state, action) => {
      const lineupIndex = state.allLineups.findIndex(ec => action.payload.id === ec.id);
      const allLineups = Array.from(state.allLineups);

      if (lineupIndex !== -1) {
        allLineups[lineupIndex].logos.push(action.payload.result);
      }

      return {
        ...state,
        allLineups
      };
    },
    REMOVE_LINEUP_LOGO: (state, action) => {
      const lineupIndex = state.allLineups.findIndex(ec => action.payload.id === ec.id);
      const allLineups = Array.from(state.allLineups);

      if (lineupIndex !== -1) {
        allLineups[lineupIndex].logos = allLineups[lineupIndex].logos.filter(l => l.id !== action.payload.logoId);
      }

      return {
        ...state,
        allLineups
      };
    }
  },
  initialState
);

export default lineupsReducer;
