import * as React from "react";
import PropTypes from "prop-types";
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  Stack,
  Typography
} from "@mui/material";

export default function LineupServiceMoveToActionModal({
  modalOpened,
  onClose,
  setLcnToScrollTo
}) {
  const [lcnTarget, setLcnTarget] = React.useState("");
  const [errorHelperText, setErrorHelperText] = React.useState("");
  const inputRef = React.useRef(null);

  const handleEntered = () => {
    if (inputRef.current != null) {
      inputRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleConfirm = () => {
    if (!Number.isNaN(lcnTarget) && lcnTarget >= 0) {
      setLcnToScrollTo(Number(lcnTarget));
      setErrorHelperText("");
      setLcnTarget("");
      onClose();
    } else {
      setErrorHelperText("LCN should be 0 or a positive integer");
    }
  };

  return (
    <Dialog
      TransitionProps={{ onEntered: handleEntered }}
      open={modalOpened}
    >
      <DialogTitle>Scroll to desired LCN</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={1}>
          <Typography>
            Please enter the LCN number you want to scroll to :
          </Typography>
          <TextField
            inputRef= {inputRef}
            value={lcnTarget}
            onChange={event => {
              setLcnTarget(event.target.value);
            }}
            error={errorHelperText.length > 0}
            helperText={errorHelperText}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
}

LineupServiceMoveToActionModal.defaultProps = {
  modalOpened: false,
  onClose: null,
  setLcnToScrollTo: null
};

LineupServiceMoveToActionModal.propTypes = {
  modalOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setLcnToScrollTo: PropTypes.func.isRequired
};
