import { createAction } from "redux-actions";

/* Storage actions */
const storeProvidersWordings = createAction("STORE_PROVIDERS_WORDINGS");
const storeProvidersWordingsFilters = createAction("STORE_PROVIDERS_WORDINGS_FILTERS");

export const addProvidersWording = createAction("ADD_PROVIDERS_WORDING");

const modifyProvidersWording = createAction("MODIFY_PROVIDERS_WORDING");

/* API actions */
export const indexProvidersWordings = createAction("API_INDEX_PROVIDERS_WORDINGS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/providers_wordings",
  data,
  fullLoad,
  onSuccessAction: storeProvidersWordings
}));

export const indexProvidersWordingsFilters = createAction("API_INDEX_PROVIDERS_WORDINGS_FILTERS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/providers_wordings/filters",
  data,
  fullLoad,
  onSuccessAction: storeProvidersWordingsFilters
}));

export const createProvidersWording = createAction("API_CREATE_PROVIDER", data => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "POST",
  url: "/providers_wordings",
  data,
  onSuccessAction: addProvidersWording
}));

export const updateProvidersWording = createAction("API_UPDATE_PROVIDER", (providerId, data) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "PUT",
  url: `/providers_wordings/${providerId}`,
  data,
  onSuccessAction: modifyProvidersWording
}));

export const deleteProvidersWording = createAction("API_DELETE_PROVIDER", providerId => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "DELETE",
  url: `/providers_wordings/${providerId}`,
  onSuccessAction: createAction("REMOVE_PROVIDERS_WORDING", () => ({ id: providerId }))
}));
