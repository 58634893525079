import { handleActions } from "redux-actions";

const initialState = {
  show: false
};

const loaderReducer = handleActions(
  {
    MODAL_SHOW: () => ({ show: true }),
    MODAL_HIDE: () => ({ show: false })
  },
  initialState
);

export default loaderReducer;
