import axios from "axios";

import { addLoader, removeLoader } from "../actions/loaders";

// {
//   if (action.type.indexOf('TOAST_') !== 0) return;

//   dispatch({ type: 'ADD_TOAST', payload: { text: action.payload.text } });

//   setTimeout(() => {
//     dispatch({ type: 'REMOVE_TOAST', payload: id });
//   }, action.payload.timeout);
// }

const handleRequest = payload => {
  const {
    baseURL = "",
    url = "",
    method = "GET",
    bearerToken = "",
    headers = {},
    data = {},
    responseType = "json"
  } = payload;

  const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";

  if (bearerToken) {
    headers.Authorization = `Bearer ${bearerToken}`;
  }

  return axios
    .request({
      baseURL,
      url,
      method,
      headers,
      responseType,
      [dataOrParams]: data
    });
};

const apiMiddleware = ({ dispatch }) => next => async action => {
  next(action);

  if (action.type.indexOf("API_") !== 0) {
    return {};
  }

  let resultApiCall = {
    isSuccess: true,
    data: {}
  };

  dispatch(addLoader(action.type));

  // axios default configs
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.withCredentials = true;

  const {
    fullLoad = false,
    data = {},
    onSuccessAction = null,
    onSuccessActions = [],
    onFailureAction = null
  } = action.payload;

  if (!fullLoad) {
    try {
      const { data: resultData, headers } = await handleRequest(action.payload);
      if (typeof onSuccessAction === "function") {
        // support legacy api payload
        await dispatch(onSuccessAction(resultData.result ? resultData : { result: resultData, headers }));
      } else if (onSuccessActions && Array.isArray(onSuccessActions)) {
        for (let i = 0; i < onSuccessActions.length; i += 1) {
          const successAction = onSuccessActions[i];

          if (typeof successAction === "function") {
            // support legacy api payload
            await dispatch(successAction(resultData.result ? resultData : { result: resultData }));
          }
        }
      }

      resultApiCall = {
        isSuccess: true,
        // support legacy api payload
        data: resultData.result || resultData
      };
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch({ type: "SET_IS_LOGGED", isLogged: false });
      }
      resultApiCall = {
        isSuccess: false,
        data: {}
      };

      if (typeof onFailureAction === "function") {
        dispatch(onFailureAction(error));
      }
    } finally {
      dispatch(removeLoader(action.type));
    }
  } else {
    let items = [];
    const perPage = (data && data.per_page) || 1000;
    let page = (data && data.page) || 1;
    let run = true;

    while (run) {
      try {
        const { payload } = action;

        payload.data = {
          ...payload.data,
          page,
          per_page: perPage
        };

        const { data: resultData } = await handleRequest(payload);
        // support legacy api payload
        const result = resultData.result || resultData;

        items = items.concat(result);

        if (result.length < perPage) {
          run = false;
        }
      } catch (error) {
        run = false;
        if (error.response && error.response.status === 401) {
          dispatch({ type: "SET_IS_LOGGED", isLogged: false });
        }

        resultApiCall = {
          isSuccess: false,
          data: items
        };

        if (typeof onFailureAction === "function") {
          dispatch(onFailureAction(error));
        }
      }

      page += 1;
    }

    if (typeof onSuccessAction === "function") {
      await dispatch(onSuccessAction({
        result: items,
        total_count: items.length
      }));
    } else if (onSuccessActions && Array.isArray(onSuccessActions)) {
      for (let i = 0; i < onSuccessActions.length; i += 1) {
        const successAction = onSuccessActions[i];

        if (typeof successAction === "function") {
          await dispatch(successAction({
            result: items,
            total_count: items.length
          }));
        }
      }
    }

    resultApiCall = {
      isSuccess: true,
      data: items
    };

    dispatch(removeLoader(action.type));
  }

  return resultApiCall;
};

export default apiMiddleware;
