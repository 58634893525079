const initialState = {
  isLogged: true
};

const workerReducer = state => {
  if (typeof state === "undefined") {
    return initialState;
  }

  return state;
};

export default workerReducer;
