import { createAction } from "redux-actions";

export const addGenreToLineupService = createAction("API_ADD_GENRE_TO_LINEUP_SERVICE", (lineupServiceId, genreId) => (
  {
    baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
    method: "POST",
    url: `/lineups_services/${lineupServiceId}/genres`,
    data: { genre_id: genreId },
    onSuccessAction: createAction("STORE_LINEUP_SERVICE_GENRE", result => ({ id: lineupServiceId, result: result.result }))
  }
));

export const removeGenreFromLineupService = createAction("API_REMOVE_GENRE_FROM_LINEUP_SERVICE", (lineupServiceId, genreId) => (
  {
    baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
    method: "DELETE",
    url: `/lineups_services/${lineupServiceId}/genres/${genreId}`,
    onSuccessAction: createAction("REMOVE_LINEUPS_SERVICE_GENRE", () => ({ id: lineupServiceId, genreId }))
  }
));
