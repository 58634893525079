import React from "react";
import PropTypes from "prop-types";
import { DialogActions } from "@mui/material";

const ModalActions = props => {
  const { align, actions } = props;

  return (
    <DialogActions sx={{ display: "flex", margin: 1.5, justifyContent: align || "space-between" }}>
      {actions && actions()}
    </DialogActions>
  );
};

ModalActions.propTypes = {
  align: PropTypes.string,
  actions: PropTypes.func.isRequired,
};

export default ModalActions;
