import React, { PureComponent } from "react";

import "./FullLoader.css";

import loaderLogo from "../../assets/loader.gif";

class FullLoader extends PureComponent {
  render() {
    return (
      <div className="full-loader">
        <img className="full-loader-image" src={loaderLogo} alt="Loading..." />
      </div>
    );
  }
}

export default FullLoader;
