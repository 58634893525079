import { handleActions } from "redux-actions";

const initialState = {
  replication: null
};

const reducer = handleActions({
  STORE_REPLICATION: (state, action) => ({
    ...state,
    replication: action.payload.result
  })
},
initialState);

export default reducer;
