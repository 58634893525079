import { createAction } from "redux-actions";

/* Storage actions */
const storeOttBackends = createAction("STORE_OTT_BACKENDS");
const storeOttBackendsFilters = createAction("STORE_OTT_BACKENDS_FILTERS");
const addOttBackend = createAction("ADD_OTT_BACKEND");
const modifyOttBackend = createAction("MODIFY_OTT_BACKEND");

/* API actions */
export const indexOttBackends = createAction("API_INDEX_OTT_BACKENDS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/ott_backends",
  data,
  fullLoad,
  onSuccessAction: storeOttBackends
}));

export const indexOttBackendsFilters = createAction("API_INDEX_OTT_BACKENDS_FILTERS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/ott_backends/filters",
  data,
  fullLoad,
  onSuccessAction: storeOttBackendsFilters
}));

export const createOttBackend = createAction("API_CREATE_OTT_BACKEND", data => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "POST",
  url: "/ott_backends",
  data,
  onSuccessAction: addOttBackend
}));

export const updateOttBackend = createAction("API_UPDATE_OTT_BACKEND", (ottBackendId, data) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "PUT",
  url: `/ott_backends/${ottBackendId}`,
  data,
  onSuccessAction: modifyOttBackend
}));

export const deleteOttBackend = createAction("API_DELETE_OTT_BACKEND", ottBackendId => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "DELETE",
  url: `/ott_backends/${ottBackendId}`,
  onSuccessAction: createAction("REMOVE_OTT_BACKEND", () => ({ id: ottBackendId }))
}));
