import { createAction } from "redux-actions";

import { createBeamsLineups } from "./beams_lineups";

/* Storage actions */
const storeLineups = createAction("STORE_LINEUPS");
const storeLineupsFilters = createAction("STORE_LINEUPS_FILTERS");
const storeSingleLineup = createAction("STORE_SINGLE_LINEUP");
const addLineup = createAction("ADD_LINEUP");
const modifyLineup = createAction("MODIFY_LINEUP");

/* API actions */
export const createLineupLogo = createAction("API_CREATE_LINEUP_LOGO", (lineupId, data) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "POST",
  url: `/lineups/${lineupId}/logos`,
  data,
  onSuccessAction: createAction("STORE_LINEUP_LOGO", result => ({ id: lineupId, result: result.result }))
}));

export const removeLineupLogo = createAction("API_REMOVE_LINEUP_LOGO", (lineupId, logoId) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "DELETE",
  url: `/lineups/${lineupId}/logos/${logoId}`,
  onSuccessAction: createAction("REMOVE_LINEUP_LOGO", () => ({ id: lineupId, logoId }))
}));

export const indexLineups = createAction("API_INDEX_LINEUPS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/lineups",
  data,
  fullLoad,
  onSuccessAction: storeLineups
}));

export const indexLineupsFilters = createAction("API_INDEX_LINEUPS_FILTERS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/lineups/filters",
  data,
  fullLoad,
  onSuccessAction: storeLineupsFilters
}));

export const updateLineup = createAction("API_UPDATE_LINEUP", (lineupId, data) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "PUT",
  url: `/lineups/${lineupId}`,
  data,
  onSuccessAction: modifyLineup
}));

export const showLineup = createAction("API_SHOW_LINEUP", lineupId => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "GET",
  url: `/lineups/${lineupId}`,
  onSuccessAction: storeSingleLineup
}));

export const deleteLineup = createAction("API_DELETE_LINEUP", lineupId => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "DELETE",
  url: `/lineups/${lineupId}`,
  onSuccessAction: createAction("REMOVE_LINEUP", () => ({ id: lineupId }))
}));

export const createLineup = createAction("API_CREATE_LINEUP", (data, beamId) => {
  const onSuccessActions = [
    args => addLineup(args),
    lineupResult => createBeamsLineups({ lineup_id: lineupResult.result.id, beam_id: beamId })
  ];

  data.logos.forEach(logoData => {
    onSuccessActions.push(lineupResult => createLineupLogo(lineupResult.result.id, logoData));
  });

  return {
    baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
    method: "POST",
    url: "/lineups",
    data: data.lineup,
    onSuccessActions
  };
});

export const exportLineups = createAction("API_EXPORT_LINEUPS", () => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "GET",
  url: "/exports/lineups.csv"
}));

export const exportLineupById = createAction("API_EXPORT_LINEUPS", lineupId => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "GET",
  url: `/exports/lineups_service/${lineupId}.csv`,
}));
