import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Menu, MenuItem, Stack } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { addToast as addToastAction } from "../../actions/toasts";
import {
  indexOttBackends as indexOttBackendsAction,
  createOttBackend as createOttBackendAction,
  indexOttBackendsFilters as indexOttBackendsFiltersAction,
  updateOttBackend as updateOttBackendAction,
  deleteOttBackend as deleteOttBackendAction
} from "../../actions/ott_backends";
import {
  listOttBackends as listOttBackendsSelector,
} from "../../selectors/ott_backends";
import { isLoading as isLoadingSelector } from "../../selectors/loaders";

import EditOttBackendModal from "../OttBackendsModal/EditOttBackendModal";
import DeleteObjectsModal from "../GlobalObjectsModal/deleteObjectsModal";

import DataTable from "../DataTable";

class OttBackends extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentOttBackend: null,

      selectedOttBackendsIds: [],

      modalOpened: false,
      menuAnchorEl: null,
      openedMenu: null,
      deleteModalOpened: false
    };

    this.deleteOttBackend = this.deleteOttBackend.bind(this);
  }

  setSelectedOttBackendsIds = newSelection => {
    this.setState({
      selectedOttBackendsIds: newSelection,
    });
  };

  /* Menus methods */

  setMenuAnchorEl = target => {
    this.setState({
      menuAnchorEl: target
    });
  };

  setOpenedMenu = menuId => {
    this.setState({
      openedMenu: menuId
    });
  };

  openMenu = (event, menuId) => {
    this.setMenuAnchorEl(event.currentTarget);
    this.setOpenedMenu(menuId);
  };
  closeMenu = menuId => {
    this.setMenuAnchorEl(null);
    this.setOpenedMenu(menuId);
  };

  /* Edit Modal */

  openEditModal = currentOttBackend => {
    this.setState({
      currentOttBackend,
      modalOpened: true
    });
  };

  closeEditModal = () => {
    this.setState({
      currentOttBackend: null,
      modalOpened: false
    });
  };

  closeDeleteModal = () => {
    this.setState({
      deleteModalOpened: false
    });
  };

  /* Add Modal */

  async deleteOttBackend(selectedOttBackends) {
    const { deleteOttBackend, addToast } = this.props;

    if (selectedOttBackends.length > 0) {
      let deleted = 0;

      for (let i = 0; i < selectedOttBackends.length; i += 1) {
        const selectedOb = selectedOttBackends[i];
        const ret = await deleteOttBackend(selectedOb);

        if (ret.isSuccess) {
          deleted += 1;
        }
      }

      if (deleted === selectedOttBackends.length) {
        addToast("success", "Success", "OTT Backend(s) successfully deleted!");
      } else if (deleted === 0) {
        addToast("error", "Error", "OTT Backend(s) cannot be deleted. Try again.");
      } else {
        addToast("warning", "Warning", "Some ott backend(s) cannot be deleted. Try again.");
      }

      this.setState({
        selectedOttBackendsIds: [],
        deleteModalOpened: false
      });
    }
  }

  /* Modal */

  render() {
    const {
      currentOttBackend,
      modalOpened,
      selectedOttBackendsIds,
      menuAnchorEl,
      openedMenu,
      deleteModalOpened
    } = this.state;

    const {
      ottBackends
    } = this.props;

    const menuOpen = Boolean(menuAnchorEl);

    return (
      <Stack spacing={2}>
        <div>
          <Button
            id="actions-button"
            sx={{
              borderColor: "primary.smokeBorder",
            }}
            variant="outlined"
            aria-controls={menuOpen && openedMenu === "actions-menu" ? "actions-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen && openedMenu === "actions-menu" ? "true" : undefined}
            disableElevation
            onClick={e => this.openMenu(e, "actions-menu")}
            endIcon={<KeyboardArrowDownIcon />}
            disabled={ selectedOttBackendsIds.length <= 0 }
          >
            Actions
          </Button>
          <Menu
            MenuListProps={{
              "aria-labelledby": "actions-button",
            }}
            id="actions-menu"
            anchorEl={menuAnchorEl}
            open={menuOpen && openedMenu === "actions-menu"}
            onClose={() => this.closeMenu()}
          >
            <MenuItem
              sx={{
                color: "red"
              }}
              onClick={() => {
                this.closeMenu();
                this.setState({
                  deleteModalOpened: true
                });
              }}
              disableRipple
            >
              Delete selected ott backends
            </MenuItem>
          </Menu>
        </div>
        <div style={{ height: "calc(100vh - 270px)", minHeight: "160px" }}>
          <DataTable
            cols={[
              { headerName: "Id", field: "id", flex: 1 },
              { headerName: "Type", field: "type", flex: 1 },
              { headerName: "Endpoint URL", field: "endpoint_url", flex: 2 },
              { headerName: "Tenant Id", field: "tenant_id", flex: 2 },
              { headerName: "DRM Endpoint URL", field: "drm_endpoint_url", flex: 2 },
              { headerName: "DRM Tenant Id", field: "drm_tenant_id", flex: 1 },
              { headerName: "Modified", field: "updated_at", flex: 2 }
            ]}
            data={ottBackends}
            onRowClick={id => this.openEditModal(id)}
            onRowSelectionModelChange={this.setSelectedOttBackendsIds}
            checkboxSelection
          />
        </div>

        {/* MODAL */}

        <EditOttBackendModal
          currentId={currentOttBackend?.id}
          modalOpened={modalOpened}
          closeEditModal={this.closeEditModal}
        />

        <DeleteObjectsModal
          selectedIds={selectedOttBackendsIds}
          modalOpened={deleteModalOpened}
          deleteObjects={this.deleteOttBackend}
          closeDeleteModal={this.closeDeleteModal}
          name="Ott backend(s)"
        />
      </Stack>
    );
  }
}

OttBackends.propTypes = {
  ottBackends: PropTypes.arrayOf(PropTypes.object).isRequired,

  deleteOttBackend: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    ottBackends: listOttBackendsSelector(state),

    isOttBackendsLoading: isLoadingSelector(state, indexOttBackendsAction.toString()),
    isOttBackendsCreating: isLoadingSelector(state, createOttBackendAction.toString()),
    isOttBackendsEditing: isLoadingSelector(state, updateOttBackendAction.toString())
  };
}

const mapDispatchToProps = {
  indexOttBackends: indexOttBackendsAction,
  indexOttBackendsFilters: indexOttBackendsFiltersAction,
  updateOttBackend: updateOttBackendAction,
  deleteOttBackend: deleteOttBackendAction,
  addToast: addToastAction
};

export default connect(mapStateToProps, mapDispatchToProps)(OttBackends);
