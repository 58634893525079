/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Box, FormControl, InputLabel, Select, MenuItem, DialogContent, Button, Alert, TextField } from "@mui/material";
import Modal from "../Modal/Modal";
import withStepper from "../Modal/withStepper";

import { addToast as addToastAction } from "../../actions/toasts";

import {
  createOttBackend as createOttBackendAction
} from "../../actions/ott_backends";

const initialState = {
  type: "cirrusr1",
  endpoint_url: "",
  tenant_id: "",
  drm_endpoint_url: "",
  drm_tenant_id: "",
  options: [
    { value: "cirrusr1", label: "cirrusr1", id: 0 },
  ],
};

class AddOttBackendModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { ...initialState };
  }

  changeInput(inputName, event) {
    const { value } = event.target;

    this.setState(() => ({ [inputName]: value }), this.validateStep);
  }

  async addOttBackend() {
    const {
      type,
      endpoint_url,
      tenant_id,
      drm_endpoint_url,
      drm_tenant_id
    } = this.state;

    const { createOttBackend, addToast } = this.props;

    const ret = await createOttBackend({
      ott_backend_type: type,
      endpoint_url,
      tenant_id,
      drm_endpoint_url,
      drm_tenant_id
    });

    if (ret.isSuccess) {
      addToast("success", "Success", "OTT Backend successfully added!");
      this.closeModal();
    } else {
      addToast("error", "Error", "Error while adding OTT Backend. Try again.");
    }
  }

  closeModal() {
    const { closeAddModal } = this.props;

    this.setState(initialState);
    closeAddModal();
  }

  isValid(inputName) {
    if (inputName instanceof Array) {
      return inputName.every(i => this.isValid(i));
    }
    return !!this.state[inputName];
  }

  modalContent() {
    const {
      options,
      type,
      endpoint_url,
      tenant_id,
      drm_endpoint_url,
      drm_tenant_id,
    } = this.state;

    const isTypeValid = this.isValid("type");
    const isEndpointValid = this.isValid("endpoint_url");
    const isTenantValid = this.isValid("tenant_id");

    const { isLastStep, stepperComponent } = this.props;

    return (
      <DialogContent sx={{ display: "flex", gap: 3, flexDirection: "column", marginTop: 1, width: "80%", alignItems: "center", alignSelf: "center" }}>
        {stepperComponent}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3, alignItems: "center", width: "100%" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 3, width: "100%" }} >
            {!isTypeValid && <Alert sx={{ width: "fit-content" }} severity="error">
              OTT Backend type is not valid
            </Alert>}
            <FormControl fullWidth>
              <InputLabel id="ott-backend-type-label">OTT Backend type</InputLabel>
              <Select
                value={type}
                labelId="ott-backend-type-label"
                label="OTT Backend type"
                inputProps={{ readOnly: isLastStep }}
                onChange={
                  e => {
                    this.changeInput("type", e);
                  }
                }
              >
                {options.map(option =>
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            {!isEndpointValid && <Alert sx={{ width: "fit-content" }} severity="error">
              Endpoint URL is not valid
            </Alert>}
            <TextField label="Endpoint URL" inputProps={{ readOnly: isLastStep }} error={!isEndpointValid} value={endpoint_url} onChange={e => this.changeInput("endpoint_url", e)}/>
            {!isTenantValid && <Alert sx={{ width: "fit-content" }} severity="error">
              Tenant ID is not valid
            </Alert>}
            <TextField label="Tenant id" inputProps={{ readOnly: isLastStep }} error={!isTenantValid} value={tenant_id} onChange={e => this.changeInput("tenant_id", e)}/>
            <TextField label="DRM Endpoint URL" inputProps={{ readOnly: isLastStep }} value={drm_endpoint_url} onChange={e => this.changeInput("drm_endpoint_url", e)}/>
            <TextField label="DRM Tenant id" inputProps={{ readOnly: isLastStep }} value={drm_tenant_id} onChange={e => this.changeInput("drm_tenant_id", e)}/>
          </Box>
        </Box>
      </DialogContent>
    );
  }

  modalActions() {
    const { isLastStep, currentStep, previousStep, nextStep } = this.props;

    const isValid = this.isValid(["type", "endpoint_url", "tenant_id"]);
    return (
      [
        <Button variant="outlined"
          key="cancel"
          onClick={() => {
            this.closeModal();
          }} >
            Cancel
        </Button>,
        currentStep > 0 && <Button variant="outlined"
          key="previous"
          onClick={() => {
            previousStep();
          }}>
            Previous
        </Button>,
        !isLastStep && <Button variant="contained"
          key="next"
          disabled={!isValid}
          color="secondary"
          onClick={() => {
            nextStep();
          }}>
            Next
        </Button>,
        isLastStep && <Button variant="contained"
          key="save"
          color="secondary"
          onClick={() => {
            this.addOttBackend();
          }}>
            Save
        </Button>
      ]
    );
  }

  render() {
    const { modalOpened } = this.props;

    return (
      <Modal
        isOpen={modalOpened}
        closeModal={() => this.closeModal()}
        title="Add OTT Backend"
        customModalContent={() => this.modalContent()}
        actions={() => this.modalActions()}
        actionAlign="flex-end"
      >
      </Modal>
    );
  }
}

AddOttBackendModal.propTypes = {
  modalOpened: PropTypes.bool.isRequired,
  closeAddModal: PropTypes.func.isRequired,

  currentStep: PropTypes.number,
  previousStep: PropTypes.func,
  nextStep: PropTypes.func,
  stepperComponent: PropTypes.object,
  isLastStep: PropTypes.bool,

  createOttBackend: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  createOttBackend: createOttBackendAction,
  addToast: addToastAction
};

export default connect(null, mapDispatchToProps)(withStepper(
  AddOttBackendModal, [
    "Set OTT Backend information",
    "Sumup"
  ]));
