import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Drawer } from "@mui/material";

import Logo from "../Logo";
import Navigation from "../Navigation";

import PageNotFound from "../../containers/PageNotFound";

import "./Sidebar.css";

class Sidebar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      shortMenu: false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    const { shortMenu } = this.state;
    this.setState({ shortMenu: !shortMenu });
  }

  render() {
    const { shortMenu } = this.state;
    const { navigationItems, currentRoute } = this.props;
    return (
      <Drawer
        sx={{
          flexShrink: 0,
          width: shortMenu ? 80 : 400,
          flexBasis: shortMenu ? 80 : 300,
          "& .MuiDrawer-paper": {
            width: shortMenu ? 80 : 300,
            flexBasis: shortMenu ? 80 : 300,
            transition: "all 0.2s ease-in"
          },
        }}
        variant="permanent"
        anchor="left"
        open={!shortMenu}
        className={`${shortMenu ? "short-menu" : ""}`}
        id="sidebar"
      >
        <Logo />

        <Navigation
          items={navigationItems}
          currentRoute={currentRoute}
          shortMenu={shortMenu}
          toggleMenu={this.toggleMenu}
        />
      </Drawer>
    );
  }
}

Sidebar.defaultProps = {
  currentRoute: {
    route: "/404",
    title: "Page not found",
    private: false,
    showInMenu: false,
    component: PageNotFound,
  },
};

Sidebar.propTypes = {
  navigationItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentRoute: PropTypes.shape({
    route: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    private: PropTypes.bool,
    showInMenu: PropTypes.bool,
    component: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.func,
    ]).isRequired,
  }),
};

export default Sidebar;
