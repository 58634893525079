import { createAction } from "redux-actions";

/* Storage actions */
const storeChannelTiers = createAction("STORE_CHANNEL_TIERS");
const storeChannelTiersFilters = createAction("STORE_CHANNEL_TIERS_FILTERS");
const addChannelTier = createAction("ADD_CHANNEL_TIER");
const modifyChannelTier = createAction("MODIFY_CHANNEL_TIER");

/* API actions */
export const indexChannelTiers = createAction("API_INDEX_CHANNEL_TIERS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/channel_tiers",
  data,
  fullLoad,
  onSuccessAction: storeChannelTiers
}));

export const indexChannelTiersFilters = createAction("API_INDEX_CHANNEL_TIERS_FILTERS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/channel_tiers/filters",
  data,
  fullLoad,
  onSuccessAction: storeChannelTiersFilters
}));

export const createChannelTier = createAction("API_CREATE_CHANNEL_TIER", data => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "POST",
  url: "/channel_tiers",
  data,
  onSuccessAction: addChannelTier
}));

export const updateChannelTier = createAction("API_UPDATE_CHANNEL_TIER", (channelTierId, data) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "PUT",
  url: `/channel_tiers/${channelTierId}`,
  data,
  onSuccessAction: modifyChannelTier
}));

export const deleteChannelTier = createAction("API_DELETE_CHANNEL_TIER", channelTierId => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "DELETE",
  url: `/channel_tiers/${channelTierId}`,
  onSuccessAction: createAction("REMOVE_CHANNEL_TIER", () => ({ id: channelTierId }))
}));
