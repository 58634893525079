import { createAction } from "redux-actions";

import { showLineup } from "./lineups";
import {
  showService,
  associateTechChannelsToService
} from "./services";

/* Storage actions */
const storeLineupsServices = createAction("STORE_LINEUPS_SERVICES");
const storeSingleLineupsServices = createAction("STORE_SINGLE_LINEUPS_SERVICES");
const storeLineupsServicesFilters = createAction("STORE_LINEUPS_SERVICES_FILTERS");
const addLineupsService = createAction("ADD_LINEUPS_SERVICE");
const modifyLineupsService = createAction("MODIFY_LINEUPS_SERVICE");
export const modifyManyLineupsServices = createAction("MODIFY_MANY_LINEUPS_SERVICES");
export const removeManyLineupsServices = createAction("REMOVE_MANY_LINEUPS_SERVICES");

/* API actions */
export const indexLineupsServices = createAction("API_INDEX_LINEUPS_SERVICES", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/lineups_services",
  data,
  fullLoad,
  onSuccessAction: storeLineupsServices
}));

export const indexLineupsServicesFilters = createAction("API_INDEX_LINEUPS_SERVICES_FILTERS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/lineups_services/filters",
  data,
  fullLoad,
  onSuccessAction: storeLineupsServicesFilters
}));

export const showLineupsServices = createAction("API_SHOW_LINEUPS_SERVICES", lsId => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: `/lineups_services/${lsId}`,
  onSuccessAction: storeSingleLineupsServices
}));

export const createLineupsService = createAction("API_CREATE_LINEUPS_SERVICE", data => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "POST",
  url: "/lineups_services",
  data,
  onSuccessAction: addLineupsService
}));

export const updateLineupsService = createAction("API_UPDATE_LINEUPS_SERVICE", (lineupsServiceId, data) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "PUT",
  url: `/lineups_services/${lineupsServiceId}`,
  data,
  onSuccessAction: modifyLineupsService
}));

export const deleteLineupsService = createAction("API_DELETE_LINEUPS_SERVICE", (lineupsServiceId, lineupId) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "DELETE",
  url: `/lineups_services/${lineupsServiceId}`,
  onSuccessActions: [
    createAction("REMOVE_LINEUPS_SERVICE", () => ({ id: lineupsServiceId })),
    () => showLineup(lineupId)
  ]
}));

/* Specific API Actions */

/* create */

export const createLineupsServiceLogo = createAction("API_CREATE_LINEUPS_SERVICE_LOGO", (lineupsServiceId, data) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "POST",
  url: `/lineups_services/${lineupsServiceId}/logos`,
  data,
  onSuccessAction: createAction("STORE_LINEUP_SERVICE_LOGO", result => ({ id: lineupsServiceId, result: result.result }))
}));

export const removeLineupsServiceLogo = createAction("API_REMOVE_LINEUPS_SERVICE_LOGO", (lineupsServiceId, logoId) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "DELETE",
  url: `/lineups_services/${lineupsServiceId}/logos/${logoId}`,
  onSuccessAction: createAction("REMOVE_LINEUPS_SERVICE_LOGO", () => ({ id: lineupsServiceId, logoId }))
}));

export const createLineupsServiceWithServiceAndGenre = createAction("API_CREATE_LINEUPS_SERVICES_WITH_SERVICE_AND_GENRE", data => {
  const createLineupsServiceGenre = (lineupServiceId, genreId) => createAction("API_CREATE_LINEUPS_SERVICE_GENRE", () => (
    {
      baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
      method: "POST",
      url: `/lineups_services/${lineupServiceId}/genres`,
      data: { genre_id: genreId, lineups_service_id: lineupServiceId }
    }
  ))();

  const techChannelsActions = [];

  if (data.techChannels && Array.isArray(data.techChannels.ids)) {
    data.techChannels.ids.forEach(tcId => {
      techChannelsActions.push(serviceResult => associateTechChannelsToService(serviceResult.result.id, tcId));
    });
  }

  const onSuccessActions = [
    ...techChannelsActions,
    serviceResult => createAction("API_CREATE_LINEUPS_SERVICE", () => (
      {
        baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
        method: "POST",
        url: "/lineups_services",
        data: { ...data.lineupService, service_id: serviceResult.result.id },
        onSuccessActions: [
          ...(data.genres.ids || []).map(genreId => lineupServiceResult => createLineupsServiceGenre(lineupServiceResult.result.id, genreId)),
          ...(data.logos || []).map(logo => lineupServiceResult => createLineupsServiceLogo(lineupServiceResult.result.id, logo)),
          () => showLineup(data.lineupService.lineup_id),
          lineupServiceResult => showLineupsServices(lineupServiceResult.result.id),
          () => showService(serviceResult.result.id)
        ]
      }
    ))()
  ];

  return {
    baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
    method: "POST",
    url: "/services",
    data: data.service,
    onSuccessActions
  };
});

export const createLineupsServiceWithGenre = createAction("API_CREATE_LINEUPS_SERVICE", data => {
  const createLineupsServiceGenre = (lineupServiceId, genreId) => createAction("API_CREATE_LINEUPS_SERVICE_GENRE", () => (
    {
      baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
      method: "POST",
      url: `/lineups_services/${lineupServiceId}/genres`,
      data: { genre_id: genreId, lineups_service_id: lineupServiceId }
    }
  ))();

  return {
    baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
    method: "POST",
    url: "/lineups_services",
    data: data.lineupService,
    onSuccessActions: [
      ...(data.logos || []).map(logo => lineupServiceResult => createLineupsServiceLogo(lineupServiceResult.result.id, logo)),
      ...(data.genres.ids || []).map(genreId => lineupServiceResult => createLineupsServiceGenre(lineupServiceResult.result.id, genreId)),
      () => showLineup(data.lineupService.lineup_id),
      lineupServiceResult => showLineupsServices(lineupServiceResult.result.id)
    ]
  };
});

/* update */

export const updateLineupsServiceWithNewService = createAction("API_UPDATE_LINEUPS_SERVICES_WITH_NEW_SERVICE", data => {
  const techChannelsActions = [];

  if (data.techChannels && Array.isArray(data.techChannels.ids)) {
    data.techChannels.ids.forEach(tcId => {
      techChannelsActions.push(serviceResult => associateTechChannelsToService(serviceResult.result.id, tcId));
    });
  }

  const onSuccessActions = [
    ...techChannelsActions,
    serviceResult => createAction("API_CREATE_LINEUPS_SERVICE", () => (
      {
        baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
        method: "PUT",
        url: `/lineups_services/${data.lineupService.id}`,
        data: { ...data.lineupService, service_id: serviceResult.result.id },
        onSuccessActions: [
          () => showLineupsServices(data.lineupService.id),
          () => showService(serviceResult.result.id)
        ]
      }
    ))()
  ];

  return {
    baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
    method: "POST",
    url: "/services",
    data: data.service,
    onSuccessActions
  };
});
