import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { DialogTitle, Typography } from "@mui/material";

const COLOR_GREY = "text.grey";

const ModalTitle = props => {
  const { title, closeModal } = props;

  return (
    <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
      <Typography variant="h3" component="span" sx={{ pt: 1 }}>
        {title}
      </Typography>
      <CloseIcon sx={{ color: COLOR_GREY, cursor: "pointer" }} onClick={() => closeModal()}/>
    </DialogTitle>
  );
};

ModalTitle.propTypes = {
  title: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default ModalTitle;
