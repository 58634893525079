import { handleActions } from "redux-actions";

const initialState = {
  allOrbitalPositions: [],
  filters: [],
  totalCount: 0,
  currentOrbitalPositionId: null
};

const orbitalPositionsReducer = handleActions(
  {
    STORE_ORBITAL_POSITIONS: (state, action) => ({
      ...state,
      allOrbitalPositions: action.payload.result,
      totalCount: action.payload.total_count || 0
    }),
    STORE_ORBITAL_POSITIONS_FILTERS: (state, action) => ({
      ...state,
      filters: action.payload.result
    }),
    SELECT_ORBITAL_POSITIONS: (state, action) => ({
      ...state,
      currentOrbitalPositionId: parseInt(action.payload.result.id, 10)
    }),
    ADD_ORBITAL_POSITION: (state, action) => {
      const allOrbitalPositions = [...state.allOrbitalPositions];

      allOrbitalPositions.push(action.payload.result);

      return {
        ...state,
        allOrbitalPositions,
        totalCount: state.totalCount + 1
      };
    },
    MODIFY_ORBITAL_POSITION: (state, action) => {
      const newState = {
        ...state,
        allOrbitalPositions: [...state.allOrbitalPositions]
      };

      const orbitalPositionIndex = newState.allOrbitalPositions.findIndex(op => action.payload.result.id === op.id);

      newState.allOrbitalPositions[orbitalPositionIndex] = action.payload.result;

      return newState;
    },
    REMOVE_ORBITAL_POSITION: (state, action) => {
      const orbitalPositionIndex = state.allOrbitalPositions.findIndex(op => action.payload.id === op.id);
      const allOrbitalPositions = Array.from(state.allOrbitalPositions);

      allOrbitalPositions.splice(orbitalPositionIndex, 1);

      return {
        ...state,
        allOrbitalPositions,
        totalCount: orbitalPositionIndex > -1 ? state.totalCount - 1 : state.totalCount
      };
    }
  },
  initialState
);

export default orbitalPositionsReducer;
