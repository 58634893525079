/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AceEditor from "react-ace";

import {
  Alert,
  Box, Button,
  DialogContent, Fab, FormControl, FormHelperText,
  InputLabel,
  MenuItem, Paper,
  Select, Stack,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { addToast as addToastAction } from "../../actions/toasts";

import {
  createLineup as createLineupAction
} from "../../actions/lineups";

import { listOrbitalPositions } from "../../selectors/orbital_positions";
import { listOrbitalPositionsLineupsFromBeamId } from "../../selectors/lineups";
import { listBeams } from "../../selectors/beams";

import {
  toJsonString,
  isValidJson,
  isValidLocaleJson,
  getObjectNameFromLocales
} from "../../helpers/utils";
import { getImageAttributes } from "../../helpers/images";

import Modal from "../Modal/Modal";
import withStepper from "../Modal/withStepper";

const initialState = {
  beam_id: 0,
  phone_contact_support: "",
  url_contact_support: "",
  default_language: "",
  locales: toJsonString("{\"en_GB\":{\"name\":\"Lineup\",\"description\":\"\"}}"),
  parent_id: null,
  logos: [],
  logosPreviews: [],

  additionalImages: [],
  additionalImagesPreviews: [],

  formErrors: [],

  globalLoading: false
};

class AddLineupModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.fileInput = React.createRef();
    this.additionalInput = React.createRef();

    this.addLogo = this.addLogo.bind(this);
    this.addAdditional = this.addAdditional.bind(this);
  }

  async addLineup() {
    const {
      phone_contact_support,
      url_contact_support,
      default_language,
      locales,
      parent_id,

      beam_id,

      logos,
      additionalImages
    } = this.state;

    const { createLineup, addToast } = this.props;

    this.setState({ globalLoading: true });

    const logosData = [];

    for (let i = 0; i < logos.length; i += 1) {
      const imageAttributes = await getImageAttributes(logos[i]);
      const logoData = new FormData();

      logoData.append("file", logos[i]);
      logoData.append("delivery", "dvb");
      logoData.append("file_type", imageAttributes.fileType);
      logoData.append("width", imageAttributes.width);
      logoData.append("height", imageAttributes.height);

      logosData.push(logoData);
    }

    for (let i = 0; i < additionalImages.length; i += 1) {
      const imageAttributes = await getImageAttributes(additionalImages[i]);
      const logoData = new FormData();

      logoData.append("file", additionalImages[i]);
      logoData.append("delivery", "dvb");
      logoData.append("file_type", imageAttributes.fileType);
      logoData.append("width", imageAttributes.width);
      logoData.append("height", imageAttributes.height);
      logoData.append("additional", true);

      logosData.push(logoData);
    }

    const ret = await createLineup({
      lineup: {
        parent_id,
        phone_contact_support,
        url_contact_support,
        default_language,
        locales: JSON.parse(locales)
      },
      logos: logosData
    }, beam_id);

    this.setState({ globalLoading: false });

    if (ret.isSuccess) {
      addToast("success", "Success", "Lineup successfully added!");
      this.closeModal();
    } else {
      addToast("error", "Error", "Error while adding lineup. Try again.");
    }
  }

  changeInput(inputName, event) {
    const {
      default_language,
      beam_id
    } = this.state;

    const {
      listOrbitalPositionLineups
    } = this.props;

    let { value } = event.target;

    if (inputName === "parent_id" && parseInt(beam_id, 10)) {
      const lineups = listOrbitalPositionLineups(parseInt(beam_id, 10));
      const parent_id = parseInt(value, 10);
      const existingLineup = lineups.some(l => l.parent_id === null && l.id === parent_id);

      if (!existingLineup) {
        value = null;
      } else {
        value = parent_id;
      }
    }

    this.setState(() => ({ [inputName]: value }), () => {
      if (inputName === "locales") {
        let languageFound = true;

        try {
          const localesJson = JSON.parse(value);

          languageFound = Object.keys(localesJson).indexOf(default_language) !== -1;
        } catch {
          languageFound = false;
        }

        if (!languageFound) {
          this.setState({ default_language: initialState.default_language });
        }
      }
    });
  }

  closeModal() {
    const { closeAddLineupModal } = this.props;

    this.setState(initialState);
    closeAddLineupModal();
  }

  stepIsValid = () => {
    const { currentStep } = this.props;

    if (currentStep === 0) {
      return this.isBeamIdValid() && this.isDefaultLanguageValid() && this.isLocalesJsonValid() && this.isLocalesEntriesValid();
    } else if (currentStep === 1) {
      return this.isParentIdValid();
    } else if (currentStep === 2) {
      return this.isLogosValid();
    }

    return true;
  };

  isBeamIdValid() {
    const { beam_id } = this.state;
    const { beams } = this.props;

    return !!beams.find(b => b.id === parseInt(beam_id, 10));
  }

  isDefaultLanguageValid() {
    const { default_language } = this.state;

    return default_language && default_language.length === 5;
  }

  isLocalesJsonValid() {
    const { locales } = this.state;

    return locales && locales.length > 0 && isValidJson(locales) && isValidLocaleJson(locales);
  }

  isLocalesEntriesValid() {
    if (this.isLocalesJsonValid()) {
      const { locales, default_language } = this.state;

      let newDefaultLanguage = default_language;

      if (!JSON.parse(locales)[default_language]) {
        newDefaultLanguage = Object.keys(JSON.parse(locales)).find(localeKey => localeKey.length === 5);
      }

      const currentLineupName = JSON.parse(locales)[newDefaultLanguage].name || "";

      return currentLineupName && currentLineupName.length > 0 && currentLineupName.toString() === currentLineupName;
    }

    return true;
  }

  isParentIdValid() {
    const { parent_id, beam_id } = this.state;
    const { listOrbitalPositionLineups } = this.props;

    if (parent_id !== null) {
      const lineups = listOrbitalPositionLineups(parseInt(beam_id, 10));

      return lineups.some(l => l.parent_id === null && l.id === parseInt(parent_id, 10));
    }

    return true;
  }

  isLogosValid() {
    const { logos } = this.state;

    return logos && logos.length;
  }

  addLogo(event) {
    const { logos, logosPreviews } = this.state;

    if (this.fileInput.current.files.length > 0) {
      const file = this.fileInput.current.files[0];

      // eslint-disable-next-line no-param-reassign
      event.target.value = null;

      const newLogos = [...logos];

      file.id = new Date().valueOf();

      newLogos.push(file);

      this.setState({ logos: newLogos });

      const reader = new FileReader();

      reader.onload = e => {
        const newLogosPreviews = [...logosPreviews];

        newLogosPreviews.push({
          id: file.id,
          url: e.target.result
        });

        this.setState({
          logosPreviews: newLogosPreviews
        });
      };

      reader.readAsDataURL(file);
    }

    event.preventDefault();
    event.stopPropagation();
  }

  addAdditional(event) {
    const { additionalImages, additionalImagesPreviews } = this.state;

    if (this.additionalInput.current.files.length > 0) {
      const file = this.additionalInput.current.files[0];

      // eslint-disable-next-line no-param-reassign
      event.target.value = null;

      const newAdditionalImages = [...additionalImages];

      file.id = new Date().valueOf();

      newAdditionalImages.push(file);

      this.setState({ additionalImages: newAdditionalImages });

      const reader = new FileReader();

      reader.onload = e => {
        const newAdditionalImagesPreviews = [...additionalImagesPreviews];

        newAdditionalImagesPreviews.push({
          id: file.id,
          url: e.target.result
        });

        this.setState({
          additionalImagesPreviews: newAdditionalImagesPreviews
        });
      };

      reader.readAsDataURL(file);
    }

    event.preventDefault();
    event.stopPropagation();
  }

  removeLogo(logoId) {
    const { logos, logosPreviews } = this.state;
    const newLogos = logos.filter(l => l.id !== logoId);
    const newLogosPreviews = logosPreviews.filter(l => l.id !== logoId);

    this.setState({ logos: newLogos, logosPreviews: newLogosPreviews });
  }

  removeAdditional(logoId) {
    const { additionalImages, additionalImagesPreviews } = this.state;
    const newAdditionalImages = additionalImages.filter(l => l.id !== logoId);
    const newAdditionalImagesPreviews = additionalImagesPreviews.filter(l => l.id !== logoId);

    this.setState({
      additionalImages: newAdditionalImages,
      additionalImagesPreviews: newAdditionalImagesPreviews
    });
  }

  triggerLogoInput(event) {
    event.preventDefault();
    event.stopPropagation();

    this.fileInput.current.click();
  }

  triggerAdditionalLogoInput(event) {
    event.preventDefault();
    event.stopPropagation();

    this.additionalInput.current.click();
  }

  modalContent = () => {
    const {
      beam_id,
      phone_contact_support,
      url_contact_support,
      default_language,
      locales,
      parent_id,
      logosPreviews,
      additionalImagesPreviews
    } = this.state;

    const {
      orbitalPositions,
      beams,
      listOrbitalPositionLineups,
      currentStep,
      stepperComponent
    } = this.props;

    let computedDefaultLanguages;

    try {
      computedDefaultLanguages = Object.keys(JSON.parse(locales)).filter(l => l.length === 5);
    } catch {
      computedDefaultLanguages = [];
    }

    let lineups = [];

    if (beam_id) {
      lineups = listOrbitalPositionLineups(parseInt(beam_id, 10));
      lineups = lineups.filter(l => l.parent_id === null);
    }

    let orbitalPositionName = "";

    if (beam_id) {
      const beam = beams.find(b => b.id === parseInt(beam_id, 10));

      if (beam) {
        const orbitalPosition = orbitalPositions.find(op => op.id === beam.orbital_position_id);

        if (orbitalPosition) {
          orbitalPositionName = `${orbitalPosition.name} (Beam ${beam.name})`;
        }
      }
    }

    return (
      <DialogContent sx={{ display: "flex", gap: 3, flexDirection: "column", marginTop: 1, width: "80%", alignItems: "center", alignSelf: "center" }}>
        {stepperComponent}
        {currentStep === 0 && <Stack direction="row" justifyContent="space-between" spacing={3} width="100%">
          <Stack spacing={3} alignItems="flex-start" minWidth="250px" maxWidth="300px" width="100%">
            <FormControl error={!beam_id} fullWidth required>
              <InputLabel id="orbital-position-select">Orbital Position</InputLabel>
              <Select
                labelId="orbital-position-select"
                label="Orbital position"
                onChange={e => this.changeInput("beam_id", e)}
                value={beam_id}
              >
                {beam_id === 0 && <MenuItem value={0}>Select an orbital position</MenuItem>}
                {orbitalPositions.map(op => {
                  const currentBeams = beams.filter(b => b.orbital_position_id === op.id);
                  return currentBeams.map(cb => <MenuItem key={cb.id} value={cb.id}>{`${op.name} - Beam ${cb.name}`}</MenuItem>);
                })}
              </Select>
              { !this.isBeamIdValid() && <FormHelperText error>Orbital Position is not valid</FormHelperText> }
            </FormControl>
            <TextField label="Phone Contact Support" value={phone_contact_support} onChange={e => this.changeInput("phone_contact_support", e)} fullWidth />
            <TextField label="Url Contact Support" value={url_contact_support} onChange={e => this.changeInput("url_contact_support", e)} fullWidth />
            <FormControl error={computedDefaultLanguages.length < 1} fullWidth required>
              <InputLabel id="default-language-select">Default language</InputLabel>
              <Select
                labelId="default-language-select"
                label="Default language"
                onChange={e => this.changeInput("default_language", e)}
                value={default_language || ""}
              >
                {computedDefaultLanguages.map(dl => <MenuItem key={dl} value={dl}>{dl}</MenuItem>)}
              </Select>
              { !this.isDefaultLanguageValid() && <FormHelperText error>You must define locale json to select a default language</FormHelperText> }
            </FormControl>
          </Stack>
          <Stack width="100%">
            <Typography variant="caption">Locales *</Typography>
            <AceEditor
              style={{ maxWidth: "400px" }}
              mode="json"
              theme="tomorrow"
              fontSize="0.9rem"
              name="lineup-add-locales-edit"
              height="300px"
              setOptions={{
                useWorker: false,
                showLineNumbers: false
              }}
              value={locales}
              onChange={value => this.changeInput("locales", { target: { value } })}
              editorProps={{ $blockScrolling: true }}
            />
            {!this.isLocalesJsonValid()
              && <FormHelperText error>Locales json is not valid. Locales json should contain language keys with length equals to 5 characters</FormHelperText>}
            {!this.isLocalesEntriesValid()
              && <FormHelperText error>You have to define a name entry in the locales JSON. For example: {"{\"en_GB\": {\"name\": \"Lineup\", \"description\": \"\"}}"}</FormHelperText>}
          </Stack>
        </Stack>}
        {currentStep === 1 && <Stack spacing={3}>
          <Alert severity="info">
            <Typography variant="body2" align="center">
              You can associate another lineup as parent of this new one. If so, this parent lineup won't be exported on air.<br/>
              The configuration of this lineup (the child) will have priority over its parent.<br/>
              For example, if the LCN 42 is associated to a service in both parent and child lineups, the child lineup LCN 42 will be exported.
            </Typography>
          </Alert>
          <FormControl fullWidth>
            <InputLabel id="parent-lineup-select" focused>Parent Lineup</InputLabel>
            <Select
              labelId="parent-lineup-select"
              label="Parent lineup"
              onChange={e => this.changeInput("parent_id", e)}
              value={parent_id || ""}
              displayEmpty
              notched
            >
              <MenuItem value="">No parent lineup</MenuItem>
              {lineups.map(lineup => <MenuItem key={lineup.id} value={lineup.id}>{getObjectNameFromLocales(lineup)}</MenuItem>)}
            </Select>
          </FormControl>
        </Stack>}
        {currentStep === 2 && <>
          <Stack direction="row" spacing={3}>
            {logosPreviews.map(logo => <Paper
              sx={{
                backgroundColor: "text.grey",
                width: "100px",
                height: "100px",
                position: "relative"
              }}
              elevation={3}
              key={logo.id}
            >
              <Fab
                sx={{
                  position: "absolute",
                  bottom: "-15px",
                  right: "-15px"
                }}
                size="small"
                onClick={() => this.removeLogo(logo.id)}
              >
                <DeleteIcon />
              </Fab>
              <img style={{ width: "100%", position: "absolute", top: "0", bottom: "0", margin: "auto" }} src={logo.url} alt="" />
            </Paper>
            )}
            <Paper
              sx={{
                backgroundColor: "text.grey",
                width: "100px",
                height: "100px",
                position: "relative"
              }}
              elevation={3}
            >
              <Fab
                sx={{
                  position: "absolute",
                  bottom: "-15px",
                  right: "-15px"
                }}
                size="small"
                onClick={e => this.triggerLogoInput(e)}
              >
                <AddIcon />
              </Fab>
              <input
                style={{ display: "none" }}
                type="file"
                id="add-logo"
                accept="image/png, image/jpeg"
                ref={this.fileInput}
                onChange={this.addLogo}
              />
            </Paper>
          </Stack>
          {!this.isLogosValid() && <Typography variant="caption" color="error.main">At least one logo should be added</Typography>}
        </>}
        {currentStep === 3 && <Stack spacing={3}>
          <Alert severity="info">
            <Typography variant="body2" align="center">
              Additional resource(s) can be used to provide ads. Technically, it will be exported in the Eutelsat Additional Resources file (EAR json)
            </Typography>
          </Alert>
          <Stack direction="row" spacing={3} justifyContent="center">
            {additionalImagesPreviews.map(logo => <Paper
              sx={{
                backgroundColor: "text.grey",
                width: "100px",
                height: "100px",
                position: "relative"
              }}
              key={logo.id}
              elevation={3}
            >
              <Fab
                sx={{
                  position: "absolute",
                  bottom: "-15px",
                  right: "-15px"
                }}
                size="small"
                onClick={() => this.removeAdditional(logo.id)}>
                <DeleteIcon />
              </Fab>
              <img style={{ width: "100%", position: "absolute", top: "0", bottom: "0", margin: "auto" }} src={logo.url} alt="" />
            </Paper>)}
            <Paper
              sx={{
                backgroundColor: "text.grey",
                width: "100px",
                height: "100px",
                position: "relative"
              }}
              elevation={3}
            >
              <Fab
                sx={{
                  position: "absolute",
                  bottom: "-15px",
                  right: "-15px"
                }}
                size="small"
                onClick={e => this.triggerAdditionalLogoInput(e)}
              >
                <AddIcon />
              </Fab>
              <input
                style={{ display: "none" }}
                type="file"
                id="add-logo"
                accept="image/png, image/jpeg"
                ref={this.additionalInput}
                onChange={this.addAdditional}
              />
            </Paper>
          </Stack>
        </Stack>}
        {currentStep === 4 && <Stack spacing={3} alignItems="center">
          <Stack direction="row" spacing={3}>
            <Stack spacing={3}>
              <TextField sx={{ input: { cursor: "default" } }} label="Orbital Position" InputLabelProps={{ focused: true }} value={orbitalPositionName} fullWidth></TextField>
              <TextField sx={{ input: { cursor: "default" } }} label="Parent Lineup" InputLabelProps={{ focused: true }} value={parent_id ? getObjectNameFromLocales(lineups.find(l => l.id === parseInt(parent_id, 10)) || {}) : "None"} fullWidth></TextField>
              <TextField sx={{ input: { cursor: "default" } }} InputProps={{ notched: true }} label="Phone Contact Support" InputLabelProps={{ focused: true }} value={phone_contact_support} fullWidth></TextField>
              <TextField sx={{ input: { cursor: "default" } }} InputProps={{ notched: true }} label="Url Contact Support" InputLabelProps={{ focused: true }} value={url_contact_support} fullWidth></TextField>
            </Stack>
            <Stack spacing={1}>
              <TextField sx={{ input: { cursor: "default" } }} label="Default Language" InputLabelProps={{ focused: true }} value={default_language} fullWidth></TextField>
              <Box>
                <Typography variant="caption">Locales</Typography>
                <AceEditor
                  style={{ maxWidth: "300px" }}
                  mode="json"
                  theme="tomorrow"
                  fontSize="0.9rem"
                  name="lineup-add-locales-show"
                  readOnly
                  height="200px"
                  setOptions={{
                    useWorker: false,
                    showLineNumbers: false
                  }}
                  value={locales}
                  editorProps={{ $blockScrolling: true }}
                />
              </Box>
            </Stack>
          </Stack>
          <Typography variant="body2">Logo(s)</Typography>
          <Stack direction="row" spacing={3}>
            {logosPreviews.map(logo => <Paper
              sx={{
                backgroundColor: "text.grey",
                width: "100px",
                height: "100px",
                position: "relative"
              }}
              elevation={3}
              key={logo.id}
            >
              <img style={{ width: "100%", position: "absolute", top: "0", bottom: "0", margin: "auto" }} src={logo.url} alt="" />
            </Paper>
            )}
          </Stack>
          {additionalImagesPreviews.length > 0 && <>
            <Typography variant="body2">Additional resource(s)</Typography>
            <Stack direction="row" spacing={3}>
              {additionalImagesPreviews.map(logo => <Paper
                sx={{
                  backgroundColor: "text.grey",
                  width: "100px",
                  height: "100px",
                  position: "relative"
                }}
                key={logo.id}
                elevation={3}
              >
                <img style={{ width: "100%", position: "absolute", top: "0", bottom: "0", margin: "auto" }} src={logo.url} alt="" />
              </Paper>)}
            </Stack>
          </>}
        </Stack>}
      </DialogContent>
    );
  };

  modalActions() {
    const { currentStep, previousStep, nextStep, isLastStep } = this.props;
    const stepIsValid = this.stepIsValid();

    return (
      [
        <Button
          variant="outlined"
          key="cancel"
          onClick={() => {
            this.closeModal();
          }}>
          Cancel
        </Button>,
        currentStep > 0
        && <Button
          variant="outlined"
          key="previous"
          onClick={() => {
            previousStep();
          }}>
          Previous
        </Button>,
        !isLastStep
        && <Button
          variant="contained"
          color="primary"
          key="next"
          disabled={!stepIsValid}
          onClick={() => {
            nextStep();
          }}>
          Next
        </Button>,
        isLastStep
        && <Button
          variant="contained"
          color="primary"
          key="save"
          onClick={() => {
            this.addLineup();
          }}>
          Save
        </Button>
      ]
    );
  }

  render() {
    const {
      modalOpened
    } = this.props;

    return (
      <Modal
        isOpen={modalOpened}
        closeModal={() => this.closeModal()}
        title="Add a lineup"
        customModalContent={() => this.modalContent()}
        actions={() => this.modalActions()}
      />
    );
  }
}

AddLineupModal.defaultProps = {

};

AddLineupModal.propTypes = {
  modalOpened: PropTypes.bool.isRequired,
  closeAddLineupModal: PropTypes.func.isRequired,

  orbitalPositions: PropTypes.arrayOf(PropTypes.object).isRequired,
  beams: PropTypes.arrayOf(PropTypes.object).isRequired,

  currentStep: PropTypes.number,
  previousStep: PropTypes.func,
  nextStep: PropTypes.func,
  stepperComponent: PropTypes.object,
  isLastStep: PropTypes.bool,

  createLineup: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  listOrbitalPositionLineups: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    beams: listBeams(state),
    listOrbitalPositionLineups: beamId => listOrbitalPositionsLineupsFromBeamId(state, beamId),
    orbitalPositions: listOrbitalPositions(state)
  };
}

const mapDispatchToProps = {
  createLineup: createLineupAction,
  addToast: addToastAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withStepper(
  AddLineupModal, [
    "Set lineup info",
    "Associate a parent lineup (optional)",
    "Add logo(s)",
    "Add additional resource(s)",
    "Sumup"
  ]));
