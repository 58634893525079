import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import camelcase from "camelcase";

import { Navigate } from "react-router";
import ServiceGenres from "../../components/ServiceGenres";
import ContentGenres from "../../components/ContentGenres";
import ChannelTiers from "../../components/ChannelTiers";
import ServiceTypes from "../../components/ServiceTypes";
import OttBackends from "../../components/OttBackends";
import Providers from "../../components/Providers";
import Importances from "../../components/Importances";
import Tabs from "../../components/Tabs";

import AddServiceGenreModal from "../../components/ServiceGenresModal/AddServiceGenreModal";
import AddContentGenresModal from "../../components/ContentGenresModal/AddContentGenresModal";
import AddChannelTierModal from "../../components/ChannelTiersModal/AddChannelTierModal";
import AddServiceTypeModal from "../../components/ServiceTypesModal/AddServiceTypeModal";
import AddOttBackendModal from "../../components/OttBackendsModal/AddOttBackendModal";
import AddProviderModal from "../../components/ProvidersModal/AddProviderModal";
import AddImportanceModal from "../../components/ImportancesModal/AddImportanceModal";

import { getCurrentLocation } from "../../selectors/routes";

import { getTotalCount as getTotalCountServiceGenresSelector } from "../../selectors/service_genres";
import { getTotalCount as getTotalCountContentGenresSelector } from "../../selectors/content_genres";
import { getTotalCount as getTotalCountChannelTiersSelector } from "../../selectors/channel_tiers";
import { getTotalCount as getTotalCountServiceTypesSelector } from "../../selectors/service_types";
import { getTotalCount as getTotalCountOttBackendsSelector } from "../../selectors/ott_backends";
import { getTotalCount as getTotalCountProvidersSelector } from "../../selectors/providers";
import { getTotalCount as getTotalCountImportancesSelector } from "../../selectors/importances";

class GlobalObjects extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      servicesGenresModalOpened: false,
      contentsGenresModalOpened: false,
      channelTiersModalOpened: false,
      serviceTypesModalOpened: false,
      ottBackendsModalOpened: false,
      providersModalOpened: false,
      importancesModalOpened: false
    };
  }

  /* Tabs */

  openAddModal(type) {
    this.setState({ [`${type}ModalOpened`]: true });
  }

  closeAddModal(type) {
    this.setState({ [`${type}ModalOpened`]: false });
  }

  render() {
    const {
      servicesGenresModalOpened,
      contentsGenresModalOpened,
      channelTiersModalOpened,
      serviceTypesModalOpened,
      ottBackendsModalOpened,
      providersModalOpened,
      importancesModalOpened
    } = this.state;

    const {
      currentTab,
      totalCountServiceGenres,
      totalCountContentGenres,
      totalCountChannelTiers,
      totalCountServiceTypes,
      totalCountOttBackends,
      totalCountProviders,
      totalCountImportances
    } = this.props;

    if (currentTab === "global_objects") {
      return <Navigate to="/global_objects/services_genres" />;
    }

    const tabs = [
      {
        name:
  <Link to="/global_objects/services_genres">
    Channel Genres (
    {totalCountServiceGenres}
    )
  </Link>,
        slug: "services_genres",
        currentClassName: "active",
        allowCreation: true
      },
      {
        name:
  <Link to="/global_objects/contents_genres">
    Content Genres (
    {totalCountContentGenres}
    )
  </Link>,
        slug: "contents_genres",
        currentClassName: "active",
        allowCreation: true
      },
      {
        name:
  <Link to="/global_objects/channel_tiers">
    Channel Tiers (
    {totalCountChannelTiers}
    )
  </Link>,
        slug: "channel_tiers",
        currentClassName: "active",
        allowCreation: true
      },
      {
        name:
  <Link to="/global_objects/service_types">
    Service Types (
    {totalCountServiceTypes}
    )
  </Link>,
        slug: "service_types",
        currentClassName: "active",
        allowCreation: true
      },
      {
        name:
  <Link to="/global_objects/ott_backends">
    OTT Backends (
    {totalCountOttBackends}
    )
  </Link>,
        slug: "ott_backends",
        currentClassName: "active",
        allowCreation: true
      },
      {
        name:
  <Link to="/global_objects/providers">
    Providers (
    {totalCountProviders}
    )
  </Link>,
        slug: "providers",
        currentClassName: "active",
        allowCreation: true
      },
      {
        name:
  <Link to="/global_objects/importances">
    Importances (
    {totalCountImportances}
    )
  </Link>,
        slug: "importances",
        currentClassName: "active",
        allowCreation: true
      }
    ];

    const currentTabObject = tabs.find(t => t.slug === currentTab);
    const tabsActions = [];

    if (currentTabObject && currentTabObject.allowCreation) {
      const singularTabName = pluralize.singular(currentTabObject.slug.replace("_", " "));
      const camelSlug = camelcase(currentTabObject.slug);

      tabsActions.push({
        name: `New ${singularTabName}`,
        className: "btn-primary",
        action: () => this.openAddModal(camelSlug)
      });
    }

    return (
      <div id="global_objects" className="page-content">
        <Tabs
          changeTab={() => {}}
          currentTab={currentTab}
          tabs={tabs}
          tabsActions={tabsActions}
          smallTabs
        />

        {currentTab === "services_genres"
          && <ServiceGenres />}
        {currentTab === "contents_genres"
          && <ContentGenres />}
        {currentTab === "channel_tiers"
          && <ChannelTiers />}
        {currentTab === "service_types"
          && <ServiceTypes />}
        {currentTab === "ott_backends"
          && <OttBackends />}
        {currentTab === "providers"
          && <Providers />}
        {currentTab === "importances"
          && <Importances />}

        <AddServiceGenreModal
          modalOpened={servicesGenresModalOpened}
          closeAddModal={() => this.closeAddModal("servicesGenres")}
        />

        <AddContentGenresModal
          modalOpened={contentsGenresModalOpened}
          closeAddModal={() => this.closeAddModal("contentsGenres")}
        />

        <AddChannelTierModal
          modalOpened={channelTiersModalOpened}
          closeAddModal={() => this.closeAddModal("channelTiers")}
        />

        <AddServiceTypeModal
          modalOpened={serviceTypesModalOpened}
          closeAddModal={() => this.closeAddModal("serviceTypes")}
        />

        <AddOttBackendModal
          modalOpened={ottBackendsModalOpened}
          closeAddModal={() => this.closeAddModal("ottBackends")}
        />

        <AddProviderModal
          modalOpened={providersModalOpened}
          closeAddModal={() => this.closeAddModal("providers")}
        />
        <AddImportanceModal
          modalOpened={importancesModalOpened}
          closeAddModal={() => this.closeAddModal("importances")}
        />
      </div>
    );
  }
}

GlobalObjects.propTypes = {
  currentTab: PropTypes.string.isRequired,
  totalCountServiceGenres: PropTypes.number.isRequired,
  totalCountContentGenres: PropTypes.number.isRequired,
  totalCountChannelTiers: PropTypes.number.isRequired,
  totalCountServiceTypes: PropTypes.number.isRequired,
  totalCountOttBackends: PropTypes.number.isRequired,
  totalCountProviders: PropTypes.number.isRequired,
  totalCountImportances: PropTypes.number.isRequired
};

function mapStateToProps(state) {
  const route = getCurrentLocation(state).pathname.split("/");

  return {
    currentTab: route[route.length - 1] || "services_genres",
    totalCountServiceGenres: getTotalCountServiceGenresSelector(state),
    totalCountContentGenres: getTotalCountContentGenresSelector(state),
    totalCountChannelTiers: getTotalCountChannelTiersSelector(state),
    totalCountServiceTypes: getTotalCountServiceTypesSelector(state),
    totalCountOttBackends: getTotalCountOttBackendsSelector(state),
    totalCountProviders: getTotalCountProvidersSelector(state),
    totalCountImportances: getTotalCountImportancesSelector(state)
  };
}

export default connect(mapStateToProps)(GlobalObjects);
