import { createAction } from "redux-actions";

import { addBeam } from "./beams";
import { addTransponder } from "./transponders";

/* Selection actions */
export const selectOrbitalPosition = createAction("SELECT_ORBITAL_POSITIONS", id => ({ id }));

/* Storage actions */
const storeOrbitalPositions = createAction("STORE_ORBITAL_POSITIONS");
const storeOrbitalPositionsFilters = createAction("STORE_ORBITAL_POSITIONS_FILTERS");
const addOrbitalPosition = createAction("ADD_ORBITAL_POSITION");
const modifyOrbitalPosition = createAction("MODIFY_ORBITAL_POSITION");

/* API actions */
export const indexOrbitalPositions = createAction("API_INDEX_ORBITAL_POSITIONS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/orbital_positions",
  data,
  fullLoad,
  onSuccessAction: storeOrbitalPositions
}));

export const indexOrbitalPositionsFilters = createAction("API_INDEX_ORBITAL_POSITIONS_FILTERS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/orbital_positions/filters",
  data,
  fullLoad,
  onSuccessAction: storeOrbitalPositionsFilters
}));

export const createOrbitalPosition = createAction("API_CREATE_ORBITAL_POSITION", data => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "POST",
  url: "/orbital_positions",
  data,
  onSuccessAction: addOrbitalPosition
}));

export const createOrbitalPositionWithBeamsAndTransponders = createAction("API_CREATE_ORBITAL_POSITION_WITH_BEAMS_AND_TRANSONDERS", data => {
  // eslint-disable-next-line camelcase
  const { name, prodbr_id, beams } = data;

  const onSuccessActions = [
    addOrbitalPosition
  ];

  if (beams && Array.isArray(beams)) {
    beams.forEach(beam => {
      const onSuccessActionsBeams = [
        addBeam
      ];

      if (beam.transponders && Array.isArray(beam.transponders)) {
        beam.transponders.forEach(transponder => {
          onSuccessActionsBeams.push(beamResult => createAction("API_CREATE_TRANSPONDER", () => ({
            baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
            method: "POST",
            url: "/transponders",
            data: {
              beam_id: beamResult.result.id,
              name: transponder.name,
              theoretical_onid: transponder.theoretical_onid,
              theoretical_tsid: transponder.theoretical_tsid,
              broadcast_onid: transponder.broadcast_onid,
              broadcast_tsid: transponder.broadcast_tsid
            },
            onSuccessAction: addTransponder
          }))());
        });
      }

      onSuccessActions.push(opResult => createAction("API_CREATE_BEAM", () => ({
        baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
        method: "POST",
        url: "/beams",
        data: {
          orbital_position_id: opResult.result.id,
          name: beam.name
        },
        onSuccessActions: onSuccessActionsBeams
      }))());
    });
  }

  return {
    baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
    method: "POST",
    url: "/orbital_positions",
    data: { name, prodbr_id },
    onSuccessActions
  };
});

export const updateOrbitalPosition = createAction("API_UPDATE_ORBITAL_POSITION", (orbitalPositionId, data) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "PUT",
  url: `/orbital_positions/${orbitalPositionId}`,
  data,
  onSuccessAction: modifyOrbitalPosition
}));

export const deleteOrbitalPosition = createAction("API_DELETE_ORBITAL_POSITION", orbitalPositionId => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "DELETE",
  url: `/orbital_positions/${orbitalPositionId}`,
  onSuccessAction: createAction("REMOVE_ORBITAL_POSITION", () => ({ id: orbitalPositionId }))
}));
