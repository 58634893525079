export const listBeamsLineups = state => state.beamsLineups.allBeamsLineups;

export const showBeamLineups = (state, beamLineupId) => state.beamsLineups.allBeamsLineups.find(beamsLineups => beamsLineups.id === beamLineupId);

export const showBeamLineupsFromLineupId = (state, lineupId) => state.beamsLineups.allBeamsLineups.filter(beamsLineups => beamsLineups.lineup_id === lineupId);

export const showBeamsLineupsFromBeamId = (state, beamId) => state.beamsLineups.allBeamsLineups.filter(beamsLineups => beamsLineups.beam_id === beamId);
export const showBeamsLineupsFromBeamIds = (state, beamIds) => state.beamsLineups.allBeamsLineups.filter(beamsLineup => beamIds.indexOf(beamsLineup.beam_id) !== -1);

export const getTotalCount = state => state.beamsLineups.totalCount;

export const listBeamsLineupsFilters = state => state.beamsLineups.filters;
