/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faCheck } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { Box, DialogContent, Typography, Button, Alert, TextField, Checkbox } from "@mui/material";
import Modal from "../Modal/Modal";
import withStepper from "../Modal/withStepper";
import { addToast as addToastAction } from "../../actions/toasts";

import {
  createChannelTier as createChannelTierAction
} from "../../actions/channel_tiers";

const initialState = {
  name: "",
  is_channel_logo_optional: false,
  is_epg_description_optional: false,
  is_epg_logo_optional: false,
  min_epg_duration: 0,
};

class AddChannelTierModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { ...initialState };
  }

  changeInput(inputName, event) {
    const { value } = event.target;

    this.setState(() => ({ [inputName]: value }));
  }

  toggleInput(inputName) {
    const { [inputName]: inputValue } = this.state;

    this.setState({ [inputName]: !inputValue });
  }

  async addChannelTier() {
    const {
      name,
      is_channel_logo_optional,
      is_epg_description_optional,
      is_epg_logo_optional,
      min_epg_duration
    } = this.state;

    const { createChannelTier, addToast } = this.props;

    const ret = await createChannelTier({
      name,
      is_channel_logo_optional,
      is_epg_description_optional,
      is_epg_logo_optional,
      min_epg_duration: parseInt(min_epg_duration, 10)
    });

    if (ret.isSuccess) {
      addToast("success", "Success", "Channel Tier successfully added!");
      this.closeModal();
    } else {
      addToast("error", "Error", "Error while adding Channel Tier. Try again.");
    }
  }

  closeModal() {
    const { closeAddModal } = this.props;

    this.setState(initialState);
    closeAddModal();
  }

  minEpgDurationIsValid() {
    const { min_epg_duration } = this.state;

    if (!min_epg_duration) {
      return false;
    }
    if (parseInt(min_epg_duration, 10) <= 0) {
      return false;
    }
    return true;
  }

  modalContent() {
    const {
      name,
      is_channel_logo_optional,
      is_epg_description_optional,
      is_epg_logo_optional,
      min_epg_duration
    } = this.state;

    const { currentStep, isLastStep, stepperComponent } = this.props;

    return (
      <DialogContent sx={{ display: "flex", gap: 3, flexDirection: "column", marginTop: 1, width: "80%", alignItems: "center", alignSelf: "center" }}>
        {stepperComponent}
        <Box sx={{ alignItems: "flex-start", display: "flex", flexDirection: "column", gap: 3, width: "100%" }}>
          {!name && <Alert severity="error">Name is not valid</Alert>}
          <TextField error={!name} inputProps={{ readOnly: isLastStep }} label="Name" value={name} onChange={() => this.setState({ name: event.target.value })}/>
          <Box sx={{ display: "flex", alignItems: "center", cursor: !isLastStep && "pointer" }}
            onClick={() => {
              if (!isLastStep) {
                this.toggleInput("is_channel_logo_optional");
              }
            }}>
            <Box sx={{ width: "50px", height: "50px", display: "flex", alignItems: "center", justifyContent: "center" }}>
              {!isLastStep
                ? <Checkbox sx={{ padding: 0, margin: 0 }} checked={is_channel_logo_optional}/>
                : <FontAwesomeIcon icon={is_channel_logo_optional ? faCheck : faXmark} size="xl"/>
              }
            </Box>
            <Typography sx={{ userSelect: "none" }} >Is channel logo optional ?</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", cursor: !isLastStep && "pointer" }}
            onClick={ () => {
              if (!isLastStep) {
                this.toggleInput("is_epg_logo_optional");
              }
            }}>
            <Box sx={{ width: "50px", height: "50px", display: "flex", alignItems: "center", justifyContent: "center" }}>
              {!isLastStep
                ? <Checkbox sx={{ padding: 0, margin: 0 }} checked={is_epg_logo_optional}/>
                : <FontAwesomeIcon icon={is_epg_logo_optional ? faCheck : faXmark} size="xl"/>
              }
            </Box>
            <Typography sx={{ userSelect: "none" }} >Is EPG logo optional ?</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", cursor: !isLastStep && "pointer" }}
            onClick={() => {
              if (!isLastStep) {
                this.toggleInput("is_epg_description_optional");
              }
            }}>
            <Box sx={{ width: "50px", height: "50px", display: "flex", alignItems: "center", justifyContent: "center" }}>
              {!isLastStep
                ? <Checkbox sx={{ padding: 0, margin: 0 }} checked={is_epg_description_optional}/>
                : <FontAwesomeIcon icon={is_epg_description_optional ? faCheck : faXmark} size="xl"/>
              }
            </Box>
            <Typography sx={{ userSelect: "none" }} >Is EPG description optional ?</Typography>
          </Box>
          {!this.minEpgDurationIsValid() && <Alert severity="error">Min. EPG Duration is not valid</Alert>}
          <TextField sx={{ width: "40%" }} error={!this.minEpgDurationIsValid()} inputProps={{ readOnly: isLastStep }} type="number" label="Minimum EPG Duration * (in seconds)" value={min_epg_duration} onChange={() => this.setState({ min_epg_duration: event.target.value })}/>
        </Box>
      </DialogContent>
    );
  }

  modalActions() {
    const { name } = this.state;
    const { currentStep, previousStep, nextStep, isLastStep } = this.props;

    return (
      [
        <Button variant="outlined"
          key="cancel"
          onClick={() => {
            this.closeModal();
          }} >
          Cancel
        </Button>,
        currentStep > 0 && <Button variant="outlined"
          key="previous"
          onClick={() => {
            previousStep();
          }}>
          Previous
        </Button>,
        !isLastStep && <Button variant="contained"
          key="next"
          disabled={!this.minEpgDurationIsValid() || !name }
          color="secondary"
          onClick={() => {
            nextStep();
          }}>
          Next
        </Button>,
        isLastStep && <Button variant="contained"
          key="save"
          color="secondary"
          onClick={() => {
            this.addChannelTier();
          }}>
            Save
        </Button>
      ]
    );
  }

  render() {
    const {
      modalOpened
    } = this.props;

    return (
      <Modal
        isOpen={modalOpened}
        title="Add channel tier"
        customModalContent={() => this.modalContent()}
        closeModal={() => this.closeModal()}
        actions={() => this.modalActions()}
        actionAlign="flex-end"
      />
    );
  }
}

AddChannelTierModal.propTypes = {
  modalOpened: PropTypes.bool.isRequired,
  closeAddModal: PropTypes.func.isRequired,

  currentStep: PropTypes.number,
  previousStep: PropTypes.func,
  nextStep: PropTypes.func,
  stepperComponent: PropTypes.object,
  isLastStep: PropTypes.bool,

  createChannelTier: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  createChannelTier: createChannelTierAction,
  addToast: addToastAction
};

export default connect(null, mapDispatchToProps)(withStepper(
  AddChannelTierModal, [
    "Set channel tier information",
    "Sumup"
  ]));
