import { render } from "@testing-library/react";
import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Alert, Checkbox } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import {
  faCheck,
  faFlag,
  faTrash,
  faHourglassHalf,
  faBan,
  faExclamationCircle,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import WarningIcon from "@mui/icons-material/Warning";
import {
  capitalize,
  stringifyQualificationType,
} from "../../helpers/utils";
import FormatTechChannel from "./FormatTechChannel";
import FormatTransponder from "./FormatTransponder";

const TECH_CHANNEL = "TechChannel";
const TRANSPONDER = "Transponder";
const CREATION = "Creation";
const UPDATE = "Update";
const DELETION = "Deletion";
const ACTION_CREATE = "create";
const ACTION_CREATE_TO_TRANSFER = "createtotransfer";
const ACTION_DISMISS_QUALIF = "dismissqualif";
const ACTION_UPDATE = "update";
const ACTION_UPDATE_PENDING = "updatepending";
const ACTION_UPDATE_TRANSFER = "updatetransfer";
const ACTION_TRANSFER_LIST = "transferlist";
const ACTION_DISCARD = "discard";
const ACTION_DELETE = "delete";
const ACTION_DELETE_QUALIF = "deletequalif";

const ICON_TRASH = faTrash;
const ICON_FLAG = faFlag;
const ICON_CHECK = faCheck;
const ICON_HOURGLASS = faHourglassHalf;
const ICON_BAN = faBan;
const ICON_EXCLAMATION = faExclamationCircle;
const ICON_QUESTION = faQuestion;

const COLOR_DELETE = "error.main";
const COLOR_PENDING = "warning.main";
const COLOR_SUCCESS = "success.main";
const COLOR_FLAG = "primary.canard";
const COLOR_DISMISS = "text.grey";

const ActionButtons = {
  [TECH_CHANNEL]: {
    [ACTION_CREATE]: {
      text: "Create this technical channel in the \"Pending\" status",
      icon: ICON_HOURGLASS,
      color: COLOR_PENDING,
    },
    [ACTION_CREATE_TO_TRANSFER]: {
      text: "Create this technical channel in the \"To transfer\" status",
      icon: ICON_EXCLAMATION,
      color: COLOR_PENDING,
    },
    [ACTION_DISMISS_QUALIF]: {
      text: "Discard the technical channel",
      icon: ICON_BAN,
      color: COLOR_DELETE,
      alert: {
        text: "This notification will be dismissed. If changes appear again for this technical channel, a notification will be raised",
        severity: "error",
      },
    },
    [ACTION_UPDATE]: {
      text: "Update information",
      icon: ICON_CHECK,
      color: COLOR_SUCCESS,
      alert: {
        text: "The technical channel information will be updated and all services link are kept",
        severity: "warning",
      },
    },
    [ACTION_UPDATE_PENDING]: {
      text: "Put in pending, update information and remove any associated services in lineups",
      icon: ICON_HOURGLASS,
      color: COLOR_PENDING,
      alert: {
        text: "This technical channel information will be updated. All link with associated services in lineups will be broken and the technical channel will be put in the pending list",
        severity: "error",
      },
    },
    [ACTION_UPDATE_TRANSFER]: {
      text: "Put in transfer, update information and remove any associated services in lineups",
      icon: ICON_EXCLAMATION,
      color: COLOR_PENDING,
      alert: {
        text: "This technical channel information will be updated. All link with associated services in lineups will be broken and the technical channel will be put in the transfer list",
        severity: "error",
      },
    },
    [ACTION_TRANSFER_LIST]: {
      text: "Flag the notification as transfer list",
      icon: ICON_FLAG,
      color: COLOR_FLAG,
    },
    [ACTION_DISCARD]: {
      text: "Discard the technical channel, update information and remove any associated services in lineups",
      icon: ICON_BAN,
      color: COLOR_DELETE,
      alert: {
        text: "This technical channel will be discard. All link with associated services in lineups will be broken and the technical channel will be put in the discard list",
        severity: "error",
      },
    },
    [ACTION_DELETE_QUALIF]: {
      text: "Dismiss this notification",
      icon: ICON_TRASH,
      color: COLOR_DISMISS,
      alert: {
        text: "This notification will be dismissed. If changes appear again for this technical channel, a notification will be raised",
        severity: "error",
      },
    },
    [ACTION_DELETE]: {
      text: "Delete this technical channel and remove any associated services in lineups",
      icon: ICON_BAN,
      color: COLOR_DELETE,
      alert: {
        text: "This technical channel will be removed with all the associated services in any lineups, if this technical channel is the only one for these services",
        severity: "error",
      },
    },
  },
  [TRANSPONDER]: {
    [ACTION_UPDATE]: {
      text: "Update information",
      icon: ICON_CHECK,
      color: COLOR_SUCCESS,
      alert: {
        text: "The transponder information will be updated",
        severity: "warning",
      },
    },
    [ACTION_DELETE]: {
      text: "Delete this transponder",
      icon: ICON_BAN,
      color: COLOR_DELETE,
      alert: {
        text: "This transponder will be deleted",
        severity: "error",
      },
    },
  },
};

const Infos = props => {
  const {
    notifData,
    transponders,
    techChannels,
    canBeProcessed,
    processQualification,
  } = props;

  const [currentAction, setCurrentAction] = useState(null);
  const [newData, setNewData] = useState(false);
  const [
    deleteEditorialChannelIfPossible,
    setDeleteEditorialChannelIfPossible,
  ] = useState(false);

  useEffect(() => {
    setCurrentAction("");
    setDeleteEditorialChannelIfPossible(false);
  }, [notifData]);

  const dataType = () =>
    notifData.data_type
      ? <Typography sx={{ fontWeight: "bold" }}>
        {capitalize(stringifyQualificationType(notifData.data_type))}
      </Typography>
      : <Box sx={{ color: COLOR_DELETE }}>No data type</Box>;

  const qualificationType = () =>
    notifData.qualification_type
      ? <Typography sx={{ color: "text.grey" }}>{notifData.qualification_type}</Typography>
      : <Typography sx={{ color: COLOR_DELETE }}>
        No qualification type
      </Typography>;

  const associatedTechChannelStatus = () => {
    if (
      notifData.data_type === TECH_CHANNEL
      && notifData.qualification_type !== CREATION
    ) {
      if (
        currentAction === ACTION_DISCARD
        || currentAction === ACTION_DELETE
        || currentAction === ACTION_UPDATE_PENDING
        || currentAction === ACTION_UPDATE_TRANSFER
      ) {
        const techChannel = techChannels.find(
          tc => tc.id === notifData.data.id
        );
        return techChannel ? techChannel.status : null;
      }
    }

    return null;
  };

  const formatQualifData = () => {
    /* TECH CHANNEL */
    if (notifData.data_type === TECH_CHANNEL) {
      return (
        <FormatTechChannel
          notifData={notifData}
          transponders={transponders}
          techChannels={techChannels}
          qualificationTypes={{ CREATION, UPDATE, DELETION }}
          colors={{ COLOR_DELETE, COLOR_SUCCESS }}
        />);
    }

    /* TRANSPONDER */
    if (notifData.data_type === TRANSPONDER) {
      return (
        <FormatTransponder
          notifData={notifData}
          transponders={transponders}
          techChannels={techChannels}
          qualificationTypes={{ UPDATE, DELETION }}
          colors={{ COLOR_DELETE, COLOR_PENDING, COLOR_SUCCESS }}
        />);
    }
    return JSON.stringify(notifData.data);
  };

  const formatChosenAction = action => action
    ? <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Typography sx={{ textAlign: "center", width: "100%" }}>
        {ActionButtons[notifData.data_type][action].text}
      </Typography>
      <FontAwesomeIcon
        size="xl"
        icon={ActionButtons[notifData.data_type][action].icon}
      />
    </Box>
    : <Typography>No action</Typography>;
  const button = action =>
    <Button
      sx={{
        "&.MuiButtonBase-root:hover": {
          color: "white",
          backgroundColor:
              ActionButtons[notifData.data_type][action].color,
        },
        border: "1px solid",
        borderRadius: "10px",
        color:
            action !== currentAction
              ? ActionButtons[notifData.data_type][action].color
              : "white",
        borderColor:
            ActionButtons[notifData.data_type][action].color,
        backgroundColor:
            action === currentAction
              ? ActionButtons[notifData.data_type][action].color
              : "transparent",
      }}
      onClick={() => {
        setCurrentAction(action);
      }}
    >
      {formatChosenAction(action)}
    </Button>;
  const formatAction = () => {
    if (notifData.data_type === TECH_CHANNEL) {
      if (notifData.qualification_type === CREATION) {
        return (
          <>
            {!canBeProcessed
              && <Typography sx={{ color: COLOR_DELETE }}>
                This{" "}
                {stringifyQualificationType(notifData.data_type)}{" "}
                cannot be created because referenced transponder or orbital
                postion do not exist.
              </Typography>
            }
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1.5,
              }}
            >
              {button(ACTION_CREATE)}
              {button(ACTION_CREATE_TO_TRANSFER)}
              {button(ACTION_DISMISS_QUALIF)}
            </Box>
          </>
        );
      } else if (notifData.qualification_type === UPDATE) {
        const currentTC = techChannels.find(
          tc => tc.id === notifData.data.id
        );

        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1.5,
            }}
          >
            {currentTC
              && notifData.transferlist === false
              && currentTC.status === "active"
              && button(ACTION_UPDATE)}
            {button(ACTION_UPDATE_PENDING)}
            {button(ACTION_UPDATE_TRANSFER)}
            {currentTC
              && notifData.transferlist === false
              && currentTC.status === "active"
              && button(ACTION_TRANSFER_LIST)}
            {button(ACTION_DISCARD)}
            {button(ACTION_DELETE_QUALIF)}
          </Box>
        );
      }
      if (notifData.qualification_type === DELETION) {
        const currentTC = techChannels.find(
          tc => tc.id === notifData.data.id
        );

        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1.5,
            }}
          >
            {currentTC
              && notifData.transferlist === false
              && currentTC.status === "active"
              && button(ACTION_TRANSFER_LIST)}
            {button(ACTION_DELETE)}
            {button(ACTION_DELETE_QUALIF)}
          </Box>
        );
      }
    }
    if (notifData.data_type === TRANSPONDER) {
      if (notifData.qualification_type === UPDATE) {
        return button(ACTION_UPDATE);
      }
      if (notifData.qualification_type === DELETION) {
        const activeTCs = techChannels.filter(
          tc =>
            tc.status === "active"
            && tc.transponder_id === notifData.data.transponder_id
        );

        return !activeTCs.length
          ? button(ACTION_DELETE)
          : <Typography sx={{ color: COLOR_DELETE }}>
            This transponder cannot be deleted because it owns active technical
            channels
          </Typography>;
      }
    }
    return <Typography>No action available</Typography>;
  };
  const alert = () =>
    <Alert
      severity={
        ActionButtons[notifData.data_type][currentAction].alert
          .severity
      }
      iconMapping={{ error: <WarningIcon /> }}
      sx={{
        border: "1px solid",
        borderColor: COLOR_DELETE,
        alignItems: "center",
      }}
    >
      <Typography>
        {
          ActionButtons[notifData.data_type][currentAction].alert
            .text
        }
      </Typography>
    </Alert>;
  const checkbox = () =>
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Typography variant="h6" component="span">
          Should we archive associated Editorial Channel if it is not use
          anymore ?
      </Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Checkbox
          value={deleteEditorialChannelIfPossible}
          onChange={() => {
            setDeleteEditorialChannelIfPossible(
              !deleteEditorialChannelIfPossible
            );
          }}
        />
        <Typography variant="h5" component="span" sx={{ width: "20px" }}>
          {deleteEditorialChannelIfPossible ? "Yes" : "No"}
        </Typography>
      </Box>
    </Box>;
  return (
    <Box className="infos" sx={{ display: "flex", marginTop: 3, gap: 2 }}>
      <Box
        className="details"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          border: "0.5px solid",
          borderRadius: "7px",
          flex: 1,
          padding: 2,
        }}
      >
        <Box sx={{}}>
          {dataType()}
          {qualificationType()}
        </Box>
        <Box sx={{}}>{formatQualifData()}</Box>
        <Box>{formatAction()}</Box>
      </Box>
      <Box
        className="action"
        sx={{
          flex: 1,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 2,
            border: "0.5px solid",
            borderRadius: "7px",
            padding: 4,
            alignItems: "center",
            justifyContent: "space-between",
            color: currentAction
              ? ActionButtons[notifData.data_type][currentAction].color
              : "primary.main",
          }}
        >
          {!currentAction
            ? <FontAwesomeIcon size="2xl" icon={ICON_QUESTION} />
            : <FontAwesomeIcon
              size="2xl"
              icon={ActionButtons[notifData.data_type][currentAction].icon}
            />
          }
          <Typography variant="h5" component="span" sx={{ width: "100%", textAlign: "center" }}>
            {!currentAction
              ? "No action chosen"
              : ActionButtons[notifData.data_type][currentAction].text}
          </Typography>
          {!currentAction
            ? <FontAwesomeIcon size="2xl" icon={ICON_QUESTION} />
            : <FontAwesomeIcon
              size="2xl"
              icon={ActionButtons[notifData.data_type][currentAction].icon}
            />
          }
        </Box>
        {currentAction
          && <>
            {ActionButtons[notifData.data_type][currentAction].alert && alert()}
            {associatedTechChannelStatus() === "active" && checkbox()}
            <Button
              variant="contained"
              sx={{
                width: "fit-content",
                alignSelf: "center",
                color: "primary.main",
                backgroundColor: "secondary.main",
                ":hover": { backgroundColor: "secondary.main_hover" },
              }}
              onClick={() => {
                processQualification(
                  currentAction,
                  deleteEditorialChannelIfPossible
                );
              }}
            >
              Execute this action
            </Button>
          </>
        }
      </Box>
    </Box>
  );
};

Infos.propTypes = {
  notifData: PropTypes.object,
  transponders: PropTypes.array.isRequired,
  techChannels: PropTypes.array.isRequired,
  canBeProcessed: PropTypes.bool.isRequired,
  processQualification: PropTypes.func.isRequired,
};

export default Infos;
