import { createAction } from "redux-actions";

/* Storage actions */
const storeQualifications = createAction("STORE_QUALIFICATIONS");
const storeQualificationsFilters = createAction("STORE_QUALIFICATIONS_FILTERS");
const addQualification = createAction("ADD_QUALIFICATION");
const modifyQualification = createAction("MODIFY_QUALIFICATION");

/* API actions */
export const indexQualifications = createAction("API_INDEX_QUALIFICATIONS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/qualifications",
  data,
  fullLoad,
  onSuccessAction: storeQualifications
}));

export const indexQualificationsFilters = createAction("API_INDEX_QUALIFICATIONS_FILTERS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/qualifications/filters",
  data,
  fullLoad,
  onSuccessAction: storeQualificationsFilters
}));

export const createQualification = createAction("API_CREATE_QUALIFICATION", data => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "POST",
  url: "/qualifications",
  data,
  onSuccessAction: addQualification
}));

export const updateQualification = createAction("API_UPDATE_QUALIFICATION", (qualificationId, data) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "PUT",
  url: `/qualifications/${qualificationId}`,
  data,
  onSuccessAction: modifyQualification
}));

export const deleteQualification = createAction("API_DELETE_QUALIFICATION", qualificationId => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "DELETE",
  url: `/qualifications/${qualificationId}`,
  onSuccessAction: createAction("REMOVE_QUALIFICATION", () => ({ id: qualificationId }))
}));
