import { createAction } from "redux-actions";

import { addToast } from "./toasts";

const storeReplication = createAction("STORE_REPLICATION");

const headers = {
  "X-Api-Key": process.env.REACT_APP_REPLICATION_API_TOKEN
};

const ENDPOINT = process.env.REACT_APP_REPLICATION_API_ENDPOINT;
const url = "/replications";

export const showReplication = createAction("API_SHOW_REPLICATION", () => ({
  method: "GET",
  baseURL: ENDPOINT,
  url,
  headers,
  onSuccessAction: storeReplication
}));

export const triggerReplication = createAction("API_TRIGGER_REPLICATION", () => ({
  method: "POST",
  baseURL: ENDPOINT,
  url,
  headers,
  onSuccessActions: [
    storeReplication,
    () => addToast("success", "Success", "Successfully triggered replication")
  ]
}));
