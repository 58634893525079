/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Box, DialogContent, Button, Alert } from "@mui/material";
import AceEditor from "react-ace";
import Modal from "../Modal/Modal";
import withStepper from "../Modal/withStepper";

import { addToast as addToastAction } from "../../actions/toasts";

import {
  createServiceGenre as createServiceGenreAction
} from "../../actions/service_genres";

import {
  toJsonString,
  isValidJson,
  isValidLocaleJson
} from "../../helpers/utils";

const initialState = {
  locales: toJsonString("{\"en_GB\":{\"name\":\"My genre\"}}"),
};

class AddServiceGenreModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { ...initialState };
  }

  changeInput(inputName, event) {
    const { value } = event.target;

    this.setState(() => ({ [inputName]: value }));
  }

  async addServiceGenre() {
    const { locales } = this.state;
    const { createServiceGenre, addToast } = this.props;
    const ret = await createServiceGenre({ locales: JSON.parse(locales) });

    if (ret.isSuccess) {
      addToast("success", "Success", "Channel genre successfully added!");
      this.closeModal();
    } else {
      addToast("error", "Error", "Error while adding channel genre. Try again.");
    }
  }

  closeModal() {
    const { closeAddModal } = this.props;

    this.setState(initialState);
    closeAddModal();
  }

  isLocalesValid() {
    const { locales } = this.state;

    return locales && locales.length !== 0 && isValidJson(locales) && isValidLocaleJson(locales);
  }

  isNameValid() {
    const { locales } = this.state;

    if (locales
      && locales.length !== 0
      && isValidJson(locales)
      && isValidLocaleJson(locales)
    ) {
      const localesObject = JSON.parse(locales);
      const languages = Object.keys(localesObject);
      const validLanguages = languages.filter(localeKey => localeKey.length === 5);
      let hasValidLocale = false;

      validLanguages.forEach(language => {
        if (localesObject[language] && localesObject[language].name && (localesObject[language].name || "").length > 0) {
          hasValidLocale = true;
        }
      });

      if (!hasValidLocale) {
        return false;
      }
    }
    return true;
  }

  modalContent = () => {
    const {
      locales,
    } = this.state;

    const { stepperComponent, isLastStep } = this.props;

    const isLocalesValid = this.isLocalesValid();
    const isNameValid = this.isNameValid();

    return (
      <DialogContent sx={{ display: "flex", gap: 3, flexDirection: "column", marginTop: 1, width: "80%", alignItems: "center", alignSelf: "center" }}>
        {stepperComponent}
        <Box sx={{ width: "inherit", display: "flex", flexDirection: "column", gap: 4 }}>
          {!isLocalesValid && <Alert severity="error">
            Locales json is not valid. Locales json should contain language keys with length equals to 5 characters
          </Alert>}
          {!isNameValid && <Alert severity="error">
            You have to define a name entry in the locales JSON. For example: { "\"en_GB\" : { \"name\": \"my channel\" }" }
          </Alert>}
          <AceEditor
            width="100%"
            mode="json"
            theme="tomorrow"
            fontSize="0.9rem"
            name="service-genre-add-locales-edit"
            height="400px"
            readOnly={isLastStep}
            setOptions={{
              useWorker: false,
              showLineNumbers: false
            }}
            value={locales}
            onChange={value => this.changeInput("locales", { target: { value } })}
            editorProps={{ $blockScrolling: true }}
          />
        </Box>
      </DialogContent>
    );
  };

  modalActions = () => {
    const { currentStep, previousStep, nextStep, isLastStep } = this.props;

    const isLocalesValid = this.isLocalesValid();
    const isNameValid = this.isNameValid();

    return (
      [
        <Button variant="outlined"
          key="cancel"
          onClick={() => {
            this.closeModal();
          }} >
            Cancel
        </Button>,
        currentStep > 0 && <Button variant="outlined"
          key="previous"
          onClick={() => {
            previousStep();
          }}>
            Previous
        </Button>,
        !isLastStep && <Button variant="contained"
          key="next"
          disabled={!isLocalesValid || !isNameValid}
          color="secondary"
          onClick={() => {
            nextStep();
          }}>
            Next
        </Button>,
        isLastStep && <Button variant="contained"
          key="save"
          color="secondary"
          onClick={() => {
            this.addServiceGenre();
          }}>
            Save
        </Button>
      ]
    );
  };

  render() {
    const {
      modalOpened
    } = this.props;

    return (
      <Modal
        isOpen={modalOpened}
        title="Add service genre"
        customModalContent={() => this.modalContent()}
        closeModal={() => this.closeModal()}
        actions={() => this.modalActions()}
        actionAlign="flex-end"
      />
    );
  }
}

AddServiceGenreModal.propTypes = {
  modalOpened: PropTypes.bool.isRequired,
  closeAddModal: PropTypes.func.isRequired,
  currentStep: PropTypes.number,
  previousStep: PropTypes.func,
  nextStep: PropTypes.func,
  stepperComponent: PropTypes.object,
  isLastStep: PropTypes.bool,
  createServiceGenre: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  createServiceGenre: createServiceGenreAction,
  addToast: addToastAction
};

export default connect(null, mapDispatchToProps)(withStepper(
  AddServiceGenreModal, [
    "Set channel genre information",
    "Sumup"
  ]));
