import { handleActions } from "redux-actions";

const initialState = {
  allProviders: [],
  filters: [],
  totalCount: 0
};

const providersReducer = handleActions(
  {
    STORE_PROVIDERS: (state, action) => ({
      ...state,
      allProviders: action.payload.result,
      totalCount: action.payload.total_count || 0
    }),
    STORE_PROVIDERS_FILTERS: (state, action) => ({
      ...state,
      filters: action.payload.result
    }),
    ADD_PROVIDER: (state, action) => {
      const allProviders = [...state.allProviders];

      allProviders.push(action.payload.result);

      return {
        ...state,
        allProviders,
        totalCount: state.totalCount + 1
      };
    },
    MODIFY_PROVIDER: (state, action) => {
      const newState = {
        ...state,
        allProviders: [...state.allProviders]
      };

      const providersIndex = newState.allProviders.findIndex(t => action.payload.result.id === t.id);

      newState.allProviders[providersIndex] = action.payload.result;

      return newState;
    },
    REMOVE_PROVIDER: (state, action) => {
      const providersIndex = state.allProviders.findIndex(t => action.payload.id === t.id);
      const allProviders = Array.from(state.allProviders);

      allProviders.splice(providersIndex, 1);

      return {
        ...state,
        allProviders,
        totalCount: providersIndex > -1 ? state.totalCount - 1 : state.totalCount
      };
    },
    SELECT_ORBITAL_POSITIONS: () => initialState
  },
  initialState
);

export default providersReducer;
