import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AceEditor from "react-ace";
import { Alert, Box, DialogContent, Typography, Button, Tabs, Tab } from "@mui/material";

import Modal from "../Modal/Modal";
import { hideGlobalModal as hideGlobalModalAction } from "../../actions/global_modal";

import { modalOpened } from "../../selectors/global_modal";

import { toJsonString } from "../../helpers/utils";

const SPACE_OBJECTS = [
  "Orbital Positions",
  "Beams",
  "Transponders"
];

const GLOBAL_OBJECTS = [
  "Channel Genres",
  "Content Genres",
  "Channel Tiers",
  "Service Types",
  "OTT Backends",
  "Providers",
  "Importances"
];

const imageConstraintsExample = `{
    "ecl": {
      "minWidth": 10
    },
    "ear": {
      "minWidth": 10
    },
    "epg": {
      "minWidth": 25
    },
    "ell-d": {
      "minWidth": 25
    }
  }`;

const carouselConfigExample = `{
    "masterIndex": {
      "default": 1
    },
    "ecf": {
      "default": 1
    },
    "ecl": {
      "default": 1
    },
    "ell-i": {
      "default": 1
    },
    "ear": {
      "default": 1
    },
    "epg": {
      "default": 1,
      "rules": [
        {
          "startFile": 1,
          "endFile": 8,
          "carouselId": 2
        }
      ]
    },
    "ell-d": {
      "default": 1,
      "1": 1,
      "2": 3,
      "3": 3
    }
  }`;

const genresOrderExample = `{
    // ...
    CHANNEL_GENRES_ORDER: [
      4,
      6,
      8
    ],
    //...
    CONTENT_GENRES_ORDER: [
      6,
      1,
      8
    ]
    // ...
  }`;

const driverExamples = [
  imageConstraintsExample,
  carouselConfigExample,
  genresOrderExample
];

const DRIVERS = {
  default:
    [
      {
        name: "EPG ingest",
        infos: [
          "This driver imports EPG data from suppliers like Babeleye or Plurimédia. For now, these are the only supported suppliers.",
          "It will automatically create / modify / delete events and editorial contents for all channels provided by theses suppliers.",
          "It was built for running automatically and periodically. You can configure the frequency of execution and the targeted FTP provided by the supplier."
        ],
      },
      {
        name: "VIA Export",
        infos: [
          "This driver exports data from the service in the VIA MT JSON format to mitXperts.",
          "It was built for running automatically and periodically. You can configure the frequency of execution and many variables are customisable."
        ],
      },
      {
        name: "PRoDBR Probes",
        infos: [
          "This driver consumes ProDBR probes data to update technical services in SatTV backend.",
          "It was built for running automatically and periodically. You can configure the frequency of execution and many variables are customisable."
        ],
      }
    ],
  scheduleConfiguration:
    [
      {
        name: "Cron",
        description: "This syntax is the most powerful but is also more complicated. It has six fileds, separated by spaces:",
        syntax: "cron(minutes hours dayOfMonth month dayOfWeek year)",
        details: "",
        examples: [
          {
            description: "Every day at 3am",
            expression: "cron(0 3 * * ? *)"
          },
          {
            description: "Every 2 hours starting at midnight",
            expression: "cron(0 0/2 * * ? *)"
          },
          {
            description: "Every 1st of each month at noon",
            expression: "cron(0 12 1 * ? *)"
          },
          {
            description: "Every 5 minutes on week days between 9am and 5pm",
            expression: "cron(0/5 9-17 ? * MON-FRI *)"
          }
        ],
        documentation: "https://docs.aws.amazon.com/AmazonCloudWatch/latest/events/ScheduledEvents.html#CronExpressions"
      },
      {
        name: "Rate",
        description: "This syntax is less flexible but is very simple. It has 2 fields, separated by spaces:",
        syntax: "rate(value unit)",
        details: "The unit can be minute(s), hour(s), day(s) or week(s).",
        examples: [
          {
            description: "Every 2 minutes",
            expression: "rate(2 minutes)"
          },
          {
            description: "Every hour",
            expression: "rate(1 hour)"
          },
          {
            description: "Every 7 days",
            expression: "rate(7 days)"
          }
        ],
        documentation: "https://docs.aws.amazon.com/AmazonCloudWatch/latest/events/ScheduledEvents.html#RateExpressions"
      }
    ],
  driverParameters:
    [
      [
        {
          name: "FTP_HOST",
          description: "Host of the EPG provider's FTP server",
          required: true,
          example: "ftp.example.com"
        },
        {
          name: "FTP_PORT",
          description: "Port of the EPG provider's FTP server",
          required: true,
          example: "2222"
        },
        {
          name: "FTP_USER",
          description: "user credential for FTP connection",
          required: true,
          example: "ftpcirrus"
        },
        {
          name: "FTP_PASSWORD",
          description: "password for FTP connection",
          required: true,
          example: "hc+qeum252"
        }
      ],
      [
        {
          name: "SLOT_DURATION",
          description: "Duration of one EPG json file in seconds",
          required: true,
          example: "10800"
        },
        {
          name: "EPG_DURATION",
          description: "Duration of total EPG files in seconds",
          required: true,
          example: "691200"
        },
        {
          name: "IMAGE_TO_EXPORT",
          description: "Type of images to export (dvb, hybrid, online or all)",
          required: true,
          example: "all"
        },
        {
          name: "EVMM_ENDPOINT",
          description: "Host of EVMM API to export data",
          required: true,
          example: "https://staging.evmm.etldna.com"
        },
        {
          name: "EVMM_TOKEN",
          description: "Access token for EVMM API",
          required: true,
          example: "xxx"
        },
        {
          name: "MITXPERTS_ENDPOINT",
          description: "Host or IP for iMux export connection",
          required: true,
          example: "127.0.0.1"
        },
        {
          name: "MITXPERTS_PORT",
          description: "Port for iMux export connection",
          required: true,
          example: "443"
        },
        {
          name: "MITXPERTS_USERNAME",
          description: "Username credential for iMux export connection",
          required: true,
          example: "eutelsat"
        },
        {
          name: "MITXPERTS_PASSWORD",
          description: "Password for iMux export connection",
          required: true,
          example: "eutelsat2019"
        },
        {
          name: "IMAGES_CONSTRAINTS",
          description: "Configuration for images constraints. For now, we can specify only one constraint: minWidth. This constraint is the minimal width we want for an image",
          required: false,
          example: imageConstraintsExample
        },
        {
          name: "CAROUSEL_CONFIG",
          description: [
            "Configuration for carousels structure. It contains ids of carousels to use for each file type. For each file type, the default key contains the id of the carousel to place the file.",
            "For ell-d* files, the default key contains the default carousel id, and the other keys map the lineup id to the carousel id to use.",
            "for EPG files, the default key contains the default carousel id. For adding specific rules, a key rules can be added in the configuration. This array contains a set of rules.",
            "One rule contains 3 keys:",
            "- startFile: Index number of the first file to export",
            "- endFile: Index number of the last file to export",
            "- carouselIid: Id of the targeted carousel",
            "\"index number of a file\": it matches the position of the file in the declared EPG files list in the masterIndex.",
            "startFile and endFile boundaries are inclusive.",
            "On the example beside, we export the 1st day in the carousel #2. SLOT_DURATION = 3hours => there are 8 files per day. So, the first 8 files go to the carousel 2. Any other EPG files go to the default carousel, which is the #1 here.",
            "If many rules collide, it takes in account the first matching of the list of rules."
          ],
          required: true,
          example: carouselConfigExample
        },
        {
          name: [
            "CHANNEL_GENRES_ORDER",
            "CONTENT_GENRES_ORDER"
          ],
          description: [
            "Configuration to order (channel or content) genres in the ECF.json file",
            "Two objects can be add to the configuration:",
            "CHANNEL_GENRES_ORDER or CONTENT_GENRES_ORDER",
            "They both work in the same way: there are arrays of genre id. You have to put in these arrays the id of genres you want to order. Other genres (those not referenced in these arrays) will be append at the end of ECF genres arrays.",
            "The first genre id in the configuration array will be the first genre if the ECF genres array."
          ],
          required: false,
          example: genresOrderExample
        }
      ],
      [
        {
          name: "FTP_HOST",
          description: "Host of the ProDBR's FTP server",
          required: true,
          example: "ftp.eutelsat.com"
        },
        {
          name: "FTP_USER",
          description: "user credential for FTP connection",
          required: true,
          example: "ftpcirrus"
        },
        {
          name: "FTP_PASSWORD",
          description: "password for FTP connection",
          required: true,
          example: "hc+qeum252"
        }
      ]
    ]
};

const REPORTS = [
  {
    name: "EPG ingest Reports",
    infos: [
      "EPG ingest file by EPG ingest fiel (ie. editorial channel by editorial channel), you can check if any information (like synopsis, images, title, etc...) is missing.",
      "if so, the report will show you for which resource(s) there is missing data."
    ]
  },
  {
    name: "VIA Export Reports",
    infos: [
      "For each export (ie. each time a VIA Export driver is running), you can access files generated.",
      "Each ZIP file (one per carousel) is saved as-is. This is exactly the same as the one sent to the mitXperts server."
    ]
  },
  {
    name: "NIT Reports",
    infos: [
      "no information yet"
    ]
  }
];

const NOTIFICATIONS = [
  {
    name: "Badge",
    infos: [
      "If notifications have to be processed, a red badge is shown at the top-right of the screen, on the bell icon",
      "This badges show you the number of notifications to process"
    ]
  },
  {
    name: "Popup",
    infos: [
      "By clicking the \"notification bell\" at the top-right of the screen, the notification popup appears",
      "To refresh and fetch any new notifications, you click on the refresh button",
      "This popup shows all notifications, classified by object type (technical channel for example), one row by object type",
      "By clicking on any row, it will route you to the notification page, to the right object type"
    ]
  },
  {
    name: "Page",
    infos: [
      "This page shows you notifications, splitted in different object type (Technical channel for example) with tabs",
      "Each notification can be actionnate by the operator. Many actions are available depending on the object type and action type of the notification",
      "When you discard a notification, this notification won't be shown forever"
    ]
  },
  {
    name: "Actions",
    infos: [
      "You can process all notifications by clicking the orange button named \"Process all notifications\"",
      "If you want to process one or few notification(s), select them by clicking on them, and then click the blue button \"Process selected notifications\"",
      "You can also delete notifications. You have to select them, then click the \"Actions\" button, then click \"Delete selected notifications\"",
      "Deleting a notification means, if the same notification will be detected by the source (probe for example). It will appear again in the list",
      "To discard the notification forever, if applicable, you have to click the \"Discard the notification\" red button, while you are processing notifications"
    ]
  }
];

const CONTACTS = [
  {
    name: "Marian André",
    email: "mandre@eutelsat.com"
  },
  {
    name: "Nicolas Léger",
    email: "nleger-ext@eutelsat.com"
  }
];

const DashboardTab = () =>
  <Box sx={{ display: "flex", flexDirection: "column", gap: 3, textAlign: "center", width: "100%" }}>
    <Typography>
      This page is under construction.
    </Typography>
    <Typography>
      It will contains sumups and the drivers execution planning.
    </Typography>
  </Box>;

const EditorialChannelsTab = () =>
  <Box sx={{ display: "flex", flexDirection: "column", gap: 3, textAlign: "center", width: "100%" }}>
    <Typography>
          This page allow you to manage editorial channels.
    </Typography>
    <Typography color="error">
          For now, the "import CSV" feature and its help pop-up are not integrated. Use the old interface if you need it.
    </Typography>
  </Box>;

const LineupsTab = () =>
  <Box sx={{ display: "flex", flexDirection: "column", gap: 3, textAlign: "center", width: "100%" }}>
    <Typography>
          This page allow you to manage lineups.
    </Typography>
    <Typography color="error">
          For now, the "import CSV" feature and its help pop-up are not integrated. Use the old interface if you need it.
    </Typography>
  </Box>;

const TechnicalChannelsTab = () =>
  <Box sx={{ display: "flex", flexDirection: "column", gap: 3, textAlign: "center", width: "100%" }}>
    <Typography>
          This page is under construction.
    </Typography>
    <Typography>
          It will allow you to manage technical channels.
    </Typography>
  </Box>;

const SpaceObjectsTab = () =>
  <Box sx={{ display: "flex", flexDirection: "column", gap: 3, textAlign: "center", alignItems: "center", width: "100%" }}>
    <Typography>
          This page allow you to manage some space objects.
    </Typography>
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "fit-content", alignItems: "flex-start" }}>
      {SPACE_OBJECTS.map((object, index) =>
        <Typography key={index}>
              - {object}
        </Typography>
      )}
    </Box>
  </Box>;

const GlobalObjectsTab = () =>
  <Box sx={{ display: "flex", flexDirection: "column", gap: 3, textAlign: "center", alignItems: "center", width: "100%" }}>
    <Typography>
          This page allow you to manage some global objects.
    </Typography>
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "fit-content", alignItems: "flex-start" }}>
      {GLOBAL_OBJECTS.map((object, index) =>
        <Typography key={index}>
              - {object}
        </Typography>
      )}
    </Box>
  </Box>;

const GlobalInformationsDriversTab = () =>
  <Box sx={{ display: "flex", flexDirection: "column", gap: 3, textAlign: "center", width: "100%" }}>
    <Typography>
          This page allow you to manage drivers.
    </Typography>
    <Typography>
          Drivers are little services used to import or export data from/to the service.
    </Typography>
    <Typography>
          There are three kind of drivers for now:
    </Typography>
    <Box sx={{ display: "flex", gap: 4 }}>
      {DRIVERS.default.map((driver, index) =>
        <Box key={index} sx={{ display: "flex", flexDirection: "column", gap: 3, flex: 1 }}>
          <Typography variant="h5">
            {driver.name}
          </Typography>
          {driver.infos.map((info, index) =>
            <Typography sx={{ textAlign: "left" }} key={index}>
              {info}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  </Box>;

const ScheduleConfigurationTab = () =>
  <Box sx={{ display: "flex", flexDirection: "column", gap: 3, textAlign: "center", alignItems: "center", width: "100%" }}>
    <Alert sx={{ width: "fit-content" }} severity="error">Note that all hours are expressed in UTC !</Alert>
    <Typography>
          The <Typography variant="h5" component="span">Schedule</Typography> parameters defines how/when the driver will run. It follow AWS schedule syntax. Official documentation can be found <a href="https://docs.aws.amazon.com/AmazonCloudWatch/latest/events/ScheduledEvents.html" target="_blank" rel="noreferrer">here</a>.
    </Typography>
    <Typography>
          It can take 2 different forms:
    </Typography>
    <Box sx={{ display: "flex", gap: 4 }}>
      {DRIVERS.scheduleConfiguration.map((driver, index) =>
        <Box key={index} sx={{ display: "flex", flexDirection: "column", gap: 3, flex: 1 }}>
          <Typography variant="h5">
            {driver.name}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography sx={{ textAlign: "left" }}>
              {driver.description}
            </Typography>
            <Typography sx={{ textAlign: "left" }}>
              <code>{driver.syntax}</code>
            </Typography>
            <Typography sx={{ textAlign: "left" }}>
              {driver.details}
            </Typography>
            <Box sx={{ display: "flex", gap: 3 }}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2, flex: 1 }}>
                <Typography variant="h5" sx={{ textAlign: "left" }}>
                      Description:
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                  {driver.examples.map((example, index) =>
                    <Typography sx={{ textAlign: "left" }} key={index}>
                      {example.description}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2, flex: 1 }}>
                <Typography variant="h5" sx={{ textAlign: "left" }}>
                      Expression:
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                  {driver.examples.map((example, index) =>
                    <Typography sx={{ textAlign: "left" }} key={index}>
                      {example.expression}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
            <Typography sx={{ textAlign: "left" }}>
                  For more advanced usage, please refer to the <a rel="noreferrer" target="_blank" href={driver.documentation}>official documentation.</a>
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  </Box>;

const named = [
  "EPG Input Name",
  "VIA JSON Output Name",
  "ProDBR Probes Name"
];

const DriverParametersTab = () =>
  <Box sx={{ display: "flex", flexDirection: "column", gap: 3, textAlign: "center", alignItems: "center", width: "100%" }}>
    <Typography>
          Each driver needs a set of parameters to work properly. The parameters are given to the driver in the <Typography variant="h5" component="span">Input</Typography> parameter in JSON format.
    </Typography>
    {DRIVERS.driverParameters.map((parameter, index) =>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5, width: "100%" }} key={index}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Typography variant="h5" sx={{ flex: 1, textAlign: "left" }} component="span">
            {named[index]}
          </Typography>
          <Typography variant="h5" sx={{ flex: 2, textAlign: "left" }} component="span">
                Description
          </Typography>
          <Typography variant="h5" sx={{ flex: 1, textAlign: "left" }} component="span">
                Required/Optional
          </Typography>
          <Typography variant="h5" sx={{ flex: 2, textAlign: "left" }} component="span">
                Example
          </Typography>
        </Box>
        {parameter.map((infos, index) =>
          <Box sx={{ display: "flex", gap: 2 }} key={index}>
            {
              typeof infos.name === "string"
                ? <Typography sx={{ flex: 1, textAlign: "left" }}>
                  {infos.name}
                </Typography>
                : <Box sx={{ display: "flex", flexDirection: "column", gap: 1, flex: 1 }}>
                  {infos.name.map((name, index) =>
                    <Typography sx={{ textAlign: "left" }} key={index}>
                      {name}
                    </Typography>
                  )}
                </Box>
            }
            {
              typeof infos.description === "string"
                ? <Typography sx={{ flex: 2, textAlign: "left" }} key={index}>
                  {infos.description}
                </Typography>
                : <Box sx={{ display: "flex", flexDirection: "column", gap: 1, flex: 2 }}>
                  {
                    infos.description.map((description, index) =>
                      <Typography sx={{ textAlign: "left" }} key={index}>
                        {description}
                      </Typography>
                    )}
                </Box>
            }
            <Typography sx={{ flex: 1, textAlign: "left" }} component="span">
              {infos.required ? "Required" : "Optional"}
            </Typography>
            <Typography sx={{ flex: 2, textAlign: "left", whiteSpace: "pre-wrap", wordWrap: "break-word" }} component="span">
              { driverExamples.includes(infos.example)
                ? <AceEditor
                  mode="json"
                  theme="tomorrow"
                  fontSize="0.9rem"
                  name="ace-editor-carousel-config"
                  readOnly
                  maxLines={Infinity}
                  width="100%"
                  setOptions={{
                    useWorker: false,
                    showLineNumbers: false
                  }}
                  value={toJsonString(infos.example)}
                  editorProps={{ $blockScrolling: Infinity }}
                />
                : infos.example
              }
            </Typography>
          </Box>
        )}
      </Box>
    )}
  </Box>;

const ReportsTab = () =>
  <Box sx={{ display: "flex", flexDirection: "column", gap: 3, textAlign: "center", width: "100%" }}>
    <Typography>
      This page allow you to see EPG ingest report and check VIA Exports files.
    </Typography>
    <Typography>
      Each time an EPG ingest driver or a VIA Export driver runs, reports are generated and are accessible in this page.
    </Typography>
    <Typography>
      There are three kind of reports for now:
    </Typography>
    <Box sx={{ display: "flex", gap: 4 }}>
      {REPORTS.map((report, index) =>
        <Box key={index} sx={{ display: "flex", flexDirection: "column", gap: 3, flex: 1 }}>
          <Typography variant="h5">
            {report.name}
          </Typography>
          {report.infos.map((info, index) =>
            <Typography sx={{ textAlign: "left" }} key={index}>
              {info}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  </Box>;

const DriversTab = () => {
  const [currentDriversTab, setCurrentDriversTab] = useState(0);

  const changeDriversTab = (event, newValue) => {
    setCurrentDriversTab(newValue);
  };

  const tabs = [
    { id: 0, label: "Global informations", component: <GlobalInformationsDriversTab /> },
    { id: 1, label: "Schedule Configuration", component: <ScheduleConfigurationTab /> },
    { id: 2, label: "Driver parameters", component: <DriverParametersTab /> }
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 4, alignSelf: "flex-start", width: "100%" }}>
      <Tabs value={currentDriversTab} onChange={changeDriversTab}>
        {tabs.map(tab =>
          <Tab key={tab.id} label={tab.label} value={tab.id} />
        )}
      </Tabs>
      {tabs.map(tab => currentDriversTab === tab.id && <React.Fragment key={tab.id}>{tab.component}</React.Fragment>)}
    </Box>
  );
};

const NotificationsTab = () => {
  const [currentNotificationsTab, setCurrentNotificationsTab] = useState(0);

  const changeNotificationsTab = (event, newValue) => {
    setCurrentNotificationsTab(newValue);
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 4, textAlign: "center", width: "100%", alignSelf: "flex-start" }}>
      <Tabs value={currentNotificationsTab} onChange={changeNotificationsTab}>
        <Tab label="Badge"/>
        <Tab label="Popup"/>
        <Tab label="Page"/>
        <Tab label="Actions"/>
      </Tabs>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4, alignSelf: "center" }}>
        <Typography variant="h5">
            Notification {NOTIFICATIONS[currentNotificationsTab].name}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, alignSelf: "center" }}>
          {NOTIFICATIONS[currentNotificationsTab].infos.map((info, index) =>
            <Typography key={index}>
              {info}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const FoundABugTab = () =>
  <Box sx={{ display: "flex", flexDirection: "column", gap: 3, textAlign: "center", width: "100%" }}>
    <Typography>
      You have found a bug or something strange ?
    </Typography>
    <Typography>
      Send an email to {CONTACTS[0].name} (<a href={"mailto:" + CONTACTS[0].email}>{CONTACTS[0].email}</a>) or {CONTACTS[1].name} (<a href={"mailto:" + CONTACTS[1].email}>{CONTACTS[1].email}</a>).
    </Typography>
  </Box>;

const GlobalModal = ({
  pageSlug,
  modalOpened,
  hideGlobalModal
}) => {
  const [currentTab, setCurrentTab] = useState(pageSlug);

  const tabs = [
    { id: "dashboard", label: "Dashboard", component: <DashboardTab /> },
    { id: "editorial_channels", label: "Editorial Channels", component: <EditorialChannelsTab /> },
    { id: "lineups", label: "Lineups", component: <LineupsTab /> },
    { id: "technical_channels", label: "Technical Channels", component: <TechnicalChannelsTab /> },
    { id: "space_objects", label: "Space Objects", component: <SpaceObjectsTab /> },
    { id: "global_objects", label: "Global Objects", component: <GlobalObjectsTab /> },
    { id: "drivers", label: "Drivers", component: <DriversTab /> },
    { id: "reports", label: "Reports", component: <ReportsTab /> },
    { id: "notifications", label: "Notifications", component: <NotificationsTab /> },
    { id: "bug", label: "Found a bug?", component: <FoundABugTab /> }
  ];

  useEffect(() => {
    setCurrentTab(pageSlug || tabs[0].id);
  }, [pageSlug]);

  const closeModal = () => {
    hideGlobalModal();
  };

  const changeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const modalContent = () =>
    <DialogContent>
      <Tabs value={currentTab} onChange={changeTab}>
        {tabs.map(tab =>
          <Tab key={tab.id} sx={{ flex: 1 }} label={tab.label} value={tab.id} />
        )}
      </Tabs>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", minHeight: "50vh" }}>
        {tabs.map(tab =>
          currentTab === tab.id && <React.Fragment key={tab.id}>{tab.component}</React.Fragment>
        )}
      </Box>
    </DialogContent>;

  const modalActions = () =>
    [
      <Button variant="outlined" key="close" onClick={closeModal}>
          Close
      </Button>
    ];

  return (
    <Modal
      isOpen={modalOpened}
      title="Help"
      closeModal={closeModal}
      customModalContent={modalContent}
      actions={modalActions}
      actionAlign="flex-end"
      maxWidth={currentTab === "drivers" ? "xl" : "lg"}
    >
    </Modal>
  );
};

GlobalModal.propTypes = {
  pageSlug: PropTypes.string.isRequired,
  modalOpened: PropTypes.bool.isRequired,
  hideGlobalModal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  modalOpened: modalOpened(state)
});

const mapDispatchToProps = {
  hideGlobalModal: hideGlobalModalAction
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalModal);
