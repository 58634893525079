import { createAction } from "redux-actions";

/* Storage actions */
const storeImportances = createAction("STORE_IMPORTANCES");
const storeImportancesFilters = createAction("STORE_IMPORTANCES_FILTERS");
const addImportance = createAction("ADD_IMPORTANCE");
const modifyImportance = createAction("MODIFY_IMPORTANCE");

/* API actions */
export const indexImportances = createAction("API_INDEX_IMPORTANCES", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/importances",
  data,
  fullLoad,
  onSuccessAction: storeImportances
}));

export const indexImportancesFilters = createAction("API_INDEX_IMPORTANCES_FILTERS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/importances/filters",
  data,
  fullLoad,
  onSuccessAction: storeImportancesFilters
}));

export const createImportance = createAction("API_CREATE_IMPORTANCE", data => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "POST",
  url: "/importances",
  data,
  onSuccessAction: addImportance
}));

export const updateImportance = createAction("API_UPDATE_IMPORTANCE", (importanceId, data) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "PUT",
  url: `/importances/${importanceId}`,
  data,
  onSuccessAction: modifyImportance
}));

export const deleteImportance = createAction("API_DELETE_IMPORTANCE", importanceId => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "DELETE",
  url: `/importances/${importanceId}`,
  onSuccessAction: createAction("REMOVE_IMPORTANCE", () => ({ id: importanceId }))
}));
