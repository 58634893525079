import { handleActions } from "redux-actions";

const initialState = {
  allServiceGenres: [],
  filters: [],
  totalCount: 0
};

const serviceGenresReducer = handleActions(
  {
    STORE_SERVICE_GENRES: (state, action) => ({
      ...state,
      allServiceGenres: action.payload.result,
      totalCount: action.payload.total_count || 0
    }),
    STORE_SERVICE_GENRES_FILTERS: (state, action) => ({
      ...state,
      filters: action.payload.result
    }),
    ADD_SERVICE_GENRE: (state, action) => {
      const allServiceGenres = [...state.allServiceGenres];

      allServiceGenres.push(action.payload.result);

      return {
        ...state,
        allServiceGenres,
        totalCount: state.totalCount + 1
      };
    },
    MODIFY_SERVICE_GENRE: (state, action) => {
      const newState = {
        ...state,
        allServiceGenres: [...state.allServiceGenres]
      };

      const serviceGenresIndex = newState.allServiceGenres.findIndex(sg => action.payload.result.id === sg.id);

      newState.allServiceGenres[serviceGenresIndex] = action.payload.result;

      return newState;
    },
    REMOVE_SERVICE_GENRE: (state, action) => {
      const serviceGenresIndex = state.allServiceGenres.findIndex(sg => action.payload.id === sg.id);
      const allServiceGenres = Array.from(state.allServiceGenres);

      allServiceGenres.splice(serviceGenresIndex, 1);

      return {
        ...state,
        allServiceGenres,
        totalCount: serviceGenresIndex > -1 ? state.totalCount - 1 : state.totalCount
      };
    }
  },
  initialState
);

export default serviceGenresReducer;
