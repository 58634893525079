/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { Box, FormControl, InputLabel, Select, MenuItem, DialogContent, Typography, Button, Alert, TextField } from "@mui/material";
import Modal from "../Modal/Modal";
import withStepper from "../Modal/withStepper";

import { addToast as addToastAction } from "../../actions/toasts";

import {
  createOrbitalPositionWithBeamsAndTransponders as createOrbitalPositionWithBeamsAndTranspondersAction
} from "../../actions/orbital_positions";

import {
  listOrbitalPositions as listOrbitalPositionsSelector
} from "../../selectors/orbital_positions";

const initialState = {
  name: "",
  prodbr_id: "",
  beams: [],

  beamName: "",

  transponderBeamName: "",
  transponderName: "",
  transponderTheoreticalOnid: "",
  transponderTheoreticalTsid: "",
  transponderBroadcastOnid: "",
  transponderBroadcastTsid: "",

  globalEditing: false,
};

class AddOrbitalPositionModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { ...initialState };
  }

  changeInput(inputName, event, flag = "") {
    const { value } = event.target;

    if (flag === "int") {
      if (value === "" || /^\d+$/.test(value)) {
        this.setState({ [inputName]: value });
      }
    } else if (flag === "float") {
      if (value === "" || /^\d*\.?\d*$/.test(value)) {
        this.setState({ [inputName]: value });
      }
    } else {
      this.setState(() => ({ [inputName]: value }));
    }
  }

  async addOrbitalPosition() {
    const { name, prodbr_id, beams } = this.state;
    const { createOrbitalPositionWithBeamsAndTransponders, addToast } = this.props;

    this.setState({ globalEditing: true });

    const ret = await createOrbitalPositionWithBeamsAndTransponders({ name, prodbr_id: (prodbr_id || "").trim(), beams });

    this.setState({ globalEditing: false }, () => {
      if (ret.isSuccess) {
        addToast("success", "Success", "Orbital Position successfully added!");
        this.closeModal();
      } else {
        addToast("error", "Error", "Error while adding Orbital Position. Try again.");
      }
    });
  }

  closeModal() {
    const { closeAddModal } = this.props;
    const { globalEditing } = this.state;

    if (globalEditing) {
      return;
    }

    this.setState(initialState);
    closeAddModal();
  }

  addBeam() {
    const { beamName, beams } = this.state;

    const newBeams = [...beams];

    newBeams.push({
      transponders: [],
      name: beamName
    });

    this.setState({ beams: newBeams, beamName: "" });
  }

  removeBeam(name) {
    const { beams } = this.state;
    const newBeams = beams.filter(beam => beam.name !== name);

    this.setState({ beams: newBeams });
  }

  addTransponder() {
    const {
      beams,
      transponderBeamName,
      transponderName,
      transponderTheoreticalOnid,
      transponderTheoreticalTsid,
      transponderBroadcastOnid,
      transponderBroadcastTsid,
    } = this.state;

    const beamIndex = beams.findIndex(beam => beam.name === transponderBeamName);

    const newBeams = [...beams];

    newBeams[beamIndex].transponders.push({
      name: transponderName,
      theoretical_onid: transponderTheoreticalOnid,
      theoretical_tsid: transponderTheoreticalTsid,
      broadcast_onid: transponderBroadcastOnid || null,
      broadcast_tsid: transponderBroadcastTsid || null
    });

    this.setState({
      beams: newBeams,
      transponderBeamName: "",
      transponderName: "",
      transponderTheoreticalOnid: "",
      transponderTheoreticalTsid: "",
      transponderBroadcastOnid: "",
      transponderBroadcastTsid: ""
    });
  }

  removeTransponder(beam, transponder) {
    const { beams } = this.state;
    const newBeams = [...beams];
    const beamIndex = newBeams.findIndex(b => b.name === beam.name);

    if (beamIndex !== -1) {
      newBeams[beamIndex].transponders = newBeams[beamIndex].transponders.filter(t => t.name !== transponder.name);
    }

    this.setState({ beams: newBeams });
  }

  isValid(inputName) {
    if (inputName instanceof Array) {
      return inputName.every(i => this.isValid(i));
    }

    return !!this.state[inputName];
  }

  isNameExist() {
    const { name } = this.state;
    const { orbitalPositions } = this.props;

    return this.isValid("name") && orbitalPositions.some(op => op.name === name);
  }

  isProdbrIdExist() {
    const { prodbr_id } = this.state;
    const { orbitalPositions } = this.props;

    return this.isValid("prodbr_id") && orbitalPositions.some(op => op.prodbr_id === prodbr_id);
  }

  isBeamNameExist() {
    const { beams, beamName } = this.state;

    return this.isValid("beamName") && beams.some(beam => beam.name.toLowerCase() === beamName.toLowerCase());
  }

  isTransponderNameExist() {
    const { beams, transponderName, transponderBeamName } = this.state;

    const beamIndex = beams.findIndex(beam => beam.name === transponderBeamName);

    return this.isValid("transponderName") && beams[beamIndex]?.transponders.find(transponder => transponder.name.toLowerCase() === transponderName.toLowerCase());
  }

  checkTransponders() {
    const { beams } = this.state;

    return beams.some(beam => beam.transponders.length > 0);
  }

  isNextPossible() {
    const { beams } = this.state;
    const { currentStep } = this.props;

    if (currentStep === 0) {
      return this.isValid(["name"]) && !this.isNameExist() && !this.isProdbrIdExist();
    } else if (currentStep === 1) {
      return beams.length > 0;
    } else if (currentStep === 2) {
      return this.checkTransponders();
    }
    return false;
  }

  step1() {
    const { name, prodbr_id } = this.state;
    const { isLastStep } = this.props;

    const isNameValid = this.isValid("name") && !this.isNameExist();
    const isNameExist = this.isNameExist();
    const isProdbrIdExist = this.isProdbrIdExist();

    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "60%" }}>
        {isNameExist && <Alert severity="error">
          An orbital position already exists with this name
        </Alert>}
        <TextField label="Name*"
          error={!isNameValid}
          inputProps={{ readOnly: isLastStep }}
          value={name}
          onChange={e => {
            this.changeInput("name", e);
          }}
        />
        {isProdbrIdExist && <Alert severity="error">
          An orbital position already exists with this proDBR Id
        </Alert>}
        <TextField label="ProDBR Id"
          error={isProdbrIdExist}
          inputProps={{ readOnly: isLastStep }}
          value={prodbr_id}
          onChange={e => {
            this.changeInput("prodbr_id", e);
          }}
        />
      </Box>
    );
  }

  step2() {
    const { beamName, beams } = this.state;
    const { isLastStep } = this.props;
    const isBeamNameValid = this.isValid("beamName") && !this.isBeamNameExist();
    const isBeamNameExist = this.isBeamNameExist();

    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4, width: "80%", alignItems: "center" }}>
        <Typography sx={{ fontWeight: "bold" }}>
          Beam(s) name
        </Typography>
        <Box sx={{ display: "flex", gap: 1, width: "60%", flexDirection: "column" }}>
          {beams?.length !== 0
            ? beams.map(v =>
              <Box key={v.name} sx={{ display: "flex", gap: 2, color: "error.main" }}>
                <Typography sx={{ color: "primary.main" }}>{v.name}</Typography>
                <FontAwesomeIcon onClick={() => {
                  this.removeBeam(v.name);
                }}
                style={{ cursor: "pointer" }}
                size="lg"
                icon={faTrash} />
              </Box>
            )
            : <Alert severity="error">
                You should add at least one beam
            </Alert>}
        </Box>
        {isBeamNameExist && <Alert severity="error">
          A beam already exists with this name
        </Alert>}
        <TextField label="Beam name*"
          sx={{ width: "60%" }}
          error={!isBeamNameValid}
          inputProps={{ readOnly: isLastStep }}
          value={beamName}
          onChange={e => {
            this.changeInput("beamName", e);
          }}
        />
        <Button
          variant="contain"
          disabled={!isBeamNameValid}
          sx={{ color: "primary.main", backgroundColor: "secondary.main", ":hover": { backgroundColor: "secondary.main_hover" } }}
          onClick={() => {
            this.addBeam();
          }}
        >
          Add beam
        </Button>
      </Box>
    );
  }

  step3() {
    const { beams, transponderBeamName, transponderName, transponderBroadcastOnid, transponderBroadcastTsid, transponderTheoreticalOnid, transponderTheoreticalTsid } = this.state;
    const { isLastStep } = this.props;

    const isTransponderBeamNameValid = this.isValid("transponderBeamName");
    const isTransponderNameValid = this.isValid("transponderName") && !this.isTransponderNameExist();
    const isTransponderTheoreticalOnidValid = this.isValid("transponderTheoreticalOnid");
    const isTransponderTheoreticalTsidValid = this.isValid("transponderTheoreticalTsid");
    const isTransponderNameExist = this.isTransponderNameExist();
    const checkTransponders = this.checkTransponders();

    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4, width: "100%", alignItems: "center" }}>
        <Box sx={{ color: "primary.main", display: "flex", gap: 2, width: "100%", textAlign: "center" }}>
          <Typography fontWeight="bold" sx={{ flexBasis: 0, flexGrow: 1 }}>
            Beam name
          </Typography>
          <Typography fontWeight="bold" sx={{ flexBasis: 0, flexGrow: 1 }}>
            Transponder name
          </Typography>
          <Typography fontWeight="bold" sx={{ flexBasis: 0, flexGrow: 1 }}>
            Theoretical Onid
          </Typography>
          <Typography fontWeight="bold" sx={{ flexBasis: 0, flexGrow: 1 }}>
            Theoretical Tsid
          </Typography>
          <Typography fontWeight="bold" sx={{ flexBasis: 0, flexGrow: 1 }}>
            Broadcast Onid
          </Typography>
          <Typography fontWeight="bold" sx={{ flexBasis: 0, flexGrow: 1 }}>
            Broadcast Tsid
          </Typography>
          {!isLastStep && <Typography fontWeight="bold" sx={{ flexBasis: 0, flexGrow: 1 }}>
            Action
          </Typography>}
        </Box>
        <Box sx={{ display: "flex", gap: 1, width: "100%", flexDirection: "column" }}>
          {checkTransponders
            ? beams.map(v =>
              v.transponders.map(transponder =>
                <Box sx={{ display: "flex", gap: 2 }} key={v.name + transponder.name}>
                  <Box sx={{ color: "primary.main", display: "flex", gap: 2, width: "100%", textAlign: "center" }}>
                    <Typography sx={{ flexBasis: 0, flexGrow: 1 }}>{v.name}</Typography>
                    <Typography sx={{ flexBasis: 0, flexGrow: 1 }}>{transponder.name}</Typography>
                    <Typography sx={{ flexBasis: 0, flexGrow: 1 }}>{transponder.theoretical_onid}</Typography>
                    <Typography sx={{ flexBasis: 0, flexGrow: 1 }}>{transponder.theoretical_tsid}</Typography>
                    <Typography sx={{ flexBasis: 0, flexGrow: 1 }}>{transponder.broadcast_onid}</Typography>
                    <Typography sx={{ flexBasis: 0, flexGrow: 1 }}>{transponder.broadcast_tsid}</Typography>
                    {!isLastStep
                    && <Box key={v.name} sx={{ color: "error.main", flexBasis: 0, flexGrow: 1 }}>
                      <FontAwesomeIcon
                        onClick={() => {
                          this.removeTransponder(v, transponder);
                        }}
                        style={{ cursor: "pointer" }}
                        size="lg"
                        icon={faTrash}
                      />
                    </Box>
                    }
                  </Box>
                </Box>
              ))
            : <Alert severity="error">
              You should add at least one transponder
            </Alert>}
        </Box>
        {!isLastStep
        && <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", gap: 3 }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel sx={{ color: !transponderBeamName && "error.main" }} id="transponder-beam--label">
                  Beam*
                </InputLabel>
                <Select
                  value={transponderBeamName}
                  error={!isTransponderBeamNameValid}
                  labelId="transponder-beam-label"
                  inputProps={{ readOnly: isLastStep }}
                  label="Beam*"
                  onChange={
                    e => {
                      this.changeInput("transponderBeamName", e);
                    }
                  }
                >
                  {beams.map(b =>
                    <MenuItem key={b.name} value={b.name}>
                      {b.name}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}>
                {isTransponderNameExist && <Alert severity="error">
                  A transponder already exists with this name
                </Alert>}
                <TextField label="Transponder Name*"
                  error={!isTransponderNameValid}
                  inputProps={{ readOnly: isLastStep }}
                  value={transponderName}
                  onChange={e => {
                    this.changeInput("transponderName", e);
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}>
                <TextField label="Theoretical Onid*"
                  error={!isTransponderTheoreticalOnidValid}
                  inputProps={{ readOnly: isLastStep }}
                  value={transponderTheoreticalOnid}
                  onChange={e => {
                    this.changeInput("transponderTheoreticalOnid", e, "int");
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}>
                <TextField label="Theoretical Tsid*"
                  error={!isTransponderTheoreticalTsidValid}
                  inputProps={{ readOnly: isLastStep }}
                  value={transponderTheoreticalTsid}
                  onChange={e => {
                    this.changeInput("transponderTheoreticalTsid", e, "int");
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}>
                <TextField label="Broadcast Onid"
                  inputProps={{ readOnly: isLastStep }}
                  value={transponderBroadcastOnid}
                  onChange={e => {
                    this.changeInput("transponderBroadcastOnid", e, "int");
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}>
                <TextField label="Broadcast Tsid"
                  inputProps={{ readOnly: isLastStep }}
                  value={transponderBroadcastTsid}
                  onChange={e => {
                    this.changeInput("transponderBroadcastTsid", e, "int");
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Button
            variant="contain"
            disabled={!isTransponderBeamNameValid || !isTransponderNameValid || !isTransponderTheoreticalOnidValid || !isTransponderTheoreticalTsidValid }
            sx={{ alignSelf: "center", color: "primary.main", backgroundColor: "secondary.main", ":hover": { backgroundColor: "secondary.main_hover" } }}
            onClick={() => {
              this.addTransponder();
            }}
          >
          Add transponder
          </Button>
        </Box>
        }
      </Box>
    );
  }

  modalContent() {
    const { currentStep, stepperComponent, isLastStep } = this.props;

    return (
      <DialogContent sx={{ display: "flex", gap: 3, flexDirection: "column", marginTop: 1, width: "80%", alignItems: "center", alignSelf: "center" }}>
        {stepperComponent}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 4, width: "100%", alignItems: "center" }}>
          { (currentStep === 0 || isLastStep) && this.step1() }
          { currentStep === 1 && this.step2() }
          { (currentStep === 2 || isLastStep) && this.step3() }
        </Box>
      </DialogContent>
    );
  }

  modalActions() {
    const { currentStep, previousStep, nextStep, isLastStep } = this.props;
    const isNextPossible = this.isNextPossible();

    return (
      [
        <Button variant="outlined"
          key="cancel"
          onClick={() => {
            this.closeModal();
          }} >
            Cancel
        </Button>,
        currentStep > 0 && <Button variant="outlined"
          key="previous"
          onClick={() => {
            previousStep();
          }}>
            Previous
        </Button>,
        !isLastStep && <Button variant="contained"
          key="next"
          disabled={!isNextPossible}
          color="secondary"
          onClick={() => {
            nextStep();
          }}>
            Next
        </Button>,
        isLastStep && <Button variant="contained"
          key="save"
          color="secondary"
          onClick={() => {
            this.addOrbitalPosition();
          }}>
            Save
        </Button>
      ]
    );
  }

  render() {
    const {
      modalOpened,
      currentStep
    } = this.props;

    return (
      <Modal
        isOpen={modalOpened}
        closeModal={() => this.closeModal()}
        title="Add orbital position"
        customModalContent={() => this.modalContent()}
        actions={() => this.modalActions()}
        actionAlign="flex-end"
        maxWidth={ currentStep > 1 ? "lg" : "md" }
      />
    );
  }
}

AddOrbitalPositionModal.propTypes = {
  modalOpened: PropTypes.bool.isRequired,
  closeAddModal: PropTypes.func.isRequired,

  orbitalPositions: PropTypes.arrayOf(PropTypes.object).isRequired,

  currentStep: PropTypes.number,
  previousStep: PropTypes.func,
  nextStep: PropTypes.func,
  stepperComponent: PropTypes.object,
  isLastStep: PropTypes.bool,

  createOrbitalPositionWithBeamsAndTransponders: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    orbitalPositions: listOrbitalPositionsSelector(state)
  };
}

const mapDispatchToProps = {
  createOrbitalPositionWithBeamsAndTransponders: createOrbitalPositionWithBeamsAndTranspondersAction,
  addToast: addToastAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withStepper(
  AddOrbitalPositionModal, [
    "Set orbital position information",
    "Add Beam(s)",
    "Add Transponder(s)",
    "Sumup"
  ]));
