// import PoppinsBlack from "./fonts/Poppins/Poppins-Black.ttf"
// import PoppinsBlackItalic from "./fonts/Poppins/Poppins-BlackItalic.ttf"
import PoppinsBold from "./fonts/Poppins/Poppins-Bold.ttf";

// import PoppinsBoldItalic from "./fonts/Poppins/Poppins-BoldItalic.ttf"
// import PoppinsExtraBold from "./fonts/Poppins/Poppins-ExtraBold.ttf"
// import PoppinsExtraBoldItalic from "./fonts/Poppins/Poppins-ExtraBoldItalic.ttf"
// import PoppinsExtraLight from "./fonts/Poppins/Poppins-ExtraLight.ttf"
// import PoppinsExtraLightItalic from "./fonts/Poppins/Poppins-ExtraLightItalic.ttf"
// import PoppinsItalic from "./fonts/Poppins/Poppins-Italic.ttf"
import PoppinsLight from "./fonts/Poppins/Poppins-Light.ttf";
import PoppinsLightItalic from "./fonts/Poppins/Poppins-LightItalic.ttf";
import PoppinsMedium from "./fonts/Poppins/Poppins-Medium.ttf";

// import PoppinsMediumItalic from "./fonts/Poppins/Poppins-MediumItalic.ttf"
import PoppinsRegular from "./fonts/Poppins/Poppins-Regular.ttf";
import PoppinsSemiBold from "./fonts/Poppins/Poppins-SemiBold.ttf";

// import PoppinsSemiBoldItalic from "./fonts/Poppins/Poppins-SemiBoldItalic.ttf"
import PoppinsThin from "./fonts/Poppins/Poppins-Thin.ttf";

// import PoppinsThinItalic from "./fonts/Poppins/Poppins-ThinItalic.ttf"

const fontFace = `
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-display: swap;
    font-weight: 100;
    src: local('Poppins'), local('Poppins-Thin'), url(${PoppinsThin}) format('truetype');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
  }
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Poppins'), local('Poppins-Light'), url(${PoppinsLight}) format('truetype');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
  }
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-display: swap;
    font-weight: 300;
    src: local('Poppins'), local('Poppins-LightItalic'), url(${PoppinsLightItalic}) format('truetype');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
  }
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Poppins'), local('Poppins-Regular'), url(${PoppinsRegular}) format('truetype');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
  }
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: local('Poppins'), local('Poppins-Medium'), url(${PoppinsMedium}) format('truetype');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
  }
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Poppins'), local('Poppins-SemiBold'), url(${PoppinsSemiBold}) format('truetype');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
  }
  @font-face {
    font-family: 'Poppins';
    font-style: bold;
    font-display: swap;
    font-weight: 900;
    src: local('Poppins'), local('Poppins-Bold'), url(${PoppinsBold}) format('truetype');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
  }
`;

export default fontFace;
