/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Checkbox, Box, FormControl, InputLabel, Select, MenuItem, DialogContent, Typography, Button, Alert, TextField } from "@mui/material";
import { isEqual } from "lodash";
import Modal from "../Modal/Modal";
import withStepper from "../Modal/withStepper";
import { addToast as addToastAction } from "../../actions/toasts";

import {
  updateTransponder as updateTransponderAction,
  indexTranspondersFilters as indexTranspondersFiltersAction
} from "../../actions/transponders";

import {
  listOrbitalPositions as listOrbitalPositionsSelector
} from "../../selectors/orbital_positions";
import {
  listBeams as listBeamsSelector
} from "../../selectors/beams";
import {
  showTransponder,
  listTransponders as listTranspondersSelector,
  listTranspondersFilters as listTranspondersFiltersSelector
} from "../../selectors/transponders";

import { capitalize } from "../../helpers/utils";

const initialState = {
  orbital_position_id: "",
  beam_id: "",
  name: "",
  theoreticalOnid: "",
  theoreticalTsid: "",
  broadcastOnid: "",
  broadcastTsid: "",
  frequency: "",
  nitOrbitalPosition: "",
  polarization: "",
  modulationSystem: "",
  modulationType: "",
  symbolRate: "",
  fecInner: "not defined",
  rollOff: "",
  homets: false,

  globalEditing: false,
};

const polarizationName = pol => {
  switch (pol) {
    case "lh":
      return "linear - horizontal";

    case "lv":
      return "linear - vertical";

    case "cl":
      return "circular - left";

    case "cr":
      return "circular - right";

    default:
      return "";
  }
};

const rollOffToString = floatRollOff => {
  switch (floatRollOff) {
    case 0.2:
      return "0.20";

    default:
      return (floatRollOff || "").toString();
  }
};

class EditTransponderModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { ...initialState };
  }

  componentDidMount() {
    const { indexTranspondersFilters } = this.props;

    indexTranspondersFilters();
  }

  componentDidUpdate(prevProps) {
    const { transponder, beams, orbitalPositions, transpondersFilters } = this.props;

    if (transponder !== null && !isEqual(transponder, prevProps.transponder)) {
      const fecInnerFilter = transpondersFilters.find(t => t.name === "fec_inner").values;
      const {
        name,
        beam_id,
        theoretical_onid: theoreticalOnid,
        theoretical_tsid: theoreticalTsid,
        broadcast_onid: broadcastOnid,
        broadcast_tsid: broadcastTsid,
        frequency,
        nit_orbital_position: nitOrbitalPosition,
        polarization,
        modulation_system: modulationSystem,
        modulation_type: modulationType,
        symbol_rate: symbolRate,
        fec_inner: fecInner,
        roll_off: rollOff,
        homets
      } = transponder;

      const beam = beams.find(b => b.id === beam_id);
      const orbitalPosition = orbitalPositions.find(op => op.id === (beam || {}).orbital_position_id);

      this.setState({
        orbital_position_id: ((orbitalPosition || {}).id || "").toString(),
        beam_id: ((beam || {}).id || "").toString(),
        name,
        theoreticalOnid: (theoreticalOnid || "").toString(),
        theoreticalTsid: (theoreticalTsid || "").toString(),
        broadcastOnid: (broadcastOnid || "").toString(),
        broadcastTsid: (broadcastTsid || "").toString(),
        frequency: (frequency || "").toString(),
        nitOrbitalPosition: (nitOrbitalPosition || "").toString(),
        polarization,
        modulationSystem,
        modulationType,
        symbolRate: (symbolRate || "").toString(),
        fecInner,
        rollOff: rollOffToString(rollOff),
        homets
      });
    }
  }

  changeInput(inputName, event, flag = "") {
    const { value } = event.target;

    if (flag === "int") {
      if (value === "" || /^\d+$/.test(value)) {
        this.setState({ [inputName]: value });
      }
    } else if (flag === "float") {
      if (value === "" || /^\d*\.?\d*$/.test(value)) {
        this.setState({ [inputName]: value });
      }
    } else {
      this.setState(() => ({ [inputName]: value }));
    }
  }

  toggleInput(inputName) {
    const { [inputName]: inputValue } = this.state;

    this.setState({ [inputName]: !inputValue });
  }

  async updateTransponder() {
    const {
      beam_id,
      name,
      theoreticalOnid,
      theoreticalTsid,
      broadcastOnid,
      broadcastTsid,
      frequency,
      nitOrbitalPosition,
      polarization,
      modulationSystem,
      modulationType,
      symbolRate,
      fecInner,
      rollOff,
      homets
    } = this.state;

    const { transponder, updateTransponder, addToast } = this.props;

    this.setState({ globalEditing: true });

    const transponderParams = {
      beam_id,
      name: name.trim(),
      theoretical_onid: theoreticalOnid,
      theoretical_tsid: theoreticalTsid,
      broadcast_onid: broadcastOnid || null,
      broadcast_tsid: broadcastTsid || null,
      frequency,
      nit_orbital_position: nitOrbitalPosition,
      polarization,
      modulation_system: modulationSystem,
      modulation_type: modulationType,
      symbol_rate: symbolRate,
      fec_inner: fecInner,
      homets
    };

    if (modulationSystem === "dvbs2") {
      transponderParams.roll_off = rollOff;
    }

    const ret = await updateTransponder(transponder.id, transponderParams);

    this.setState({ globalEditing: false });

    if (ret.isSuccess) {
      addToast("success", "Success", "Transponder successfully updated!");
      this.closeModal();
    } else {
      addToast("error", "Error", "Error while updating transponder. Try again.");
    }
  }

  closeModal() {
    const { closeEditModal } = this.props;
    const { globalEditing } = this.state.globalEditing;

    if (globalEditing) {
      return;
    }

    this.setState(initialState);
    closeEditModal();
  }

  isNextPossible() {
    const { currentStep } = this.props;
    const { modulationSystem } = this.state;

    if (currentStep === 0) {
      if (this.isValid(["orbital_position_id", "beam_id", "name"]) && !this.isNameExist()) {
        return true;
      }
      return false;
    } else if (currentStep === 1) {
      if (this.isValid(["theoreticalOnid", "theoreticalTsid"])) {
        return true;
      }
      return false;
    } else if (currentStep === 2) {
      if (this.isValid(["frequency", "nitOrbitalPosition", "symbolRate", "polarization", "modulationType", "modulationSystem", "fecInner"])) {
        if (modulationSystem === "dvbs2" && !this.isValid("rollOff")) {
          return false;
        }
        return true;
      }
      return false;
    }
    return true;
  }

  isValid(inputName, filter = false) {
    if (inputName instanceof Array) {
      return inputName.every(i => this.isValid(i));
    }

    if (filter) {
      return this.state[inputName] && this.isValueOfFilter(this.props.transpondersFilters.find(t => t.name === inputName), this.state[inputName]);
    }

    if (inputName === "rollOff") {
      return this.state.modulationSystem === "dvbs2" && (this.state.rollOff && ["0.20", "0.25", "0.35"].indexOf(this.state.rollOff) !== -1);
    }

    return !!this.state[inputName];
  }

  isNameExist() {
    const { name, beam_id } = this.state;
    const { transponders, transponder } = this.props;

    return this.isValid("name") && transponders.some(t => t.id !== (transponder || {}).id && t.beam_id === parseInt(beam_id, 10) && t.name.toLowerCase() === name.toLowerCase().trim());
  }

  isValueOfFilter(filter, value) {
    if (!filter?.values) {
      return true;
    }

    return filter.values.find(v => v === value);
  }

  step1() {
    const { orbital_position_id, beam_id, name } = this.state;
    const { orbitalPositions, beams, isLastStep } = this.props;

    const isNameValid = this.isValid("name") && !this.isNameExist();
    const isOrbitalIdValid = this.isValid("orbital_position_id");
    const isBeamValid = this.isValid("beam_id");
    const isNameExist = this.isNameExist();

    return (
      <Box sx={{ display: "flex", width: isLastStep ? "100%" : "80%", flexDirection: "column", gap: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}>
          <FormControl fullWidth>
            <InputLabel id="orbital-label">
              Orbital position*
            </InputLabel>
            <Select
              value={orbital_position_id}
              labelId="orbital-label"
              inputProps={{ readOnly: isLastStep }}
              label="Orbital position"
              onChange={
                e => {
                  this.changeInput("orbital_position_id", e);
                }
              }
            >
              {orbitalPositions.map(op =>
                <MenuItem key={op.id} value={op.id}>
                  {op.name}
                </MenuItem>
              )}
            </Select>
          </FormControl>
          { isOrbitalIdValid
          && <FormControl fullWidth>
            <InputLabel id="beam-label">
              Beam*
            </InputLabel>
            <Select
              value={beam_id}
              labelId="beam-label"
              inputProps={{ readOnly: isLastStep }}
              label="Beam"
              onChange={
                e => {
                  this.changeInput("beam_id", e);
                }
              }
            >
              {beams.filter(b => b.orbital_position_id === parseInt(orbital_position_id, 10)).map(b =>
                <MenuItem key={b.id} value={b.id}>
                  {b.name}
                </MenuItem>
              )}
            </Select>
          </FormControl>
          }
          { isBeamValid
            && <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {isNameExist && <Alert severity="error">
                A transponder already exists with this name
              </Alert>}
              <TextField label="Name*"
                error={!isNameValid}
                inputProps={{ readOnly: isLastStep }}
                value={name}
                onChange={e => {
                  this.changeInput("name", e);
                }}
              />
            </Box>
          }
        </Box>
      </Box>
    );
  }

  step2() {
    const { theoreticalOnid, theoreticalTsid, broadcastOnid, broadcastTsid } = this.state;
    const { isLastStep } = this.props;

    const isTheoOnidValid = this.isValid("theoreticalOnid");
    const isTheoTsidValid = this.isValid("theoreticalTsid");

    return (
      <Box sx={{ display: "flex", width: isLastStep ? "100%" : "80%", flexDirection: "column", gap: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}>
          <TextField label="Theoretical ONID*"
            error={!isTheoOnidValid}
            inputProps={{ readOnly: isLastStep }}
            value={theoreticalOnid}
            onChange={e => {
              this.changeInput("theoreticalOnid", e, "int");
            }}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField label="Theoretical TSID*"
            error={!isTheoTsidValid}
            inputProps={{ readOnly: isLastStep }}
            value={theoreticalTsid}
            onChange={e => {
              this.changeInput("theoreticalTsid", e, "int");
            }}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField label="Broadcast ONID"
            inputProps={{ readOnly: isLastStep }}
            value={broadcastOnid}
            onChange={e => {
              this.changeInput("broadcastOnid", e, "int");
            }}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField label="Theoretical TSID"
            inputProps={{ readOnly: isLastStep }}
            value={broadcastTsid}
            onChange={e => {
              this.changeInput("broadcastTsid", e, "int");
            }}
          />
        </Box>
      </Box>
    );
  }

  step3() {
    const { homets, frequency, nitOrbitalPosition, symbolRate, polarization, modulationType, modulationSystem, fecInner, rollOff } = this.state;

    const { transpondersFilters, isLastStep, modalOpened } = this.props;

    const isFrequencyValid = this.isValid("frequency");
    const isOrbitalPosIdentifierValid = this.isValid("nitOrbitalPosition");
    const isSymbolRateValid = this.isValid("symbolRate");
    const isPolarizationValid = this.isValid("polarization", true);
    const isModulationTypeValid = this.isValid("modulationType", true);
    const isModulationSystemValid = this.isValid("modulationSystem", true);
    const isRollOffValid = this.isValid("rollOff");

    if (!modalOpened) {
      return;
    }

    return (
      <Box sx={{ display: "flex", width: "100%", gap: 4 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}>
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <Typography>
              Home TS :
            </Typography>
            <Checkbox
              checked={!!homets}
              onClick={() => this.toggleInput("homets")}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField label="Frequency"
              error={!isFrequencyValid}
              inputProps={{ readOnly: isLastStep }}
              value={frequency}
              onChange={e => {
                this.changeInput("frequency", e, "float");
              }}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField label="Orbital Position Identifier"
              error={!isOrbitalPosIdentifierValid}
              inputProps={{ readOnly: isLastStep }}
              value={nitOrbitalPosition}
              onChange={e => {
                this.changeInput("nitOrbitalPosition", e);
              }}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField label="Symbol Rater"
              error={!isSymbolRateValid}
              inputProps={{ readOnly: isLastStep }}
              value={symbolRate}
              onChange={e => {
                this.changeInput("symbolRate", e, "float");
              }}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%", justifyContent: isLastStep ? "center" : "flex-end" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="beam-label">
                Polarization*
              </InputLabel>
              <Select
                value={polarization}
                error={!isPolarizationValid}
                labelId="beam-label"
                inputProps={{ readOnly: isLastStep }}
                label="Polarization"
                onChange={
                  e => {
                    this.changeInput("polarization", e);
                  }
                }
              >
                {((transpondersFilters.find(t => t.name === "polarization") || {}).values || []).map(v =>
                  <MenuItem key={v} value={v}>
                    {polarizationName(v)}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="beam-label">
                Modulation Type*
              </InputLabel>
              <Select
                value={modulationType}
                error={!isModulationTypeValid}
                labelId="beam-label"
                inputProps={{ readOnly: isLastStep }}
                label="Modulation Type"
                onChange={
                  e => {
                    this.changeInput("modulationType", e);
                  }
                }
              >
                {((transpondersFilters.find(t => t.name === "modulation_type") || {}).values || []).map(v =>
                  <MenuItem key={v} value={v}>
                    {capitalize(v)}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="beam-label">
                Modulation System*
              </InputLabel>
              <Select
                value={modulationSystem}
                error={!isModulationSystemValid}
                labelId="beam-label"
                inputProps={{ readOnly: isLastStep }}
                label="Modulation System"
                onChange={
                  e => {
                    this.changeInput("modulationSystem", e);
                  }
                }
              >
                {((transpondersFilters.find(t => t.name === "modulation_system") || {}).values || []).map(v =>
                  <MenuItem key={v} value={v}>
                    {capitalize(v)}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="beam-label">
                FEC Inner*
              </InputLabel>
              <Select
                value={fecInner}
                labelId="beam-label"
                inputProps={{ readOnly: isLastStep }}
                label="FEC Inner"
                onChange={
                  e => {
                    this.changeInput("fecInner", e);
                  }
                }
              >
                {((transpondersFilters.find(t => t.name === "fec_inner") || {}).values || []).map((v, index) =>
                  <MenuItem key={index} value={v}>
                    {capitalize(v)}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
          { modulationSystem === "dvbs2"
        && <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="beam-label">
              Roll Off
            </InputLabel>
            <Select
              value={rollOff}
              error={!isRollOffValid}
              labelId="beam-label"
              inputProps={{ readOnly: isLastStep }}
              label="Roll Off"
              onChange={
                e => {
                  this.changeInput("rollOff", e);
                }
              }
            >
              <MenuItem value="0.20" >
              0.20
              </MenuItem>
              <MenuItem value="0.25" >
              0.25
              </MenuItem>
              <MenuItem value="0.35" >
              0.35
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
          }
        </Box>
      </Box>
    );
  }

  modalContent() {
    const { currentStep, isLastStep, stepperComponent } = this.props;

    return (
      <DialogContent sx={{ display: "flex", gap: 3, flexDirection: "column", marginTop: 1, width: "80%", alignItems: "center", alignSelf: "center" }}>
        { stepperComponent }
        { currentStep === 0 && this.step1() }
        { currentStep === 1 && this.step2() }
        { currentStep === 2 && this.step3() }
        { isLastStep
          && <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 3 }}>
            {this.step3()}
            <Box sx={{ display: "flex", width: "100%", gap: 4 }}>
              <Box sx={{ width: "100%" }}>
                {this.step1()}
              </Box>
              <Box sx={{ width: "100%" }}>
                {this.step2()}
              </Box>
            </Box>
          </Box>
        }
      </DialogContent>
    );
  }

  modalActions() {
    const { currentStep, previousStep, nextStep, isLastStep } = this.props;
    const isNextPossible = this.isNextPossible();

    return (
      [
        <Button variant="outlined"
          key="cancel"
          onClick={() => {
            this.closeModal();
          }} >
            Cancel
        </Button>,
        currentStep > 0 && <Button variant="outlined"
          key="previous"
          onClick={() => {
            previousStep();
          }}>
            Previous
        </Button>,
        !isLastStep && <Button variant="contained"
          key="next"
          disabled={ !isNextPossible }
          color="secondary"
          onClick={() => {
            nextStep();
          }}>
            Next
        </Button>,
        isLastStep && <Button variant="contained"
          key="save"
          color="secondary"
          onClick={() => {
            this.updateTransponder();
          }}>
            Save
        </Button>
      ]
    );
  }

  render() {
    const {
      modalOpened,
      transponder
    } = this.props;

    return (
      <Modal
        isOpen={modalOpened}
        closeModal={() => this.closeModal()}
        title={"Edit transponder " + transponder.name}
        customModalContent={() => this.modalContent()}
        actions={() => this.modalActions()}
        actionAlign="flex-end"
      >
      </Modal>
    );
  }
}

EditTransponderModal.defaultProps = {
  currentId: null
};

EditTransponderModal.propTypes = {
  // Used in mapStateToProps
  // eslint-disable-next-line react/no-unused-prop-types
  currentId: PropTypes.number,

  transponder: PropTypes.object.isRequired,

  modalOpened: PropTypes.bool.isRequired,
  closeEditModal: PropTypes.func.isRequired,

  orbitalPositions: PropTypes.arrayOf(PropTypes.object).isRequired,
  beams: PropTypes.arrayOf(PropTypes.object).isRequired,
  transponders: PropTypes.arrayOf(PropTypes.object).isRequired,
  transpondersFilters: PropTypes.arrayOf(PropTypes.object).isRequired,

  currentStep: PropTypes.number,
  previousStep: PropTypes.func,
  nextStep: PropTypes.func,
  stepperComponent: PropTypes.object,
  isLastStep: PropTypes.bool,

  updateTransponder: PropTypes.func.isRequired,
  indexTranspondersFilters: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    orbitalPositions: listOrbitalPositionsSelector(state),
    beams: listBeamsSelector(state),
    transponders: listTranspondersSelector(state),
    transpondersFilters: listTranspondersFiltersSelector(state),

    transponder: showTransponder(state, ownProps.currentId) || {}
  };
}

const mapDispatchToProps = {
  updateTransponder: updateTransponderAction,
  indexTranspondersFilters: indexTranspondersFiltersAction,
  addToast: addToastAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withStepper(
  EditTransponderModal, [
    "Set beam information",
    "Set transponder technical information #1",
    "Set transponder technical information #2",
    "Sumup"
  ]));
