/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AceEditor from "react-ace";
import { isEqual } from "lodash";

import {
  Alert,
  Box,
  Button,
  DialogContent, Fab,
  DialogTitle,
  Dialog,
  DialogActions,
  FormControl, FormHelperText,
  InputLabel, MenuItem, Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import { addToast as addToastAction } from "../../actions/toasts";

import {
  updateLineup as updateLineupAction,
  deleteLineup as deleteLineupAction,
  createLineupLogo as createLineupLogoAction,
  removeLineupLogo as removeLineupLogoAction
} from "../../actions/lineups";

import {
  createBeamsLineups as createBeamsLineupsAction,
  udpateBeamsLineups as udpateBeamsLineupsAction
} from "../../actions/beams_lineups";

import { listBeams } from "../../selectors/beams";
import { listBeamsLineups as listBeamsLineupsSelector } from "../../selectors/beams_lineups";
import { showLineup, listOrbitalPositionsLineupsFromBeamId } from "../../selectors/lineups";
import { listLineupsServices } from "../../selectors/lineups_services";
import { listOrbitalPositions } from "../../selectors/orbital_positions";

import { isLoading as isLoadingSelector } from "../../selectors/loaders";

import {
  toJsonString,
  isValidJson,
  isValidLocaleJson,
  getObjectNameFromLocales
} from "../../helpers/utils";
import { getImageAttributes } from "../../helpers/images";

import Modal from "../Modal/Modal";
import withStepper from "../Modal/withStepper";

const initialState = {
  beam_id: 0,
  phone_contact_support: "",
  url_contact_support: "",
  wakeup_service_id: 0,
  default_language: "",
  locales: toJsonString("{\"en_GB\":{\"name\":\"Lineup\",\"description\":\"\"}}"),
  parent_id: null,
  logos: [],
  logosPreviews: [],

  additionalImages: [],
  additionalImagesPreviews: [],

  hasFetchingLS: false,
  deleteModalOpened: false
};

class EditLineupModal extends PureComponent {
  constructor(props) {
    super(props);

    let state = { ...initialState };

    if (props.lineup) {
      const {
        id,
        phone_contact_support,
        url_contact_support,
        wakeup_service_id,
        default_language,
        locales,
        parent_id,

        logos
      } = props.lineup;

      const realLogos = (logos || []).filter(logo => logo.additional !== true);

      const logosPreviews = realLogos.map(logo => ({
        id: logo.id,
        url: logo.file_url
      }));

      const realAdditionalsImages = (logos || []).filter(logo => logo.additional === true);

      const additionalImagesPreviews = realAdditionalsImages.map(logo => ({
        id: logo.id,
        url: logo.file_url
      }));

      const currentBeamsLineups = props.beamsLineups.filter(bl => bl.lineup_id === id);

      state = {
        ...state,

        beam_id: currentBeamsLineups.length > 0 ? currentBeamsLineups[0].beam_id : 0,
        phone_contact_support,
        url_contact_support,
        default_language,
        wakeup_service_id: wakeup_service_id || -1,
        locales: toJsonString(JSON.stringify(locales)),
        parent_id,
        logos: realLogos,
        logosPreviews,
        additionalImages: realAdditionalsImages,
        additionalImagesPreviews
      };
    }

    this.state = { ...state };

    this.fileInput = React.createRef();
    this.additionalInput = React.createRef();

    this.addLogo = this.addLogo.bind(this);
    this.addAdditional = this.addAdditional.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {
      lineup,
      beamsLineups,
      modalOpened
    } = this.props;

    if ((prevProps.modalOpened === false && modalOpened === true) || (!isEqual(prevProps.lineup, lineup) && !isEqual(lineup, null) && !isEqual(lineup, {}))) {
      const {
        id,
        phone_contact_support,
        url_contact_support,
        wakeup_service_id,
        default_language,
        locales,
        parent_id,

        logos
      } = lineup;

      const realLogos = (logos || []).filter(logo => logo.additional !== true);

      const logosPreviews = realLogos.map(logo => ({
        id: logo.id,
        url: logo.file_url
      }));

      const realAdditionalsImages = (logos || []).filter(logo => logo.additional === true);

      const additionalImagesPreviews = realAdditionalsImages.map(logo => ({
        id: logo.id,
        url: logo.file_url
      }));

      const currentBeamsLineups = beamsLineups.filter(bl => bl.lineup_id === id);

      this.setState({
        beam_id: currentBeamsLineups.length > 0 ? currentBeamsLineups[0].beam_id : 0,
        phone_contact_support,
        url_contact_support,
        default_language,
        wakeup_service_id: wakeup_service_id || -1,
        locales: toJsonString(JSON.stringify(locales)),
        parent_id,
        logos: realLogos,
        logosPreviews,
        additionalImages: realAdditionalsImages,
        additionalImagesPreviews
      });
    }
  }

  async editLineup() {
    const {
      beam_id,
      phone_contact_support,
      url_contact_support,
      wakeup_service_id,
      default_language,
      locales,
      parent_id,

      logos,
      additionalImages
    } = this.state;

    const {
      lineup,
      beamsLineups,

      updateLineup,
      createLineupLogo,
      removeLineupLogo,
      createBeamsLineups,
      udpateBeamsLineups,
      addToast
    } = this.props;

    // TODO: additionals

    // Logo to remove
    const logosToRemove = lineup.logos.filter(logo => logo.additional !== true && !logos.find(l => l.id === logo.id));

    // Additional to remove
    const additionalToRemove = lineup.logos.filter(logo => logo.additional === true && !additionalImages.find(l => l.id === logo.id));

    // Logo to add
    const logosData = [];

    for (let i = 0; i < logos.length; i += 1) {
      if (logos[i].isNew) {
        const imageAttributes = await getImageAttributes(logos[i]);
        const logoData = new FormData();

        logoData.append("file", logos[i]);
        logoData.append("delivery", "dvb");
        logoData.append("file_type", imageAttributes.fileType);
        logoData.append("width", imageAttributes.width);
        logoData.append("height", imageAttributes.height);

        logosData.push(logoData);
      }
    }

    // Additional to add
    for (let i = 0; i < additionalImages.length; i += 1) {
      if (additionalImages[i].isNew) {
        const imageAttributes = await getImageAttributes(additionalImages[i]);
        const logoData = new FormData();

        logoData.append("file", additionalImages[i]);
        logoData.append("delivery", "dvb");
        logoData.append("file_type", imageAttributes.fileType);
        logoData.append("width", imageAttributes.width);
        logoData.append("height", imageAttributes.height);
        logoData.append("additional", true);

        logosData.push(logoData);
      }
    }

    const currentBeamsLineups = beamsLineups.filter(bl => bl.lineup_id === lineup.id);

    // If there is not any beams_lineups or if the beam_id has been changed
    if (currentBeamsLineups.length === 0 && beam_id > 0) {
      await createBeamsLineups({
        beam_id,
        lineup_id: lineup.id
      });
    } else if (currentBeamsLineups[0].beam_id !== beam_id) {
      await udpateBeamsLineups(currentBeamsLineups[0].id, {
        beam_id
      });
    }

    const lineupData = {
      parent_id,
      phone_contact_support,
      url_contact_support,
      wakeup_service_id: parseInt(wakeup_service_id, 10) > 0 ? wakeup_service_id : null,
      default_language,
      locales: JSON.parse(locales)
    };

    // Update Lineup
    const ret = await updateLineup(lineup.id, lineupData);

    if (ret.isSuccess) {
      // Remove logos
      for (let i = 0; i < logosToRemove.length; i += 1) {
        await removeLineupLogo(lineup.id, logosToRemove[i].id);
      }

      // Remove additional
      for (let i = 0; i < additionalToRemove.length; i += 1) {
        await removeLineupLogo(lineup.id, additionalToRemove[i].id);
      }

      // Add logos
      for (let i = 0; i < logosData.length; i += 1) {
        await createLineupLogo(lineup.id, logosData[i]);
      }

      addToast("success", "Success", "Lineup successfully updated!");
      this.closeModal();
    } else {
      addToast("error", "Error", "Error while updating lineup. Try again.");
    }
  }

  changeInput(inputName, event) {
    const {
      default_language,
      beam_id
    } = this.state;

    const {
      lineup,
      listOrbitalPositionLineups
    } = this.props;

    let { value } = event.target;

    if (inputName === "parent_id" && parseInt(beam_id, 10)) {
      const lineups = listOrbitalPositionLineups(parseInt(beam_id, 10));
      const parent_id = parseInt(value, 10);
      const existingLineup = lineups.some(l => l.id !== lineup.id && l.parent_id === null && l.id === parent_id);

      if (!existingLineup) {
        value = null;
      } else {
        value = parent_id;
      }
    }

    this.setState(() => ({ [inputName]: value }), () => {
      if (inputName === "locales") {
        let languageFound = true;

        try {
          const localesJson = JSON.parse(value);

          languageFound = Object.keys(localesJson).indexOf(default_language) !== -1;
        } catch {
          languageFound = false;
        }

        if (!languageFound) {
          this.setState({ default_language: initialState.default_language });
        }
      }
    });
  }

  closeModal() {
    const { closeEditLineupModal } = this.props;

    this.setState(initialState);
    closeEditLineupModal();
  }

  stepIsValid = () => {
    const { currentStep } = this.props;

    if (currentStep === 0) {
      return this.isBeamIdValid() && this.isDefaultLanguageValid() && this.isLocalesJsonValid() && this.isLocalesEntriesValid();
    } else if (currentStep === 1) {
      return this.isParentIdValid();
    } else if (currentStep === 2) {
      return this.isLogosValid();
    }

    return true;
  };

  isBeamIdValid() {
    const { beam_id } = this.state;
    const { beams } = this.props;

    return !!beams.find(b => b.id === parseInt(beam_id, 10));
  }

  isDefaultLanguageValid() {
    const { default_language } = this.state;

    return default_language && default_language.length === 5;
  }

  isLocalesJsonValid() {
    const { locales } = this.state;

    return locales && locales.length > 0 && isValidJson(locales) && isValidLocaleJson(locales);
  }

  openDeleteModal() {
    this.setState({ deleteModalOpened: true });
  }

  closeDeleteModal() {
    this.setState({ deleteModalOpened: false });
  }

  isLocalesEntriesValid() {
    if (this.isLocalesJsonValid()) {
      const { locales, default_language } = this.state;

      let newDefaultLanguage = default_language;

      if (!JSON.parse(locales)[default_language]) {
        newDefaultLanguage = Object.keys(JSON.parse(locales)).find(localeKey => localeKey.length === 5);
      }

      const currentLineupName = JSON.parse(locales)[newDefaultLanguage].name || "";

      return currentLineupName && currentLineupName.length > 0 && currentLineupName.toString() === currentLineupName;
    }

    return true;
  }

  isParentIdValid() {
    const { parent_id, beam_id } = this.state;
    const { listOrbitalPositionLineups, lineup } = this.props;

    if (parent_id !== null) {
      const lineups = listOrbitalPositionLineups(parseInt(beam_id, 10));

      return lineups.some(l => l.id !== lineup.id && l.parent_id === null && l.id === parseInt(parent_id, 10));
    }

    return true;
  }

  isLogosValid() {
    const { logos } = this.state;

    return logos && logos.length;
  }

  addLogo(event) {
    const { logos, logosPreviews } = this.state;

    if (this.fileInput.current.files.length > 0) {
      const file = this.fileInput.current.files[0];

      // eslint-disable-next-line no-param-reassign
      event.target.value = null;

      const newLogos = [...logos];

      file.id = new Date().valueOf();
      file.isNew = true;

      newLogos.push(file);

      this.setState({ logos: newLogos });

      const reader = new FileReader();

      reader.onload = e => {
        const newLogosPreviews = [...logosPreviews];

        newLogosPreviews.push({
          id: file.id,
          url: e.target.result
        });

        this.setState({
          logosPreviews: newLogosPreviews
        });
      };

      reader.readAsDataURL(file);
    }

    event.preventDefault();
    event.stopPropagation();
  }

  addAdditional(event) {
    const { additionalImages, additionalImagesPreviews } = this.state;

    if (this.additionalInput.current.files.length > 0) {
      const file = this.additionalInput.current.files[0];

      // eslint-disable-next-line no-param-reassign
      event.target.value = null;

      const newAdditionalImages = [...additionalImages];

      file.id = new Date().valueOf();
      file.isNew = true;

      newAdditionalImages.push(file);

      this.setState({ additionalImages: newAdditionalImages });

      const reader = new FileReader();

      reader.onload = e => {
        const newAdditionalImagesPreviews = [...additionalImagesPreviews];

        newAdditionalImagesPreviews.push({
          id: file.id,
          url: e.target.result
        });

        this.setState({
          additionalImagesPreviews: newAdditionalImagesPreviews
        });
      };

      reader.readAsDataURL(file);
    }

    event.preventDefault();
    event.stopPropagation();
  }

  removeLogo(logoId) {
    const { logos, logosPreviews } = this.state;
    const newLogos = logos.filter(l => l.id !== logoId);
    const newLogosPreviews = logosPreviews.filter(l => l.id !== logoId);

    this.setState({ logos: newLogos, logosPreviews: newLogosPreviews });
  }

  removeAdditional(logoId) {
    const { additionalImages, additionalImagesPreviews } = this.state;
    const newAdditionalImages = additionalImages.filter(l => l.id !== logoId);
    const newAdditionalImagesPreviews = additionalImagesPreviews.filter(l => l.id !== logoId);

    this.setState({ additionalImages: newAdditionalImages, additionalImagesPreviews: newAdditionalImagesPreviews });
  }

  async deleteExistingLineup() {
    const { currentLineupId, deleteLineup, addToast } = this.props;

    const ret = await deleteLineup(currentLineupId);

    if (ret.isSuccess) {
      addToast("success", "Success", "Lineup successfully deleted!");
      this.closeModal();
    } else {
      addToast("error", "Error", "Error while deleting lineup. Try again");
    }
  }

  triggerLogoInput(event) {
    event.preventDefault();
    event.stopPropagation();

    this.fileInput.current.click();
  }

  triggerAdditionalLogoInput(event) {
    event.preventDefault();
    event.stopPropagation();

    this.additionalInput.current.click();
  }

  modalContent() {
    const {
      beam_id,
      parent_id,
      phone_contact_support,
      url_contact_support,
      wakeup_service_id,
      default_language,
      locales,
      logosPreviews,
      additionalImagesPreviews,
      deleteModalOpened
    } = this.state;

    const {
      lineup,
      lineupsServices,
      beams,
      orbitalPositions,
      listOrbitalPositionLineups,
      currentStep,
      stepperComponent
    } = this.props;

    const services = [];

    lineupsServices.filter(ls => ls.lineup_id === lineup.id || ls.lineup_id === lineup.parent_id).forEach(ls => {
      if (ls.service && !services.find(s => s.id === ls.service.id)) {
        services.push(ls.service);
      }
    });

    const sortedServices = services.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }

      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }

      return 0;
    });

    let computedDefaultLanguages;

    try {
      computedDefaultLanguages = Object.keys(JSON.parse(locales)).filter(l => l.length === 5);
    } catch {
      computedDefaultLanguages = [];
    }

    let lineups = [];
    let isParent = false;

    if (beam_id) {
      lineups = listOrbitalPositionLineups(parseInt(beam_id, 10));
      isParent = !!lineups.find(l => l.parent_id === lineup.id);
      lineups = lineups.filter(l => l.id !== lineup.id && l.parent_id === null);
    }

    let orbitalPositionName = "";

    if (beam_id) {
      const beam = beams.find(b => b.id === parseInt(beam_id, 10));

      if (beam) {
        const orbitalPosition = orbitalPositions.find(op => op.id === beam.orbital_position_id);

        if (orbitalPosition) {
          orbitalPositionName = `${orbitalPosition.name} (Beam ${beam.name})`;
        }
      }
    }

    return (
      <DialogContent sx={{ display: "flex", gap: 3, flexDirection: "column", marginTop: 1, width: "80%", alignItems: "center", alignSelf: "center" }}>
        {stepperComponent}
        {currentStep === 0 && <Stack direction="row" justifyContent="space-between" spacing={3} width="100%">
          <Stack spacing={3} alignItems="flex-start" minWidth="250px" maxWidth="300px" width="100%">
            <FormControl error={!beam_id} fullWidth required>
              <InputLabel id="orbital-position-select">Orbital Position</InputLabel>
              <Select
                labelId="orbital-position-select"
                label="Orbital position"
                onChange={e => this.changeInput("beam_id", e)}
                value={beam_id || ""}
              >
                {beam_id === 0 && <MenuItem value={0}>Select an orbital position</MenuItem>}
                {orbitalPositions.map(op => {
                  const currentBeams = beams.filter(b => b.orbital_position_id === op.id);
                  return currentBeams.map(cb => <MenuItem key={cb.id} value={cb.id}>{`${op.name} - Beam ${cb.name}`}</MenuItem>);
                })}
              </Select>
              {!this.isBeamIdValid() && <FormHelperText error>Orbital Position is not valid</FormHelperText>}
            </FormControl>
            <TextField label="Phone Contact Support" value={phone_contact_support} onChange={e => this.changeInput("phone_contact_support", e)} fullWidth />
            <TextField label="Url Contact Support" value={url_contact_support} onChange={e => this.changeInput("url_contact_support", e)} fullWidth />
            <FormControl fullWidth>
              <InputLabel id="wekeup_channel_select">Wakeup channel</InputLabel>
              <Select
                labelId="wakeup_channel_select"
                label="Wakeup channel"
                onChange={e => this.changeInput("wakeup_service_id", e)}
                value={wakeup_service_id || -1}
                displayEmpty
              >
                <MenuItem value={-1}>No wakeup channel</MenuItem>
                {sortedServices.map(s => <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>)}
              </Select>
            </FormControl>
            <FormControl error={computedDefaultLanguages.length < 1} fullWidth required>
              <InputLabel id="default-language-select">Default language</InputLabel>
              <Select
                labelId="default-language-select"
                label="Default language"
                onChange={e => this.changeInput("default_language", e)}
                value={default_language || ""}
              >
                {computedDefaultLanguages.map(dl => <MenuItem key={dl} value={dl}>{dl}</MenuItem>)}
              </Select>
              {!this.isDefaultLanguageValid() && <FormHelperText error>You must define locale json to select a default language</FormHelperText>}
            </FormControl>
          </Stack>
          <Stack width="100%">
            <Typography variant="caption">Locales *</Typography>
            <AceEditor
              style={{ maxWidth: "400px" }}
              mode="json"
              theme="tomorrow"
              fontSize="0.9rem"
              name="lineup-edit-locales-edit"
              height="300px"
              setOptions={{
                useWorker: false,
                showLineNumbers: false
              }}
              value={locales}
              onChange={value => this.changeInput("locales", { target: { value } })}
              editorProps={{ $blockScrolling: true }}
            />
            {!this.isLocalesJsonValid()
              && <FormHelperText error>Locales json is not valid. Locales json should contain language keys with length equals to 5 characters</FormHelperText>}
            {!this.isLocalesEntriesValid()
              && <FormHelperText error>You have to define a name entry in the locales JSON. For example: {"{\"en_GB\": {\"name\": \"Lineup\", \"description\": \"\"}}"}</FormHelperText>}
          </Stack>
        </Stack>}
        {currentStep === 1 && <Stack spacing={3}>
          {!isParent
            && <>
              <Alert severity="info">
                <Typography variant="body2" align="center">
                You can associate another lineup as parent of this new one. If so, this parent lineup won't be exported on air.<br/>
                The configuration of this lineup (the child) will have priority over its parent.<br/>
                For example, if the LCN 42 is associated to a service in both parent and child lineups, the child lineup LCN 42 will be exported.
                </Typography>
              </Alert>
              <FormControl fullWidth>
                <InputLabel id="parent-lineup-select" focused>Parent Lineup</InputLabel>
                <Select
                  labelId="parent-lineup-select"
                  label="Parent lineup"
                  onChange={e => this.changeInput("parent_id", e)}
                  value={parent_id || ""}
                  displayEmpty
                  notched
                >
                  <MenuItem value="">No parent lineup</MenuItem>
                  {lineups.map(lineup => <MenuItem key={lineup.id} value={lineup.id}>{getObjectNameFromLocales(lineup)}</MenuItem>)}
                </Select>
                { !this.isParentIdValid() && <FormHelperText error>You must define locale json to select a default language</FormHelperText> }
              </FormControl>
            </>}
          {isParent && <Alert severity="warning">
            <Typography variant="body2" align="center">
              This lineups is referenced as parent in some other lineups. It cannot be either parent and child
            </Typography>
          </Alert>}
        </Stack>}
        {currentStep === 2 && <>
          <Stack direction="row" spacing={3}>
            {logosPreviews.map(logo => <Paper
              sx={{
                backgroundColor: "text.grey",
                width: "100px",
                height: "100px",
                position: "relative"
              }}
              elevation={3}
              key={logo.id}
            >
              <Fab
                sx={{
                  position: "absolute",
                  bottom: "-15px",
                  right: "-15px"
                }}
                size="small"
                onClick={() => this.removeLogo(logo.id)}
              >
                <DeleteIcon />
              </Fab>
              <img style={{ width: "100%", position: "absolute", top: "0", bottom: "0", margin: "auto" }} src={logo.url} alt="" />
            </Paper>
            )}
            <Paper
              sx={{
                backgroundColor: "text.grey",
                width: "100px",
                height: "100px",
                position: "relative"
              }}
              elevation={3}
            >
              <Fab
                sx={{
                  position: "absolute",
                  bottom: "-15px",
                  right: "-15px"
                }}
                size="small"
                onClick={e => this.triggerLogoInput(e)}
              >
                <AddIcon />
              </Fab>
              <input
                style={{ display: "none" }}
                type="file"
                id="add-logo"
                accept="image/png, image/jpeg"
                ref={this.fileInput}
                onChange={this.addLogo}
              />
            </Paper>
          </Stack>
          {!this.isLogosValid() && <Typography variant="caption" color="error.main">At least one logo should be added</Typography>}
        </>}
        {currentStep === 3 && <Stack spacing={3}>
          <Alert severity="info">
            <Typography variant="body2" align="center">
              Additional resource(s) can be used to provide ads. Technically, it will be exported in the Eutelsat Additional Resources file (EAR json)
            </Typography>
          </Alert>
          <Stack direction="row" spacing={3} justifyContent="center">
            {additionalImagesPreviews.map(logo => <Paper
              sx={{
                backgroundColor: "text.grey",
                width: "100px",
                height: "100px",
                position: "relative"
              }}
              key={logo.id}
              elevation={3}
            >
              <Fab
                sx={{
                  position: "absolute",
                  bottom: "-15px",
                  right: "-15px"
                }}
                size="small"
                onClick={() => this.removeAdditional(logo.id)}>
                <DeleteIcon />
              </Fab>
              <img style={{ width: "100%", position: "absolute", top: "0", bottom: "0", margin: "auto" }} src={logo.url} alt="" />
            </Paper>)}
            <Paper
              sx={{
                backgroundColor: "text.grey",
                width: "100px",
                height: "100px",
                position: "relative"
              }}
              elevation={3}
            >
              <Fab
                sx={{
                  position: "absolute",
                  bottom: "-15px",
                  right: "-15px"
                }}
                size="small"
                onClick={e => this.triggerAdditionalLogoInput(e)}
              >
                <AddIcon />
              </Fab>
              <input
                style={{ display: "none" }}
                type="file"
                id="add-logo"
                accept="image/png, image/jpeg"
                ref={this.additionalInput}
                onChange={this.addAdditional}
              />
            </Paper>
          </Stack>
        </Stack>}
        {currentStep === 4 && <Stack spacing={3} alignItems="center">
          <Stack direction="row" spacing={3}>
            <Stack spacing={3}>
              <TextField sx={{ input: { cursor: "default" } }} label="Orbital Position" InputLabelProps={{ focused: true }} value={orbitalPositionName} fullWidth></TextField>
              <TextField sx={{ input: { cursor: "default" } }} label="Parent Lineup" InputLabelProps={{ focused: true }} value={parent_id ? getObjectNameFromLocales(lineups.find(l => l.id === parseInt(parent_id, 10)) || {}) : "None"} fullWidth></TextField>
              <TextField sx={{ input: { cursor: "default" } }} InputProps={{ notched: true }} label="Phone Contact Support" InputLabelProps={{ focused: true }} value={phone_contact_support} fullWidth></TextField>
              <TextField sx={{ input: { cursor: "default" } }} InputProps={{ notched: true }} label="Url Contact Support" InputLabelProps={{ focused: true }} value={url_contact_support} fullWidth></TextField>
            </Stack>
            <Stack spacing={1}>
              <TextField sx={{ input: { cursor: "default" } }} label="Default Language" InputLabelProps={{ focused: true }} value={default_language} fullWidth></TextField>
              <Box>
                <Typography variant="caption">Locales</Typography>
                <AceEditor
                  style={{ maxWidth: "300px" }}
                  mode="json"
                  theme="tomorrow"
                  fontSize="0.9rem"
                  name="lineup-add-locales-show"
                  readOnly
                  height="200px"
                  setOptions={{
                    useWorker: false,
                    showLineNumbers: false
                  }}
                  value={locales}
                  editorProps={{ $blockScrolling: true }}
                />
              </Box>
            </Stack>
          </Stack>
          <Typography variant="body2">Logo(s)</Typography>
          <Stack direction="row" spacing={3}>
            {logosPreviews.map(logo => <Paper
              sx={{
                backgroundColor: "text.grey",
                width: "100px",
                height: "100px",
                position: "relative"
              }}
              elevation={3}
              key={logo.id}
            >
              <img style={{ width: "100%", position: "absolute", top: "0", bottom: "0", margin: "auto" }} src={logo.url} alt="" />
            </Paper>
            )}
          </Stack>
          {additionalImagesPreviews.length > 0 && <>
            <Typography variant="body2">Additional resource(s)</Typography>
            <Stack direction="row" spacing={3}>
              {additionalImagesPreviews.map(logo => <Paper
                sx={{
                  backgroundColor: "text.grey",
                  width: "100px",
                  height: "100px",
                  position: "relative"
                }}
                key={logo.id}
                elevation={3}
              >
                <img style={{ width: "100%", position: "absolute", top: "0", bottom: "0", margin: "auto" }} src={logo.url} alt="" />
              </Paper>)}
            </Stack>
          </>}
        </Stack>}

        { /* Delete Modal */ }

        <Dialog
          open={deleteModalOpened}
        >
          <DialogTitle>Remove the Lineup {getObjectNameFromLocales(lineup)}</DialogTitle>
          <DialogContent dividers>
            <Typography>
                  Are you sure to delete the Lineup {getObjectNameFromLocales(lineup)} ?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.closeDeleteModal()}>
                  Cancel
            </Button>
            <Button onClick={() => this.deleteExistingLineup()}>Confirm</Button>
          </DialogActions>
        </Dialog>
      </DialogContent>
    );
  }

  modalActions() {
    const { currentStep, previousStep, nextStep, isLastStep } = this.props;
    const stepIsValid = this.stepIsValid();

    return (
      [
        <Button
          variant="outlined"
          key="delete"
          color="error"
          onClick={() => {
            this.openDeleteModal();
          }}>
          Delete
        </Button>,
        <Button
          variant="outlined"
          key="cancel"
          onClick={() => {
            this.closeModal();
          }}>
          Cancel
        </Button>,
        currentStep > 0
        && <Button
          variant="outlined"
          key="previous"
          onClick={() => {
            previousStep();
          }}>
          Previous
        </Button>,
        !isLastStep
        && <Button
          variant="contained"
          color="primary"
          key="next"
          disabled={!stepIsValid}
          onClick={() => {
            nextStep();
          }}>
          Next
        </Button>,
        isLastStep
        && <Button
          variant="contained"
          color="primary"
          key="save"
          onClick={() => {
            this.editLineup();
          }}>
          Save
        </Button>
      ]
    );
  }

  render() {
    const {
      lineup,
      modalOpened
    } = this.props;

    const currentLineupName = getObjectNameFromLocales(lineup);

    return (
      <Modal
        isOpen={modalOpened}
        closeModal={() => this.closeModal()}
        title={`Edit the lineup ${currentLineupName}`}
        customModalContent={() => this.modalContent()}
        actions={() => this.modalActions()}
        actionAlign="flex-end"
      />
    );
  }
}

EditLineupModal.defaultProps = {
  currentLineupId: null
};

EditLineupModal.propTypes = {
  currentLineupId: PropTypes.number,
  lineup: PropTypes.object.isRequired,
  lineupsServices: PropTypes.arrayOf(PropTypes.object).isRequired,
  orbitalPositions: PropTypes.arrayOf(PropTypes.object).isRequired,
  beams: PropTypes.arrayOf(PropTypes.object).isRequired,
  beamsLineups: PropTypes.arrayOf(PropTypes.object).isRequired,

  modalOpened: PropTypes.bool.isRequired,
  closeEditLineupModal: PropTypes.func.isRequired,

  currentStep: PropTypes.number,
  previousStep: PropTypes.func,
  nextStep: PropTypes.func,
  stepperComponent: PropTypes.object,
  isLastStep: PropTypes.bool,

  isLineupEditing: PropTypes.bool.isRequired,

  updateLineup: PropTypes.func.isRequired,
  deleteLineup: PropTypes.func.isRequired,
  createLineupLogo: PropTypes.func.isRequired,
  removeLineupLogo: PropTypes.func.isRequired,
  createBeamsLineups: PropTypes.func.isRequired,
  udpateBeamsLineups: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  listOrbitalPositionLineups: PropTypes.func.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    lineup: showLineup(state, ownProps.currentLineupId) || {},

    lineupsServices: listLineupsServices(state),
    orbitalPositions: listOrbitalPositions(state),
    beams: listBeams(state),
    beamsLineups: listBeamsLineupsSelector(state),
    listOrbitalPositionLineups: beamId => listOrbitalPositionsLineupsFromBeamId(state, beamId),

    isLineupEditing: isLoadingSelector(state, updateLineupAction.toString())
                      || isLoadingSelector(state, createLineupLogoAction.toString())
                      || isLoadingSelector(state, removeLineupLogoAction.toString())
  };
}

const mapDispatchToProps = {
  updateLineup: updateLineupAction,
  deleteLineup: deleteLineupAction,
  createLineupLogo: createLineupLogoAction,
  removeLineupLogo: removeLineupLogoAction,
  createBeamsLineups: createBeamsLineupsAction,
  udpateBeamsLineups: udpateBeamsLineupsAction,
  addToast: addToastAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withStepper(
  EditLineupModal, [
    "Set lineup info",
    "Associate a parent lineup (optional)",
    "Edit logo(s)",
    "Edit additional resource(s)",
    "Sumup"
  ]));
