import { combineReducers } from "redux";
import { createRouterReducer } from "@lagunovsky/redux-react-router";

import orbitalPositionsReducer from "./orbital_positions";
import loadersReducer from "./loaders";
import beamsReducer from "./beams";
import beamsLineupsReducer from "./beams_lineups";
import channelTiersReducer from "./channel_tiers";
import contentGenresReducer from "./content_genres";
import editorialChannelsReducer from "./editorial_channels";
import transpondersReducer from "./transponders";
import lineupsReducer from "./lineups";
import lineupsServicesReducer from "./lineups_services";
import ottBackendsReducer from "./ott_backends";
import importancesReducer from "./importances";
import qualificationsReducer from "./qualifications";
import servicesReducer from "./services";
import servicesGenresReducer from "./service_genres";
import servicesTypesReducer from "./service_types";
import techChannelsReducer from "./tech_channels";
import providersReducer from "./providers";
import providersWordingsReducer from "./providers_wordings";
import workerReducer from "./worker";
import authReducer from "./auth";
import epgReports from "./epg_reports";
import nitReports from "./nit_reports";
import exportBackups from "./export_backups";
import drivers from "./drivers";
import replicationsReducer from "./replications";
import toasts from "./toasts";
import globalModalReducer from "./global_modal";

const createRootReducer = history => combineReducers({
  router: createRouterReducer(history),
  worker: workerReducer,
  loaders: loadersReducer,

  orbitalPositions: orbitalPositionsReducer,
  beams: beamsReducer,
  beamsLineups: beamsLineupsReducer,
  channelTiers: channelTiersReducer,
  contentGenres: contentGenresReducer,
  editorialChannels: editorialChannelsReducer,
  lineups: lineupsReducer,
  lineupsServices: lineupsServicesReducer,
  ottBackends: ottBackendsReducer,
  importances: importancesReducer,
  qualifications: qualificationsReducer,
  services: servicesReducer,
  serviceGenres: servicesGenresReducer,
  serviceTypes: servicesTypesReducer,
  techChannels: techChannelsReducer,
  transponders: transpondersReducer,
  providers: providersReducer,
  providersWordings: providersWordingsReducer,
  replications: replicationsReducer,
  epgReports,
  nitReports,
  exportBackups,
  drivers,
  toasts,
  globalModal: globalModalReducer,
  auth: authReducer
});

export default createRootReducer;
