import { createAction } from "redux-actions";

/* Storage actions */
const storeServiceTypes = createAction("STORE_SERVICE_TYPES");
const storeServiceTypesFilters = createAction("STORE_SERVICE_TYPES_FILTERS");
const addServiceType = createAction("ADD_SERVICE_TYPE");
const modifyServiceType = createAction("MODIFY_SERVICE_TYPE");

/* API actions */
export const indexServiceTypes = createAction("API_INDEX_SERVICE_TYPES", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/service_types",
  data,
  fullLoad,
  onSuccessAction: storeServiceTypes
}));

export const indexServiceTypesFilters = createAction("API_INDEX_SERVICE_TYPES_FILTERS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/service_types/filters",
  data,
  fullLoad,
  onSuccessAction: storeServiceTypesFilters
}));

export const createServiceType = createAction("API_CREATE_SERVICE_TYPE", data => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "POST",
  url: "/service_types",
  data,
  onSuccessAction: addServiceType
}));

export const updateServiceType = createAction("API_UPDATE_SERVICE_TYPE", (serviceTypeId, data) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "PUT",
  url: `/service_types/${serviceTypeId}`,
  data,
  onSuccessAction: modifyServiceType
}));

export const deleteServiceType = createAction("API_DELETE_SERVICE_TYPE", serviceTypeId => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "DELETE",
  url: `/service_types/${serviceTypeId}`,
  onSuccessAction: createAction("REMOVE_SERVICE_TYPE", () => ({ id: serviceTypeId }))
}));
