/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  Alert, Box,
  Button, DialogContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack, Table, TableBody, TableCell, TableHead, TableRow,
  TextField,
} from "@mui/material";
import { addToast as addToastAction } from "../../actions/toasts";

import {
  createLineupsService as createLineupsServiceAction
} from "../../actions/lineups_services";

import { listEditorialChannels } from "../../selectors/editorial_channels";
import { showLineup } from "../../selectors/lineups";
import { listLineupsServicesByLineupId } from "../../selectors/lineups_services";

import {
  sortObjectByLocale,
  getObjectNameFromLocales
} from "../../helpers/utils";
import Modal from "../Modal/Modal";

const initialState = {
  lcns: "",
  parent_id: 0,

  globalEditing: false,
};

class AddInheritedLineupServiceModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      lcns: props.predefinedLCNS || "",
      parent_id: props.parentLineupServiceId || ""
    };
  }

  componentDidUpdate(prevProps) {
    const {
      predefinedLCNS,
      parentLineupServiceId
    } = this.props;

    if (prevProps.predefinedLCNS !== predefinedLCNS) {
      this.setState({ lcns: predefinedLCNS });
    }

    if (prevProps.parentLineupServiceId !== parentLineupServiceId) {
      this.setState({ parent_id: parentLineupServiceId });
    }
  }

  formatLCNS = (lcnsOverride = "") => {
    const {
      lcns
    } = this.state;

    const validLcnsArray = [];
    const lcnsToProcess = lcnsOverride.length > 0 ? lcnsOverride : lcns;

    if (lcnsToProcess.length === 0) {
      return [];
    }

    lcnsToProcess
      .split(",")
      .map(lcn => lcn.trim())
      .filter(e => e)
      .forEach(lcn => {
        const validLcn = lcn.replace(/ /gi, "");

        if (!isNaN(validLcn) && validLcnsArray.indexOf(parseInt(validLcn, 10)) === -1) {
          validLcnsArray.push(parseInt(validLcn, 10));
        }
      });

    validLcnsArray.sort((a, b) => {
      if (a < b) {
        return -1;
      }

      if (a > b) {
        return 1;
      }

      return 0;
    });

    return validLcnsArray;
  };

  changeInput(inputName, event) {
    const { value } = event.target;

    this.setState(() => ({ [inputName]: value }));
  }

  async addChannelToLineup() {
    const {
      parent_id
    } = this.state;

    const {
      currentLineupId,
      createLineupsService,
      addToast
    } = this.props;

    if (this.stepIsValid()) {
      this.setState({ globalEditing: true });

      const validLcnsArray = this.formatLCNS();

      const ret = await createLineupsService({
        lineup_id: currentLineupId,
        parent_id,
        lcns: validLcnsArray
      });

      this.setState({ globalEditing: false });

      if (ret.isSuccess) {
        addToast("success", "Success", "Service successfully added to the lineup!");
        this.closeModal(true, true);
      } else {
        addToast("error", "Error", "Error while adding Service to the lineup. Try again.");
      }
    }
  }

  closeModal(shouldClearParentState = true) {
    const { closeLineupServiceModal } = this.props;

    this.setState(initialState);
    closeLineupServiceModal(shouldClearParentState);
  }

  isParentIdValid() {
    const { parent_id } = this.state;
    const { parentLineupServices } = this.props;

    return parent_id && parentLineupServices.find(ls => ls.id === parseInt(parent_id, 10));
  }

  isLcnsValid() {
    const { lcns } = this.state;

    const formattedLcns = this.formatLCNS(lcns);
    return formattedLcns.length > 0;
  }

  isLcnsTaken() {
    if (this.isLcnsValid()) {
      const { lcns } = this.state;
      const { lineupServices } = this.props;

      const formattedLcns = this.formatLCNS(lcns);
      let lcnsError = "";

      formattedLcns.forEach(lcn => {
        const existingLsInLcn = lineupServices.some(ls => ls.lcns.indexOf(lcn) !== -1);

        if (existingLsInLcn) {
          if (lcnsError.length === 0) {
            lcnsError = `These LCN(s) are already taken: ${lcn}`;
          } else {
            lcnsError += `, ${lcn}`;
          }
        }
      });

      return lcnsError;
    }

    return "";
  }

  stepIsValid() {
    return this.isParentIdValid() && this.isLcnsValid() && this.isLcnsTaken() === "";
  }

  modalContent() {
    const { parent_id, lcns } = this.state;

    const {
      editorialChannels,
      parentLineupServices,
    } = this.props;

    let inheritedLineupService = null;
    let editorialChannelLogo = null;
    let editorialChannelName = null;

    if (parent_id) {
      inheritedLineupService = parentLineupServices.find(ls => ls.id === parseInt(parent_id, 10));

      const editorialChannel = editorialChannels.find(ec => inheritedLineupService.service && ec.id === inheritedLineupService.service.channel_id);

      editorialChannelName = getObjectNameFromLocales(editorialChannel);

      if (!!editorialChannel && !!editorialChannel.logos && !!editorialChannel.logos.length && !!editorialChannel.logos[0].file_url) {
        editorialChannelLogo =
          <div className="table-item-logo" style={{ display: "flex" }}>
            <img style={{ width: "100px", alignSelf: "center" }} src={editorialChannel.logos[0].file_url} alt="" />
          </div>;
      }
    }

    const sortedParentLineupServices = [...parentLineupServices].sort((a, b) => sortObjectByLocale(a, b, "service"));

    return <DialogContent sx={{ display: "flex", gap: 3, flexDirection: "column", marginTop: 1, width: "80%", alignItems: "center", alignSelf: "center" }}>
      <Alert severity="info">
        You can extend a parent lineup service into this service. Except LCN(s), that ou have to define, all attributes of the parent service will be inherited by this new service
      </Alert>
      <FormControl required error={false} fullWidth>
        <InputLabel id="parent-service-select">Select a parent lineup service</InputLabel>
        <Select
          labelId="parent-service-select"
          label="Select a parent lineup service"
          value={parent_id || ""}
          onChange={e => this.changeInput("parent_id", e)}
        >
          {sortedParentLineupServices.map(ls => <MenuItem key={ls.id} value={ls.id}>
            {getObjectNameFromLocales(ls.service)}&nbsp;
              (parent LCN[{ls.lcns.join(",")}] / Genre:&nbsp;{ls?.genres?.map(g => getObjectNameFromLocales(g)).join(", ")})
          </MenuItem>
          )}
        </Select>
        { !this.isParentIdValid() && <FormHelperText error>Parent Service is invalid</FormHelperText> }
      </FormControl>
      <Box>
        <TextField
          label="Child LCNS"
          value={lcns || ""}
          error={false}
          onChange={e => this.changeInput("lcns", e)}
          required
          fullWidth
        />
        { !this.isLcnsValid() && <FormHelperText error>LCNs are not valid</FormHelperText> }
        { this.isLcnsTaken() !== "" && <FormHelperText error>{this.isLcnsTaken()}</FormHelperText> }
      </Box>
      {!!parent_id && !!lcns && <Table>
        <TableHead>
          <TableRow>
            <TableCell>Preview</TableCell>
            <TableCell>Service Name</TableCell>
            <TableCell>Editorial Channel</TableCell>
            <TableCell>LCN(s)</TableCell>
            <TableCell>Genres</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ display: "flex", gap: 1 }}><div className="familyLineup inheritedLineupLight">inherit</div>{editorialChannelLogo}</TableCell>
            <TableCell>{inheritedLineupService.service && inheritedLineupService.service.name}</TableCell>
            <TableCell>{editorialChannelName}</TableCell>
            <TableCell>{lcns}</TableCell>
            <TableCell>{inheritedLineupService.genres.map(g => <span key={g}>{getObjectNameFromLocales(g)}<br /></span>)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>}
    </DialogContent>;
  }

  modalActions() {
    return (
      [
        <Button
          variant="outlined"
          key="cancel"
          onClick={() => {
            this.closeModal();
          }}>
          Cancel
        </Button>,
        <Button
          variant="contained"
          color="secondary"
          key="save"
          disabled={!this.stepIsValid()}
          onClick={() => {
            this.addChannelToLineup();
          }}>
          Save
        </Button>
      ]
    );
  }

  render() {
    const {
      modalOpened,
      lineup
    } = this.props;

    const currentLineupName = getObjectNameFromLocales(lineup);

    return (
      <Modal
        closeModal={() => this.closeModal()}
        isOpen={modalOpened}
        title={`Extend a parent lineup service into this lineup "${currentLineupName}"`}
        customModalContent={() => this.modalContent()}
        actions={() => this.modalActions()}
      />
    );
  }
}

AddInheritedLineupServiceModal.defaultProps = {
  currentLineupId: null,
  predefinedLCNS: "",
  lineup: null,
  parentLineupServiceId: null,
  parentLineupId: null,
  lineupServices: [],
  parentLineupServices: [],
  editorialChannels: []
};

AddInheritedLineupServiceModal.propTypes = {
  modalOpened: PropTypes.bool.isRequired,

  // Used in mapStateToProps
  currentLineupId: PropTypes.number,
  // eslint-disable-next-line react/no-unused-prop-types
  parentLineupId: PropTypes.number,
  parentLineupServiceId: PropTypes.number,

  predefinedLCNS: PropTypes.string,

  lineup: PropTypes.object,

  lineupServices: PropTypes.arrayOf(PropTypes.object),
  parentLineupServices: PropTypes.arrayOf(PropTypes.object),
  editorialChannels: PropTypes.arrayOf(PropTypes.object),

  closeLineupServiceModal: PropTypes.func.isRequired,
  createLineupsService: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    lineup: showLineup(state, ownProps.currentLineupId),
    lineupServices: listLineupsServicesByLineupId(state, ownProps.currentLineupId),
    parentLineupServices: listLineupsServicesByLineupId(state, ownProps.parentLineupId),
    editorialChannels: listEditorialChannels(state)
  };
}

const mapDispatchToProps = {
  createLineupsService: createLineupsServiceAction,
  addToast: addToastAction
};

export default connect(mapStateToProps, mapDispatchToProps)(AddInheritedLineupServiceModal);
