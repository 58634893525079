/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Box, DialogContent, Button, Alert, TextField } from "@mui/material";
import Modal from "../Modal/Modal";
import { addToast as addToastAction } from "../../actions/toasts";
import withStepper from "../Modal/withStepper";
import {
  createServiceType as createServiceTypeAction
} from "../../actions/service_types";

const initialState = {
  name: "",
};

class AddServiceTypeModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { ...initialState };
  }

  changeInput(inputName, event) {
    const { value } = event.target;

    this.setState(() => ({ [inputName]: value }));
  }

  async addServiceType() {
    const {
      name
    } = this.state;

    const {
      createServiceType,
      addToast
    } = this.props;

    const ret = await createServiceType({
      name
    });

    if (ret.isSuccess) {
      addToast("success", "Success", "Service Type successfully added!");
      this.closeModal();
    } else {
      addToast("error", "Error", "Error while adding Service Type. Try again.");
    }
  }

  closeModal() {
    const { closeAddModal } = this.props;

    this.setState(initialState);
    closeAddModal();
  }

  modalContent() {
    const { name } = this.state;

    const { isLastStep, stepperComponent } = this.props;

    return (
      <DialogContent sx={{ display: "flex", gap: 3, flexDirection: "column", marginTop: 1, width: "80%", alignItems: "center", alignSelf: "center" }}>
        {stepperComponent}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          {name?.length === 0 && <Alert severity="error">Name is not valid</Alert>}
          <TextField label="Name" inputProps={{ readOnly: isLastStep }} error={name?.length === 0} value={name} onChange={e => this.changeInput("name", e)}/>
        </Box>
      </DialogContent>
    );
  }

  modalActions() {
    const { name } = this.state;
    const { isLastStep, currentStep, nextStep, previousStep } = this.props;

    return (
      [
        <Button variant="outlined"
          key="cancel"
          onClick={() => {
            this.closeModal();
          }} >
            Cancel
        </Button>,
        currentStep > 0 && <Button variant="outlined"
          key="previous"
          onClick={() => {
            previousStep();
          }}>
            Previous
        </Button>,
        !isLastStep && <Button variant="contained"
          key="next"
          disabled={name?.length === 0}
          color="secondary"
          onClick={() => {
            nextStep();
          }}>
            Next
        </Button>,
        isLastStep && <Button variant="contained"
          key="save"
          color="secondary"
          onClick={() => {
            this.addServiceType();
          }}>
            Save
        </Button>
      ]
    );
  }

  render() {
    const { modalOpened } = this.props;

    return (
      <Modal
        isOpen={modalOpened}
        closeModal={() => this.closeModal()}
        title="Add service type"
        customModalContent={() => this.modalContent()}
        actions={() => this.modalActions()}
        actionAlign="flex-end"
      >
      </Modal>
    );
  }
}

AddServiceTypeModal.propTypes = {
  modalOpened: PropTypes.bool.isRequired,
  closeAddModal: PropTypes.func.isRequired,

  currentStep: PropTypes.number,
  previousStep: PropTypes.func,
  nextStep: PropTypes.func,
  stepperComponent: PropTypes.object,
  isLastStep: PropTypes.bool,

  createServiceType: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  createServiceType: createServiceTypeAction,
  addToast: addToastAction
};

export default connect(null, mapDispatchToProps)(withStepper(
  AddServiceTypeModal, [
    "Set service type information",
    "Sumup"
  ]));
