/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Box, DialogContent, Button, Alert, TextField } from "@mui/material";
import Modal from "../Modal/Modal";

import { addToast as addToastAction } from "../../actions/toasts";

import {
  updateServiceType as updateServiceTypeAction
} from "../../actions/service_types";
import { showServiceType } from "../../selectors/service_types";
import withStepper from "../Modal/withStepper";

import { isLoading as isLoadingSelector } from "../../selectors/loaders";

const initialState = {
  name: "",
};

class EditServiceTypeModal extends PureComponent {
  constructor(props) {
    super(props);

    const state = { ...initialState };

    if (props.serviceType) {
      const {
        name
      } = props.serviceType;
      this.state = { ...state, name: name };
    }
  }

  componentDidUpdate(prevProps) {
    const { serviceType } = this.props;

    if (prevProps.serviceType !== serviceType && serviceType !== null) {
      const {
        name
      } = serviceType;

      this.setState({
        name: name || initialState.name
      });
    }
  }

  changeInput(inputName, event) {
    const { value } = event.target;

    this.setState(() => ({ [inputName]: value }), this.validateStep);
  }

  async editServiceType() {
    const { currentId } = this.props;

    const {
      name
    } = this.state;

    const { updateServiceType, addToast } = this.props;

    const ret = await updateServiceType(currentId, {
      name
    });

    if (ret.isSuccess) {
      addToast("success", "Success", "Service Type successfully updated!");
      this.closeModal();
    } else {
      addToast("error", "Error", "Error while updating Service Type. Try again.");
    }
  }

  closeModal() {
    const { closeEditModal } = this.props;

    this.setState(initialState);
    closeEditModal();
  }

  modalContent() {
    const { name } = this.state;

    const { isLastStep, stepperComponent } = this.props;

    return (
      <DialogContent sx={{ display: "flex", gap: 3, flexDirection: "column", marginTop: 1, width: "80%", alignItems: "center", alignSelf: "center" }}>
        {stepperComponent}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          {name?.length === 0 && <Alert severity="error">Name is not valid</Alert>}
          <TextField label="Name" inputProps={{ readOnly: isLastStep }} error={name?.length === 0} value={name} onChange={e => this.changeInput("name", e)}/>
        </Box>
      </DialogContent>
    );
  }

  modalActions() {
    const { name } = this.state;
    const { currentStep, previousStep, nextStep, isLastStep } = this.props;

    return (
      [
        <Button variant="outlined"
          key="cancel"
          onClick={() => {
            this.closeModal();
          }} >
            Cancel
        </Button>,
        currentStep > 0 && <Button variant="outlined"
          key="previous"
          onClick={() => {
            previousStep();
          }}>
            Previous
        </Button>,
        !isLastStep && <Button variant="contained"
          key="next"
          disabled={name?.length === 0}
          color="secondary"
          onClick={() => {
            nextStep();
          }}>
            Next
        </Button>,
        isLastStep && <Button variant="contained"
          key="save"
          color="secondary"
          onClick={() => {
            this.editServiceType();
          }}>
            Save
        </Button>
      ]
    );
  }

  render() {
    const { modalOpened } = this.props;
    const { serviceType } = this.props;

    return (
      <Modal
        isOpen={modalOpened}
        closeModal={() => this.closeModal()}
        title={"Edit service type " + serviceType?.name }
        customModalContent={() => this.modalContent()}
        actions={() => this.modalActions()}
        actionAlign="flex-end"
      >
      </Modal>
    );
  }
}

EditServiceTypeModal.defaultProps = {
  currentId: null
};

EditServiceTypeModal.propTypes = {
  currentId: PropTypes.number,

  serviceType: PropTypes.object.isRequired,

  currentStep: PropTypes.number,
  previousStep: PropTypes.func,
  nextStep: PropTypes.func,
  stepperComponent: PropTypes.object,
  isLastStep: PropTypes.bool,

  modalOpened: PropTypes.bool.isRequired,
  closeEditModal: PropTypes.func.isRequired,

  updateServiceType: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    serviceType: showServiceType(state, ownProps.currentId) || {},

    isServiceTypeEditing: isLoadingSelector(state, updateServiceTypeAction.toString())
  };
}

const mapDispatchToProps = {
  updateServiceType: updateServiceTypeAction,
  addToast: addToastAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withStepper(
  EditServiceTypeModal, [
    "Set service type information",
    "Sumup"
  ]));
