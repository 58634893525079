import { createAction } from "redux-actions";

/* Storage actions */
const storeTransponders = createAction("STORE_TRANSPONDERS");
const storeTranspondersFilters = createAction("STORE_TRANSPONDERS_FILTERS");

export const addTransponder = createAction("ADD_TRANSPONDER");

const modifyTransponder = createAction("MODIFY_TRANSPONDER");

/* API actions */
export const indexTransponders = createAction("API_INDEX_TRANSPONDERS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/transponders",
  data,
  fullLoad,
  onSuccessAction: storeTransponders
}));

export const indexTranspondersFilters = createAction("API_INDEX_TRANSPONDERS_FILTERS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/transponders/filters",
  data,
  fullLoad,
  onSuccessAction: storeTranspondersFilters
}));

export const createTransponder = createAction("API_CREATE_TRANSPONDER", data => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "POST",
  url: "/transponders",
  data,
  onSuccessAction: addTransponder
}));

export const updateTransponder = createAction("API_UPDATE_TRANSPONDER", (transponderId, data) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "PUT",
  url: `/transponders/${transponderId}`,
  data,
  onSuccessAction: modifyTransponder
}));

export const deleteTransponder = createAction("API_DELETE_TRANSPONDER", transponderId => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "DELETE",
  url: `/transponders/${transponderId}`,
  onSuccessAction: createAction("REMOVE_TRANSPONDER", () => ({ id: transponderId }))
}));
