import React, { useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLifeRing, faBell } from "@fortawesome/free-regular-svg-icons";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import RefreshIcon from "@mui/icons-material/Refresh";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import {
  Badge,
  Button,
  Chip,
  Link,
  Stack,
  Typography,
  Popover,
} from "@mui/material";

import FullLoader from "../FullLoader";

import { indexProvidersWordings as indexProvidersWordingsAction } from "../../actions/providers_wordings";
import { indexQualifications as indexQualificationsAction } from "../../actions/qualifications";
import { listQualifications as listQualificationsSelector } from "../../selectors/qualifications";
import { listProvidersWordings as listProvidersWordingsSelector } from "../../selectors/providers_wordings";
import { listTechChannels as listTechChannelsSelector } from "../../selectors/tech_channels";
import { listOrbitalPositions as listOrbitalPositionsSelector } from "../../selectors/orbital_positions";

import { isLoading as isLoadingSelector } from "../../selectors/loaders";

import { stringifyQualificationType, groupBy } from "../../helpers/utils";

const APP_NAME = "SatTv Platform";

const Header = ({
  pageName,
  qualifications,
  providersWordings,
  techChannels,
  orbitalPositions,
  indexQualifications,
  indexProvidersWordings,
  isLoadingQualifications,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPopover, setCurrentPopover] = useState(null);

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setCurrentPopover(id);
  };

  const toggleNotifications = () => {
    setShowPopup(!showPopup);
  };

  const refreshQualifications = () => {
    indexQualifications({ discarded: false }, true);
    indexProvidersWordings({}, true);
  };

  const qualifByType = (qualifs, type) => qualifs.filter(q => q.data_type === type);

  // Update title
  document.title = pageName ? `${pageName} - ${APP_NAME}` : APP_NAME;

  const toTransferTechChannels = orbitalPosId => techChannels.filter(tc => tc.status === "transfer" && tc.orbital_position_id === orbitalPosId);
  const nullProvidersWordings = providersWordings.filter(pw => !pw.genre_id);
  const qualifByOrbitalPos = groupBy(qualifications, "orbital_position_id");
  const qualifTypes = Object.keys(groupBy(qualifications, "data_type"));

  return (
    <Stack
      id="header"
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={ 2 }
      sx={{ px: 4, py: 2, backgroundColor: "primary.smoke", position: "sticky", top: 0, zIndex: 10 }} >
      <Typography color="primary.canard" sx={{ marginRight: "auto" }}>
        EVMM > <Typography component="span" fontWeight="700">{ pageName }</Typography>
      </Typography>
      <Stack direction="row" alignItems="center" spacing={3}>
        { orbitalPositions.map(orbitalPosition => {
          const orbitalPositionId = orbitalPosition.id;
          const qualifs = qualifByOrbitalPos[orbitalPositionId];
          if (!qualifs || !qualifs.length) {
            return false;
          }

          return <div key={orbitalPositionId}>
            <Badge badgeContent={ qualifs.length } color="secondary" onClick={e => handleClick(e, orbitalPositionId)}>
              <Chip sx={{ textDecoration: "none", cursor: "pointer" }} label={orbitalPosition.name} size="small" className="pointer" />
            </Badge>

            <Popover
              onClose={handleClick}
              open={currentPopover === orbitalPositionId}
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ p: 4, pb: 2 }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={ 2 }
                  sx={{ mr: "auto" }}>
                  <Typography variant="h4" component="span" >Notifications</Typography>
                  {qualifs.length > 0 && <Chip color="secondary" size="small" label={ qualifs.length } />}
                </Stack>
                <RefreshIcon onClick={refreshQualifications} />
              </Stack>
              <Stack sx={{ p: 4, pt: 0 }} onClick={handleClick}>
                { isLoadingQualifications
                  ? <FullLoader />
                  : <Stack sx={{ py: 1 }}>
                    { qualifs.length === 0
                      ? <Typography variant="body2" align="center">No notification</Typography>
                      : <>
                        {!!toTransferTechChannels(orbitalPositionId).length
                          && <Link
                            component={RouterLink}
                            sx={{ my: 1, textDecoration: "none", "&:hover": { textDecoration: "underline" } }}
                            to={`/technical_channels/transfer?orbital_position=${orbitalPosition.id}`}>
                            <Typography paragraph>
                              <Typography component="span" fontWeight="bold">{ toTransferTechChannels(orbitalPositionId).length }</Typography>&nbsp;
                              {`"to transfer" technical channel${toTransferTechChannels(orbitalPositionId).length > 1 ? "s" : ""} to proceed`}
                            </Typography>
                          </Link>
                        }
                        {qualifTypes.map(type => {
                          const curQualifs = qualifByType(qualifs, type);

                          return <Link
                            component={RouterLink}
                            sx={{ my: 1, textDecoration: "none", "&:hover": { textDecoration: "underline" } }}
                            key={ type }
                            to={`/notifications/${type.toLowerCase()}?orbital_position=${orbitalPosition.id}`}>
                            <Typography paragraph>
                              <Typography component="span" fontWeight="bold">{ curQualifs.length }</Typography>&nbsp;
                              {`change${curQualifs.length > 1 ? "s" : ""} on ${type} to proceed`}
                            </Typography>
                          </Link>;
                        })}
                      </>
                    }
                  </Stack>
                }
              </Stack>
            </Popover>
          </div>;
        })}
        {!!nullProvidersWordings.length
          && <Link
            component={RouterLink}
            sx={{ my: 1, textDecoration: "none" }}
            to="/notifications/providers_wordings">
            <Badge badgeContent={nullProvidersWordings.length} color="secondary">
              <Chip sx={{ cursor: "pointer" }} label="Wordings" size="small" />
            </Badge>
          </Link>
        }
        <Button variant="contained" color="secondary" href={process.env.REACT_APP_SATTV_ENDPOINT + "/logout"} sx={{ my: 1 }}>
          Logout
        </Button>
      </Stack>
    </Stack>
  );
};

Header.propTypes = {
  pageName: PropTypes.string.isRequired,
  qualifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  providersWordings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  techChannels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  orbitalPositions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  indexQualifications: PropTypes.func.isRequired,
  indexProvidersWordings: PropTypes.func.isRequired,
  isLoadingQualifications: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    qualifications: listQualificationsSelector(state, { discarded: false }),
    providersWordings: listProvidersWordingsSelector(state),
    techChannels: listTechChannelsSelector(state),
    orbitalPositions: listOrbitalPositionsSelector(state),
    isLoadingQualifications: isLoadingSelector(state, indexQualificationsAction.toString())
                              || isLoadingSelector(state, indexProvidersWordingsAction.toString())
  };
}

const mapDispatchToProps = {
  indexQualifications: indexQualificationsAction,
  indexProvidersWordings: indexProvidersWordingsAction
};

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
